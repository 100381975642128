import List, { Item } from '../../IndentedList'
import SafeText from '../../SafeText'
import { getDate, joinFields, joinFieldsToArray } from '../../Utils/helper'
import ExperienceValueMapper from './ExperienceValueMapper'

class CityExperienceValueMapper extends ExperienceValueMapper {
  title() {
    const { location } = this.experience
    const locationText =
      location && joinFields(', ', location.city, location.region)
    return joinFieldsToArray(
      <SafeText val={true} style={this.styles.titleSecondary}>
        ,{' '}
      </SafeText>,
      this.experience.company,
      locationText && (
        <SafeText val={locationText} style={this.styles.titleSecondary}>
          {locationText}
        </SafeText>
      )
    )
  }

  subtitle() {
    const { startDate, endDate, present } = this.experience
    return getDate(startDate, endDate, present)
  }

  subheader() {
    return this.experience.position
  }

  children() {
    const { highlights } = this.experience
    return (
      highlights && (
        <List>
          {highlights.map((detail, i) => (
            <Item styles={this.styles} bullet={true} key={i}>
              {detail}
            </Item>
          ))}
        </List>
      )
    )
  }
}

export default CityExperienceValueMapper
