import React from 'react'
import { Navigate, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const PrivateRoute = ({ children, auth }) => {
  if (!auth.isAuthenticated) {
    return <Navigate to="/auth/login" />
  }
  return <>{children}</>
}

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(PrivateRoute)
