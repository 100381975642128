import {
  ArrowNarrowRightIcon,
  CogIcon,
  LightningBoltIcon,
  SparklesIcon,
  UserCircleIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, NavLink, Outlet } from 'react-router-dom'
import { classNames } from 'utils/helper'
import Navbar from './Navbar'
import { navigation } from './common'

const NavItem = ({ path, Icon, name, isComingSoon = false, isNew = false }) => {
  return (
    <NavLink
      to={isComingSoon ? null : path}
      id={name}
      className={({ isActive }) =>
        classNames(
          !isComingSoon && isActive ? 'bg-gray-100' : '',
          'flex items-center py-1.5 px-3 rounded space-x-2 hover:bg-gray-100'
        )
      }
    >
      <Icon className="h-4 w-4"></Icon>
      <div className="flex-1 font-semibold text-sm flex items-center justify-between gap-1">
        {name}
        {isComingSoon && (
          <span className="text-xs rounded-full py-0.5 px-2 bg-blue-200 font-medium">
            Coming soon
          </span>
        )}
        {isNew && (
          <span className="text-xs rounded-full py-0.5 px-2 bg-yellow-200 font-medium">
            New
          </span>
        )}
      </div>
    </NavLink>
  )
}

const UserDropdown = ({}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { user } = useSelector((state) => state.auth)

  const handleBlur = (e) => {
    if (e.relatedTarget?.id !== 'logout') {
      setIsOpen(false)
    }
  }

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div
      className={classNames(
        'relative rounded flex flex-col gap-1 transition-all'
      )}
    >
      {isOpen && (
        <div className="absolute bottom-full pb-1 border border-b-0 rounded-t w-full bg-white flex flex-col gap-1">
          <Link
            id="logout"
            to="/auth/logout"
            className="w-full flex items-center py-1.5 px-3 rounded space-x-2 hover:bg-gray-100"
          >
            <div className="flex-1 font-semibold text-sm flex items-center justify-between gap-1 text-gray-500">
              Log out
              <ArrowNarrowRightIcon className="h-4 w-4"></ArrowNarrowRightIcon>
            </div>
          </Link>
        </div>
      )}
      <button
        onBlur={handleBlur}
        onClick={handleToggle}
        type="button"
        className={classNames(
          isOpen ? 'border-gray-300 rounded-b' : 'border-transparent rounded',
          'border w-full flex items-center py-1.5 px-3 space-x-2 hover:bg-gray-100'
        )}
      >
        <UserCircleIcon className="h-4 w-4"></UserCircleIcon>
        <div className="flex-1 font-semibold text-sm flex items-center justify-between gap-1">
          {user.fullName}
          {isOpen ? (
            <ChevronDownIcon className="w-4 h-4" />
          ) : (
            <ChevronUpIcon className="w-4 h-4" />
          )}
        </div>
      </button>
    </div>
  )
}

const Sidebar = () => {
  const { user } = useSelector((state) => state.auth)
  const { premium } = user
  return (
    <div className="hidden flex-none md:flex flex-col w-64 h-full bg-white items-start px-3 pt-1 pb-3 border-r">
      <div className="h-16 w-full bg-white flex items-center justify-start px-3 border-b">
        <Link to="/dashboard/resumes" className="flex items-center space-x-2">
          {/* <div className="h-4 w-4 bg-indigo-600 rounded"></div> */}
          <img
            src={require('assets/img/R_logo_round.png')}
            alt="logo"
            className="w-5 h-5"
          />
          <div className="font-bold text-gray-800 text-lg font-raleway">
            Resumary
          </div>
        </Link>
      </div>
      <div className="flex flex-col divide-y w-full">
        {navigation.map((section, idx) => (
          <div
            key={idx}
            className="flex flex-col gap-1 py-4 w-full text-gray-900"
          >
            {section.map((item, idx) => (
              <NavItem
                key={idx}
                path={item.to}
                Icon={item.Icon}
                name={item.name}
                isComingSoon={item.isComingSoon}
              />
            ))}
          </div>
        ))}
        <div className="flex flex-col gap-1 py-4 w-full text-gray-900">
          {premium ? (
            <div className="select-none flex items-center gap-2 py-1.5 px-3 rounded border border-indigo-600 text-indigo-600 group">
              <SparklesIcon className="h-4 w-4"></SparklesIcon>
              <div className="flex-1 font-semibold text-sm">
                Premium account
              </div>
            </div>
          ) : (
            <NavLink
              to="/dashboard/upgrade"
              className="flex items-center gap-2 py-1.5 px-3 rounded hover:bg-blue-700 hover:text-white border border-blue-600 text-blue-600 transition-all group"
            >
              <SparklesIcon className="h-4 w-4"></SparklesIcon>
              <div className="flex-1 font-semibold text-sm">Upgrade</div>
              <ArrowNarrowRightIcon className="h-4 w-4 mr-1 duration-300 group-hover:mr-0"></ArrowNarrowRightIcon>
            </NavLink>
          )}
        </div>
      </div>
      <div className="flex-1"></div>

      <div className="flex flex-col gap-1 py-4 w-full text-gray-900">
        <UserDropdown />
        <a
          href="https://chrome.google.com/webstore/detail/save-linkedin-jobs-to-she/mkjedhephckpdnpadogmilejdbgbbdfm?hl=en&utm_source=website&utm_medium=dashboard&utm_campaign=promote"
          target="_blank"
          className="flex items-center space-x-2 py-1.5 px-3 rounded hover:bg-gray-100"
          rel="noreferrer"
        >
          <LightningBoltIcon className="h-4 w-4"></LightningBoltIcon>
          <div className="font-semibold text-sm">Chrome Extension</div>
        </a>
        {/* <a
            href="/faq"
            target="_blank"
            className="flex items-center space-x-2 py-1.5 px-3 rounded hover:bg-gray-100"
          >
            <QuestionMarkCircleIcon className="h-4 w-4"></QuestionMarkCircleIcon>
            <div className="font-semibold text-sm">Help</div>
          </a> */}
        <NavItem path="/dashboard/settings" Icon={CogIcon} name="Settings" />
      </div>
    </div>
  )
}

export default function StandardDashboardLayout() {
  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <Navbar />
      <div className="h-full flex flex-1 overflow-y-auto sm:overflow-y-hidden">
        <Sidebar />
        <Outlet />
      </div>
    </div>
  )
}
