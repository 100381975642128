import ActionTypes from '../actions/types'

const initialState = {
  previewResume: undefined,
  resume: undefined,
  resumes: undefined,
  loading: false,
  template: undefined,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_RESUME:
      return {
        ...state,
        resume: action.payload,
      }
    case ActionTypes.SET_RESUMES:
      return {
        ...state,
        resumes: action.payload,
      }
    case ActionTypes.SET_RESUME_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}
