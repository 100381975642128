export const resume = {
  basics: {
    firstName: 'Nina',
    lastName: 'Chen',
    title: 'Data Scientist',
    picture: 'https://example.com/profile_picture.jpg',
    email: 'ninachen@email.com',
    phone: '123-456-7890',
    url: 'https://ninachen.com',
    summary:
      'Experienced Data Scientist with a strong background in statistical analysis, machine learning, and data visualization. Proven track record of extracting actionable insights from complex data sets.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'CA',
    },
    linkedInUrl: 'https://www.linkedin.com/in/ninachen',
    portfolioUrl: 'https://github.com/ninachen',
  },
  experience: [
    {
      company: 'Google',
      location: {
        city: 'Mountain View',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'Data Scientist',
      url: 'https://www.google.com',
      startDate: '2020-01-01',
      endDate: '2023-07-31',
      summary:
        'Analyzed large-scale data sets to derive actionable insights and drive data-driven decision-making across the organization.',
      highlights: [
        'Designed and implemented machine learning models using Python and scikit-learn, resulting in a 20% increase in click-through rates for targeted advertisements.',
        'Collaborated with cross-functional teams to identify key business questions and develop data-driven solutions, leading to a 15% improvement in user engagement.',
        'Presented findings and insights to non-technical stakeholders using data visualizations and clear storytelling techniques.',
      ],
      present: false,
    },
    {
      company: 'Amazon',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'WA',
      },
      position: 'Data Analyst',
      url: 'https://www.amazon.com',
      startDate: '2017-06-01',
      endDate: '2019-12-31',
      summary:
        'Conducted exploratory data analysis and generated actionable insights to support business operations and strategy.',
      highlights: [
        'Extracted and transformed data from various sources using SQL and Python, enabling efficient analysis and reporting.',
        'Developed and maintained interactive dashboards using Tableau, facilitating real-time monitoring and decision-making.',
        'Collaborated with business stakeholders to define KPIs and metrics, leading to improved tracking and measurement of key business goals.',
      ],
      present: false,
    },
    {
      company: 'DataTech Solutions',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'Data Science Intern',
      url: 'https://www.datatechsolutions.com',
      startDate: '2015-02-15',
      endDate: '2015-05-31',
      summary:
        'Assisted in the development of machine learning models and data analysis projects, contributing to client deliverables.',
      highlights: [
        'Cleaned and preprocessed data using Python and pandas, ensuring high-quality data inputs for analysis.',
        'Supported senior data scientists in model training and evaluation, contributing to the successful deployment of predictive models.',
        'Participated in client meetings to gather requirements and present analysis findings, enhancing client satisfaction and project outcomes.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Statistics',
      major: 'Statistics',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'CA',
      },
      startDate: '2013-09-01',
      endDate: '2015-05-15',
      gpa: '3.9',
      courses: [
        'Machine Learning',
        'Statistical Modeling',
        'Data Visualization',
      ],
      summary: 'Graduated with Honors',
      present: false,
    },
  ],
  skills: [
    {
      skillGroup: 'Programming Languages',
      skill: [
        {
          name: 'Python',
        },
        {
          name: 'R',
        },
        {
          name: 'SQL',
        },
      ],
    },
    {
      skillGroup: 'Data Analysis',
      skill: [
        {
          name: 'Exploratory Data Analysis',
        },
        {
          name: 'Statistical Testing',
        },
        {
          name: 'Data Cleaning',
        },
      ],
    },
    {
      skillGroup: 'Machine Learning',
      skill: [
        {
          name: 'Regression Analysis',
        },
        {
          name: 'Classification',
        },
        {
          name: 'Clustering',
        },
      ],
    },
    {
      skillGroup: 'Data Visualization',
      skill: [
        {
          name: 'Tableau',
        },
        {
          name: 'Matplotlib',
        },
        {
          name: 'Seaborn',
        },
      ],
    },
    {
      skillGroup: 'Big Data Technologies',
      skill: [
        {
          name: 'Hadoop',
        },
        {
          name: 'Spark',
        },
        {
          name: 'Hive',
        },
      ],
    },
    {
      skillGroup: 'Version Control',
      skill: [
        {
          name: 'Git',
        },
        {
          name: 'SVN',
        },
      ],
    },
  ],
  projects: [
    {
      name: 'Customer Segmentation',
      description:
        'Segmented customer base using unsupervised learning techniques, resulting in targeted marketing campaigns and a 10% increase in conversion rates.',
      startDate: '2019-02-01',
      endDate: '2020-05-31',
      present: false,
    },
    {
      name: 'Predictive Maintenance',
      description:
        'Developed a predictive maintenance model for manufacturing equipment, reducing unplanned downtime by 15%.',
      startDate: '2017-08-15',
      endDate: '2018-12-31',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Excellence in Data Science',
      date: '2022-03-10',
      awarder: 'Google',
      summary:
        'Recognized for outstanding contributions to data science and innovative solutions.',
    },
    {
      title: 'Analytics Achievement Award',
      date: '2018-10-18',
      awarder: 'Amazon',
      summary:
        'Received for impactful data analysis and insights that contributed to business growth.',
    },
  ],
  customSections: [],
}
