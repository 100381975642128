import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { connect, useDispatch, useSelector } from 'react-redux'

import { logoutUser } from 'actions/authActions'

function Logout() {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(logoutUser())
  })

  if (!auth.isAuthenticated) {
    return <Navigate to="/" />
  }
  return <></>
}

export default Logout
