import { memo, useEffect, useRef, useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { classNames } from 'utils/helper'

const LoadingDisplay = () => (
  <div className={classNames('bg-white mx-auto aspect-w-17 aspect-h-22')}>
    <div className="p-[8%] animate-pulse h-full">
      <div className="bg-gray-300 h-[3.5%] w-1/2 rounded mb-[1%]"></div>
      <div className="bg-gray-300 h-[1.5%] w-1/4 rounded mb-[1%]"></div>
      <div className="bg-gray-300 h-[1%] w-3/4 rounded mb-[3%]"></div>
      <div className="bg-gray-300 h-[1.5%] w-1/6 rounded mb-[3%]"></div>
      <div className="bg-gray-300 h-[1.5%] w-1/3 rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-full rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-3/4 rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-full rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-3/4 rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-full rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-3/4 rounded mb-[3%]"></div>
      <div className="bg-gray-300 h-[1.5%] w-1/6 rounded mb-[3%]"></div>
      <div className="bg-gray-300 h-[1.5%] w-1/3 rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-full rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-3/4 rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-full rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-3/4 rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-full rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-3/4 rounded mb-[3%]"></div>
      <div className="bg-gray-300 h-[1.5%] w-1/6 rounded mb-[3%]"></div>
      <div className="bg-gray-300 h-[1.5%] w-1/3 rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-full rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-3/4 rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-full rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-3/4 rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-full rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-3/4 rounded mb-[3%]"></div>
      <div className="bg-gray-300 h-[1.5%] w-1/6 rounded mb-[3%]"></div>
      <div className="bg-gray-300 h-[1.5%] w-1/3 rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-full rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-3/4 rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-full rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-3/4 rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-full rounded mb-[.5%]"></div>
      <div className="bg-gray-300 h-[1%] w-3/4 rounded mb-[3%]"></div>
    </div>
  </div>
)

const PdfPages = ({ singlePage, numPages, myRef }) => {
  const [width, setWidth] = useState(null)

  useEffect(() => {
    const element = myRef?.current
    if (!element) return
    const observer = new ResizeObserver(() => {
      setWidth(myRef.current?.getBoundingClientRect().width)
    })

    observer.observe(element)
    return () => {
      observer.disconnect()
    }
  }, [])

  if (!numPages) return null
  return (
    <>
      {new Array(numPages).fill().map((item, index) => {
        if (singlePage && index > 0) {
          return null
        }
        return (
          <div key={index} className="">
            <div className="bg-white">
              <Page
                loading={LoadingDisplay}
                noData={LoadingDisplay}
                key={`page_${index + 1}`}
                className=""
                width={width}
                pageNumber={index + 1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </div>
          </div>
        )
      })}
    </>
  )
}

const PdfViewer = (props) => {
  const [numPages, setNumPages] = useState(0)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }
  return (
    <Document
      file={props.url}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={LoadingDisplay}
      noData={LoadingDisplay}
      className="flex flex-col gap-4"
    >
      <PdfPages
        singlePage={props.singlePage}
        numPages={numPages}
        myRef={props.myRef}
      />
    </Document>
  )
}

const MemoizedPdfViewer = memo(PdfViewer)

const PageViewer = (props) => {
  return (
    <MemoizedPdfViewer
      singlePage={props.singlePage}
      url={props.resume.url}
      myRef={props.myRef}
    />
  )
}

export default function Viewer(props) {
  const ref = useRef(null)

  return (
    <div ref={ref} className={`${props.scale ? 'mx-auto w-4/5' : ''}`}>
      {props.resume ? (
        <PageViewer
          singlePage={props.singlePage}
          resume={props.resume}
          myRef={ref}
        />
      ) : (
        <LoadingDisplay />
      )}
    </div>
  )
}
