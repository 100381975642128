import { Outlet, useLocation, useMatch } from 'react-router-dom'

import Navbar from 'marketing/components/Navbar'
import Footer from 'marketing/components/Footer'
import { classNames } from 'utils/helper'

export default function Landing() {
  const { pathname } = useLocation()
  var bgColor = 'bg-white'
  var navbarBgColor = 'bg-transparent'
  switch (true) {
    case pathname === '/':
      bgColor = 'bg-blueGray-50'
      break
    case pathname === '/resume-builder':
      navbarBgColor = 'bg-gray-50'
      break
    case pathname === '/pricing':
      navbarBgColor = 'bg-blue-50'
      break
    case pathname.startsWith('/resume-templates'):
      navbarBgColor = 'bg-indigo-50'
      break
    case pathname === '/resume-examples':
    case pathname === '/resume-examples/software-engineer':
      navbarBgColor = 'bg-blue-100'
      break
    default:
      break
  }
  return (
    <div
      className={classNames(
        bgColor,
        'flex flex-col min-h-screen overflow-hidden'
      )}
    >
      <main className="flex-grow">
        <Navbar bgColor={navbarBgColor} />
        <Outlet />
        <Footer />
      </main>
    </div>
  )
}
