import ActionTypes from '../actions/types'

const initialState = {
  errors: {},
  notifications: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
        notifications: {},
      }
    case ActionTypes.CLEAR_ERRORS:
      return {
        ...state,
        errors: {},
      }
    case ActionTypes.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: {},
      }
    case ActionTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        errors: {},
        notifications: action.payload,
      }
    default:
      return state
  }
}
