import Modal from 'react-modal'
import { XIcon } from '@heroicons/react/solid'
import Viewer from 'components/Viewer/Viewer'
import { useContext } from 'react'
import { EditorContext } from '../EditorLayout'

export default function PreviewResumeModal({ isOpen, closeModal }) {
  const { resume } = useContext(EditorContext)
  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          zIndex: 10,
        },
      }}
      id="preview-resume"
      isOpen={isOpen}
      className="overflow-auto outline-none p-4 bg-gray-50 h-full mt-4"
      onRequestClose={closeModal}
      contentLabel="Example Modal"
    >
      <h3 className="text-lg font-medium flex justify-between items-center text-gray-900">
        Preview
        <div className="flex gap-3">
          <div onClick={() => closeModal()} className="p-0.5">
            <XIcon className="w-5 h-5" />
          </div>
        </div>
      </h3>
      <div className="my-4 max-w-2xl mx-auto">
        <Viewer resume={resume} />
      </div>
    </Modal>
  )
}
