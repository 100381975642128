import { Link, useParams } from 'react-router-dom'

import Viewer from 'components/Viewer/Viewer.js'
import { useResume } from 'hooks/resumes'

export default function Preview() {
  const { id } = useParams()
  const { resume } = useResume(id)

  return (
    <div className="bg-warmGray-100 min-h-screen">
      <div className="max-w-4xl mx-6 sm:mx-12 md:mx-auto pt-12 pb-24">
        <div className="shadow-xl">
          <Viewer resume={resume} />
        </div>
        <div className="flex justify-center mt-8">
          <Link to="/" className="text-xl text-gray-500 hover:text-gray-700">
            <span className="font-normal">Made with</span>{' '}
            <span className="font-raleway font-semibold">Resumary</span>
          </Link>
        </div>
      </div>
    </div>
  )
}
