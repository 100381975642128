import BostonConfigs from './template-configs/BostonConfigs'
import CleanConfigs from './template-configs/CleanConfigs'
import FreshConfigs from './template-configs/FreshConfigs'
import ModernConfigs from './template-configs/ModernConfigs'
import PortlandConfigs from './template-configs/PortlandConfigs'
import PureConfigs from './template-configs/PureConfigs'
import SharpConfigs from './template-configs/SharpConfigs'

const TemplateConfigs = {
  0: SharpConfigs,
  1: ModernConfigs,
  2: CleanConfigs,
  3: FreshConfigs,
  4: PureConfigs,
  5: BostonConfigs,
  6: PortlandConfigs,
}

export default TemplateConfigs
