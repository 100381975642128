import { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { UserAddIcon } from '@heroicons/react/outline'
import { useDispatch, useSelector } from 'react-redux'
import { Transition } from '@headlessui/react'

import { setBannerMessage } from 'actions/actions'

export default function Banner() {
  const didMount = useRef(false)
  const location = useLocation()
  const app = useSelector((state) => state.app)
  const dispatch = useDispatch()

  useEffect(() => {
    if (didMount.current) {
      if (app.bannerDetails) {
        dispatch(setBannerMessage(undefined))
      }
    } else {
      didMount.current = true
    }
  }, [location.pathname])

  return (
    <div className="bg-[#1e293b]">
      <Transition
        show={!!app.bannerDetails}
        enter="transition-all ease-in-out duration-500 transform"
        enterFrom="max-h-0"
        enterTo="max-h-16"
        entered="max-h-16"
        leave="transition-all ease-in-out duration-500 transform"
        leaveFrom="max-h-16"
        leaveTo="max-h-0"
      >
        <div className="bg-indigo-600">
          <div className="max-w-7xl mx-auto py-3 px-4 sm:px-6">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className="flex p-2 rounded-lg bg-indigo-800 hidden md:inline mr-3">
                  <UserAddIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
                <p className="font-medium text-white truncate">
                  <span className="md:hidden">
                    {app.bannerDetails && app.bannerDetails.mobileMessage}
                  </span>
                  <span className="hidden md:inline">
                    {app.bannerDetails && app.bannerDetails.message}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  )
}
