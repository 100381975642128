const resumeStyles = [
  'CV Templates',
  'Downloadable Resume Templates',
  'Printable Resume Templates',
  'PDF Resume Templates',
  'Free Resume Templates',
  'ATS-Friendly Resume Templates',
  'One-Column Resume Templates',
  'Two-Column Resume Templates',
  'Student Resume Templates',
  'Executive Resume Templates',
  'Academic Resume Templates',
  'Clean Resume Templates',
  'Basic Resume Templates',
  'Premium Resume Templates',
  'Unique Resume Templates',
  'Traditional Resume Templates',
  'Elegant Resume Templates',
  'Reverse Chronological Resume Templates',
  'Combination Resume Templates',
  'Functional Resume Templates',
  'One Page Resume Templates',
  'Minimalist Resume Templates',
  'Simple Resume Templates',
  'Modern Resume Templates',
  'Creative Resume Templates',
  'Professional Resume Templates',
]

export const ResumeTemplatesMap = Object.fromEntries(
  resumeStyles.map((style) => [
    style.trim().toLowerCase().replace(/\s+/g, '-'),
    { name: style },
  ])
)

export const ResumeTemplatesArray = Object.entries(ResumeTemplatesMap).map(
  ([key, value]) => {
    return {
      id: key,
      name: value.name,
      to: `/resume-templates/${key}`,
    }
  }
)
