import { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { classNames } from 'utils/helper'

function SectionHeaderEditable({ fieldPath }) {
  const { watch, register } = useFormContext()
  const [isFocus, setIsFocus] = useState(false)
  const handleBlur = () => {
    setIsFocus(false)
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsFocus(false)
    }
  }
  const { ref, ...rest } = register(fieldPath, { onBlur: handleBlur })
  const inputRef = useRef(null)

  useEffect(() => {
    if (isFocus) inputRef.current.focus()
  }, [isFocus])

  return (
    <div
      className={classNames(
        'cursor-pointer min-w-0 w-full hover:bg-gray-100 rounded group'
      )}
    >
      <input
        {...rest}
        ref={(e) => {
          ref(e)
          inputRef.current = e // you can still assign to ref
        }}
        onKeyDown={handleKeyDown}
        type="text"
        id={fieldPath}
        className={classNames(
          isFocus ? 'block' : 'hidden',
          'py-1 px-3 w-full border-gray-200 rounded-md text font-medium focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400'
        )}
      />
      <div className="">
        <div
          onClick={() => {
            setIsFocus(true)
          }}
          className={classNames(
            isFocus ? 'hidden' : 'block',
            'border sm:border-white rounded truncate py-1 font-medium sm:py-2 px-3 pr-8 flex-1 focus:outline-none'
          )}
        >
          {watch(fieldPath)}
        </div>
        {/* {!isFocus && (
          <div
            onClick={() => {
              setIsFocus(true)
            }}
            className="absolute right-1 px-2 inset-y-0 flex items-center"
          >
            <PencilIcon className="group-hover:text-gray-700 w-4 h-4 text-gray-400" />
          </div>
        )} */}
      </div>
    </div>
  )
}

export default SectionHeaderEditable
