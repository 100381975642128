export const resume = {
  basics: {
    firstName: 'Emily',
    lastName: 'Johnson',
    title: 'Cloud Software Engineer',
    picture: 'https://example.com/emily-johnson.jpg',
    email: 'emily.johnson@example.com',
    phone: '987-654-3210',
    url: 'https://www.emilyjohnson.dev',
    summary:
      'Results-driven Cloud Software Engineer with a proven track record of designing, developing, and optimizing scalable and reliable cloud-based applications. Expertise in cloud technologies, microservices architecture, and DevOps practices. Committed to delivering high-quality software solutions that drive business growth.',
    location: {
      city: 'Seattle',
      countryCode: 'US',
      region: 'Washington',
    },
    linkedInUrl: 'https://www.linkedin.com/in/emilyjohnson',
    portfolioUrl: 'https://portfolio.emilyjohnson.dev',
  },
  experience: [
    {
      company: 'Google Cloud',
      location: {
        city: 'Mountain View',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Cloud Software Engineer',
      url: 'https://cloud.google.com',
      startDate: '2020-03-15',
      endDate: '2023-06-30',
      summary: null,
      highlights: [
        'Led the migration of a monolithic application to a microservices architecture, resulting in a 30% reduction in response times.',
        'Implemented CI/CD pipelines using Jenkins and Kubernetes, enabling automated deployments and reducing release cycles by 50%.',
        'Improved application performance by 20% through code optimization and efficient use of cloud resources.',
        'Collaborated with cross-functional teams to define technical requirements and ensure seamless integration of cloud services.',
      ],
      present: false,
    },
    {
      company: 'Oracle Corporation',
      location: {
        city: 'Redwood City',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Cloud DevOps Engineer',
      url: 'https://www.oracle.com/cloud',
      startDate: '2018-09-01',
      endDate: '2020-02-28',
      summary: null,
      highlights: [
        'Built and maintained Terraform scripts for provisioning cloud resources, reducing manual configuration efforts by 40%.',
        'Implemented monitoring and alerting using Prometheus and Grafana, leading to a 25% decrease in downtime incidents.',
        'Developed Ansible playbooks for application deployment, resulting in consistent and repeatable deployments across environments.',
      ],
      present: false,
    },
    {
      company: 'Salesforce',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Cloud Software Engineer Intern',
      url: 'https://www.salesforce.com',
      startDate: '2017-06-01',
      endDate: '2017-08-31',
      summary: null,
      highlights: [
        'Implemented RESTful APIs for data synchronization between Salesforce cloud services, improving data consistency by 30%.',
        'Developed unit tests and integration tests using Mocha and Chai, increasing test coverage by 20%.',
        'Collaborated with UX designers to enhance user interface components, improving overall user experience.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'University of Washington',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      startDate: '2016-09-01',
      endDate: '2018-05-31',
      gpa: '3.9',
      courses: [
        'Distributed Systems',
        'Cloud Computing',
        'Software Design Patterns',
      ],
      summary: '',
      present: false,
    },
    {
      institution: 'University of Texas at Austin',
      degree: 'Bachelor of Science in Computer Engineering',
      major: 'Computer Engineering',
      location: {
        city: 'Austin',
        countryCode: 'US',
        region: 'Texas',
      },
      startDate: '2012-08-01',
      endDate: '2016-05-31',
      gpa: '3.7',
      courses: ['Operating Systems', 'Database Management', 'Web Development'],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Excellence in Cloud Computing Award',
      date: '2022-04-15',
      awarder: 'Cloud Computing Association',
      summary:
        'Recognized for exceptional contributions to the field of cloud computing and innovative solutions in cloud software development.',
    },
  ],
  skills: [
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'Python' }, { name: 'Java' }, { name: 'JavaScript' }],
    },
    {
      skillGroup: 'Cloud Platforms',
      skill: [
        { name: 'Google Cloud Platform' },
        { name: 'Amazon Web Services' },
        { name: 'Oracle Cloud' },
      ],
    },
    {
      skillGroup: 'DevOps Tools',
      skill: [
        { name: 'Docker' },
        { name: 'Kubernetes' },
        { name: 'Terraform' },
        { name: 'Jenkins' },
      ],
    },
  ],
  projects: [
    {
      name: 'Serverless E-Commerce Application',
      description:
        'Built a serverless e-commerce application using AWS Lambda, API Gateway, and DynamoDB, achieving high scalability and cost-efficiency.',
      startDate: '2022-01-01',
      endDate: '2022-05-31',
      present: false,
    },
    {
      name: 'Microservices-based Social Network',
      description:
        'Designed and implemented a microservices-based social network using Kubernetes and Istio, enabling seamless communication between services.',
      startDate: '2019-08-01',
      endDate: '2019-12-15',
      present: false,
    },
    {
      name: 'Automated Cloud Resource Management',
      description:
        'Developed an automated resource management system using Terraform and Ansible, reducing manual provisioning efforts by 40%.',
      startDate: '2018-03-01',
      endDate: '2018-06-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Certifications',
      details: [
        {
          title: 'Google Cloud Professional Cloud Architect',
          subtitle: 'Certification',
          location: {
            city: 'Online',
            countryCode: 'US',
            region: 'Virtual',
          },
          url: 'https://www.credential.net/1234567890',
          startDate: '2021-02-15',
          endDate: '2024-02-15',
          summary:
            'Certified Professional Cloud Architect with expertise in designing and managing Google Cloud solutions.',
        },
        {
          title: 'AWS Certified DevOps Engineer - Professional',
          subtitle: 'Certification',
          location: {
            city: 'Online',
            countryCode: 'US',
            region: 'Virtual',
          },
          url: 'https://www.credly.com/badges/1234567890',
          startDate: '2020-07-01',
          endDate: '2023-07-01',
          summary:
            'AWS Certified DevOps Engineer with advanced skills in building and deploying applications on AWS cloud.',
        },
      ],
    },
  ],
}
