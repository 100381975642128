export const resume = {
  basics: {
    firstName: 'Alex',
    lastName: 'Johnson',
    title: 'Test Automation Engineer',
    picture: 'https://example.com/alex-johnson.jpg',
    email: 'alex.johnson@example.com',
    phone: '123-456-7890',
    url: 'https://www.alexjohnsonqa.com',
    summary:
      'Experienced Test Automation Engineer with a proven track record of designing and implementing automated testing solutions to enhance product quality and accelerate software delivery. Skilled in test strategy development, test scripting, and continuous integration. Passionate about ensuring robust software through comprehensive testing methodologies.',
    location: {
      city: 'Seattle',
      countryCode: 'US',
      region: 'Washington',
    },
    linkedInUrl: 'https://www.linkedin.com/in/alexjohnsonqa',
    portfolioUrl: 'https://portfolio.alexjohnsonqa.com',
  },
  experience: [
    {
      company: 'Expedia Group',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Test Automation Engineer',
      url: 'https://www.expedia.com',
      startDate: '2020-03-15',
      endDate: '2023-08-31',
      summary: null,
      highlights: [
        'Designed and implemented end-to-end test automation framework using Selenium WebDriver and Java, covering 300+ test scenarios and reducing manual testing efforts by 40%.',
        'Collaborated with cross-functional teams to define and implement automated test strategies for new features, resulting in early detection of defects and improved release cycles.',
        'Implemented performance testing using JMeter, identifying and resolving performance bottlenecks that improved application response times by 25%.',
        'Mentored junior team members in test automation best practices, coding standards, and tools, fostering a culture of continuous learning and improvement.',
      ],
      present: false,
    },
    {
      company: 'Zillow Group',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'QA Engineer',
      url: 'https://www.zillow.com',
      startDate: '2018-07-01',
      endDate: '2020-02-28',
      summary: null,
      highlights: [
        'Developed and executed automated test scripts using TestNG and Selenium WebDriver, covering critical user workflows and reducing regression testing time by 30%.',
        'Contributed to the creation of a comprehensive test plan for a major app release, resulting in a 50% reduction in post-release defects and improved user satisfaction.',
        'Automated API testing using RestAssured, enabling efficient validation of backend functionality and data integrity.',
      ],
      present: false,
    },
    {
      company: 'Redfin',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Quality Assurance Intern',
      url: 'https://www.redfin.com',
      startDate: '2017-05-15',
      endDate: '2017-08-31',
      summary: null,
      highlights: [
        'Executed manual test cases to validate new features and identify defects, contributing to the timely release of high-quality software.',
        'Automated regression tests using Selenium WebDriver and Java, resulting in a 20% reduction in regression testing time.',
        'Assisted in creating and maintaining test documentation, ensuring accurate and up-to-date testing processes.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'University of Washington',
      degree: 'Bachelor of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      startDate: '2014-09-01',
      endDate: '2018-06-30',
      gpa: '3.7',
      courses: [
        'Software Engineering',
        'Database Systems',
        'Software Testing and Quality Assurance',
      ],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Test Automation Achievement Award',
      date: '2022-05-15',
      awarder: 'QA Professionals Association',
      summary:
        'Recognized for outstanding contributions to test automation practices and successful implementation of automation solutions.',
    },
  ],
  skills: [
    {
      skillGroup: 'Test Automation',
      skill: [
        { name: 'Selenium WebDriver' },
        { name: 'Java' },
        { name: 'TestNG' },
        { name: 'Cucumber' },
        { name: 'Jenkins' },
        { name: 'Gatling' },
      ],
    },
    {
      skillGroup: 'API Testing',
      skill: [
        { name: 'RestAssured' },
        { name: 'Postman' },
        { name: 'API Performance Testing' },
      ],
    },
    {
      skillGroup: 'Performance Testing',
      skill: [
        { name: 'JMeter' },
        { name: 'Load Testing' },
        { name: 'Scalability Assessment' },
      ],
    },
    {
      skillGroup: 'Version Control',
      skill: [{ name: 'Git' }, { name: 'GitHub' }],
    },
  ],
  projects: [
    {
      name: 'Automated Regression Framework',
      description:
        'Designed and developed a modular automated regression testing framework using Selenium WebDriver, Java, and TestNG. Reduced regression testing time by 40% and improved test coverage.',
      startDate: '2021-01-01',
      endDate: '2021-06-30',
      present: false,
    },
    {
      name: 'API Test Suite',
      description:
        'Created a comprehensive API test suite using RestAssured and Postman for validation of backend services. Ensured data integrity and consistent functionality across different endpoints.',
      startDate: '2019-10-15',
      endDate: '2020-03-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Professional Certifications',
      details: [
        {
          title: 'Certified Selenium Professional',
          subtitle: 'Selenium Certification Institute',
          location: {
            city: 'Online',
            countryCode: 'US',
            region: 'Virtual',
          },
          url: 'https://www.seleniumcertificationinstitute.com',
          startDate: '2022-02-01',
          endDate: '2022-02-28',
          summary:
            'Demonstrated expertise in Selenium WebDriver by passing the Certified Selenium Professional exam.',
        },
      ],
    },
  ],
}
