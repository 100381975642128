import { useEffect, useState } from 'react'
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import { CheckCircleIcon } from '@heroicons/react/solid'
import axios from 'axios'
import ErrorMessage from 'components/Core/ErrorMessage'

export default function EmailVerification(props) {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const confirmation = searchParams.get('confirmation')
  const state = useLocation().state

  useEffect(() => {
    const verifyEmail = () => {
      setLoading(true)
      axios
        .post('/confirmation', { token: confirmation })
        .then((res) => {
          setLoading(false)
        })
        .catch((err) => {
          if (err.response) {
            switch (err.response.status) {
              case 302:
                navigate(err.response.data.url)
                break
              case 400:
                navigate(
                  {
                    pathname: '.',
                    search: `?${searchParams}`,
                  },
                  {
                    replace: true,
                    state: {
                      errors: err.response.data.map((v) => {
                        return {
                          msg: v.msg,
                        }
                      }),
                    },
                  }
                )
                break
              case 401:
                navigate(
                  {
                    pathname: '.',
                    search: `?${searchParams}`,
                  },
                  {
                    replace: true,
                    state: { errors: [{ msg: err.response.data.msg }] },
                  }
                )
                break
              default:
                break
            }
          }
        })
    }

    if (confirmation) {
      verifyEmail()
    }
  }, [confirmation])

  if (!confirmation) {
    return <Navigate to="/auth/login" />
  }

  if (loading) {
    return (
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <ErrorMessage
              errors={state?.errors}
              notifications={state?.notifications}
            />
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
              <div className="px-6 py-20 animate-pulse">
                <div className="text-center">
                  <div className="mx-auto rounded-full h-20 w-20 bg-gray-300 mb-4" />
                  <div className="h-9 bg-gray-300 rounded w-1/2 mx-auto mb-4" />
                  <div className="h-6 bg-gray-300 rounded w-full mx-auto mb-1" />
                  <div className="h-6 bg-gray-300 rounded w-1/2 mx-auto" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container mx-auto px-4 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-4/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
            <div className="px-6 py-20">
              <div className="text-center">
                <CheckCircleIcon className="mx-auto h-20 w-20 text-green-400 mb-4" />
                <h2 className="text-gray-700 text-3xl font-semibold mb-4">
                  Email Verification
                </h2>
                <p className="">
                  Your email was verified. Continue to start building your
                  resume.
                </p>
              </div>
              <Link
                to="/auth/login"
                className="mt-8 block text-center w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
              >
                Continue
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
