export const resume = {
  basics: {
    firstName: 'Alexandra',
    lastName: 'Nguyen',
    title: 'Firmware Engineer',
    picture: 'https://example.com/alexandra-nguyen.jpg',
    email: 'alexandra.nguyen@example.com',
    phone: '987-654-3210',
    url: 'https://www.alexandranguyen.dev',
    summary:
      'Innovative Firmware Engineer with a strong background in embedded systems design and development. Proven expertise in coding, testing, and optimizing firmware for complex hardware platforms. Skilled in collaborating with cross-functional teams to deliver high-performance and reliable firmware solutions.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/alexandranguyen',
    portfolioUrl: 'https://portfolio.alexandranguyen.dev',
  },
  experience: [
    {
      company: 'AMD',
      location: {
        city: 'Santa Clara',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Firmware Engineer',
      url: 'https://www.amd.com',
      startDate: '2020-08-15',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Led the development of a power management algorithm that reduced energy consumption by 15% without sacrificing performance.',
        'Implemented advanced error handling mechanisms, resulting in a 30% reduction in system crashes and enhanced reliability.',
        'Collaborated with hardware engineers to design efficient communication protocols between CPU cores, optimizing data transfer speed by 20%.',
        'Designed and executed comprehensive test plans, identifying and resolving firmware bugs to ensure robust system operation.',
      ],
      present: false,
    },
    {
      company: 'Western Digital',
      location: {
        city: 'Irvine',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Embedded Systems Engineer',
      url: 'https://www.westerndigital.com',
      startDate: '2018-06-01',
      endDate: '2020-07-31',
      summary: null,
      highlights: [
        'Designed and implemented wear-leveling algorithms, extending the lifespan of NAND flash memory and reducing write amplification by 25%.',
        'Collaborated with hardware teams to optimize data transfer between storage devices and host systems, achieving a 15% improvement in read/write performance.',
        'Led the integration of secure boot and encryption features into firmware, enhancing data security for storage devices.',
      ],
      present: false,
    },
    {
      company: 'Logitech',
      location: {
        city: 'Newark',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Firmware Developer',
      url: 'https://www.logitech.com',
      startDate: '2016-09-01',
      endDate: '2018-04-30',
      summary: null,
      highlights: [
        'Optimized power management algorithms for wireless input devices, extending battery life by 20% and enhancing user convenience.',
        'Collaborated with cross-functional teams to implement advanced features such as gesture recognition and low-latency communication.',
        'Participated in debugging and troubleshooting efforts, identifying and resolving firmware issues to meet product quality standards.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'University of California, Los Angeles',
      degree: 'Master of Science in Electrical Engineering',
      major: 'Embedded Systems',
      location: {
        city: 'Los Angeles',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2014-09-15',
      endDate: '2016-05-15',
      gpa: '3.8',
      courses: [
        'Advanced Embedded Systems',
        'Real-Time Operating Systems',
        'Digital Signal Processing',
      ],
      summary: '',
      present: false,
    },
    {
      institution: 'University of Texas at Dallas',
      degree: 'Bachelor of Science in Computer Engineering',
      major: 'Computer Engineering',
      location: {
        city: 'Richardson',
        countryCode: 'US',
        region: 'Texas',
      },
      startDate: '2010-08-15',
      endDate: '2014-05-15',
      gpa: '3.7',
      courses: [
        'Microcontroller Systems',
        'Digital Logic Design',
        'Embedded System Design',
      ],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Outstanding Firmware Engineer Award',
      date: '2022-05-15',
      awarder: 'AMD',
      summary:
        'Recognized for exceptional contributions to firmware development and optimization.',
    },
    {
      title: 'Embedded Systems Excellence Scholarship',
      date: '2015-04-01',
      awarder: 'University of California, Los Angeles',
      summary:
        'Awarded for academic excellence and achievements in the field of embedded systems.',
    },
  ],
  skills: [
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'C' }, { name: 'C++' }, { name: 'Assembly' }],
    },
    {
      skillGroup: 'Embedded Systems',
      skill: [
        { name: 'Microcontrollers' },
        { name: 'RTOS' },
        { name: 'Device Drivers' },
      ],
    },
    {
      skillGroup: 'Firmware Development',
      skill: [
        { name: 'Firmware Design' },
        { name: 'Debugging' },
        { name: 'Code Optimization' },
      ],
    },
  ],
  projects: [
    {
      name: 'High-Performance IoT Sensor Node',
      description:
        'Designed and implemented firmware for an IoT sensor node using low-power microcontrollers, achieving 30% reduction in power consumption while maintaining accurate data collection.',
      startDate: '2021-01-01',
      endDate: '2021-06-30',
      present: false,
    },
    {
      name: 'Smart Wearable Device',
      description:
        'Led firmware development for a smart wearable device, enabling real-time health monitoring and synchronization with mobile applications.',
      startDate: '2019-03-01',
      endDate: '2019-09-30',
      present: false,
    },
    {
      name: 'Embedded Robotics Control System',
      description:
        'Contributed to the design and optimization of firmware for an embedded control system used in autonomous robotic applications.',
      startDate: '2017-04-01',
      endDate: '2017-09-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Certifications',
      details: [
        {
          title: 'Embedded Systems Professional (ESP)',
          subtitle: 'Certification',
          location: {
            city: 'Online',
            countryCode: 'US',
            region: 'Virtual',
          },
          url: 'https://www.embeddedinstitute.com/certifications/esp',
          startDate: '2023-02-15',
          endDate: '2025-02-15',
          summary:
            'Certified Embedded Systems Professional with expertise in firmware development and optimization.',
        },
        {
          title: 'ARM Certified Embedded Software Engineer',
          subtitle: 'Certification',
          location: {
            city: 'Online',
            countryCode: 'US',
            region: 'Virtual',
          },
          url: 'https://developer.arm.com/certification/embedded',
          startDate: '2022-04-01',
          endDate: '2024-04-01',
          summary:
            'Certified ARM Embedded Software Engineer with proficiency in ARM-based firmware development.',
        },
      ],
    },
  ],
}
