import { Listbox, Transition } from '@headlessui/react'
import {
  AdjustmentsIcon,
  CheckIcon,
  DocumentTextIcon,
  PencilAltIcon,
  SelectorIcon,
  TemplateIcon,
} from '@heroicons/react/outline'
import { Fragment } from 'react'
import { classNames } from 'utils/helper'

const SelectOptions = [
  { id: 0, value: 'Content', icon: DocumentTextIcon },
  { id: 1, value: 'Template', icon: TemplateIcon },
  { id: 2, value: 'Customize', icon: AdjustmentsIcon },
]

function MobileSelector({ selected, setSelected, setTab }) {
  // const [selected, setSelected] = useState(SelectOptions[props.tab])

  const onChange = (value) => {
    setTab(value.id)
    setSelected(value)
  }

  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <div className="relative mt-1 mx-3">
          <Listbox.Button className="relative w-full cursor-pointer rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm sm:text-sm">
            <span className="flex items-center">
              <selected.icon className="w-6 h-6 flex-shrink-0" />
              <span className="ml-3 block truncate">{selected.value}</span>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <SelectorIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-20 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {SelectOptions.map((option) => (
                <Listbox.Option
                  key={option.id}
                  className={({ active }) =>
                    classNames(
                      active ? 'text-white bg-indigo-600' : 'text-gray-900',
                      'relative cursor-pointer select-none py-2 pl-3 pr-9'
                    )
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <div className="flex items-center">
                        <option.icon className="w-6 h-6 flex-shrink-0" />
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'ml-3 block truncate'
                          )}
                        >
                          {option.value}
                        </span>
                      </div>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}

export default function Selector({ tabs, selectedTab, setSelectedTab }) {
  return (
    <div className="h-full flex bg-white py-4 px-2 border-r">
      <nav
        className="hidden sm:flex flex-col gap-4"
        aria-label="Tabs"
        role="tablist"
      >
        {tabs.map((tab, idx) =>
          idx === selectedTab ? (
            <button
              key={idx}
              className="text-left flex flex-col gap-2 items-center px-1 py-1 text-sm text-blue-600 rounded"
            >
              <tab.Icon className="w-5 h-5" />
              {tab.name}
            </button>
          ) : (
            <button
              key={idx}
              onClick={() => setSelectedTab(idx)}
              className="text-left flex flex-col gap-2 items-center px-1 py-1 text-sm rounded hover:text-gray-600 hover:border-gray-300"
            >
              <tab.Icon className="w-5 h-5" />
              {tab.name}
            </button>
          )
        )}
      </nav>
    </div>
  )
}
