import { Field } from 'formik'
import { useState } from 'react'

import { PencilIcon, XIcon } from '@heroicons/react/solid'

const TitleInput = (props) => {
  const [edit, setEdit] = useState(false)

  function editInput() {
    setEdit(true)
  }

  function closeInput() {
    setEdit(false)
  }

  return (
    <div className="pt-4 pb-8">
      <label
        htmlFor={props.id}
        className="tracking-wide block text-xs text-gray-500 font-medium"
      >
        Custom Section
        <span className="text-xs text-yellow-600 bg-yellow-200 py-0.5 px-1 ml-1">
          Premium
        </span>
      </label>
      <div className="flex">
        <Field
          onBlur={closeInput}
          disabled={!edit}
          component="input"
          className={[
            'flex-shrink text-xl font-bold leading-6 text-gray-900 border rounded-md',
            edit ? 'border-gray-300 px-4 py-2' : 'bg-gray-50 border-gray-50',
          ].join(' ')}
          name={props.name}
          id={props.id}
        />
        <div className="flex flex-col justify-center content-center">
          <div>
            {!edit ? (
              <PencilIcon
                className="cursor-pointer mr-1 inline h-5 w-5"
                onClick={editInput}
              />
            ) : (
              <XIcon
                className="cursor-pointer mr-1 inline h-5 w-5"
                onClick={closeInput}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TitleInput
