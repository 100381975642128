import { useField, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')

  useEffect(() => {
    if (field.value && !(field.value instanceof Date)) {
      const date = new Date(field.value)
      const m = date.getUTCMonth()
      setMonth(('0' + (m + 1)).slice(-2))
      setYear(date.getFullYear())
    }
  }, [])

  const updateField = (newMonth, newYear) => {
    if (newMonth === '' && newYear === '') {
      setFieldValue(field.name, '')
    } else if (newMonth === '' || newYear === '') {
      return
    }
    const m = parseInt(newMonth)
    const y = parseInt(newYear)
    if (m > 0 && m <= 12 && y >= 1900 && y <= 2050) {
      const date = new Date(Date.UTC(y, m - 1))
      setFieldValue(field.name, date.toUTCString())
    }
  }

  const onMonthChange = (e) => {
    const value = e.target.value
    if (!value) {
      setMonth('')
      updateField('', year)
      return
    }
    if (value.length > 2) {
      return
    }
    const m = parseInt(value)
    if (m != null) {
      if (m === 0 && value.length < 2) {
        setMonth(value)
      } else if (m === 1 && value === '01') {
        setMonth(value)
      } else if (m === 1) {
        setMonth(m)
      } else if (m >= 1 && m <= 9) {
        setMonth('0' + m)
      } else if (m >= 10 && m <= 12) {
        setMonth(value)
      }
      updateField(value, year)
    }
  }

  const onYearChange = (e) => {
    const value = e.target.value
    if (!value) {
      setYear('')
      updateField(month, '')
      return
    }
    if (value.length > 4) {
      return
    }
    const y = parseInt(value)
    if (y != null) {
      if (y >= 1 && y <= 2) {
        setYear(value)
      } else if (y >= 19 && y <= 20) {
        setYear(value)
      } else if (y >= 190 && y <= 205) {
        setYear(value)
      } else if (y >= 1900 && y <= 2050) {
        setYear(value)
      }
      updateField(month, value)
    }
  }

  return (
    <div className="flex items-center justify-center shadow-sm rounded-md">
      <input
        className="w-2/5 mt-1 focus:ring-0 focus:border-gray-300 block sm:text-md border-gray-300 rounded-md placeholder-gray-400 border-r-0 rounded-r-none text-right pr-1"
        placeholder="MM"
        type="text"
        value={month}
        onChange={onMonthChange}
      ></input>
      <div className="self-stretch mt-1 border-t border-b flex items-center border-gray-300">
        /
      </div>
      <input
        className="w-3/5 mt-1 focus:ring-0 focus:border-gray-300 block sm:text-md border-gray-300 rounded-md placeholder-gray-400 border-l-0 rounded-l-none pl-1"
        type="text"
        placeholder="YYYY"
        value={year}
        onChange={onYearChange}
      ></input>
    </div>
    // <DatePicker
    //   {...field}
    //   {...props}
    //   dateFormat="MM/yyyy"
    //   showMonthYearPicker
    //   selected={(field.value && new Date(field.value)) || null}
    //   onChange={(val) => {
    //     setFieldValue(field.name, val)
    //   }}
    // />
  )
}

export default DatePickerField
