import { Font, pdf } from '@react-pdf/renderer'
import { showModal } from 'actions/actions'
import axios from 'axios'
import TemplateDecider from 'components/Generator/Templates/TemplateDecider'
import saveAs from 'file-saver'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

const loadFonts = async () => {
  await Promise.all([
    Font.load({ fontFamily: 'Open Sans' }),
    Font.load({ fontFamily: 'Open Sans', fontWeight: '300' }),
    Font.load({ fontFamily: 'Open Sans', fontWeight: '600' }),
    Font.load({ fontFamily: 'Open Sans', fontWeight: '700' }),
  ])
}

export const loadPdfs = async (resumes) => {
  await loadFonts()
  var pdfs = await Promise.all(
    resumes.map((resume) => {
      const template = TemplateDecider({ ...resume })
      return pdf(template).toBlob()
    })
  )
  var complete = pdfs.map((p, i) => {
    return {
      ...resumes[i],
      blob: p,
      url: URL.createObjectURL(p),
    }
  })
  return complete
}

export function useResume(resumeId) {
  const [resume, setResume] = useState(null)
  const [loading, setLoading] = useState(undefined)
  const [saved, setSaved] = useState(false)
  const dispatch = useDispatch()

  const populateResume = async (resumeNew) => {
    var pdfs = await loadPdfs([resumeNew])
    setResume(pdfs[0])
  }

  useEffect(() => {
    const fetchResume = async () => {
      setLoading(true)
      axios
        .get('/resume', { params: { id: resumeId } })
        .then(async (res) => {
          await populateResume(res.data.resume)
          setLoading(false)
        })
        .catch((err) => {
          console.log('error', err)
        })
    }
    fetchResume()
  }, [])

  const updateName = async (name) => {
    setLoading(true)
    return axios
      .put('/resume', { ...resume, name: name })
      .then(async (res) => {
        await populateResume(res.data.resume)
        setLoading(false)
        setSaved(true)
        return res.data.resume
      })
      .catch((err) => {
        console.log('error', err)
      })
  }

  const updateResume = async (params) => {
    setLoading(true)
    return axios
      .put('/resume', params)
      .then(async (res) => {
        await populateResume(res.data.resume)
        setLoading(false)
        setSaved(true)
        return res.data.resume
      })
      .catch((err) => {
        console.log('error', err)
      })
  }

  const updateOrder = async (order) => {
    setLoading(true)
    return axios
      .put('/resume/order', { _id: resume._id, order: order })
      .then(async (res) => {
        await populateResume(res.data.resume)
        setLoading(false)
        setSaved(true)
        return res.data.resume
      })
      .catch((err) => {
        console.log('error', err)
      })
  }

  const selectTemplate = async (resumeId, template) => {
    setLoading(true)
    return axios
      .put('/resume/template', { _id: resumeId, template: template })
      .then(async (res) => {
        await populateResume(res.data.resume)
        setLoading(false)
        setSaved(true)
        return res.data.resume
      })
      .catch((err) => {
        console.log('error', err)
      })
  }

  const updateColor = async (resumeId, color) => {
    setLoading(true)
    return axios
      .put('/resume/color', { _id: resumeId, color: color })
      .then(async (res) => {
        await populateResume(res.data.resume)
        setLoading(false)
        setSaved(true)
        return res.data.resume
      })
      .catch((err) => {
        console.log('error', err)
      })
  }

  const downloadResume = () => {
    if (resume.allowedToDownload) {
      ReactGA.event('file_download')
      saveAs(resume.blob, resume.name)
    } else {
      dispatch(
        showModal('premiumDownloadModal', {
          resume: resume,
          width: 'max-w-4xl',
          type: 'download_editor',
        })
      )
    }
  }

  return {
    saved,
    loading,
    resume,
    setResume,
    updateName,
    updateResume,
    updateColor,
    updateOrder,
    selectTemplate,
    downloadResume,
  }
}

export default function useResumes() {
  const [resumes, setResumes] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)

  const populateResumes = async (resumes) => {
    if (resumes.length === 0) {
      setResumes([])
    } else {
      var pdfs = await loadPdfs(resumes)
      setResumes(pdfs)
    }
  }

  useEffect(() => {
    const fetchResumes = async () => {
      setLoading(true)
      axios
        .get('/resumeAll')
        .then(async (res) => {
          await populateResumes(res.data.resumes)
          setLoading(false)
        })
        .catch((err) => {
          console.log('error', err)
        })
    }

    fetchResumes()
  }, [])

  const isAllowed = (type, width = 'max-w-xl') => {
    const { user } = auth
    if (!user.premium) {
      if (resumes.length > 0) {
        dispatch(
          showModal('premiumDownloadModal', {
            resume: resumes[0],
            width: width,
            type: type,
          })
        )
        return false
      }
    }
    return true
  }

  const createResume = (template) => {
    if (!isAllowed('duplicate')) return
    axios
      .post('/resume', null, { params: { template: template } })
      .then((res) => {
        navigate('/dashboard/resumes/' + res.data.resume + '/edit')
      })
      .catch((err) => {
        console.log('error', err)
      })
  }

  const uploadResume = (file) => {
    const formData = new FormData()
    formData.append('resume', file)
    axios
      .post('/resume/upload', formData)
      .then((res) => {
        navigate('/dashboard/resumes/' + res.data.resume + '/edit')
      })
      .catch((error) => console.log('error', error))
  }

  const deleteResume = (params) => {
    axios
      .delete('/resume', { data: params })
      .then((res) => {
        populateResumes(res.data.resumes)
      })
      .catch((err) => {
        console.log('error', err)
      })
  }

  const duplicateResume = (params) => {
    if (!isAllowed('duplicate')) return
    axios
      .post('/resume/copy', null, { params: params })
      .then((res) => {
        populateResumes(res.data.resumes)
      })
      .catch((err) => {
        console.log('error', err)
      })
  }

  const downloadResume = (resume) => {
    if (resume.allowedToDownload) {
      ReactGA.event('file_download')
      saveAs(resume.blob, resume.name)
    } else {
      dispatch(
        showModal('premiumDownloadModal', {
          resume: resume,
          width: 'max-w-4xl',
          type: 'download',
        })
      )
    }
  }

  return {
    loading,
    resumes,
    createResume,
    uploadResume,
    deleteResume,
    duplicateResume,
    downloadResume,
    isAllowed,
  }
}
