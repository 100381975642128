export const resume = {
  basics: {
    firstName: 'Michael',
    lastName: 'Chen',
    title: 'Software Architect',
    picture: 'https://example.com/michaelchen_pic',
    email: 'michael.chen@example.com',
    phone: '(987) 654-3210',
    url: 'https://michaelchen.dev',
    summary:
      'Results-driven Software Architect with a proven history of designing and implementing innovative software solutions. Skilled in leading cross-functional teams to achieve technical excellence and business success.',
    location: {
      city: 'Seattle',
      countryCode: 'US',
      region: 'Washington',
    },
    linkedInUrl: 'https://www.linkedin.com/in/michaelchen',
    portfolioUrl: 'https://portfolio.michaelchen.dev',
  },
  experience: [
    {
      company: 'TechNexa',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Lead Software Architect',
      url: 'https://technexa.com',
      startDate: '2020-02-15',
      endDate: '2023-06-30',
      summary: null,
      highlights: [
        'Designed and implemented a microservices-based platform, reducing latency by 35% and allowing seamless scalability.',
        'Led the migration of a monolithic legacy system to a cloud-native architecture on AWS, resulting in a 50% decrease in operational costs.',
        'Collaborated with product managers to align technical decisions with business goals, contributing to a 25% increase in customer satisfaction.',
        'Established and optimized CI/CD pipelines, reducing deployment time by 40% and improving team efficiency.',
      ],
      present: false,
    },
    {
      company: 'InnoSoft',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Senior Software Architect',
      url: 'https://innosoft.io',
      startDate: '2017-06-01',
      endDate: '2020-01-31',
      summary: null,
      highlights: [
        'Designed a real-time data processing pipeline, enhancing data analysis capabilities by 30% and supporting data-driven decision-making.',
        'Implemented Docker and Kubernetes for containerization and orchestration, leading to a 45% reduction in infrastructure costs.',
        'Mentored junior architects, conducted code reviews, and promoted best practices, resulting in a 15% improvement in code quality.',
      ],
      present: false,
    },
    {
      company: 'MetaTech',
      location: {
        city: 'New York',
        countryCode: 'US',
        region: 'New York',
      },
      position: 'Software Architect',
      url: 'https://metatech.com',
      startDate: '2013-03-15',
      endDate: '2017-05-31',
      summary: null,
      highlights: [
        'Architected a high-performance caching mechanism, resulting in a 40% reduction in database query times and improved application responsiveness.',
        'Led cross-functional teams to define technical roadmaps, resulting in a 20% reduction in time-to-market for critical features.',
        'Designed and implemented a service-oriented architecture, enabling seamless integration of third-party APIs and reducing development effort by 25%.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2010-09-01',
      endDate: '2012-06-30',
      gpa: '3.9',
      courses: [
        'Advanced Algorithms',
        'Distributed Systems',
        'Software Design Patterns',
      ],
      summary: null,
      present: false,
    },
    {
      institution: 'University of California, Berkeley',
      degree: 'Bachelor of Science in Computer Engineering',
      major: 'Computer Engineering',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2006-08-01',
      endDate: '2010-05-15',
      gpa: '3.8',
      courses: [
        'Digital Logic Design',
        'Embedded Systems',
        'Operating Systems',
      ],
      summary: null,
      present: false,
    },
  ],
  skills: [
    {
      skillGroup: 'Programming Languages',
      skill: [
        {
          name: 'Java',
        },
        {
          name: 'Python',
        },
        {
          name: 'JavaScript',
        },
        {
          name: 'C++',
        },
      ],
    },
    {
      skillGroup: 'Architectural Patterns',
      skill: [
        {
          name: 'Microservices',
        },
        {
          name: 'Service-Oriented Architecture',
        },
        {
          name: 'Event-Driven Architecture',
        },
      ],
    },
    {
      skillGroup: 'Cloud Technologies',
      skill: [
        {
          name: 'Amazon Web Services (AWS)',
        },
        {
          name: 'Microsoft Azure',
        },
      ],
    },
    {
      skillGroup: 'DevOps',
      skill: [
        {
          name: 'Docker',
        },
        {
          name: 'Kubernetes',
        },
      ],
    },
    {
      skillGroup: 'Leadership',
      skill: [
        {
          name: 'Team Management',
        },
        {
          name: 'Technical Leadership',
        },
      ],
    },
  ],
  projects: [
    {
      name: 'E-commerce Platform Enhancement',
      description:
        'Led the redesign and enhancement of an e-commerce platform, optimizing performance and user experience.',
      startDate: '2022-02-01',
      endDate: '2022-06-30',
      present: false,
    },
    {
      name: 'IoT Data Analytics System',
      description:
        'Designed and oversaw the development of a scalable IoT data analytics system, enabling real-time insights from sensor data.',
      startDate: '2019-09-01',
      endDate: '2020-12-15',
      present: false,
    },
    {
      name: 'Cloud-Native Application Migration',
      description:
        'Led the migration of a monolithic application to a cloud-native architecture, enhancing scalability and reducing costs.',
      startDate: '2016-01-01',
      endDate: '2016-06-30',
      present: false,
    },
  ],
}
