import { getDate, joinFields } from '../../Utils/helper'
import ExperienceValueMapper from './ExperienceValueMapper'

class SplitExperienceValueMapper extends ExperienceValueMapper {
  subtitle() {
    const { location, startDate, endDate, present } = this.experience
    const locationText =
      location && joinFields(', ', location.city, location.region)
    const dateText = getDate(startDate, endDate, present)
    return joinFields(' • ', locationText, dateText)
  }
}

export default SplitExperienceValueMapper
