export const resume = {
  basics: {
    firstName: 'Alex',
    lastName: 'Nguyen',
    title: 'Software Development Engineer in Test',
    picture: 'https://example.com/alex-nguyen.jpg',
    email: 'alex.nguyen@example.com',
    phone: '555-123-4567',
    url: 'https://www.alexnguyen-sdet.dev',
    summary:
      'Dedicated Software Development Engineer in Test with a proven track record of designing and implementing robust testing frameworks and strategies. Skilled in automated testing, performance testing, and ensuring the delivery of high-quality software products.',
    location: {
      city: 'Seattle',
      countryCode: 'US',
      region: 'Washington',
    },
    linkedInUrl: 'https://www.linkedin.com/in/alexnguyen',
    portfolioUrl: 'https://portfolio.alexnguyen-sdet.dev',
  },
  experience: [
    {
      company: 'CodeTech Solutions',
      location: {
        city: 'Bellevue',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Senior SDET',
      url: 'https://www.codetechsolutions.com',
      startDate: '2021-03-15',
      endDate: '2023-09-30',
      summary: null,
      highlights: [
        'Designed and implemented an automated testing framework using Selenium and TestNG, reducing regression testing time by 40%.',
        'Developed load testing scripts using JMeter to simulate user traffic, uncovering performance bottlenecks and improving system scalability.',
        'Collaborated with cross-functional teams to define testing requirements and scenarios, resulting in a 20% reduction in defect rate.',
        'Led efforts to establish a continuous integration and continuous deployment (CI/CD) pipeline, enhancing development and testing workflows.',
      ],
      present: false,
    },
    {
      company: 'TechLink Systems',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'SDET II',
      url: 'https://www.techlinksystems.com',
      startDate: '2019-06-01',
      endDate: '2021-01-31',
      summary: null,
      highlights: [
        'Automated end-to-end testing for web applications using Cypress, reducing testing time by 50% and improving test coverage.',
        'Designed and executed performance tests using Gatling, identifying and resolving scalability issues and achieving a 20% increase in system capacity.',
        'Collaborated with developers to review and optimize unit and integration tests, resulting in a 15% reduction in build and deployment failures.',
      ],
      present: false,
    },
    {
      company: 'QualiSoft',
      location: {
        city: 'Austin',
        countryCode: 'US',
        region: 'Texas',
      },
      position: 'SDET Intern',
      url: 'https://www.qualisoft.com',
      startDate: '2018-05-15',
      endDate: '2018-08-15',
      summary: null,
      highlights: [
        'Assisted in developing and maintaining automated test scripts using Java and JUnit, contributing to a 25% reduction in testing time.',
        'Participated in exploratory testing sessions to identify defects and improve test coverage.',
        'Collaborated with SDET team members to analyze and triage defects, facilitating timely bug fixes.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'University of California, Berkeley',
      degree: 'Master of Science in Computer Science',
      major: 'Software Engineering',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2017-09-01',
      endDate: '2019-05-15',
      gpa: '3.7',
      courses: [
        'Software Testing and Quality Assurance',
        'Test Automation',
        'Performance Testing',
        'Agile Software Development',
      ],
      summary: null,
      present: false,
    },
    {
      institution: 'University of Texas at Austin',
      degree: 'Bachelor of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Austin',
        countryCode: 'US',
        region: 'Texas',
      },
      startDate: '2013-09-01',
      endDate: '2017-05-15',
      gpa: '3.5',
      courses: [
        'Data Structures and Algorithms',
        'Software Engineering',
        'Operating Systems',
        'Database Systems',
      ],
      summary: null,
      present: false,
    },
  ],
  awards: [
    {
      title: 'Excellence in Testing Award',
      date: '2022-07-10',
      awarder: 'CodeTech Solutions',
      summary:
        'Recognized for outstanding contributions to testing practices and achieving high-quality software releases.',
    },
    {
      title: 'SDET Achievement Scholarship',
      date: '2018-03-20',
      awarder: 'University of California, Berkeley',
      summary:
        'Received a scholarship in recognition of achievements in the field of software development and testing.',
    },
  ],
  skills: [
    {
      skillGroup: 'Test Automation',
      skill: [{ name: 'Selenium' }, { name: 'TestNG' }, { name: 'JUnit' }],
    },
    {
      skillGroup: 'Performance Testing',
      skill: [{ name: 'Gatling' }, { name: 'JMeter' }],
    },
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'Java' }, { name: 'Python' }, { name: 'JavaScript' }],
    },
    {
      skillGroup: 'CI/CD',
      skill: [{ name: 'Jenkins' }],
    },
  ],
  projects: [
    {
      name: 'Automated Regression Testing Framework',
      description:
        'Developed a scalable and extensible regression testing framework using Selenium and TestNG, reducing manual testing efforts by 60%.',
      startDate: '2022-01-15',
      endDate: '2022-08-31',
      present: false,
    },
    {
      name: 'Load Testing and Performance Optimization',
      description:
        'Led the performance testing effort for a high-traffic e-commerce website, identifying and addressing bottlenecks to achieve a 15% improvement in response time.',
      startDate: '2021-04-01',
      endDate: '2021-11-30',
      present: false,
    },
    {
      name: 'Continuous Integration Pipeline Enhancement',
      description:
        'Implemented a robust CI/CD pipeline using Jenkins and GitLab CI/CD, reducing build and deployment failures by 25% and enabling faster feedback loops.',
      startDate: '2020-07-01',
      endDate: '2020-12-15',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Languages',
      details: [
        {
          title: 'English',
          subtitle: 'Native',
        },
        {
          title: 'Spanish',
          subtitle: 'Intermediate',
        },
      ],
    },
    {
      name: 'Certifications',
      details: [
        {
          title: 'ISTQB Certified Tester - Foundation Level',
          subtitle: 'International Software Testing Qualifications Board',
          location: {
            city: 'Online',
            countryCode: 'US',
          },
          startDate: '2020-06-01',
          endDate: 'No Expiry',
          summary:
            'Demonstrated understanding of essential software testing concepts and techniques.',
        },
        {
          title: 'Certified Agile Tester',
          subtitle: 'iSQI',
          location: {
            city: 'Online',
            countryCode: 'US',
          },
          startDate: '2019-09-01',
          endDate: 'No Expiry',
          summary: 'Certification in agile testing principles and practices.',
        },
      ],
    },
  ],
}
