import { useContext } from 'react'
import SectionOrdering from 'views/editor/customize-sections/section-ordering'
import { EditorContext } from '../EditorLayout'

const CustomizeTabContent = () => {
  const { resume, updateOrder } = useContext(EditorContext)
  return (
    <div className="px-4 py-4">
      <SectionOrdering resume={resume} updateOrder={updateOrder} />
    </div>
  )
}

export default CustomizeTabContent
