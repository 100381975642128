export const resume = {
  basics: {
    firstName: 'Emma',
    lastName: 'Johnson',
    title: 'Backend Engineer',
    picture: 'https://example.com/emma-johnson.jpg',
    email: 'emma.johnson@example.com',
    phone: '987-654-3210',
    url: 'https://www.emmajohnson.dev',
    summary:
      'Passionate backend engineer with a proven track record of designing and building scalable and high-performance web applications. Experienced in developing RESTful APIs, optimizing database performance, and ensuring code quality. Dedicated to delivering reliable and efficient solutions that meet business objectives.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/emmajohnson',
    portfolioUrl: 'https://portfolio.emmajohnson.dev',
  },
  experience: [
    {
      company: 'Google',
      location: {
        city: 'Mountain View',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Backend Engineer',
      url: 'https://www.google.com',
      startDate: '2020-03-15',
      endDate: '2023-08-31',
      summary: null,
      highlights: [
        'Designed and implemented a RESTful API for a new internal tool, reducing development time by 20% and improving team efficiency.',
        'Optimized database queries, resulting in a 30% reduction in response time for API requests and improved overall system performance.',
        'Collaborated with cross-functional teams to identify and address scalability bottlenecks, leading to a 50% increase in concurrent user capacity.',
        'Led code reviews, mentored junior engineers, and promoted best practices to ensure code quality and maintainable architecture.',
      ],
      present: false,
    },
    {
      company: 'Airbnb',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Software Engineer',
      url: 'https://www.airbnb.com',
      startDate: '2018-06-01',
      endDate: '2020-01-31',
      summary: null,
      highlights: [
        'Developed and maintained RESTful APIs for user authentication and booking management, serving millions of requests daily.',
        'Implemented data pipelines using Kafka for real-time data processing, resulting in a 25% reduction in data processing latency.',
        'Participated in on-call rotations to provide rapid response and resolution to critical incidents, ensuring high availability of services.',
      ],
      present: false,
    },
    {
      company: 'Box',
      location: {
        city: 'Redwood City',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Backend Developer',
      url: 'https://www.box.com',
      startDate: '2016-08-15',
      endDate: '2018-05-31',
      summary: null,
      highlights: [
        'Designed and implemented API endpoints for file upload and download, improving data transfer speed by 30%.',
        'Optimized database schema and queries, leading to a 40% reduction in database response time and improved application responsiveness.',
        'Participated in performance testing and profiling, identifying and resolving performance bottlenecks to ensure a seamless user experience',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2014-09-01',
      endDate: '2016-06-30',
      gpa: '3.9',
      courses: [
        'Distributed Systems',
        'Advanced Databases',
        'Algorithms and Data Structures',
      ],
      summary: '',
      present: false,
    },
    {
      institution: 'University of California, Berkeley',
      degree:
        'Bachelor of Science in Electrical Engineering and Computer Sciences',
      major: 'Electrical Engineering and Computer Sciences',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2010-08-15',
      endDate: '2014-05-31',
      gpa: '3.7',
      courses: [
        'Database Systems',
        'Operating Systems',
        'Software Engineering',
      ],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Outstanding Engineering Achievement Award',
      date: '2022-05-15',
      awarder: 'California Engineering Society',
      summary:
        'Recognized for exceptional contributions and achievements in the field of engineering.',
    },
  ],
  skills: [
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'Java' }, { name: 'Python' }, { name: 'Node.js' }],
    },
    {
      skillGroup: 'Backend Frameworks',
      skill: [
        { name: 'Spring Boot' },
        { name: 'Django' },
        { name: 'Express.js' },
      ],
    },
    {
      skillGroup: 'Database Technologies',
      skill: [{ name: 'MySQL' }, { name: 'MongoDB' }, { name: 'Redis' }],
    },
    {
      skillGroup: 'Cloud Platforms',
      skill: [
        { name: 'Amazon Web Services (AWS)' },
        { name: 'Google Cloud Platform (GCP)' },
      ],
    },
    {
      skillGroup: 'Version Control',
      skill: [{ name: 'Git' }, { name: 'GitHub' }],
    },
  ],
  projects: [
    {
      name: 'High-Performance API Gateway',
      description:
        'Designed and developed a high-performance API gateway using Node.js and Express.js, achieving sub-millisecond response times and handling thousands of requests per second.',
      startDate: '2022-01-01',
      endDate: '2022-06-30',
      present: false,
    },
    {
      name: 'Distributed Microservices',
      description:
        'Led a team in designing and implementing a distributed microservices architecture for a large-scale e-commerce platform, resulting in improved scalability and fault tolerance.',
      startDate: '2020-09-01',
      endDate: '2021-04-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Open Source Contributions',
      details: [
        {
          title: 'Contributor',
          subtitle: 'Node.js Open Source Project',
          location: {
            city: 'Online',
            countryCode: 'Global',
            region: 'Virtual',
          },
          url: 'https://github.com/nodejs/node',
          startDate: '2019-03-01',
          endDate: '2023-08-01',
          summary:
            'Actively contributed to the development of the Node.js runtime and its ecosystem by submitting code enhancements and bug fixes.',
          highlights: [
            'Collaborated with a diverse group of developers in code reviews and discussions to improve code quality and maintainability.',
          ],
          present: true,
        },
      ],
    },
  ],
}
