const blogPostPath = 'posts/'

const blogPostsConfig = [
  {
    title: 'Top 5 Resume Writing Tips for 2023',
    url: 'top-5-resume-writing-tips-for-2023',
    date: 'Mar 20, 2023',
    datetime: '2023-03-20',
    category: { title: 'Resume Writing', href: '#' },
    description:
      "Learn the top 5 tips for writing a winning resume in today's competitive job market. Follow these tips to create a powerful, impactful resume that will help you stand out and impress potential employers.",
    author: {
      name: 'Andrew Feng',
      role: 'Founder',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    title: 'How to Write a Resume: A Comprehensive Guide [Updated 2023]',
    url: 'how-to-write-a-resume-a-comprehensive-guide-updated-2023',
    date: 'Mar 16, 2023',
    datetime: '2023-03-16',
    category: { title: 'Resume Writing', href: '#' },
    description:
      'Learn how to craft a winning resume with our comprehensive guide, updated for 2023. Our step-by-step process covers everything you need to know, from choosing the right resume builder to tailoring your resume to the job you want.',
    author: {
      name: 'Andrew Feng',
      role: 'Founder',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
]

export const blogPosts = blogPostsConfig.map((config) => {
  return {
    ...config,
    filepath: `${blogPostPath}${config.url}.md`,
  }
})
