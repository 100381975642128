export const resume = {
  basics: {
    firstName: 'Jordan',
    lastName: 'Williams',
    title: 'Augmented Reality Engineer',
    picture: 'https://example.com/jordan-williams.jpg',
    email: 'jordan.williams@example.com',
    phone: '123-456-7890',
    url: 'https://www.jordanwilliams.dev',
    summary:
      'Innovative Augmented Reality Engineer with a proven track record of developing cutting-edge AR applications and experiences. Skilled in AR software development, computer vision, and 3D graphics. Passionate about pushing the boundaries of reality and delivering immersive user experiences.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/jordanwilliams',
    portfolioUrl: 'https://portfolio.jordanwilliams.dev',
  },
  experience: [
    {
      company: 'MetaMind',
      location: {
        city: 'Mountain View',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Senior Augmented Reality Engineer',
      url: 'https://www.metamind.com',
      startDate: '2020-06-15',
      endDate: '2023-08-31',
      summary: null,
      highlights: [
        'Designed and implemented real-time markerless tracking algorithms for accurate and robust AR object placement.',
        'Developed AR content creation tools and workflows, reducing content creation time by 30% and improving efficiency.',
        'Collaborated with cross-functional teams to integrate AR functionality into mobile and web applications, resulting in a 20% increase in user retention.',
        'Optimized AR rendering pipelines for improved performance and visual quality, resulting in a 25% reduction in rendering latency.',
        'Led a team of junior engineers, providing guidance and mentorship in AR software development best practices.',
      ],
      present: false,
    },
    {
      company: 'DreamSpace',
      location: {
        city: 'Los Angeles',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Augmented Reality Developer',
      url: 'https://www.dreamspacear.com',
      startDate: '2018-03-01',
      endDate: '2020-05-31',
      summary: null,
      highlights: [
        'Developed interactive AR filters and effects for mobile apps, resulting in a 15% increase in user engagement and social sharing.',
        'Collaborated with 3D artists to optimize and integrate 3D models and animations into AR scenes, improving visual quality and performance.',
        'Created location-based AR experiences using geolocation and mapping technologies, enhancing user interaction and exploration.',
      ],
      present: false,
    },
    {
      company: 'VirtuaTech',
      location: {
        city: 'Austin',
        countryCode: 'US',
        region: 'Texas',
      },
      position: 'AR Software Engineer',
      url: 'https://www.virtuatech.com',
      startDate: '2016-08-15',
      endDate: '2018-02-28',
      summary: null,
      highlights: [
        'Developed custom AR modules for industrial training simulations, reducing training time and improving employee performance.',
        'Collaborated with UX/UI designers to create intuitive AR user interfaces, resulting in a 20% increase in user satisfaction and ease of use.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'University of Washington',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      startDate: '2014-09-01',
      endDate: '2016-06-15',
      gpa: '3.87',
      courses: [
        'Computer Graphics',
        'Computer Vision',
        'Human-Computer Interaction',
        'Machine Learning',
      ],
      summary: null,
      present: false,
    },
    {
      institution: 'University of Southern California',
      degree: 'Bachelor of Science in Electrical Engineering',
      major: 'Electrical Engineering',
      location: {
        city: 'Los Angeles',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2010-08-01',
      endDate: '2014-05-15',
      gpa: '3.78',
      courses: [
        'Digital Signal Processing',
        'Circuit Design',
        'Embedded Systems',
        'Signals and Systems',
      ],
      summary: null,
      present: false,
    },
  ],
  awards: [
    {
      title: 'Innovator Award',
      date: '2022-03-15',
      awarder: 'Tech Innovators Conference',
      summary:
        'Recognized for outstanding contributions and innovation in the field of Augmented Reality.',
    },
    {
      title: 'Excellence in Engineering Award',
      date: '2018-06-01',
      awarder: 'VirtuaTech',
      summary:
        'Received in recognition of exceptional performance and dedication to engineering excellence.',
    },
  ],
  skills: [
    {
      skillGroup: 'AR Development',
      skill: [
        { name: 'Unity3D' },
        { name: 'ARKit' },
        { name: 'ARCore' },
        { name: 'Vuforia' },
      ],
    },
    {
      skillGroup: 'Computer Vision',
      skill: [
        { name: 'OpenCV' },
        { name: 'Feature Tracking' },
        { name: 'Object Recognition' },
      ],
    },
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'C#' }, { name: 'C++' }, { name: 'Python' }],
    },
  ],
  projects: [
    {
      name: 'Interactive AR Art Gallery',
      description:
        'Developed an AR app that allows users to explore virtual art galleries and interact with 3D artworks using gestures and touch interactions.',
      startDate: '2022-01-15',
      endDate: '2022-07-30',
      present: false,
    },
    {
      name: 'AR Product Visualization',
      description:
        'Created an AR experience for an e-commerce platform, enabling users to visualize and interact with products in a real-world environment before making a purchase.',
      startDate: '2020-03-01',
      endDate: '2021-08-31',
      present: false,
    },
    {
      name: 'Educational AR Science Kit',
      description:
        'Designed an AR science kit for middle school students, offering interactive lessons and experiments to enhance STEM education.',
      startDate: '2018-08-15',
      endDate: '2019-03-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Publications',
      details: [
        {
          title:
            'J. Williams, A. Chen, and M. Patel. "Advancements in Markerless AR Tracking Algorithms." ACM Transactions on Graphics (TOG), 2023.',
          subtitle: 'ACM Transactions on Graphics',
          location: {
            city: 'New York',
            countryCode: 'US',
            region: 'New York',
          },
          url: 'https://dl.acm.org/doi/10.1145/1234567.1234567',
          startDate: '2023-05-01',
          endDate: '2023-05-05',
          summary:
            'Published research on novel markerless AR tracking methods for accurate and robust object placement in AR environments.',
        },
        {
          title:
            'J. Williams and S. Lee. "User-Centered AR Design: Principles and Guidelines." Proceedings of the International Symposium on Mixed and Augmented Reality (ISMAR), 2021.',
          subtitle: 'International Symposium on Mixed and Augmented Reality',
          location: {
            city: 'Tokyo',
            countryCode: 'JP',
            region: 'Tokyo',
          },
          url: 'https://www.ismar.org/proceedings/2021/pdfs/0001.pdf',
          startDate: '2021-10-04',
          endDate: '2021-10-08',
          summary:
            'Presented guidelines for creating user-centered AR experiences to enhance usability and engagement.',
        },
      ],
    },
    {
      name: 'Languages',
      details: [
        {
          title: 'English',
          subtitle: 'Fluent',
        },
        {
          title: 'Spanish',
          subtitle: 'Intermediate',
        },
      ],
    },
  ],
}
