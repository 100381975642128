export const resume = {
  basics: {
    firstName: 'Emily',
    lastName: 'Johnson',
    title: 'Frontend Software Engineer',
    picture: 'https://example.com/emily-johnson.jpg',
    email: 'emily.johnson@example.com',
    phone: '123-456-7890',
    url: 'https://www.emilyjohnson.com',
    summary:
      'Passionate frontend software engineer with a strong foundation in building user-centric web applications. Experienced in creating responsive and visually appealing interfaces while optimizing for performance and usability.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/emilyjohnson',
    portfolioUrl: 'https://portfolio.emilyjohnson.com',
  },
  experience: [
    {
      company: 'Facebook',
      location: {
        city: 'Menlo Park',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Frontend Software Engineer',
      url: 'https://www.facebook.com',
      startDate: '2020-06-01',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Designed and implemented responsive and accessible UI components for a high-traffic e-commerce platform, resulting in a 20% increase in user engagement.',
        'Optimized web performance by reducing page load time by 30% through code optimization and lazy loading strategies.',
        'Collaborated closely with product managers and designers to deliver features that align with user needs and business objectives.',
        'Led A/B testing efforts to measure and improve user interactions, resulting in a 15% increase in click-through rates.',
      ],
      present: false,
    },
    {
      company: 'Netflix',
      location: {
        city: 'Los Gatos',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Frontend Engineer',
      url: 'https://www.netflix.com',
      startDate: '2018-03-15',
      endDate: '2020-05-31',
      summary: null,
      highlights: [
        'Redesigned and implemented the user profile management page, reducing user error rates by 25% and improving overall user satisfaction.',
        'Collaborated with backend engineers to develop real-time user data synchronization features, improving content recommendations and user engagement.',
      ],
      present: false,
    },
    {
      company: 'Salesforce',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Associate Frontend Developer',
      url: 'https://www.salesforce.com',
      startDate: '2016-07-01',
      endDate: '2018-01-31',
      summary: null,
      highlights: [
        'Implemented a reusable component library, reducing development time for new features by 20% and maintaining a consistent user experience across the application.',
        'Optimized performance and reduced render times through advanced CSS techniques, resulting in a 40% improvement in page load speed.',
        'Collaborated with UX designers to iterate on user interface designs and implement interactive prototypes for user testing.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2014-09-01',
      endDate: '2016-06-30',
      gpa: '3.8',
      courses: [
        'Advanced Web Development',
        'Human-Computer Interaction',
        'Software Design Patterns',
      ],
      summary: '',
      present: false,
    },
    {
      institution: 'University of California, Berkeley',
      degree: 'Bachelor of Arts in Cognitive Science',
      major: 'Cognitive Science',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2010-09-01',
      endDate: '2014-05-31',
      gpa: '3.7',
      courses: [
        'Introduction to Human-Computer Interaction',
        'Cognitive Psychology',
        'User-Centered Design',
      ],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Facebook Innovation Award',
      date: '2022-08-15',
      awarder: 'Facebook',
      summary:
        'Recognized for innovative contributions to the development of user interfaces and user experience.',
    },
    {
      title: 'Netflix UI Design Excellence Award',
      date: '2019-06-10',
      awarder: 'Netflix',
      summary:
        'Received for outstanding design and engineering efforts to enhance the streaming user interface.',
    },
  ],
  skills: [
    {
      skillGroup: 'Frontend Technologies',
      skill: [
        { name: 'HTML5' },
        { name: 'CSS3' },
        { name: 'JavaScript' },
        { name: 'React' },
        { name: 'Redux' },
        { name: 'Webpack' },
      ],
    },
    {
      skillGroup: 'Responsive Design',
      skill: [
        { name: 'Mobile-First Design' },
        { name: 'CSS Grid' },
        { name: 'Flexbox' },
      ],
    },
    {
      skillGroup: 'User Experience',
      skill: [
        { name: 'UI/UX Design Principles' },
        { name: 'Wireframing' },
        { name: 'Prototyping' },
      ],
    },
  ],
  projects: [
    {
      name: 'E-Commerce Website Redesign',
      description:
        'Led the frontend redesign of an e-commerce website, focusing on improving user experience and increasing conversion rates.',
      startDate: '2022-01-01',
      endDate: '2022-06-30',
      present: false,
    },
    {
      name: 'Interactive Data Visualization Dashboard',
      description:
        'Developed an interactive data visualization dashboard to display real-time analytics for a SaaS platform.',
      startDate: '2021-04-01',
      endDate: '2021-08-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Hackathons',
      details: [
        {
          title: 'Tech Innovators Hackathon',
          subtitle: '1st Place',
          location: {
            city: 'San Francisco',
            countryCode: 'US',
            region: 'California',
          },
          url: 'https://techinnovatorshackathon.com',
          startDate: '2019-10-15',
          endDate: '2019-10-16',
          summary:
            'Led a team of frontend engineers to create an innovative web application for real-time collaboration.',
          highlights: [
            'Designed and implemented the real-time collaborative editing feature using WebSocket technology.',
            'Integrated third-party APIs for file sharing and video conferencing, enhancing user productivity.',
            'Presented the winning solution to a panel of industry experts and investors.',
          ],
          present: false,
        },
      ],
    },
  ],
}
