import {
  AcademicCapIcon,
  ClipboardListIcon,
  OfficeBuildingIcon,
  ViewListIcon,
} from '@heroicons/react/outline'
import { PlusIcon } from '@heroicons/react/solid'
import ObjectID from 'bson-objectid'
import {
  BasicsDropdown,
  FieldArraySectionDropdown,
} from 'components/Forms/EditorV2/SectionDropdown'
import SectionHeaderEditable from 'components/Forms/EditorV2/SectionHeaderEditable'
import { useContext } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { ContentLeftPanelContext } from './PanelLayoutSelector'
import { EditorContext } from 'layouts/Dashboard/EditorLayout'

const sections = [
  {
    section: 'experience',
    title: 'Experience',
    titleSelector: 'company',
    icon: OfficeBuildingIcon,
  },
  {
    section: 'education',
    title: 'Education',
    titleSelector: 'institution',
    icon: AcademicCapIcon,
  },
  {
    section: 'projects',
    title: 'Projects',
    titleSelector: 'name',
    icon: ClipboardListIcon,
  },
  {
    section: 'skills',
    title: 'Skills',
    titleSelector: 'skillGroup',
    icon: ViewListIcon,
  },
  { section: 'customSections' },
]

function CustomSectionDropdowns() {
  const customSectionsFieldArray = useFieldArray({ name: 'customSections' })
  const { resume } = useContext(EditorContext)
  const orderedCustomSections = resume.order
    .flat()
    .filter((v) => v.startsWith('custom:'))
    .map((v) => v.slice(-24))

  const weightedMap = orderedCustomSections.reduce((map, obj, index) => {
    map[obj] = index
    return map
  }, {})

  const fieldMap = customSectionsFieldArray.fields.reduce((map, obj, index) => {
    map[obj._id] = index
    return map
  }, {})

  const sortedFields = [...customSectionsFieldArray.fields].sort((a, b) => {
    return weightedMap[a._id] - weightedMap[b._id]
  })

  return (
    <>
      {sortedFields.map((section) => {
        const sectionId = section._id
        const idx = fieldMap[sectionId]
        if (idx === undefined) {
          return null
        }
        return (
          <CustomSectionDropdown
            name={`customSections.${idx}.details`}
            fieldId={sectionId}
            key={sectionId}
            title={
              <SectionHeaderEditable fieldPath={`customSections.${idx}.name`} />
            }
            deleteSection={() => customSectionsFieldArray.remove(idx)}
          />
        )
      })}
      <AddCustomSectionButton fieldArray={customSectionsFieldArray} />
    </>
  )
}

function CustomSectionDropdown({ name, title, deleteSection, fieldId }) {
  const { dropdownState, setDropdownState, selectedItem, setSelectedItem } =
    useContext(ContentLeftPanelContext)
  const fieldArray = useFieldArray({ name: name })
  return (
    <FieldArraySectionDropdown
      showActionMenu
      fieldArray={fieldArray}
      section={name}
      overrideName={fieldId}
      selectedItem={selectedItem}
      dropdownState={dropdownState}
      setDropdownState={setDropdownState}
      setSelectedItem={setSelectedItem}
      title={title}
      titleSelector="title"
      deleteSection={deleteSection}
    />
  )
}

function StandardSectionDropdown({ section, title, titleSelector, Icon }) {
  const { dropdownState, setDropdownState, selectedItem, setSelectedItem } =
    useContext(ContentLeftPanelContext)
  const fieldArray = useFieldArray({ name: section })
  return (
    <FieldArraySectionDropdown
      key={section}
      Icon={Icon}
      section={section}
      dropdownState={dropdownState}
      setDropdownState={setDropdownState}
      fieldArray={fieldArray}
      title={title}
      titleSelector={titleSelector}
      setSelectedItem={setSelectedItem}
      selectedItem={selectedItem}
    />
  )
}

function AddCustomSectionButton({ fieldArray }) {
  const { append } = fieldArray
  return (
    <div className="py-2">
      <div className="mt-1 flex justify-start items-center">
        <span className="text-xs bg-yellow-200 rounded-full py-0.5 px-2">
          Premium
        </span>
      </div>
      <button
        onClick={() => {
          append({
            name: `Section Name`,
            details: [],
            _id: ObjectID().toHexString(),
          })
        }}
        className="w-full flex items-center h-12 focus:outline-none group"
      >
        <div className="flex items-center flex-grow">
          <span className="leading-none font-medium text-base">
            Add Custom Section
          </span>
        </div>
        <div className="flex items-center justify-center w-6 h-6 group-hover:bg-gray-100 rounded">
          <PlusIcon className="w-5 h-5" />
        </div>
      </button>
    </div>
  )
}

export default function ContentView() {
  return (
    <div className="min-h-0 flex-1 overflow-y-scroll pb-6 px-6 divide-y">
      <BasicsDropdown />
      {sections.map((item) => {
        if (item.section === 'customSections') {
          return <CustomSectionDropdowns key="custom-sections" />
        }
        return (
          <StandardSectionDropdown
            key={item.section}
            Icon={item.icon}
            section={item.section}
            title={item.title}
            titleSelector={item.titleSelector}
          />
        )
      })}
    </div>
  )
}
