import { StyleSheet } from '@react-pdf/renderer'
import OneColumnLayout from '../common/layouts/OneColumnLayout'
import SplitTemplateEntry from '../common/template-entry/SplitTemplateEntry'
import DefaultTemplateSection from '../common/template-section/DefaultTemplateSection'
import HyphenatedCustomSectionValueMapper from '../common/value-mappers/custom-section/HyphenatedCustomSectionValueMapper'
import DateEducationValueMapper from '../common/value-mappers/education/DateEducationValueMapper'
import HyphenatedExperienceValueMapper from '../common/value-mappers/experience/HypenatedExperienceValueMapper'
import ProjectValueMapper from '../common/value-mappers/project/ProjectValueMapper'
import CondensedSkillValueMapper from '../common/value-mappers/skill/CondensedSkillValueMapper'
import Header from '../boston/sections/Header'
import SplitExperienceValueMapper from '../common/value-mappers/experience/SplitExperienceValueMapper'
import CityExperienceValueMapper from '../common/value-mappers/experience/CityExperienceValueMapper'
import CityEducationValueMapper from '../common/value-mappers/education/CityEducationValueMapper'
import CityCustomSectionValueMapper from '../common/value-mappers/custom-section/CityCustomSectionValueMapper'
import StackedSkillValueMapper from '../common/value-mappers/skill/StackedSkillValueMapper'
import InlineSkillValueMapper from '../common/value-mappers/skill/InlineSkillValueMapper'
import InlineColonSkillValueMapper from '../common/value-mappers/skill/InlineColonSkillValueMapper'

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 50,
    paddingVertical: 40,
    fontFamily: 'Open Sans',
  },
  sectionTitle: {
    fontSize: 9,
    marginBottom: 6,
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  container: {
    marginTop: 5,
    // marginBottom: 5,
  },
  entryContainer: {
    marginBottom: 6,
  },
  subtitle: {
    fontSize: 9,
    // textAlign: 'right',
  },
  detailContainer: {
    flexDirection: 'row',
  },
  headerContainer: {
    flexDirection: 'row',
    // marginBottom: .5,
  },
  leftColumn: {
    flex: 1,
  },
  rightColumn: {},
  titleDateContainer: {
    flexDirection: 'row',
    marginBottom: 6,
  },
  title: {
    fontSize: 9,
    fontWeight: 700,
  },
  inlineSubtitle: {
    fontSize: 9,
  },
  titleSecondary: {
    fontWeight: 400,
  },
  bulletPoint: {
    fontSize: 8.5,
    fontFamily: 'Open Sans',
    marginLeft: 6,
    marginRight: 3,
    fontWeight: 700,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
  },
  itemContent: {
    flex: 1,
  },
  htmlContent: {
    fontSize: 9,
  },
})

const BostonConfigs = {
  styles: styles,
  Layout: OneColumnLayout,
  Header: Header,
  entryTransformerMap: {
    education: CityEducationValueMapper,
    experience: CityExperienceValueMapper,
    projects: ProjectValueMapper,
    skills: InlineColonSkillValueMapper,
    custom: CityCustomSectionValueMapper,
  },
  TemplateSection: DefaultTemplateSection,
  TemplateEntry: SplitTemplateEntry,
  settings: {},
}

export default BostonConfigs
