import { Field, FieldArray } from 'formik'

import { AccordionPage } from 'components/Forms/Editor/Accordion'
import QuillInput from './common/QuillInput'

function ProjectForm(index) {
  return (
    <div className="m-4 grid grid-cols-6 gap-6">
      <div className="col-span-6">
        <label
          htmlFor={`project-name-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          Project Name
        </label>
        <Field
          component="input"
          type="text"
          name={`projects.${index}.name`}
          id={`project-name-${index}`}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
          placeholder="Your project name"
        />
      </div>

      <div className="col-span-6">
        <label
          htmlFor={`project-summary-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          Summary
        </label>
        <div className="mt-1">
          <QuillInput
            id={`project-summary-${index}`}
            name={`projects.${index}.description`}
            placeholder="Include 1-2 sentences summarizing your project and what you achieved"
          />
        </div>
      </div>
    </div>
  )
}

export default function ProjectsForm() {
  return (
    <>
      <div className="mt-5 md:mt-0 md:col-span-3">
        <div className="overflow-hidden sm:rounded-md">
          <div className="px-4 sm:px-6">
            <FieldArray
              name="projects"
              render={(arrayHelpers) => (
                <AccordionPage
                  {...arrayHelpers}
                  render={ProjectForm}
                  header="Projects"
                  titleSelector="name"
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  )
}
