import ActionTypes from '../actions/types'

const initialState = {
  recommendedJobs: undefined,
  loading: false,
  jobs: undefined,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_RECOMMENDED_JOBS:
      return {
        ...state,
        recommendedJobs: action.payload,
      }
    case ActionTypes.SET_JOBS:
      return {
        ...state,
        jobs: action.payload,
      }
    case ActionTypes.SET_JOBS_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}
