export const resume = {
  basics: {
    firstName: 'Emily',
    lastName: 'Nguyen',
    title: 'Frontend Developer',
    picture: 'https://example.com/profile_picture.jpg',
    email: 'emilynguyen@email.com',
    phone: '123-456-7890',
    url: 'https://emilynguyen.com',
    summary:
      'Passionate Frontend Developer with a strong focus on creating engaging and user-friendly web experiences. Proficient in modern frontend technologies and UI/UX design principles.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'CA',
    },
    linkedInUrl: 'https://www.linkedin.com/in/emilynguyen',
    portfolioUrl: 'https://github.com/emilynguyen',
  },
  experience: [
    {
      company: 'Apple Inc.',
      location: {
        city: 'Cupertino',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'Frontend Developer',
      url: 'https://www.apple.com',
      startDate: '2020-01-01',
      endDate: '2023-07-31',
      summary:
        "Designed and developed user interfaces for Apple's flagship products, focusing on performance and accessibility.",
      highlights: [
        'Implemented responsive and pixel-perfect designs using HTML, CSS, and JavaScript.',
        'Collaborated closely with UX designers to translate wireframes and prototypes into functional web interfaces.',
        'Optimized website performance, resulting in a 20% improvement in page load times and enhanced user experience.',
      ],
      present: false,
    },
    {
      company: 'Netflix',
      location: {
        city: 'Los Gatos',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'Frontend Engineer',
      url: 'https://www.netflix.com',
      startDate: '2017-06-01',
      endDate: '2019-12-31',
      summary:
        "Contributed to the development of Netflix's streaming platform, focusing on interactive and visually appealing user interfaces.",
      highlights: [
        'Built dynamic and interactive web components using React.js, resulting in a seamless and engaging user experience.',
        'Worked closely with backend engineers to integrate RESTful APIs and enhance data-driven features.',
        'Collaborated with cross-functional teams to optimize UI/UX design and implement A/B testing for user interface improvements.',
      ],
      present: false,
    },
    {
      company: 'Google',
      location: {
        city: 'Mountain View',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'Frontend Developer Intern',
      url: 'https://www.google.com',
      startDate: '2015-02-15',
      endDate: '2015-05-31',
      summary:
        "Contributed to the development of Google's advertising platform, focusing on user interface enhancements and performance optimization.",
      highlights: [
        'Developed and maintained frontend components using AngularJS, improving overall application responsiveness.',
        'Collaborated with UX designers to implement design updates and ensure a consistent visual identity across the platform.',
        'Assisted in conducting usability tests and gathering user feedback to inform design decisions.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Bachelor of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'CA',
      },
      startDate: '2013-09-01',
      endDate: '2017-05-15',
      gpa: '3.8',
      courses: [
        'Web Development',
        'User Interface Design',
        'Human-Computer Interaction',
      ],
      summary: "Dean's List",
      present: false,
    },
  ],
  skills: [
    {
      skillGroup: 'Languages',
      skill: [
        {
          name: 'HTML',
        },
        {
          name: 'CSS',
        },
        {
          name: 'JavaScript',
        },
      ],
    },
    {
      skillGroup: 'Frameworks',
      skill: [
        {
          name: 'React.js',
        },
        {
          name: 'AngularJS',
        },
        {
          name: 'Vue.js',
        },
      ],
    },
    {
      skillGroup: 'UI/UX Design',
      skill: [
        {
          name: 'Wireframing',
        },
        {
          name: 'Prototyping',
        },
        {
          name: 'User Testing',
        },
      ],
    },
    {
      skillGroup: 'Version Control',
      skill: [
        {
          name: 'Git',
        },
        {
          name: 'SVN',
        },
      ],
    },
    {
      skillGroup: 'Responsive Design',
      skill: [
        {
          name: 'Responsive Web Design',
        },
        {
          name: 'Mobile-First Design',
        },
      ],
    },
    {
      skillGroup: 'Performance Optimization',
      skill: [
        {
          name: 'Web Performance',
        },
        {
          name: 'Browser Developer Tools',
        },
      ],
    },
  ],
  projects: [
    {
      name: 'E-commerce Website Redesign',
      description:
        'Revamped the user interface and navigation of an e-commerce website, resulting in a 30% increase in user engagement.',
      startDate: '2019-02-01',
      endDate: '2020-05-31',
      present: false,
    },
    {
      name: 'Interactive Web Dashboard',
      description:
        'Developed an interactive dashboard for visualizing real-time data analytics, enabling users to make informed decisions.',
      startDate: '2017-08-15',
      endDate: '2018-12-31',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Excellence in Frontend Development',
      date: '2022-03-10',
      awarder: 'Apple Inc.',
      summary:
        'Recognized for outstanding contributions to frontend development and user interface design.',
    },
    {
      title: 'Innovation Award',
      date: '2018-10-18',
      awarder: 'Netflix',
      summary:
        'Received for innovative solutions and impactful contributions to the development of visually appealing user interfaces.',
    },
  ],
  customSections: [],
}
