import axios from 'axios'
import jwt_decode from 'jwt-decode'
import ReactGA from 'react-ga4'
import setAuthToken from '../utils/setAuthToken'

import ActionTypes from './types'

// Register User
export const registerUser = (userData, props) => (dispatch) => {
  axios
    .post('/signup', userData)
    .then((res) => {
      dispatch({
        type: ActionTypes.SET_REFERRAL_CODE,
        payload: null,
      })
      ReactGA.event('sign_up')
      const { token, user } = res.data
      localStorage.setItem('jwtToken', token)
      // Set token to Auth header
      setAuthToken(token)
      // Set current user
      dispatch(setIsAuthenticated(true))
      // dispatch(setCurrentUser(user))
    })
    .catch((err) =>
      dispatch({
        type: ActionTypes.GET_ERRORS,
        payload: err.response.data,
      })
    )
}

// Resend verification email
export const resendVerification = (userData, props) => (dispatch) => {
  axios
    .post('/resend', userData)
    .then((res) => {
      dispatch({
        type: ActionTypes.SET_NOTIFICATIONS,
        payload: res.data,
      })
      props.history.push('/auth/login')
      // history.push("/login");
      // toast.success('A verification email has been sent to your email.');
    })
    .catch((err) =>
      dispatch({
        type: ActionTypes.GET_ERRORS,
        payload: err.response.data,
      })
    )
}

// Confirm user
export const confirmUser = (userData) => (dispatch) => {
  axios
    .post('/confirmation', userData)
    .then((res) => {
      dispatch({
        type: ActionTypes.SET_NOTIFICATIONS,
        payload: res.data,
      })
      // toast.success('Your account has been confirmed! Sign in to your account.')
    })
    .catch((err) =>
      dispatch({
        type: ActionTypes.GET_ERRORS,
        payload: err.response.data,
      })
    )
}

// Login - get user token
export const loginUser = (userData, props) => (dispatch) => {
  axios
    .post('/login', userData)
    .then((res) => {
      // Save to localStorage

      ReactGA.event('login')
      // Set token to localStorage
      const { token, user } = res.data
      localStorage.setItem('jwtToken', token)
      // localStorage.setItem("user", JSON.stringify(user));
      // Set token to Auth header
      setAuthToken(token)
      // Decode token to get user data
      const decoded = jwt_decode(token)
      // Set current user
      dispatch(setIsAuthenticated(true))
      dispatch(setCurrentUser(user))
    })
    .catch((err) => {
      if (err.response.status === 302) {
        props.history.push(err.response.data.url)
        dispatch({
          type: ActionTypes.GET_ERRORS,
          payload: err.response.data,
        })
        // history.push(err.response.data.url)
      } else {
        dispatch({
          type: ActionTypes.GET_ERRORS,
          payload: err.response.data,
        })
      }
    })
}

// Forgot password
export const forgotPassword = (userData, props) => (dispatch) => {
  // console.log(userData);
  axios
    .post('/forgot', userData)
    .then((res) => {
      dispatch({
        type: ActionTypes.SET_NOTIFICATIONS,
        payload: res.data,
      })
      props.history.push('/auth/login')
      // history.push("/login");
      // dispatch(setForgot(true));
      // toast.success('Check your email to finish resetting your account.');
    })
    .catch((err) =>
      dispatch({
        type: ActionTypes.GET_ERRORS,
        payload: err.response.data,
      })
    )
}

// Reset password
export const resetPassword = (userData, token, props) => (dispatch) => {
  // console.log(userData);
  axios
    .post('/reset/' + token, userData)
    .then((res) => {
      dispatch({
        type: ActionTypes.SET_NOTIFICATIONS,
        payload: res.data,
      })
      props.history.push('/auth/login')
      // history.push("/login");
      // dispatch(setForgot(true));
      // toast.success('Your account has been updated. You can now login.');
    })
    .catch((err) =>
      dispatch({
        type: ActionTypes.GET_ERRORS,
        payload: err.response.data,
      })
    )
}

// Set logged in user
export const setCurrentUser = (decoded) => {
  localStorage.setItem('user', JSON.stringify(decoded))
  return {
    type: ActionTypes.SET_CURRENT_USER,
    payload: decoded,
  }
}

// User loading
export const setUserLoading = () => {
  return {
    type: ActionTypes.USER_LOADING,
  }
}

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem('jwtToken')
  // Remove auth header for future requests
  setAuthToken(false)
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}))
  dispatch(setIsAuthenticated(false))
  // history.push("/");
}

export const setIsAuthenticated = (value) => {
  return {
    type: ActionTypes.SET_IS_AUTHENTICATED,
    payload: value,
  }
}
