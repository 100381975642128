export const resume = {
  basics: {
    firstName: 'Jessica',
    lastName: 'Anderson',
    title: 'DevOps Engineer',
    picture: 'https://example.com/jessica-anderson.jpg',
    email: 'jessica.anderson@example.com',
    phone: '987-654-3210',
    url: 'https://www.jessicaanderson.dev',
    summary:
      'DevOps engineer with a proven track record of optimizing software development processes through automation, continuous integration, and deployment. Adept at designing, implementing, and maintaining scalable infrastructure and ensuring seamless collaboration between development and operations teams. Passionate about leveraging technology to achieve operational excellence.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/jessicaanderson',
    portfolioUrl: 'https://portfolio.jessicaanderson.dev',
  },
  experience: [
    {
      company: 'Microsoft',
      location: {
        city: 'Redmond',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'DevOps Engineer',
      url: 'https://www.microsoft.com',
      startDate: '2021-02-15',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Automated CI/CD pipelines using Jenkins, reducing deployment time by 40% and increasing release frequency.',
        'Implemented infrastructure as code using Terraform, resulting in 20% faster provisioning of cloud resources.',
        'Developed monitoring and alerting systems with Prometheus and Grafana, reducing incident response time by 30%.',
        'Collaborated with cross-functional teams to optimize application performance, resulting in a 25% reduction in latency.',
      ],
      present: false,
    },
    {
      company: 'Facebook',
      location: {
        city: 'Menlo Park',
        countryCode: 'US',
        region: 'California',
      },
      position: 'DevOps Engineer',
      url: 'https://www.facebook.com',
      startDate: '2019-07-01',
      endDate: '2021-01-31',
      summary: null,
      highlights: [
        'Optimized resource utilization and cost efficiency on AWS by implementing autoscaling and reserved instances.',
        'Led incident response and root cause analysis efforts, resulting in a 20% reduction in mean time to resolution.',
        'Mentored junior engineers and conducted workshops to promote DevOps best practices and knowledge sharing.',
      ],
      present: false,
    },
    {
      company: 'Netflix',
      location: {
        city: 'Los Gatos',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Software Engineer',
      url: 'https://www.netflix.com',
      startDate: '2017-05-01',
      endDate: '2019-06-30',
      summary: null,
      highlights: [
        'Designed and implemented containerized applications using Docker and orchestrated with Kubernetes, achieving high scalability and availability.',
        'Developed custom deployment tools and scripts, reducing manual intervention and deployment errors by 40%.',
        'Collaborated with cross-functional teams to improve continuous integration processes, resulting in a 30% increase in code quality.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2015-09-01',
      endDate: '2017-06-30',
      gpa: '3.8',
      courses: [
        'Cloud Computing',
        'Distributed Systems',
        'Software Engineering',
      ],
      summary: '',
      present: false,
    },
    {
      institution: 'University of California, Berkeley',
      degree:
        'Bachelor of Science in Electrical Engineering and Computer Science',
      major: 'Electrical Engineering and Computer Science',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2011-09-01',
      endDate: '2015-05-31',
      gpa: '3.9',
      courses: ['Operating Systems', 'Database Systems', 'Algorithms'],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Outstanding DevOps Professional Award',
      date: '2022-03-15',
      awarder: 'DevOps Excellence Association',
      summary:
        'Recognized for exceptional contributions to the field of DevOps and continuous improvement.',
    },
  ],
  skills: [
    {
      skillGroup: 'DevOps Tools',
      skill: [
        { name: 'Jenkins' },
        { name: 'Terraform' },
        { name: 'Kubernetes' },
        { name: 'Docker' },
        { name: 'AWS' },
        { name: 'Azure' },
        { name: 'Prometheus' },
        { name: 'Grafana' },
      ],
    },
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'Python' }, { name: 'Bash' }, { name: 'JavaScript' }],
    },
    {
      skillGroup: 'Infrastructure as Code',
      skill: [{ name: 'Terraform' }, { name: 'CloudFormation' }],
    },
    {
      skillGroup: 'Monitoring and Logging',
      skill: [
        { name: 'Prometheus' },
        { name: 'Grafana' },
        { name: 'ELK Stack' },
      ],
    },
  ],
  projects: [
    {
      name: 'Automated Deployment Platform',
      description:
        'Built a custom deployment platform using Kubernetes and Jenkins to streamline application deployments and reduce manual errors.',
      startDate: '2022-01-01',
      endDate: '2022-06-30',
      present: false,
    },
    {
      name: 'Infrastructure as Code',
      description:
        'Led the implementation of infrastructure as code using Terraform and CloudFormation, resulting in consistent and automated provisioning of cloud resources.',
      startDate: '2021-05-01',
      endDate: '2021-09-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Open Source Contributions',
      details: [
        {
          title: 'Kubernetes Helm',
          subtitle: 'Contributor',
          location: {
            city: 'Virtual',
            countryCode: 'Global',
            region: 'Online',
          },
          url: 'https://github.com/kubernetes/helm',
          startDate: '2021-03-01',
          endDate: '2023-01-31',
          summary:
            'Contributed enhancements and bug fixes to the Kubernetes Helm project, a package manager for Kubernetes applications.',
          highlights: [
            'Developed new Helm charts and improved existing ones for popular microservices and applications.',
            'Collaborated with the community to address user issues and improve documentation.',
            'Reviewed and merged pull requests, ensuring code quality and project integrity.',
          ],
          present: false,
        },
      ],
    },
  ],
}
