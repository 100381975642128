import List, { Item } from '../../List'
import ValueMapper from '../ValueMapper'

class SkillValueMapper extends ValueMapper {
  constructor(skillEntry, styles) {
    super()
    this.skillEntry = skillEntry
    this.styles = styles
  }

  title() {
    return this.skillEntry.skillGroup
  }

  subtitle() {
    return null
  }

  text() {
    return null
  }

  children() {
    return (
      this.skillEntry.skill && (
        <List>
          {this.skillEntry.skill.map((s, i) => {
            if (s.name) {
              return (
                <Item styles={this.styles} key={i}>
                  {s.name}
                </Item>
              )
            }
            return (
              <Item styles={this.styles} key={i}>
                {' '}
              </Item>
            )
          })}
        </List>
      )
    )
  }
}

export default SkillValueMapper
