export const resume = {
  basics: {
    firstName: 'John',
    lastName: 'Doe',
    title: 'Embedded Software Engineer',
    picture: 'https://example.com/john-doe.jpg',
    email: 'john.doe@example.com',
    phone: '123-456-7890',
    url: 'https://www.johndoedeveloper.com',
    summary:
      'Results-oriented Embedded Software Engineer with a strong background in developing and optimizing embedded systems. Proven track record of designing and implementing efficient and reliable software solutions for hardware platforms. Skilled in C/C++ programming, firmware development, and system-level integration.',
    location: {
      city: 'San Jose',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/johndoe',
    portfolioUrl: 'https://portfolio.johndoedeveloper.com',
  },
  experience: [
    {
      company: 'Intel Corporation',
      location: {
        city: 'Santa Clara',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Embedded Software Engineer',
      url: 'https://www.intel.com',
      startDate: '2019-04-15',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Led the design and implementation of a real-time communication protocol for an IoT device, resulting in a 20% reduction in data transfer latency.',
        'Optimized memory usage and reduced resource overhead by 15% through code refactoring and memory profiling techniques.',
        'Collaborated with hardware engineers to define software requirements, ensuring seamless integration between software and hardware components.',
        'Debugged and resolved critical issues in embedded software, ensuring product stability and reliability.',
        'Mentored junior engineers, providing technical guidance and facilitating knowledge sharing within the team.',
      ],
      present: false,
    },
    {
      company: 'NVIDIA Corporation',
      location: {
        city: 'Santa Clara',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Firmware Engineer',
      url: 'https://www.nvidia.com',
      startDate: '2017-08-01',
      endDate: '2019-03-31',
      summary: null,
      highlights: [
        'Designed and implemented low-level firmware drivers for GPU hardware components, resulting in improved system stability and performance.',
        'Collaborated with software and hardware teams to define firmware specifications and validate system-level functionality.',
        'Utilized performance profiling tools to identify and address performance bottlenecks, resulting in a 10% increase in system responsiveness.',
      ],
      present: false,
    },
    {
      company: 'Qualcomm Technologies',
      location: {
        city: 'San Diego',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Software Development Engineer',
      url: 'https://www.qualcomm.com',
      startDate: '2015-06-01',
      endDate: '2017-07-31',
      summary: null,
      highlights: [
        'Implemented and tested software features for wireless communication protocols, ensuring seamless connectivity and data transfer.',
        'Optimized power consumption of embedded software through low-power design techniques, extending battery life by 15%.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'University of California, Los Angeles',
      degree: 'Master of Science in Electrical Engineering',
      major: 'Electrical Engineering',
      location: {
        city: 'Los Angeles',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2013-09-01',
      endDate: '2015-05-31',
      gpa: '3.8',
      courses: [
        'Embedded Systems Design',
        'Real-Time Operating Systems',
        'Digital Signal Processing',
      ],
      summary: '',
      present: false,
    },
    {
      institution: 'University of Texas at Austin',
      degree: 'Bachelor of Science in Computer Engineering',
      major: 'Computer Engineering',
      location: {
        city: 'Austin',
        countryCode: 'US',
        region: 'Texas',
      },
      startDate: '2009-08-01',
      endDate: '2013-05-31',
      gpa: '3.6',
      courses: [
        'Microprocessor Systems',
        'Embedded System Architecture',
        'Digital Logic Design',
      ],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Outstanding Embedded Software Engineer Award',
      date: '2022-05-15',
      awarder: 'Embedded Systems Association',
      summary:
        'Recognized for exceptional contributions to the field of embedded software engineering and innovative solutions in hardware-software integration.',
    },
  ],
  skills: [
    {
      skillGroup: 'Programming Languages',
      skill: [
        { name: 'C' },
        { name: 'C++' },
        { name: 'Python' },
        { name: 'Assembly' },
      ],
    },
    {
      skillGroup: 'Embedded Systems',
      skill: [
        { name: 'RTOS' },
        { name: 'Microcontroller Programming' },
        { name: 'Device Drivers' },
        { name: 'System Integration' },
      ],
    },
    {
      skillGroup: 'Firmware Development',
      skill: [
        { name: 'ARM Assembly' },
        { name: 'Bootloaders' },
        { name: 'Peripheral Drivers' },
        { name: 'Debugging Tools' },
      ],
    },
  ],
  projects: [
    {
      name: 'Smart Home Automation System',
      description:
        'Developed an embedded software solution for a smart home automation system, enabling remote control of devices via a mobile app.',
      startDate: '2021-01-15',
      endDate: '2021-06-30',
      present: false,
    },
    {
      name: 'Real-Time Communication Protocol',
      description:
        'Led the design and implementation of a real-time communication protocol for an IoT device, resulting in a 20% reduction in data transfer latency.',
      startDate: '2020-03-01',
      endDate: '2020-08-31',
      present: false,
    },
    {
      name: 'Wireless Sensor Network',
      description:
        'Developed firmware for a wireless sensor network, enabling real-time data collection and transmission for environmental monitoring.',
      startDate: '2018-02-01',
      endDate: '2018-07-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Professional Memberships',
      details: [
        {
          title: 'Embedded Systems Association',
          subtitle: 'Member',
          location: {
            city: 'Online',
            countryCode: 'US',
            region: 'Virtual',
          },
          url: 'https://www.embeddedassociation.org',
          startDate: '2019-01-01',
          endDate: '2023-12-31',
          summary:
            'Active participant in knowledge-sharing forums and conferences related to embedded systems development.',
        },
      ],
    },
  ],
}
