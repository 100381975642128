import {
  ExamplesMapToArray,
  ResumeExamplesArrayNoItems,
} from 'marketing/resources/ResumeExamplesMap'
import { Link } from 'react-router-dom'

const ExamplesList = () => {
  return (
    <div className="relative">
      <div className="max-w-6xl mx-auto px-4 py-32">
        <div className="flex flex-col">
          <h1 className="mb-16 text-3xl font-bold text-gray-900">
            Check out some of our resume examples
          </h1>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-y-4 gap-x-6">
            {ResumeExamplesArrayNoItems.map((category) => {
              const examples = ExamplesMapToArray(category.id)
              return examples.map((example) => (
                <Link to={example.to} key={example.id}>
                  <span className="text-lg hover:underline">
                    {example.name}
                  </span>
                </Link>
              ))
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExamplesList
