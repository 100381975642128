export const resume = {
  basics: {
    firstName: 'Aisha',
    lastName: 'Singh',
    title: 'Robotics Software Engineer',
    picture: 'https://example.com/aisha-singh.jpg',
    email: 'aisha.singh@example.com',
    phone: '987-654-3210',
    url: 'https://www.aishasingh.dev',
    summary:
      'Passionate Robotics Software Engineer with a strong background in developing and optimizing robotic control systems and algorithms. Experienced in designing and implementing perception, planning, and control software for autonomous robots. Dedicated to advancing robotics technology to solve real-world challenges.',
    location: {
      city: 'Pittsburgh',
      countryCode: 'US',
      region: 'Pennsylvania',
    },
    linkedInUrl: 'https://www.linkedin.com/in/aishasingh',
    portfolioUrl: 'https://portfolio.aishasingh.dev',
  },
  experience: [
    {
      company: 'Boston Dynamics',
      location: {
        city: 'Waltham',
        countryCode: 'US',
        region: 'Massachusetts',
      },
      position: 'Senior Robotics Software Engineer',
      url: 'https://www.bostondynamics.com',
      startDate: '2020-03-15',
      endDate: '2023-06-30',
      summary: null,
      highlights: [
        'Designed and implemented real-time control algorithms for legged robots, enhancing stability and locomotion capabilities.',
        'Optimized perception and sensor fusion algorithms, reducing sensor noise and improving environment awareness.',
        'Collaborated with cross-functional teams to integrate hardware and software components, resulting in successful robot prototypes.',
        'Contributed to the implementation of AI-driven behaviors and path planning algorithms for autonomous navigation and obstacle avoidance.',
      ],
      present: false,
    },
    {
      company: 'Fetch Robotics',
      location: {
        city: 'San Jose',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Robotics Software Engineer',
      url: 'https://www.fetchrobotics.com',
      startDate: '2018-07-01',
      endDate: '2020-02-28',
      summary: null,
      highlights: [
        'Implemented real-time mapping and localization algorithms, enabling accurate and reliable navigation in dynamic environments.',
        'Designed and optimized motion planning algorithms for efficient and collision-free robot movement.',
        'Collaborated with customers to gather requirements and customize robotic solutions to meet specific industrial needs.',
      ],
      present: false,
    },
    {
      company: 'Aurora Robotics',
      location: {
        city: 'Palo Alto',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Robotics Software Developer',
      url: 'https://www.aurora-robotics.com',
      startDate: '2016-04-15',
      endDate: '2018-06-30',
      summary: null,
      highlights: [
        'Implemented computer vision algorithms for object detection and tracking, enhancing the perception capabilities of drones.',
        'Collaborated with research teams to integrate machine learning models for tasks such as image recognition and path planning.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Carnegie Mellon University',
      degree: 'Master of Science in Robotics',
      major: 'Robotics',
      location: {
        city: 'Pittsburgh',
        countryCode: 'US',
        region: 'Pennsylvania',
      },
      startDate: '2014-09-01',
      endDate: '2016-05-15',
      gpa: '3.90',
      courses: [
        'Robot Perception and Sensing',
        'Robot Kinematics and Dynamics',
        'Artificial Intelligence for Robotics',
        'Robotic Manipulation',
      ],
      summary:
        'Acquired advanced knowledge in robotics principles, algorithms, and systems.',
      present: false,
    },
    {
      institution: 'University of California, Berkeley',
      degree: 'Bachelor of Science in Electrical Engineering',
      major: 'Electrical Engineering and Computer Science',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2010-08-01',
      endDate: '2014-05-15',
      gpa: '3.85',
      courses: [
        'Digital Signal Processing',
        'Control Systems',
        'Machine Learning',
        'Embedded Systems',
      ],
      summary:
        'Built a strong foundation in electrical engineering and computer science principles.',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Outstanding Robotics Achievement Award',
      date: '2022-02-15',
      awarder: 'Robotics Society of America',
      summary:
        'Recognized for exceptional contributions to the field of robotics and innovative software development.',
    },
    {
      title: 'Innovation in Robotic Control',
      date: '2019-05-01',
      awarder: 'IEEE Robotics and Automation Society',
      summary:
        'Received recognition for designing novel control algorithms for legged robots.',
    },
  ],
  skills: [
    {
      skillGroup: 'Robotics Software',
      skill: [
        { name: 'Robot Control Algorithms' },
        { name: 'SLAM and Localization' },
        { name: 'Path Planning' },
        { name: 'Computer Vision' },
      ],
    },
    {
      skillGroup: 'Programming Languages',
      skill: [
        { name: 'Python' },
        { name: 'C++' },
        { name: 'ROS' },
        { name: 'MATLAB' },
      ],
    },
    {
      skillGroup: 'Simulation and Modeling',
      skill: [{ name: 'Gazebo' }, { name: 'V-REP' }, { name: 'Simulink' }],
    },
  ],
  projects: [
    {
      name: 'Autonomous Drone Delivery System',
      description:
        'Led the development of an autonomous drone delivery system, integrating perception, planning, and control software to safely navigate and deliver packages.',
      startDate: '2022-01-15',
      endDate: '2022-07-30',
      present: false,
    },
    {
      name: 'Legged Robot Locomotion',
      description:
        'Designed and implemented control algorithms for a quadruped robot, achieving dynamic and stable locomotion on challenging terrains.',
      startDate: '2020-03-01',
      endDate: '2021-08-31',
      present: false,
    },
    {
      name: 'Robotic Manipulation',
      description:
        'Developed software for a robotic arm to perform complex manipulation tasks, including grasping and pick-and-place operations.',
      startDate: '2018-08-15',
      endDate: '2019-03-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Publications',
      details: [
        {
          title:
            'A. Singh, B. Chen, and C. Lee. "Real-Time Path Planning for Quadruped Robots on Uneven Terrain." Proceedings of the IEEE International Conference on Robotics and Automation (ICRA), 2023.',
          subtitle: 'IEEE International Conference on Robotics and Automation',
          location: {
            city: 'Paris',
            countryCode: 'FR',
            region: 'Île-de-France',
          },
          url: 'https://www.ieee-ras.org/robotics-and-automation',
          startDate: '2023-05-01',
          endDate: '2023-05-05',
          summary:
            'Presented research on dynamic path planning for legged robots to enhance mobility on challenging terrains.',
        },
        {
          title:
            'A. Singh, J. Zhang, and E. Kim. "Robotic Grasping and Manipulation with Visual Feedback." International Journal of Robotics Research (IJRR), 2021.',
          subtitle: 'International Journal of Robotics Research',
          location: {
            city: 'Oxford',
            countryCode: 'GB',
            region: 'England',
          },
          url: 'https://journals.sagepub.com/doi/10.1177/02783649211011234',
          startDate: '2021-02-15',
          endDate: '2021-06-15',
          summary:
            'Published findings on robotic manipulation techniques using visual feedback for improved grasp planning and execution.',
        },
      ],
    },
    {
      name: 'Languages',
      details: [
        {
          title: 'English',
          subtitle: 'Fluent',
        },
        {
          title: 'Spanish',
          subtitle: 'Intermediate',
        },
      ],
    },
  ],
}
