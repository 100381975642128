import { StyleSheet } from '@react-pdf/renderer'
import HorizontalHeader from '../common/header/HorizontalHeader'
import OneColumnLayout from '../common/layouts/OneColumnLayout'
import SplitTemplateEntry from '../common/template-entry/SplitTemplateEntry'
import DefaultTemplateSection from '../common/template-section/DefaultTemplateSection'
import CustomSectionValueMapper from '../common/value-mappers/custom-section/CustomSectionValueMapper'
import SplitEducationValueMapper from '../common/value-mappers/education/SplitEducationValueMapper'
import SplitExperienceValueMapper from '../common/value-mappers/experience/SplitExperienceValueMapper'
import InlineProjectValueMapper from '../common/value-mappers/project/InlineProjectValueMapper'
import InlineSkillValueMapper from '../common/value-mappers/skill/InlineSkillValueMapper'
import SplitCustomSectionValueMapper from '../common/value-mappers/custom-section/SplitCustomSectionValueMapper'

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 50,
    paddingVertical: 40,
    fontFamily: 'Open Sans',
  },
  sectionTitle: {
    fontFamily: 'Open Sans',
    fontSize: 9,
    marginBottom: 10,
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  entryContainer: {
    marginBottom: 10,
  },
  title: {
    fontSize: 9.5,
    fontFamily: 'Open Sans',
    fontWeight: 600,
  },
  titleSecondary: {
    fontFamily: 'Open Sans',
    marginLeft: 2,
    fontWeight: 400,
  },
  inlineSubtitle: {
    fontSize: 9,
    lineHeight: 1.5,
    fontFamily: 'Open Sans',
  },
  subtitle: {
    fontSize: 8.5,
    fontFamily: 'Open Sans',
  },
  detailContainer: {
    flexDirection: 'row',
  },
  details: {
    lineHeight: 1.5,
    marginBottom: 1,
  },
  headerContainer: {
    flexDirection: 'row',
    marginBottom: 3,
    alignItems: 'flex-end',
  },
  leftColumn: {
    flex: 1,
  },
  rightColumn: {},
  highlights: {
    lineHeight: 1.5,
  },
  htmlContent: {
    fontFamily: 'Open Sans',
    fontSize: 9,
  },
  item: {
    marginBottom: 1,
  },
  itemContent: {
    lineHeight: 1.5,
  },
})

const ModernConfigs = {
  styles: styles,
  Layout: OneColumnLayout,
  Header: HorizontalHeader,
  entryTransformerMap: {
    education: SplitEducationValueMapper,
    experience: SplitExperienceValueMapper,
    projects: InlineProjectValueMapper,
    skills: InlineSkillValueMapper,
    custom: SplitCustomSectionValueMapper,
  },
  TemplateSection: DefaultTemplateSection,
  TemplateEntry: SplitTemplateEntry,
  settings: {},
}

export default ModernConfigs
