import { View } from '@react-pdf/renderer'
import HtmlContent from './HtmlContent'

import SafeText from './SafeText'

const List = ({ children }) => children

export const Item = ({ styles, bullet = false, children }) => {
  return (
    <View style={styles?.item}>
      <SafeText val={children} style={styles?.itemContent}>
        <HtmlContent styles={styles?.htmlContent}>
          {bullet ? `•&nbsp;&nbsp;${children}` : children}
        </HtmlContent>
      </SafeText>
    </View>
  )
}

export default List
