export const resume = {
  basics: {
    firstName: 'Emily',
    lastName: 'Smith',
    title: 'UI/UX Engineer',
    picture: 'https://example.com/emily-smith.jpg',
    email: 'emily.smith@example.com',
    phone: '987-654-3210',
    url: 'https://www.emilysmith.design',
    summary:
      'Passionate UI/UX Engineer with a strong background in creating intuitive and visually appealing user interfaces. Experienced in designing user-centric digital experiences that enhance usability and drive user engagement. Committed to delivering outstanding designs that seamlessly combine aesthetics with functionality.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/emilysmith',
    portfolioUrl: 'https://portfolio.emilysmith.design',
  },
  experience: [
    {
      company: 'Google',
      location: {
        city: 'Mountain View',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Senior UI/UX Designer',
      url: 'https://www.google.com',
      startDate: '2017-06-15',
      endDate: '2023-08-31',
      summary: null,
      highlights: [
        'Designed and prototyped user interfaces for the Google Search app, resulting in a 15% increase in user engagement and improved discoverability of features.',
        'Led a cross-functional design team in creating a new mobile app interface for Google Maps, contributing to a 20% growth in active users within six months.',
        'Collaborated with engineers to ensure seamless implementation of designs and provided detailed design specifications and assets, resulting in pixel-perfect user interfaces.',
        "Contributed to the development of Google's Material Design guidelines and provided mentorship to junior designers to promote design consistency and best practices.",
      ],
      present: false,
    },
    {
      company: 'Adobe',
      location: {
        city: 'San Jose',
        countryCode: 'US',
        region: 'California',
      },
      position: 'UI/UX Designer',
      url: 'https://www.adobe.com',
      startDate: '2015-03-01',
      endDate: '2017-05-31',
      summary: null,
      highlights: [
        "Redesigned the user interface of Adobe Photoshop's toolbar, resulting in a 30% increase in user efficiency and improved accessibility of tools.",
        "Led the redesign of Adobe Illustrator's color picker tool, incorporating user feedback and usability studies to create a more intuitive and efficient color selection process.",
      ],
      present: false,
    },
    {
      company: 'Airbnb',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Junior UI/UX Designer',
      url: 'https://www.airbnb.com',
      startDate: '2013-09-15',
      endDate: '2015-01-31',
      summary: null,
      highlights: [
        'Designed and implemented new user flows for the Airbnb booking process, resulting in a 20% increase in completed bookings and a 15% decrease in user drop-off rates.',
        'Assisted in the creation of interactive prototypes and conducted user testing sessions to gather insights and validate design decisions, leading to a 25% improvement in task completion rates.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Human-Computer Interaction',
      major: 'Human-Computer Interaction',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2011-08-15',
      endDate: '2013-05-31',
      gpa: '3.9',
      courses: [
        'User-Centered Design',
        'Usability Testing',
        'Information Architecture',
      ],
      summary: '',
      present: false,
    },
    {
      institution: 'University of California, Berkeley',
      degree: 'Bachelor of Arts in Design',
      major: 'Design',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2007-08-15',
      endDate: '2011-05-31',
      gpa: '3.8',
      courses: ['Visual Communication', 'Interaction Design', 'Typography'],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Excellence in UI/UX Design',
      date: '2022-01-15',
      awarder: 'UX Design Awards',
      summary:
        'Recognized for outstanding contributions to user interface and user experience design in the tech industry.',
    },
  ],
  skills: [
    {
      skillGroup: 'UI/UX Design',
      skill: [
        { name: 'User Research' },
        { name: 'Wireframing' },
        { name: 'Prototyping' },
        { name: 'Usability Testing' },
      ],
    },
    {
      skillGroup: 'Design Tools',
      skill: [
        { name: 'Adobe XD' },
        { name: 'Sketch' },
        { name: 'Figma' },
        { name: 'InVision' },
      ],
    },
    {
      skillGroup: 'Front-End Development',
      skill: [{ name: 'HTML' }, { name: 'CSS' }, { name: 'JavaScript' }],
    },
  ],
  projects: [
    {
      name: 'Serenity App',
      description:
        'Designed and prototyped a meditation and mindfulness app focused on providing users with a serene and calming experience.',
      startDate: '2022-03-01',
      endDate: '2022-06-30',
      present: false,
    },
    {
      name: 'Foodie Discover',
      description:
        'Led the UI/UX design for a food discovery app that offers personalized restaurant recommendations based on user preferences.',
      startDate: '2021-05-01',
      endDate: '2021-09-30',
      present: false,
    },
    {
      name: 'TravelPlanner',
      description:
        'Contributed to the design and development of a travel planning platform, enabling users to create and organize their dream vacations.',
      startDate: '2020-01-15',
      endDate: '2020-08-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Conference Presentations',
      details: [
        {
          title: 'Enhancing User Engagement through Visual Storytelling',
          subtitle: 'UX Design Conference 2022',
          location: {
            city: 'San Francisco',
            countryCode: 'US',
            region: 'California',
          },
          url: 'https://www.uxdesignconf.com/sessions/...',
          startDate: '2022-08-15',
          endDate: '2022-08-17',
          summary:
            'Delivered a talk on using visual storytelling techniques to create emotionally compelling user experiences.',
          highlights: [
            'Shared case studies from real projects to demonstrate the impact of storytelling in design.',
            'Provided practical tips for incorporating storytelling elements into UI/UX designs to evoke user empathy and engagement.',
          ],
          present: false,
        },
      ],
    },
  ],
}
