import { PencilIcon } from '@heroicons/react/outline'
import { useEffect, useRef, useState } from 'react'
import { classNames } from 'utils/helper'

function ResumeNameEditable({ name, updateName }) {
  const [text, setText] = useState(name)
  const [isFocus, setIsFocus] = useState(false)
  const handleBlur = () => {
    setIsFocus(false)
    if (text !== name) {
      updateName(text)
    }
  }
  const handleChange = (e) => {
    setText(e.target.value)
  }
  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsFocus(false)
    }
  }
  const inputRef = useRef(null)
  const displayRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()
  }, [isFocus])

  return (
    <div
      className={classNames(
        'cursor-pointer min-w-0 relative hover:bg-gray-100 rounded group'
      )}
    >
      <input
        ref={inputRef}
        value={text}
        type="text"
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleOnKeyDown}
        style={{
          width: displayRef?.current?.getBoundingClientRect().width + 'px',
        }}
        className={classNames(
          isFocus ? 'block' : 'hidden',
          'py-1 px-3 w-full border-gray-200 rounded-md text font-medium focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400'
        )}
      />
      <div ref={displayRef}>
        <div
          onClick={() => {
            setIsFocus(true)
          }}
          className={classNames(
            isFocus ? 'hidden' : 'block',
            'border border-white rounded truncate font-medium py-2 px-3 pr-8 flex-1 focus:outline-none'
          )}
        >
          {text}
        </div>
        {!isFocus && (
          <div
            onClick={() => {
              setIsFocus(true)
            }}
            className="absolute right-0 pr-3 inset-y-0 flex items-center"
          >
            <PencilIcon className="group-hover:text-gray-700 w-4 h-4 text-gray-400" />
          </div>
        )}
      </div>
    </div>
  )
}

export default ResumeNameEditable
