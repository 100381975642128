import React from 'react'
import { Formik, Field } from 'formik'

const Form = (props) => {
  return (
    <Formik
      initialValues={{
        email: undefined,
      }}
      onSubmit={(values, actions) => {
        props.onSubmit(values)
      }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <div>
              <Field
                autoFocus
                component="input"
                name="email"
                type="email"
                placeholder="Email"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
          <div className="text-center mt-6">
            <button
              className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
              type="submit"
            >
              Forgot Password
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default Form
