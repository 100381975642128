export const resume = {
  basics: {
    firstName: 'Emma',
    lastName: 'Smith',
    title: 'Algorithm Engineer',
    picture: 'https://example.com/emma-smith.jpg',
    email: 'emma.smith@example.com',
    phone: '555-987-6543',
    url: 'https://www.emmasmith-algorithms.dev',
    summary:
      'Passionate Algorithm Engineer with a strong background in designing and optimizing complex algorithms for solving real-world problems. Experienced in algorithm analysis, implementation, and optimization across various domains.',
    location: {
      city: 'New York',
      countryCode: 'US',
      region: 'New York',
    },
    linkedInUrl: 'https://www.linkedin.com/in/emmasmith',
    portfolioUrl: 'https://portfolio.emmasmith-algorithms.dev',
  },
  experience: [
    {
      company: 'Microsoft Research',
      location: {
        city: 'Redmond',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Senior Algorithm Engineer',
      url: 'https://www.microsoft.com',
      startDate: '2019-06-15',
      endDate: '2022-08-31',
      summary: null,
      highlights: [
        'Designed and implemented efficient algorithms for large-scale data processing, reducing execution time by 30%.',
        'Collaborated with interdisciplinary teams to address complex research challenges and contributed to multiple published papers.',
        'Developed novel optimization algorithms for resource allocation, resulting in a 25% reduction in energy consumption in data centers.',
      ],
      present: false,
    },
    {
      company: 'Uber Technologies',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Algorithm Engineer',
      url: 'https://www.uber.com',
      startDate: '2017-02-01',
      endDate: '2019-04-30',
      summary: null,
      highlights: [
        'Designed dynamic pricing algorithms that increased overall driver earnings by 15% while maintaining rider satisfaction.',
        'Implemented route optimization algorithms, reducing average trip durations by 10% and saving millions of travel hours annually.',
        'Contributed to the development of machine learning algorithms for demand forecasting, leading to a 20% improvement in supply-demand balancing.',
      ],
      present: false,
    },
    {
      company: 'IBM Research',
      location: {
        city: 'Yorktown Heights',
        countryCode: 'US',
        region: 'New York',
      },
      position: 'Research Intern',
      url: 'https://www.ibm.com',
      startDate: '2016-06-01',
      endDate: '2016-08-31',
      summary: null,
      highlights: [
        'Developed a novel optimization algorithm for resource allocation in cloud computing environments, improving resource utilization by 20%.',
        'Contributed to the implementation and testing of machine learning algorithms for natural language processing tasks.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Massachusetts Institute of Technology (MIT)',
      degree: 'Doctor of Philosophy (Ph.D.) in Computer Science',
      major: 'Algorithms and Complexity',
      location: {
        city: 'Cambridge',
        countryCode: 'US',
        region: 'Massachusetts',
      },
      startDate: '2013-09-01',
      endDate: '2019-05-15',
      gpa: '4.0',
      courses: [
        'Advanced Algorithms',
        'Randomized Algorithms',
        'Combinatorial Optimization',
        'Theory of Computation',
      ],
      summary: null,
      present: false,
    },
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Electrical Engineering',
      major: 'Signal Processing',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2011-09-01',
      endDate: '2013-06-15',
      gpa: '3.9',
      courses: [
        'Digital Signal Processing',
        'Statistical Signal Processing',
        'Machine Learning',
        'Convex Optimization',
      ],
      summary: null,
      present: false,
    },
    {
      institution: 'University of California, Berkeley',
      degree: 'Bachelor of Science in Computer Science',
      major: 'Algorithms and Data Structures',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2007-08-01',
      endDate: '2011-05-15',
      gpa: '3.8',
      courses: [
        'Algorithm Design and Analysis',
        'Data Structures',
        'Theory of Computation',
        'Machine Learning',
      ],
      summary: null,
      present: false,
    },
  ],
  awards: [
    {
      title: 'Outstanding Research Contribution Award',
      date: '2021-02-28',
      awarder: 'Association for Computing Machinery (ACM)',
      summary:
        'Recognized for exceptional contributions to the field of algorithm design and analysis.',
    },
    {
      title: 'Innovator of the Year',
      date: '2020-09-15',
      awarder: 'IEEE Computer Society',
      summary:
        'Received for pioneering work in developing novel optimization algorithms for resource allocation.',
    },
  ],
  skills: [
    {
      skillGroup: 'Algorithm Design and Analysis',
      skill: [
        { name: 'Complexity Theory' },
        { name: 'Greedy Algorithms' },
        { name: 'Dynamic Programming' },
        { name: 'Network Flow Algorithms' },
      ],
    },
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'C++' }, { name: 'Python' }, { name: 'Java' }],
    },
    {
      skillGroup: 'Machine Learning',
      skill: [
        { name: 'Supervised Learning' },
        { name: 'Unsupervised Learning' },
        { name: 'Reinforcement Learning' },
        { name: 'Neural Networks' },
      ],
    },
  ],
  projects: [
    {
      name: 'Genetic Algorithm Optimization Framework',
      description:
        'Developed a genetic algorithm framework for solving complex optimization problems, achieving optimal solutions in various domains.',
      startDate: '2022-02-01',
      endDate: '2022-06-30',
      present: false,
    },
    {
      name: 'Real-Time Traffic Prediction',
      description:
        'Led a team to build a real-time traffic prediction system using machine learning algorithms, improving accuracy by 20% over existing methods.',
      startDate: '2018-09-01',
      endDate: '2019-03-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Professional Memberships',
      details: [
        {
          title: 'Member',
          subtitle: 'Association for Computing Machinery (ACM)',
          location: {
            city: 'New York',
            countryCode: 'US',
            region: 'New York',
          },
          startDate: '2015-01-01',
          endDate: null,
          summary:
            'Active participant in ACM events, conferences, and knowledge-sharing initiatives.',
          highlights: [],
          present: true,
        },
        {
          title: 'Member',
          subtitle: 'IEEE Computer Society',
          location: {
            city: 'San Francisco',
            countryCode: 'US',
            region: 'California',
          },
          startDate: '2016-03-15',
          endDate: null,
          summary:
            'Engaged in knowledge exchange and networking with fellow professionals in the field.',
          highlights: [],
          present: true,
        },
      ],
    },
  ],
}
