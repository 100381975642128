import {
  CogIcon,
  DocumentTextIcon,
  LogoutIcon,
  OfficeBuildingIcon,
  SearchCircleIcon,
  SparklesIcon,
  TableIcon,
  UserIcon,
} from '@heroicons/react/outline'
import { ArrowRightIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { FacebookIcon, LinkedinIcon, TwitterIcon } from 'react-share'

export const CompanyCard = ({
  name,
  url,
  description,
  location,
  image_id,
  twitter,
  linkedin,
  facebook,
  permalink,
  listed_stock_symbol = null,
  showButton = false,
}) => {
  console.log(name[0])
  return (
    <div className="flex-none flex flex-col text-left">
      <div className="py-6 sm:py-8 px-4 sm:px-6">
        <div className="w-full flex flex-row items-start gap-4">
          {image_id ? (
            <img
              className="w-12 sm:w-20 flex-none bg-white"
              alt="logo"
              src={`https://res.cloudinary.com/crunchbase-production/image/upload/${image_id}`}
            ></img>
          ) : (
            <div className="w-20 h-20 flex-none bg-gray-100 flex items-center justify-center text-4xl text-blue-600">
              {name[0]}
            </div>
          )}
          <div className="flex flex-col items-start gap-4">
            <div className="flex-1">
              <p className="text-gray-900 font-semibold text-2xl">{name}</p>
              <p className="text-gray-500 line-clamp-2">{description}</p>
            </div>
            {showButton && (
              <div className="h-full flex flex-col">
                <Link to={`/dashboard/companies/${permalink}`}>
                  <button className="border sm:text-lg text-white font-semibold rounded bg-blue-600 hover:bg-blue-700 py-1.5 px-4 sm:py-2 sm:px-8">
                    View Profile
                  </button>
                </Link>
              </div>
            )}
            <div className="grid grid-cols-1 sm:grid-cols-3 w-full gap-4">
              <div className="col-span-1">
                <div className="text-sm font-medium mb-1">Location</div>
                <div className="text-gray-900">{location}</div>
              </div>
              {listed_stock_symbol && (
                <div className="col-span-1">
                  <div className="text-sm font-medium mb-1">Stock Symbol</div>
                  <div className="text-gray-900">{listed_stock_symbol}</div>
                </div>
              )}
              <div className="sm:col-span-2">
                <div className="text-sm font-medium mb-1">Website</div>
                {url && (
                  <a
                    href={url}
                    target="_blank"
                    className="text-blue-500 hover:text-blue-600 inline-flex items-center space-x-1"
                    rel="noreferrer"
                  >
                    <span>{url}</span>
                    <ArrowRightIcon className="w-3 h-3 -rotate-45" />
                  </a>
                )}
              </div>
              <div className="mt-3 col-span-1 sm:col-span-3 flex-1 flex items-center space-x-2">
                {facebook && (
                  <a href={facebook.value} target="_blank" rel="noreferrer">
                    <FacebookIcon className="w-6 h-6 rounded-full" />
                  </a>
                )}
                {linkedin && (
                  <a href={linkedin.value} target="_blank" rel="noreferrer">
                    <LinkedinIcon className="w-6 h-6 rounded-full" />
                  </a>
                )}
                {twitter && (
                  <a href={twitter.value} target="_blank" rel="noreferrer">
                    <TwitterIcon className="w-6 h-6 rounded-full" />
                  </a>
                )}
                {permalink && (
                  <a
                    href={`https://crunchbase.com/organization/${permalink}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://res.cloudinary.com/crunchbase-production/image/upload/unj63uuxb8ooxctihr1w"
                      className="w-6 h-6 rounded-full"
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const navigation = [
  [{ name: 'Resumes', to: '/dashboard/resumes', Icon: DocumentTextIcon }],
  [
    {
      name: 'Jobs',
      to: '/dashboard/jobs',
      isComingSoon: true,
      Icon: SearchCircleIcon,
    },
    // {
    //   name: 'Companies',
    //   to: '/dashboard/companies',
    //   isComingSoon: true,
    //   Icon: OfficeBuildingIcon,
    // },
    // {
    //   name: 'Tracker',
    //   to: '/dashboard/track',
    //   isComingSoon: true,
    //   Icon: TableIcon,
    // },
  ],
  // [
  //   {
  //     name: 'Profile',
  //     to: '/dashboard/profile',
  //     isComingSoon: true,
  //     Icon: UserIcon,
  //   },
  // ],
  // [
  //   {
  //     name: 'Upgrade',
  //     Icon: SparklesIcon,
  //     to: '/dashboard/upgrade',
  //     hideSidebar: true,
  //   },
  // ],
]

export const subNavigation = [
  [{ name: 'Settings', to: '/dashboard/settings', Icon: CogIcon }],
  [{ name: 'Log out', to: '/auth/logout', Icon: LogoutIcon }],
]
