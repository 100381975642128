import { Dialog, Transition } from '@headlessui/react'
import {
  ChevronRightIcon,
  DocumentAddIcon,
  UploadIcon,
} from '@heroicons/react/outline'
import ThreeDotsLoadingScreen from 'components/LoadingScreens/ThreeDotsLoadingScreen'
import { Fragment, useEffect, useRef, useState } from 'react'

export default function CreateResumeModal({
  isOpen,
  closeModal,
  createResume,
  uploadResume,
}) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(undefined)
  const hiddenFileInput = useRef(null)

  useEffect(() => {
    if (error) {
      setError(undefined)
    }
  }, [isOpen])

  const handleChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      if (file.size < 2000) {
        setError('File is too small < 2 KB')
        return
      } else if (file.size > 10000000) {
        setError('File is too large > 10 MB')
        return
      }
      setLoading(true)
      uploadResume(file)
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden md:inline-block md:align-middle md:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              {loading ? (
                <div className="h-64 text-center font-medium text-lg">
                  Uploading resume
                  <ThreeDotsLoadingScreen />
                </div>
              ) : (
                <div>
                  <Dialog.Title
                    as="h3"
                    className="text-center text-lg font-medium leading-6 text-gray-900"
                  >
                    Let's get started
                  </Dialog.Title>
                  <div className="mt-2 text-center mb-6">
                    <p className="text-sm text-gray-500">
                      Choose whether to start fresh or use an existing resume
                    </p>
                  </div>
                  <div className="flex flex-col gap-2 px-6">
                    <div
                      onClick={() => createResume()}
                      className="h-24 p-4 rounded flex items-center gap-4 bg-gray-50 cursor-pointer"
                    >
                      <DocumentAddIcon className="w-10 h-10" />
                      <div className="flex-1">
                        <p className="font-semibold mb-1">Create new resume</p>
                        <p className="text-xs">
                          Fill out a new template with your details.
                        </p>
                      </div>
                      <ChevronRightIcon className="w-5 h-5" />
                    </div>
                    <div
                      onClick={() => hiddenFileInput.current.click()}
                      className="h-24 p-4 rounded flex items-center gap-4 bg-gray-50 cursor-pointer"
                    >
                      <input
                        type="file"
                        accept=".doc,.docx,.pdf"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: 'none' }}
                      />
                      <UploadIcon className="w-10 h-10" />
                      <div className="flex-1">
                        <p className="font-semibold mb-1">Upload resume</p>
                        <p className="text-xs">
                          Upload your existing resume (PDF, DOC, DOCX). File
                          must be less than 10MB.
                        </p>
                        {error && (
                          <p className="text-xs text-red-500 mt-1">{error}</p>
                        )}
                      </div>
                      <ChevronRightIcon className="w-5 h-5" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
