import { useDispatch } from 'react-redux'
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import axios from 'axios'
import ErrorMessage from 'components/Core/ErrorMessage'
import Form from 'components/Forms/Auth/ResetPasswordForm'

export default function ResetPassword(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const state = useLocation().state

  const resetPassword = (userData, token) => {
    axios
      .post('/reset', { token, ...userData })
      .then((res) => {
        navigate('/auth/login', {
          state: { notifications: [{ msg: res.data.msg }] },
        })
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 302:
              navigate(err.response.data.url)
              break
            case 400:
              navigate(
                {
                  pathname: '.',
                  search: `?${searchParams}`,
                },
                {
                  replace: true,
                  state: {
                    errors: err.response.data.map((v) => {
                      return {
                        msg: v.msg,
                      }
                    }),
                  },
                }
              )
              break
            case 401:
              navigate(
                {
                  pathname: '.',
                  search: `?${searchParams}`,
                },
                {
                  replace: true,
                  state: { errors: [{ msg: err.response.data.msg }] },
                }
              )
              break
            default:
              break
          }
        }
      })
  }

  if (!token) {
    return <Navigate to="/auth/login" />
  }

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <ErrorMessage
              errors={state?.errors}
              notifications={state?.notifications}
            />
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Reset Password
                  </h6>
                </div>
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <Form
                  onSubmit={(values) =>
                    dispatch(resetPassword(values, token, props))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
