import { DownloadIcon } from '@heroicons/react/outline'
import { CheckCircleIcon, ChevronLeftIcon } from '@heroicons/react/solid'
import CopyResumeLinkButton from 'components/Buttons/CopyResumeLinkButton'
import DownloadResumeButton from 'components/Buttons/DownloadResumeButton'
import ResumeNameEditable from 'components/Forms/EditorV2/ResumeNameEditable'
import ThreeDotsLoadingScreen from 'components/LoadingScreens/ThreeDotsLoadingScreen'
import Viewer from 'components/Viewer/Viewer'
import { useResume } from 'hooks/resumes'
import { createContext, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import PanelLayout from 'views/editor/PanelLayoutSelector'
import LoadingIcon from './editor/LoadingIcon'
import MobileLayoutContent from './editor/MobileLayoutContent'

function LayoutContent(props) {
  const { user } = useSelector((state) => state.auth)
  const { resume, downloadResume } = useContext(EditorContext)
  const newUser = new Date(user.createdAt) > new Date('2023-07-17')
  return (
    <div className="hidden sm:flex flex-1 overflow-y-hidden">
      <div className="flex flex-col flex-1 bg-white w-screen">
        <div className="flex items-center h-14 w-full border-b pr-6 gap-4">
          <div className="flex items-center h-full">
            <Link
              to="/dashboard"
              className="px-2 h-full items-center gap-1 flex"
            >
              <ChevronLeftIcon className="transition text-gray-400 w-4 h-4" />
              <span className="hidden sm:block transition font-medium text-gray-400 text-sm">
                Dashboard
              </span>
            </Link>
          </div>
          <div className="hidden flex-1 sm:flex items-center gap-1">
            <ResumeNameEditable
              name={props.resume.name}
              updateName={props.updateName}
            />
            <LoadingIcon />
          </div>
          <div className="hidden sm:flex justify-end gap-3">
            <button
              onClick={() => downloadResume(resume)}
              className="inline-flex items-center gap-2 px-4 py-1.5 font-semibold text-sm border border-blue-500 bg-blue-500 hover:bg-blue-600 text-white rounded cursor-pointer"
            >
              {/* <DownloadIcon className="w-4 h-4" /> */}
              Download PDF
            </button>
            {/* <DownloadResumeButton
              isVerified={user.isVerified}
              resume={props.resume}
              className="inline-flex items-center space-x-2 px-6 py-2 font-semibold text-md border border-gray-100 bg-gray-100 hover:bg-gray-200 text-gray-900 rounded cursor-pointer"
              text={
                <>
                  <DownloadIcon className="w-4 h-4" />
                  <span>Download PDF</span>
                </>
              }
              hideIcon
              hideTooltip
            /> */}
            {/* <CopyResumeLinkButton resumeId={props.resume.id} /> */}
            {!newUser && (
              <Link
                to={`/resumes/${resume.id}/edit`}
                className="px-6 py-2 bg-gray-100 text-gray-500 rounded text-sm font-medium flex items-center"
              >
                Switch to V1 Editor
              </Link>
            )}
          </div>
        </div>
        <div className="flex-1 flex w-full overflow-y-hidden">
          <PanelLayout
            {...{
              resume: props.resume,
              updateResume: props.updateResume,
              selectTemplate: props.selectTemplate,
              updateOrder: props.updateOrder,
            }}
          />
          <div className="hidden sm:block flex-1 h-full py-16 px-20 overflow-y-scroll overflow-x-hidden bg-gray-500">
            <Viewer resume={props.resume} />
          </div>
        </div>
      </div>
    </div>
  )
}

export const EditorContext = createContext(undefined)

export default function EditorLayout() {
  const [searchParams, setSearchParams] = useSearchParams()
  let params = useParams()
  const resumeContext = useResume(params.id)
  const {
    updateResume,
    selectTemplate,
    updateOrder,
    updateName,
    downloadResume,
    resume,
  } = resumeContext
  const downloadParam = searchParams.get('download')

  useEffect(() => {
    if (resume && downloadParam) {
      console.log('download')
      searchParams.delete('download')
      setSearchParams(searchParams)
      downloadResume()
    }
  }, [downloadParam, resume])

  if (resumeContext.resume === null) {
    return <ThreeDotsLoadingScreen />
  }

  return (
    <EditorContext.Provider value={{ ...resumeContext }}>
      <LayoutContent
        updateResume={updateResume}
        selectTemplate={selectTemplate}
        updateOrder={updateOrder}
        updateName={updateName}
        resume={resume}
      />
      <MobileLayoutContent />
    </EditorContext.Provider>
  )
}
