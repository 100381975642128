import debounce from 'just-debounce-it'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

function Autosave({ initialValues, onSubmit }) {
  const [initial, setInitial] = useState(true)
  const formValues = useWatch()
  const [prevState, setPrevState] = useState(initialValues)

  const { handleSubmit } = useFormContext()

  const debouncedSave = useCallback(
    debounce(() => {
      handleSubmit(onSubmit)()
    }, 1000),
    []
  )

  useEffect(() => {
    if (initial) {
      setInitial(false)
    } else if (!_.isEqual(prevState, formValues)) {
      debouncedSave()
    }

    setPrevState(_.cloneDeep(formValues))
    return () => {}
  }, [formValues])

  return null
}

export default Autosave
