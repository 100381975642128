import { useFormContext, useWatch } from 'react-hook-form'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './quill.css'

const QuillInput = ({ name, placeholder }) => {
  const editorContent = useWatch({ name: name })
  const { setValue } = useFormContext()
  const modules = {
    toolbar: [['bold', 'italic', 'underline']],
  }
  const formats = ['bold', 'italic', 'underline']

  const onEditorStateChange = (editorState) => {
    if (editorState === '<p><br></p>') {
      setValue(name, '')
    } else {
      const regex = /(style=".+?")/gm
      const result = editorState.replace(regex, '')
      setValue(name, result)
    }
  }

  return (
    <ReactQuill
      className="bg-blueGray-100"
      id={name}
      value={editorContent}
      onChange={onEditorStateChange}
      modules={modules}
      formats={formats}
      placeholder={placeholder}
    />
  )
}

export default QuillInput
