export const templates = [
  {
    premium: false,
    twoColumn: true,
    name: 'Sharp',
  },
  {
    premium: false,
    twoColumn: false,
    name: 'Modern',
  },
  {
    premium: true,
    twoColumn: false,
    name: 'Clean',
  },
  {
    premium: true,
    twoColumn: false,
    name: 'Fresh',
  },
  {
    premium: true,
    twoColumn: false,
    name: 'Pure',
  },
  {
    premium: true,
    twoColumn: false,
    name: 'Boston',
  },
  {
    premium: true,
    twoColumn: true,
    name: 'Portland',
  },
]

export const templateTabs = [
  {
    name: 'All Templates',
  },
  {
    name: 'Premium Templates',
    premium: true,
  },
  {
    name: 'Free Templates',
    premium: false,
  },
  {
    name: 'One-Column Templates',
    twoColumn: false,
  },
  {
    name: 'Two-Column Templates',
    twoColumn: true,
  },
]

export const customizeTabs = [
  {
    name: 'Section Ordering',
  },
  {
    name: 'Color',
    premium: true,
  },
]
