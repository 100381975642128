import { MinusIcon, XIcon } from '@heroicons/react/solid'
import DateInput from 'components/Forms/Editor/common/DateInput'
import moment from 'moment'
import { useController, useFieldArray, useFormContext } from 'react-hook-form'
import Modal from 'react-modal'
import ReactQuill from 'react-quill'
import { Checkbox } from 'views/editor/Form'
import Input from './Input'
import LoadingIcon from './LoadingIcon'

export const QuillInput = ({ value, setValue, placeholder }) => {
  const modules = {
    toolbar: [['bold', 'italic', 'underline']],
  }
  const formats = ['bold', 'italic', 'underline']
  const onEditorStateChange = (editorState) => {
    if (editorState === '<p><br></p>') {
      setValue('')
    } else setValue(editorState)
  }
  return (
    <ReactQuill
      className="bg-blueGray-100"
      value={value}
      onChange={onEditorStateChange}
      modules={modules}
      formats={formats}
      placeholder={placeholder}
    />
  )
}

const NestedArray = ({ section, idx, name, label, usage }) => {
  const { field } = useController({ name: `${section}.${idx}.${name}` })
  const onChange = (value, idx) => {
    const newValue = field.value
    newValue[idx] = value
    field.onChange(newValue)
  }
  const addValue = () => {
    const newValue = field.value
    newValue.push('')
    field.onChange(newValue)
  }
  const removeValue = (idx) => {
    const newValue = field.value
    newValue.splice(idx, 1)
    field.onChange(newValue)
  }
  return (
    <div className="col-span-12 grid gap-2">
      {label && (
        <label htmlFor={name} className="block text-sm">
          {label}
        </label>
      )}
      {field.value?.map((item, itemIdx) => {
        return (
          <div key={itemIdx} className="flex items-center gap-2">
            <div className="flex-1 min-w-0">
              <QuillInput
                value={item}
                setValue={(value) => onChange(value, idx)}
                placeholder={'Add a bullet point'}
              />
            </div>
            <button
              className="p-1 border rounded"
              type="button"
              onClick={() => removeValue(idx)}
            >
              <MinusIcon className="w-4 h-4" />
            </button>
          </div>
        )
      })}
      <div className="">
        <button
          className="text-sm font-semibold py-1.5 px-3 border rounded hover:bg-gray-100"
          type="button"
          onClick={addValue}
        >
          + Add bullet point
        </button>
      </div>
    </div>
  )
}

const NestedFieldArray = ({ section, idx, name, label, valueKey }) => {
  const { fields, append, remove } = useFieldArray({
    name: `${section}.${idx}.${name}`,
  })
  return (
    <div className="col-span-12 grid gap-2">
      {label && (
        <label htmlFor={name} className="block text-sm">
          {label}
        </label>
      )}
      {fields.map((item, itemIdx) => {
        return (
          <div className="inline-flex w-full items-center gap-2">
            <Input
              handleKeyDown={(e) => {
                if (e.key === 'Enter') {
                  append({ name: '', keywords: [] })
                }
              }}
              key={item.id}
              name={`${section}.${idx}.${name}.${itemIdx}.${valueKey}`}
              className="w-2/3"
              label=""
              placeholder="Add skill"
            />
            <button
              className="p-1 border rounded"
              type="button"
              onClick={() => remove(itemIdx)}
            >
              <MinusIcon className="w-4 h-4" />
            </button>
          </div>
        )
      })}
      <div className="w-full">
        <button
          className="text-sm font-semibold py-1.5 px-3 border rounded hover:bg-gray-100"
          type="button"
          onClick={() => append({ name: '', keywords: [] })}
        >
          + Add Skill
        </button>
      </div>
    </div>
  )
}

export default function EditItemModal({
  name,
  title,
  idx,
  isOpen,
  fields,
  closeModal,
  openConfirmModal,
  isCustomSection,
}) {
  const { register, watch, setValue } = useFormContext()

  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          zIndex: 10,
        },
      }}
      isOpen={isOpen}
      className="overflow-auto outline-none p-4 bg-gray-50 h-full mt-4"
      onRequestClose={closeModal}
      contentLabel="Edit Item Modal"
    >
      <h3 className="text-lg font-medium flex justify-between items-center text-gray-900">
        Edit {isCustomSection ? 'Entry' : title}
        <div className="flex items-center gap-2">
          <LoadingIcon />
          <div onClick={() => closeModal()} className="p-0.5">
            <XIcon className="w-5 h-5" />
          </div>
        </div>
      </h3>
      <div className="my-4">
        {idx !== undefined && (
          <>
            <div className="bg-white rounded-lg p-3 grid grid-cols-12 gap-3">
              {fields.map(([key, value]) => {
                if (value.isArray) {
                  return (
                    <NestedArray
                      label={value.label}
                      key={`${name}.${idx}.${key}`}
                      section={name}
                      name={key}
                      idx={idx}
                      usage={value.usage}
                    />
                  )
                }
                if (value.isFieldArray) {
                  return (
                    <NestedFieldArray
                      label={value.label}
                      valueKey={value.valueKey}
                      key={`${name}.${idx}.${key}`}
                      section={name}
                      name={key}
                      idx={idx}
                    />
                  )
                }
                if (value.isQuill) {
                  const fieldValue = watch(`${name}.${idx}.${key}`)
                  return (
                    <div key={`${name}.${idx}.${key}`} className="col-span-12">
                      <label className="mb-2 block text-sm font-normal">
                        {value.label}
                        {value.usage && (
                          <p className="mt-2 font-normal text-sm">
                            {value.usage}
                          </p>
                        )}
                      </label>
                      {fieldValue === null ? (
                        <div className="">
                          <button
                            className="text-sm font-semibold py-1.5 px-3 border rounded hover:bg-gray-100"
                            type="button"
                            onClick={() =>
                              setValue(`${name}.${idx}.${key}`, '')
                            }
                          >
                            + Add {value.label}
                          </button>
                        </div>
                      ) : (
                        <QuillInput
                          value={fieldValue}
                          setValue={(value) =>
                            setValue(`${name}.${idx}.${key}`, value)
                          }
                          placeholder={value.placeholder}
                        />
                      )}
                    </div>
                  )
                }
                if (value.isCheckbox) {
                  return (
                    <Checkbox
                      key={`${name}.${idx}.${key}`}
                      name={`${name}.${idx}.${key}`}
                      className={value.className}
                      label={value.label}
                      register={register}
                    />
                  )
                }
                if (value.isDate) {
                  const dateValue = watch(`${name}.${idx}.${key}`)
                  const present = value.isEndDate
                    ? watch(`${name}.${idx}.present`)
                    : null
                  const formattedDate = dateValue
                    ? moment.utc(dateValue).format('MM/YYYY')
                    : ''
                  return (
                    <DateInput
                      key={`${name}.${idx}.${key}`}
                      name={`${name}.${idx}.${key}`}
                      initialValue={formattedDate}
                      setValue={(v) => {
                        setValue(
                          `${name}.${idx}.${key}`,
                          moment.utc(v, 'MM/YYYY').toISOString()
                        )
                      }}
                      className={value.className}
                      label={value.label}
                      present={present}
                    />
                  )
                }
                return (
                  <Input
                    key={`${name}.${idx}.${key}`}
                    className={value.className}
                    name={`${name}.${idx}.${key}`}
                    label={value.label}
                  />
                )
              })}
            </div>
            <div className="mt-4 flex justify-between">
              <button
                onClick={() => {
                  // deleteItem(idx)
                  // closeModal()
                  openConfirmModal()
                  // setConfirmIsOpen(true)
                }}
                type="button"
                className="text-sm border bg-gray-100 border-red-400 py-2 px-6 text-red-400 rounded font-medium"
              >
                Delete
              </button>
              <button
                onClick={() => {
                  // deleteItem(idx)
                  closeModal()
                  // openConfirmModal()
                  // setConfirmIsOpen(true)
                }}
                type="button"
                className="text-sm border py-2 px-6 border-blue-500 bg-blue-500 text-white rounded font-medium"
              >
                Close
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}
