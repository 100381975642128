export const resume = {
  basics: {
    firstName: 'Alex',
    lastName: 'Martinez',
    title: 'iOS Developer',
    picture: 'https://example.com/profile_picture.jpg',
    email: 'alexmartinez@email.com',
    phone: '123-456-7890',
    url: 'https://alexmartinez.com',
    summary:
      'Skilled iOS Developer with a passion for creating innovative and user-centric mobile applications. Proficient in Swift, Objective-C, and app architecture.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'CA',
    },
    linkedInUrl: 'https://www.linkedin.com/in/alexmartinez',
    portfolioUrl: 'https://github.com/alexmartinez',
  },
  experience: [
    {
      company: 'Apple Inc.',
      location: {
        city: 'Cupertino',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'iOS Developer',
      url: 'https://www.apple.com',
      startDate: '2020-01-01',
      endDate: '2023-07-31',
      summary:
        "Developed and maintained high-quality and user-friendly iOS applications for Apple's ecosystem.",
      highlights: [
        'Designed and implemented user interfaces using Swift and UIKit, ensuring consistent and intuitive app experiences.',
        'Collaborated with cross-functional teams to define technical requirements and integrate backend services.',
        'Optimized app performance and responsiveness, resulting in a 15% improvement in app launch times.',
      ],
      present: false,
    },
    {
      company: 'Facebook',
      location: {
        city: 'Menlo Park',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'iOS Software Engineer',
      url: 'https://www.facebook.com',
      startDate: '2017-06-01',
      endDate: '2019-12-31',
      summary:
        "Contributed to the development of user-centric and feature-rich iOS applications for Facebook's social platform.",
      highlights: [
        'Implemented new features and enhancements using Swift and Core Data, resulting in improved user engagement.',
        'Collaborated with design teams to ensure pixel-perfect UI/UX and seamless integration of design assets.',
        'Participated in code reviews and provided mentorship to junior developers, improving code quality and knowledge sharing.',
      ],
      present: false,
    },
    {
      company: 'Uber Technologies, Inc.',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'iOS Developer Intern',
      url: 'https://www.uber.com',
      startDate: '2015-02-15',
      endDate: '2015-05-31',
      summary:
        "Contributed to the development of Uber's rider and driver applications, focusing on user interface enhancements and performance optimization.",
      highlights: [
        'Developed and maintained iOS app components using Objective-C and UIKit.',
        'Worked closely with product managers to implement new features and improve app functionality.',
        'Collaborated with QA teams to conduct testing and resolve bugs, ensuring a smooth user experience.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'CA',
      },
      startDate: '2013-09-01',
      endDate: '2015-05-15',
      gpa: '3.9',
      courses: [
        'Mobile App Development',
        'User Interface Design',
        'Software Engineering',
      ],
      summary: 'Graduated with Honors',
      present: false,
    },
  ],
  skills: [
    {
      skillGroup: 'Programming Languages',
      skill: [
        {
          name: 'Swift',
        },
        {
          name: 'Objective-C',
        },
        {
          name: 'C++',
        },
      ],
    },
    {
      skillGroup: 'Frameworks',
      skill: [
        {
          name: 'UIKit',
        },
        {
          name: 'Core Data',
        },
        {
          name: 'SwiftUI',
        },
      ],
    },
    {
      skillGroup: 'Version Control',
      skill: [
        {
          name: 'Git',
        },
        {
          name: 'SVN',
        },
      ],
    },
    {
      skillGroup: 'UI/UX Design',
      skill: [
        {
          name: 'Interface Builder',
        },
        {
          name: 'Storyboard',
        },
        {
          name: 'Sketch',
        },
      ],
    },
    {
      skillGroup: 'App Architecture',
      skill: [
        {
          name: 'MVVM',
        },
        {
          name: 'VIPER',
        },
        {
          name: 'Clean Architecture',
        },
      ],
    },
    {
      skillGroup: 'Testing and Debugging',
      skill: [
        {
          name: 'Xcode Instruments',
        },
        {
          name: 'Unit Testing',
        },
        {
          name: 'UI Testing',
        },
      ],
    },
  ],
  projects: [
    {
      name: 'Task Manager App',
      description:
        'Developed a task management app for iOS, enabling users to organize and track their daily tasks.',
      startDate: '2019-02-01',
      endDate: '2020-05-31',
      present: false,
    },
    {
      name: 'Social Networking App',
      description:
        'Designed and implemented a social networking app with real-time messaging and multimedia sharing capabilities.',
      startDate: '2017-08-15',
      endDate: '2018-12-31',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Excellence in iOS Development',
      date: '2022-03-10',
      awarder: 'Apple Inc.',
      summary:
        'Recognized for exceptional contributions to iOS app development and innovation.',
    },
    {
      title: 'Innovation Award',
      date: '2018-10-18',
      awarder: 'Facebook',
      summary:
        'Received for innovative solutions and impactful contributions to the development of user-centric iOS applications.',
    },
  ],
  customSections: [],
}
