export const resume = {
  basics: {
    firstName: 'Alex',
    lastName: 'Smith',
    title: 'Web Developer',
    picture: 'https://example.com/alex-smith.jpg',
    email: 'alex.smith@example.com',
    phone: '123-456-7890',
    url: 'https://www.alexsmith.dev',
    summary:
      'Results-driven web developer with a passion for creating efficient, user-friendly, and visually appealing websites. Proficient in front-end and back-end technologies, with a strong focus on delivering exceptional user experiences. Experienced in working collaboratively within cross-functional teams to deliver high-quality web solutions.',
    location: {
      city: 'New York',
      countryCode: 'US',
      region: 'New York',
    },
    linkedInUrl: 'https://www.linkedin.com/in/alexsmith',
    portfolioUrl: 'https://portfolio.alexsmith.dev',
  },
  experience: [
    {
      company: 'Google',
      location: {
        city: 'Mountain View',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Web Developer',
      url: 'https://www.google.com',
      startDate: '2020-08-01',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Collaborated with UX designers to implement pixel-perfect designs, resulting in a seamless user experience and consistent branding.',
        'Implemented advanced front-end features using modern JavaScript frameworks, increasing user engagement by 25%.',
        'Optimized website loading times by implementing efficient code and optimizing images, leading to a 30% decrease in bounce rate.',
        'Led the migration of a legacy web application to a modern tech stack, improving maintainability and reducing bug incidents by 40%.',
      ],
      present: false,
    },
    {
      company: 'Amazon',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Frontend Developer',
      url: 'https://www.amazon.com',
      startDate: '2018-03-15',
      endDate: '2020-07-31',
      summary: null,
      highlights: [
        'Implemented dynamic and interactive product catalog pages, resulting in a 15% increase in product views and conversions.',
        'Collaborated with UX teams to conduct usability tests and implemented improvements based on user feedback, enhancing user satisfaction.',
        'Optimized web pages for mobile devices, resulting in a 20% increase in mobile conversion rates.',
        'Developed reusable UI components and libraries, improving development efficiency and ensuring consistent design patterns.',
      ],
      present: false,
    },
    {
      company: 'Netflix',
      location: {
        city: 'Los Gatos',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Web Developer Intern',
      url: 'https://www.netflix.com',
      startDate: '2017-06-01',
      endDate: '2017-08-31',
      summary: null,
      highlights: [
        'Developed a responsive and dynamic carousel component for showcasing featured content, resulting in a 30% increase in user engagement.',
        'Collaborated with senior developers to integrate third-party APIs and services, enhancing app functionality and user experience.',
        'Contributed to performance optimization efforts, leading to a 25% improvement in page load times.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Massachusetts Institute of Technology',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Cambridge',
        countryCode: 'US',
        region: 'Massachusetts',
      },
      startDate: '2016-09-01',
      endDate: '2018-05-31',
      gpa: '3.9',
      courses: ['Web Development', 'Database Systems', 'User Interface Design'],
      summary: '',
      present: false,
    },
    {
      institution: 'University of California, Los Angeles',
      degree: 'Bachelor of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Los Angeles',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2012-09-01',
      endDate: '2016-05-15',
      gpa: '3.7',
      courses: [
        'Data Structures and Algorithms',
        'Web Programming',
        'Software Engineering',
      ],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Google Web Developer Excellence Award',
      date: '2023-06-15',
      awarder: 'Google',
      summary:
        'Recognized for outstanding contributions to the development of a high-impact web application feature.',
    },
    {
      title: 'Amazon Innovation Award',
      date: '2020-06-10',
      awarder: 'Amazon',
      summary:
        'Received for designing and implementing an innovative and user-friendly product catalog.',
    },
  ],
  skills: [
    {
      skillGroup: 'Frontend Development',
      skill: [
        { name: 'HTML5' },
        { name: 'CSS3' },
        { name: 'JavaScript' },
        { name: 'React' },
        { name: 'Vue.js' },
      ],
    },
    {
      skillGroup: 'Backend Development',
      skill: [
        { name: 'Node.js' },
        { name: 'Express' },
        { name: 'Python' },
        { name: 'Ruby on Rails' },
      ],
    },
    {
      skillGroup: 'Database',
      skill: [{ name: 'MySQL' }, { name: 'MongoDB' }, { name: 'PostgreSQL' }],
    },
    {
      skillGroup: 'Version Control',
      skill: [{ name: 'Git' }, { name: 'SVN' }],
    },
    {
      skillGroup: 'UI/UX Design',
      skill: [{ name: 'Adobe XD' }, { name: 'Figma' }],
    },
  ],
  projects: [
    {
      name: 'E-commerce Website',
      description:
        'Developed a fully functional e-commerce website with product listings, shopping cart, and secure checkout.',
      startDate: '2022-02-01',
      endDate: '2022-06-30',
      present: false,
    },
    {
      name: 'Portfolio Website',
      description:
        'Designed and built a personal portfolio website to showcase projects and skills.',
      startDate: '2021-08-01',
      endDate: '2021-10-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Volunteer Experience',
      details: [
        {
          title: 'Code for Good Hackathon',
          subtitle: 'Mentor',
          location: {
            city: 'San Francisco',
            countryCode: 'US',
            region: 'California',
          },
          url: 'https://www.codeforgood.org',
          startDate: '2020-10-01',
          endDate: '2020-10-03',
          summary:
            'Provided guidance and technical support to student teams during a hackathon focused on creating technology solutions for social impact.',
          highlights: [
            'Assisted teams in ideation, prototyping, and implementation of web-based projects addressing community challenges.',
            'Facilitated workshops on web development best practices and presented on emerging web technologies.',
          ],
          present: false,
        },
      ],
    },
  ],
}
