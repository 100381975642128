export const resume = {
  basics: {
    firstName: 'Alex',
    lastName: 'Johnson',
    title: 'Computer Vision Engineer',
    picture: 'https://example.com/alex-johnson.jpg',
    email: 'alex.johnson@example.com',
    phone: '555-123-4567',
    url: 'https://www.alexjohnson-cv.dev',
    summary:
      'Passionate Computer Vision Engineer with a strong background in designing and implementing computer vision algorithms for real-world applications. Experienced in image processing, machine learning, and deep learning techniques.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/alexjohnson',
    portfolioUrl: 'https://portfolio.alexjohnson-cv.dev',
  },
  experience: [
    {
      company: 'Google Research',
      location: {
        city: 'Mountain View',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Senior Computer Vision Engineer',
      url: 'https://www.google.com',
      startDate: '2018-07-15',
      endDate: '2022-09-30',
      summary: null,
      highlights: [
        'Designed and implemented deep learning models for image recognition, achieving a 98% accuracy rate on benchmark datasets.',
        'Optimized real-time object detection algorithms, reducing inference time by 25% and enabling efficient deployment on edge devices.',
        "Collaborated with cross-functional teams to integrate computer vision technologies into Google's products, including Google Photos and Android.",
        'Published research papers in top computer vision conferences, contributing to advancements in the field.',
      ],
      present: false,
    },
    {
      company: 'Apple Inc.',
      location: {
        city: 'Cupertino',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Computer Vision Engineer',
      url: 'https://www.apple.com',
      startDate: '2016-02-01',
      endDate: '2018-06-30',
      summary: null,
      highlights: [
        'Implemented real-time facial recognition algorithms for the iPhone X, enabling secure device unlocking.',
        "Collaborated with hardware engineers to optimize computer vision algorithms for Apple's custom-designed processors.",
      ],
      present: false,
    },
    {
      company: 'Amazon Web Services (AWS)',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Computer Vision Research Scientist',
      url: 'https://aws.amazon.com',
      startDate: '2014-06-01',
      endDate: '2016-01-15',
      summary: null,
      highlights: [
        "Developed image recognition algorithms for AWS's cloud-based services, enabling customers to perform visual analysis on large datasets.",
        "Collaborated with data scientists to integrate computer vision capabilities into AWS's machine learning services.",
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Doctor of Philosophy (Ph.D.) in Computer Science',
      major: 'Computer Vision and Machine Learning',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2010-09-01',
      endDate: '2016-05-15',
      gpa: '4.0',
      courses: [
        'Advanced Computer Vision',
        'Machine Learning',
        'Deep Learning',
        'Image Processing',
      ],
      summary:
        'Doctoral research focused on developing state-of-the-art computer vision algorithms for object recognition and scene understanding.',
      present: false,
    },
    {
      institution: 'University of California, Berkeley',
      degree: 'Master of Science in Electrical Engineering',
      major: 'Signal Processing',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2008-08-01',
      endDate: '2010-05-15',
      gpa: '3.9',
      courses: [
        'Digital Image Processing',
        'Pattern Recognition',
        'Linear Algebra',
        'Probabilistic Graphical Models',
      ],
      summary: null,
      present: false,
    },
    {
      institution: 'Massachusetts Institute of Technology (MIT)',
      degree: 'Bachelor of Science in Computer Science',
      major: 'Artificial Intelligence',
      location: {
        city: 'Cambridge',
        countryCode: 'US',
        region: 'Massachusetts',
      },
      startDate: '2004-09-01',
      endDate: '2008-05-15',
      gpa: '3.8',
      courses: [
        'Computer Vision',
        'Machine Learning',
        'Data Structures',
        'Artificial Intelligence',
      ],
      summary: null,
      present: false,
    },
  ],
  awards: [
    {
      title: 'Outstanding Research Contribution Award',
      date: '2021-03-15',
      awarder: 'Association for Computing Machinery (ACM)',
      summary:
        'Recognized for significant contributions to the field of computer vision research.',
    },
    {
      title: 'Innovator of the Year',
      date: '2020-09-30',
      awarder: 'IEEE Computer Society',
      summary:
        'Received for pioneering work in developing novel computer vision algorithms for real-world applications.',
    },
  ],
  skills: [
    {
      skillGroup: 'Computer Vision',
      skill: [
        { name: 'Image Classification' },
        { name: 'Object Detection' },
        { name: 'Semantic Segmentation' },
        { name: '3D Vision' },
      ],
    },
    {
      skillGroup: 'Machine Learning',
      skill: [
        { name: 'Deep Learning' },
        { name: 'Neural Networks' },
        { name: 'Feature Extraction' },
        { name: 'Transfer Learning' },
      ],
    },
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'Python' }, { name: 'C++' }, { name: 'MATLAB' }],
    },
  ],
  projects: [
    {
      name: 'Real-Time Object Tracking',
      description:
        'Developed a real-time object tracking system using deep learning and Kalman filtering, achieving high accuracy in dynamic environments.',
      startDate: '2022-01-01',
      endDate: '2022-06-30',
      present: false,
    },
    {
      name: 'Medical Image Analysis',
      description:
        'Led a team to create an automated system for detecting anomalies in medical images, improving diagnosis accuracy by 15%.',
      startDate: '2019-03-01',
      endDate: '2020-02-15',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Professional Memberships',
      details: [
        {
          title: 'Member',
          subtitle: 'Association for Computer Machinery (ACM)',
          location: {
            city: 'New York',
            countryCode: 'US',
            region: 'New York',
          },
          startDate: '2016-01-01',
          endDate: null,
          summary:
            "Active participant in ACM's computer vision community, attending conferences and networking with researchers.",
          highlights: [],
          present: true,
        },
        {
          title: 'Member',
          subtitle: 'IEEE Computer Society',
          location: {
            city: 'San Francisco',
            countryCode: 'US',
            region: 'California',
          },
          startDate: '2014-03-15',
          endDate: null,
          summary:
            'Engaged in knowledge exchange and collaboration with fellow computer vision professionals.',
          highlights: [],
          present: true,
        },
      ],
    },
  ],
}
