export const formFields = {
  basics: {
    title: {
      label: 'Job title',
      placeholder: 'List your current job title',
      className: 'col-span-12',
    },
    firstName: {
      label: 'First name',
      placeholder: 'First name',
      className: 'col-span-12 sm:col-span-6',
    },
    lastName: {
      label: 'Last name',
      placeholder: 'Last name',
      className: 'col-span-12 sm:col-span-6',
    },
    email: {
      label: 'Email address',
      placeholder: 'Email address',
      className: 'col-span-12',
    },
    phone: {
      label: 'Phone number',
      placeholder: 'Phone number',
      className: 'col-span-12 sm:col-span-6',
    },
    linkedInUrl: {
      label: 'LinkedIn',
      placeholder: 'LinkedIn url',
      className: 'col-span-12 sm:col-span-6',
    },
    portfolioUrl: {
      label: 'Professional website',
      placeholder: 'Professional website url',
      className: 'col-span-12 sm:col-span-6',
    },
    url: {
      label: 'Personal website',
      placeholder: 'Personal website url',
      className: 'col-span-12 sm:col-span-6',
    },
  },
  education: {
    institution: {
      label: 'School',
      className: 'col-span-12',
    },
    degree: {
      label: 'Degree',
      className: 'col-span-12 sm:col-span-8',
    },
    gpa: {
      label: 'GPA (optional)',
      placeholder: 'GPA',
      className: 'col-span-12 sm:col-span-4',
    },
    startDate: {
      label: 'Start Date',
      isDate: true,
      className: 'col-span-6 sm:col-span-3',
    },
    endDate: {
      label: 'End Date',
      isDate: true,
      className: 'col-span-6 sm:col-span-3',
    },
    'location.city': {
      label: 'City',
      className: 'col-span-6 sm:col-span-4',
    },
    'location.region': {
      label: 'State',
      className: 'col-span-6 sm:col-span-2',
    },
    summary: {
      label: 'Description',
      placeholder: 'Add education description',
      isQuill: true,
    },
  },
  experience: {
    company: {
      label: 'Company',
      className: 'col-span-12 sm:col-span-6',
    },
    position: {
      label: 'Position',
      className: 'col-span-12 sm:col-span-6',
    },
    startDate: {
      label: 'Start Date',
      isDate: true,
      className: 'col-span-6 sm:col-span-3',
    },
    endDate: {
      label: 'End Date',
      isDate: true,
      isEndDate: true,
      className: 'col-span-6 sm:col-span-3',
    },
    'location.city': {
      label: 'City',
      className: 'col-span-6 sm:col-span-4',
    },
    'location.region': {
      label: 'State',
      className: 'col-span-6 sm:col-span-2',
    },
    present: {
      label: 'I currently work here',
      isCheckbox: true,
      className: 'col-span-12',
    },
    summary: {
      label: 'Description',
      usage: 'Add a text paragraph to your experience',
      placeholder: 'Add experience description.',
      isQuill: true,
    },
    highlights: {
      label: 'Bullet points',
      usage: 'Add bullet points items to your experience',
      isArray: true,
    },
  },
  projects: {
    name: {
      label: 'Name',
      placeholder: 'Project Name',
      className: 'col-span-12',
    },
    description: {
      label: 'Description',
      placeholder: 'Add project description',
      isQuill: true,
    },
  },
  skills: {
    skillGroup: {
      label: 'Skill Group (optional)',
      placeholder: 'Group your skills - e.g. Tools, Languages, etc.',
      className: 'col-span-12',
    },
    skill: {
      isFieldArray: true,
      valueKey: 'name',
      label: 'Skills',
    },
  },
  custom: {
    title: {
      label: 'Title',
      className: 'col-span-12 sm:col-span-6',
    },
    subtitle: {
      label: 'Subtitle',
      className: 'col-span-12 sm:col-span-6',
    },
    startDate: {
      label: 'Start Date',
      isDate: true,
      className: 'col-span-6 sm:col-span-3',
    },
    endDate: {
      label: 'End Date',
      isDate: true,
      isEndDate: true,
      className: 'col-span-6 sm:col-span-3',
    },
    'location.city': {
      label: 'City',
      className: 'col-span-6 sm:col-span-4',
    },
    'location.region': {
      label: 'State',
      className: 'col-span-6 sm:col-span-2',
    },
    present: {
      label: 'Present',
      isCheckbox: true,
      className: 'col-span-12',
    },
    summary: {
      label: 'Description',
      placeholder: 'Add description',
      isQuill: true,
    },
    highlights: {
      label: 'Bullet points',
      isArray: true,
    },
  },
}

export const defaultValues = {
  basics: {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    linkedInUrl: '',
    portfolioUrl: '',
    url: '',
  },
  education: {
    institution: '',
    degree: '',
    gpa: '',
    location: {
      city: '',
      region: '',
    },
    startDate: '',
    endDate: '',
    summary: '',
  },
  experience: {
    company: '',
    position: '',
    location: {
      city: '',
      region: '',
    },
    startDate: '',
    endDate: '',
    summary: null,
    highlights: [],
  },
  projects: {
    name: '',
    description: '',
  },
  skills: {
    skillGroup: '',
    skills: [],
  },
  custom: {
    title: '',
    subtitle: '',
    location: {
      city: '',
      region: '',
    },
    startDate: '',
    endDate: '',
    summary: null,
    highlights: [],
  },
}
