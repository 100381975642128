export const resume = {
  basics: {
    firstName: 'John',
    lastName: 'Doe',
    title: 'Software Engineer',
    picture: 'https://example.com/john-doe.jpg',
    email: 'john.doe@example.com',
    phone: '123-456-7890',
    url: 'https://www.johndoe.com',
    summary:
      'Results-driven software engineer with a proven track record of delivering high-quality code and innovative solutions. Passionate about software development and eager to contribute to cutting-edge projects.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/johndoe',
    portfolioUrl: 'https://portfolio.johndoe.com',
  },
  experience: [
    {
      company: 'VMware',
      location: {
        city: 'Palo Alto',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Senior Software Engineer',
      url: 'https://www.vmware.com',
      startDate: '2019-06-01',
      endDate: '2023-07-31',
      summary:
        'Contributed to the development of virtualization technologies and cloud solutions.',
      highlights: [
        'Led a cross-functional team of 8 engineers to architect and implement a containerization feature, reducing deployment time by 30%.',
        'Optimized critical code paths, resulting in a 20% improvement in application performance.',
        'Designed and implemented a real-time monitoring system, enhancing system reliability and reducing downtime by 15%.',
        'Collaborated with product managers to define technical requirements and deliver high-impact features on schedule.',
      ],
      present: false,
    },
    {
      company: 'Square',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Software Engineer II',
      url: 'https://squareup.com',
      startDate: '2017-09-15',
      endDate: '2019-05-31',
      summary:
        'Contributed to the design and development of payment processing systems.',
      highlights: [
        'Implemented a multi-factor authentication system, reducing fraudulent transactions by 25% and saving $500K annually.',
        'Collaborated with the design team to revamp the user interface of the merchant dashboard, resulting in a 40% increase in user satisfaction.',
        'Led a code refactoring initiative, reducing technical debt and improving system maintainability.',
      ],
      present: false,
    },
    {
      company: 'Lockheed Martin',
      location: {
        city: 'Bethesda',
        countryCode: 'US',
        region: 'Maryland',
      },
      position: 'Software Engineer',
      url: 'https://www.lockheedmartin.com',
      startDate: '2015-08-01',
      endDate: '2017-08-31',
      summary: null,
      highlights: [
        'Led a team of 6 engineers in the development of a real-time data processing module, reducing data latency by 40%.',
        'Collaborated with systems engineers to define software requirements and ensure seamless integration with hardware components.',
        "Received Lockheed Martin's Innovation Award for proposing and implementing a novel algorithm that enhanced target tracking accuracy by 15%.",
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2013-09-01',
      endDate: '2015-06-30',
      gpa: '3.9',
      courses: [
        'Advanced Algorithms',
        'Machine Learning',
        'Distributed Systems',
      ],
      summary: '',
      present: false,
    },
    {
      institution: 'Massachusetts Institute of Technology (MIT)',
      degree:
        'Bachelor of Science in Electrical Engineering and Computer Science',
      major: 'Electrical Engineering and Computer Science',
      location: {
        city: 'Cambridge',
        countryCode: 'US',
        region: 'Massachusetts',
      },
      startDate: '2009-09-01',
      endDate: '2013-06-30',
      gpa: '3.8',
      courses: [
        'Introduction to Artificial Intelligence',
        'Digital Systems Design',
        'Operating Systems',
      ],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'VMware Innovation Excellence Award',
      date: '2022-03-15',
      awarder: 'VMware',
      summary:
        'Recognized for outstanding contributions to the development of innovative software solutions.',
    },
    {
      title: 'Square Engineering Achievement Award',
      date: '2018-06-20',
      awarder: 'Square',
      summary:
        'Received for exceptional leadership and technical excellence in driving impactful projects.',
    },
  ],
  skills: [
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'Java' }, { name: 'Python' }, { name: 'C++' }],
    },
    {
      skillGroup: 'Web Technologies',
      skill: [{ name: 'HTML/CSS' }, { name: 'JavaScript' }, { name: 'React' }],
    },
    {
      skillGroup: 'Databases',
      skill: [{ name: 'SQL' }, { name: 'MongoDB' }, { name: 'Redis' }],
    },
  ],
  projects: [
    {
      name: 'Smart Home Automation System',
      description:
        'Designed and implemented a smart home system that allows users to control devices remotely using a mobile app.',
      startDate: '2022-01-01',
      endDate: '2022-06-30',
      present: false,
    },
    {
      name: 'E-commerce Platform Optimization',
      description:
        'Led a project to optimize the performance and scalability of an e-commerce platform, resulting in a 50% reduction in page load times.',
      startDate: '2021-03-01',
      endDate: '2021-08-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Professional Organizations',
      details: [
        {
          title: 'IEEE Computer Society',
          subtitle: 'Member',
          location: {
            city: 'San Francisco',
            countryCode: 'US',
            region: 'California',
          },
          url: 'https://www.computer.org',
          startDate: '2017-01-01',
          endDate: '2023-06-30',
          summary: 'Active participant in technical seminars and workshops.',
          highlights: [
            'Presented a paper on AI-driven software testing at the annual conference.',
            'Collaborated with fellow members on open-source projects.',
          ],
          present: false,
        },
      ],
    },
  ],
}
