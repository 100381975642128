import { Link, StyleSheet, View } from '@react-pdf/renderer'
import HtmlContent from '../../common/HtmlContent'
import SafeText from '../../common/SafeText'
import { renderLink, renderLinkedInLink } from '../../common/Utils/linkHelper'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingBottom: '15',
  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  leftColumn: {
    flexDirection: 'column',
    width: '72',
  },
  rightColumn: {
    flexDirection: 'column',
    flex: 1,
  },
  linkColumn: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  name: {
    fontSize: 16,
    lineHeight: 1.2,
    fontFamily: 'Open Sans',
    fontWeight: '700',
  },
  subtitle: {
    fontSize: 9,
    fontFamily: 'Open Sans',
    marginBottom: 3,
    fontWeight: '700',
  },
  summary: {
    justifySelf: 'flex-end',
    marginTop: 10,
  },
  link: {
    fontSize: 8,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Open Sans',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  linkGroup: {
    fontSize: 9,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Open Sans',
    display: 'flex',
    flexDirection: 'row',
  },
  subtitleSeparator: {
    marginRight: 4,
    marginLeft: 4,
  },
  htmlContent: {
    fontSize: 8,
    fontFamily: 'Open Sans',
  },
})

function getName(basics) {
  if (basics.firstName && basics.lastName) {
    return `${basics.firstName} ${basics.lastName}`
  } else if (basics.firstName) {
    return basics.firstName
  } else if (basics.lastName) {
    return basics.lastName
  }
  return undefined
}

function getContactInfo(basics) {
  let contact = []
  let urls = []
  if (basics.email) {
    contact.push(
      <Link style={styles.link} src={'mailto:' + basics.email}>
        {basics.email}
      </Link>
    )
  }
  if (basics.phone) {
    contact.push(
      <Link style={styles.link} src={'tel:' + basics.phone}>
        {basics.phone}
      </Link>
    )
  }
  if (basics.url) {
    urls.push(renderLink(basics.url, styles))
  }
  if (basics.portfolioUrl) {
    urls.push(renderLink(basics.portfolioUrl, styles))
  }
  if (basics.linkedInUrl) {
    urls.push(renderLinkedInLink(basics.linkedInUrl, styles))
  }
  contact = contact.flatMap((value, index, array) =>
    array.length - 1 !== index // check for the last item
      ? [
          value,
          <SafeText val={true} style={styles.subtitleSeparator}>
            •
          </SafeText>,
        ]
      : value
  )
  urls = urls.flatMap((value, index, array) =>
    array.length - 1 !== index // check for the last item
      ? [
          value,
          <SafeText val={true} style={styles.subtitleSeparator}>
            •
          </SafeText>,
        ]
      : value
  )
  return (
    <View>
      <View style={styles.linkGroup}>{contact}</View>
      <View style={styles.linkGroup}>{urls}</View>
    </View>
  )
}

export default function Header({ basics, color }) {
  var name = getName(basics)
  var title = basics.title
  var summary = basics.summary
  return (
    <View style={styles.container}>
      <View style={styles.leftColumn}></View>
      <View style={styles.rightColumn}>
        <SafeText val={name} style={styles.name}>
          {name}
        </SafeText>
        <SafeText val={title} style={{ ...styles.subtitle, color: color }}>
          {title}
        </SafeText>
        {getContactInfo(basics)}
        <SafeText val={summary} style={styles.summary}>
          <HtmlContent styles={styles.htmlContent}>{summary}</HtmlContent>
        </SafeText>
      </View>
    </View>
  )
}
