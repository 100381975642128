import { loadPdfs } from 'hooks/resumes'
import { useEffect, useRef, useState } from 'react'
import Viewer from './Viewer'
import saveAs from 'file-saver'

const resumeExample = {
  basics: {
    profiles: [],
    title: 'Senior Software Engineer',
    firstName: 'Kimberley',
    lastName: 'Powell',
    email: 'kimberley.powell@resumary.com',
    phone: '512-123-4567',
    portfolioUrl: 'www.resumary.com',
    linkedInUrl: 'linkedin.com/in/kpowell',
    url: '',
    summary: '',
  },
  name: 'Software Engineer',
  template: 6,
  order: [
    ['projects', 'skills'],
    ['experience', 'education'],
  ],
  _id: 'CrolKhhnJ',
  experience: [
    {
      location: {
        city: 'San Francisco',
        region: 'CA',
      },
      highlights: [
        '<p>Designed and owned the code migration process of payment service out of the monolithic infrastructure, reducing payment-related development cycles by 50%</p>',
        '<p>Developed and integrated the customer metrics data pipeline to provide product and marketing teams visibility into user behavior and increased the number of ideas for product features and marketing campaigns by 2x</p>',
      ],
      _id: '5fa8e99d24901c00176adb6a',
      position: 'Senior Software Engineer',
      company: 'Coinbase',
      startDate: '2017-11-01T00:00:00.000Z',
      present: true,
    },
    {
      location: {
        city: 'Mountain View',
        region: 'CA',
      },
      highlights: [
        "<p>Developed a system that analyzes and scores advertiser's goals to improve how Google ad servers target the right customers for different products</p>",
      ],
      _id: '5fa8e79c24901c00176adb11',
      position: 'Software Engineer',
      startDate: '2015-08-01T00:00:00.000Z',
      company: 'Google',
      endDate: '2017-10-01T00:00:00.000Z',
    },
    {
      location: {
        city: 'San Francisco',
        region: 'CA',
      },
      highlights: [
        '<p>Coordinated with the support team to reduce bug tickets by 20% through understanding customers needs and driving technical solutions to improve the user experience</p>',
        '<p>Utilized scripting languages to automate retrieval and processing of log data for seamless system debugging</p>',
      ],
      _id: '5fa8e79c24901c00176adb10',
      position: 'Software Engineer Intern',
      company: 'Twilio',
      startDate: '2014-06-01T00:00:00.000Z',
      present: false,
      endDate: '2014-08-01T00:00:00.000Z',
    },
    {
      location: {
        city: 'Menlo Park',
        region: 'CA',
      },
      highlights: [
        '<p>Analyzed 20,000+ users to construct a machine learning model to identify fraudulent accounts with 80% accuracy</p>',
      ],
      _id: '5fa8e79c24901c00176adb0f',
      position: 'Software Engineer Intern',
      company: 'Facebook',
      startDate: '2012-06-01T00:00:00.000Z',
      present: false,
      endDate: '2012-08-01T00:00:00.000Z',
    },
    {
      location: {
        city: 'Austin',
        region: 'TX',
      },
      highlights: [
        '<p>Developed a network flooding simulation to test load capacity, existing infrastructure did not exceed 70% load</p>',
      ],
      _id: '5fa8e79c24901c00176adb0e',
      position: 'Software Engineer Intern',
      company: 'Indeed',
      startDate: '2011-06-01T00:00:00.000Z',
      present: false,
      endDate: '2011-08-01T00:00:00.000Z',
    },
  ],
  education: [
    {
      location: {
        city: 'Stanford',
        region: 'CA',
      },
      courses: [],
      _id: '5fa8e79c24901c00176adb0d',
      institution: 'Stanford University',
      degree: 'M.S. in Computer Science',
      startDate: '2012-01-01T05:00:00.000Z',
      present: false,
      endDate: '2015-05-01T05:00:00.000Z',
      major: '',
      gpa: '4.0',
    },
    {
      location: {
        city: 'Austin',
        region: 'TX',
      },
      courses: [],
      _id: '5fa8e79c24901c00176adb0c',
      institution: 'University of Texas at Austin',
      degree: 'B.S. in Electrical and Computer Engineering',
      startDate: '2009-08-01T00:00:00.000Z',
      present: false,
      endDate: '2013-05-01T00:00:00.000Z',
      gpa: '3.7',
    },
  ],
  awards: [],
  skills: [
    {
      _id: '5fa8eb8624901c00176adc16',
      skillGroup: 'Technical',
      skill: [
        {
          keywords: [],
          _id: '5fa8eb8624901c00176adc18',
          name: 'Node.js',
        },
        {
          keywords: [],
          _id: '5fa8eb8624901c00176adc19',
          name: 'AWS',
        },
        {
          keywords: [],
          _id: '5fa8eb8624901c00176adc1a',
          name: 'Python',
        },
        {
          keywords: [],
          _id: '5fa8eb8624901c00176adc1b',
          name: 'Javascript',
        },
        {
          keywords: [],
          _id: '5fa8eb8624901c00176adc1c',
          name: 'React.js',
        },
      ],
    },
    {
      _id: '5fa8ec2024901c00176adcb9',
      skill: [
        {
          keywords: [],
          _id: '5fa8ec2024901c00176adcba',
          name: 'Avid skier',
        },
        {
          keywords: [],
          _id: '5fa8ec2024901c00176adcbb',
          name: 'Backpacking national parks',
        },
        {
          keywords: [],
          _id: '5fa8edc824901c00176addfb',
          name: 'Making pastas from scratch',
        },
      ],
      skillGroup: 'Interests',
    },
    {
      _id: '5fab51532054550017f63da9',
      skill: [
        {
          keywords: [],
          _id: '5fac5fdfb843180017d89d44',
          name: 'Spanish',
        },
        {
          keywords: [],
          _id: '5fac5fdfb843180017d89d45',
          name: 'English',
        },
      ],
      skillGroup: 'Languages',
    },
  ],
  languages: [],
  interests: [],
  projects: [
    {
      highlights: [],
      keywords: [],
      roles: [],
      _id: '5fa8e99d24901c00176adb67',
      description:
        '<p>All-in-one resume builder inspired by product design to help applicants stand out in their job searches</p>',
      name: 'Resumary',
    },
    {
      highlights: [],
      keywords: [],
      roles: [],
      _id: '5fa8e99d24901c00176adb68',
      description:
        '<p>Built two-sided recruiting marketplace to assist job seekers through their job search journey. Created a user-friendly, effective platform that resulted in 200,000+ page views and 3000+ applications</p>',
      name: 'Ralph & Recruit',
    },
    {
      highlights: [],
      keywords: [],
      roles: [],
      _id: '5fa8e99d24901c00176adb69',
      description:
        '<p>Evaluated a market need for improving textbook buying to develop a textbook price aggregator that helps college students save on average 15%. Designed social media marketing strategy to drive 40,000+ page views</p>',
      name: 'Bookkeeper',
    },
  ],
  color: '#4f46e5',
  custom: [],
  customSections: [],
  id: 'CrolKhhnJ',
  premium: true,
}

export default function DemoViewer({ resumeObj }) {
  const [resume, setResume] = useState(null)

  useEffect(() => {
    const initDemo = async () => {
      var pdfs = await loadPdfs([
        {
          ...resumeObj,
          template: 0,
          order: [
            ['projects', 'skills'],
            ['experience', 'education'],
          ],
          color: '#000000',
        },
      ])
      setResume(pdfs[0])
    }
    initDemo()
  }, [])

  return (
    <div>
      <Viewer resume={resume} />
    </div>
  )
}
