import { Text } from '@react-pdf/renderer'

export default function SafeText({ debug = false, val, children, style }) {
  if (!val) {
    return null
  }
  return (
    <Text debug={debug} style={style}>
      {children}
    </Text>
  )
}
