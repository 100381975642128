export const resume = {
  basics: {
    firstName: 'Emily',
    lastName: 'Chen',
    title: 'Infrastructure Engineer',
    picture: 'https://example.com/emily-chen.jpg',
    email: 'emily.chen@example.com',
    phone: '555-987-6543',
    url: 'https://www.emilychen-infrastructure.dev',
    summary:
      'Experienced Infrastructure Engineer with a proven track record of designing, implementing, and optimizing scalable and reliable infrastructure solutions. Skilled in cloud technologies, automation, and ensuring high availability of critical systems.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/emilychen',
    portfolioUrl: 'https://portfolio.emilychen-infrastructure.dev',
  },
  experience: [
    {
      company: 'CloudScape Technologies',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Senior Infrastructure Engineer',
      url: 'https://www.cloudscapetech.com',
      startDate: '2020-04-15',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Architected and implemented an auto-scaling infrastructure on AWS, reducing operational costs by 25% while maintaining 99.99% uptime.',
        'Automated deployment processes using Terraform and Ansible, accelerating application deployments by 50% and reducing configuration errors.',
        'Collaborated with development teams to design and implement CI/CD pipelines using Jenkins, reducing release cycle time by 40%.',
        'Led efforts to optimize database performance and reliability, resulting in a 30% reduction in query response time and eliminating downtime due to database issues.',
      ],
      present: false,
    },
    {
      company: 'TechWave Solutions',
      location: {
        city: 'New York',
        countryCode: 'US',
        region: 'New York',
      },
      position: 'Infrastructure Engineer',
      url: 'https://www.techwavesolutions.com',
      startDate: '2018-09-01',
      endDate: '2020-03-31',
      summary: null,
      highlights: [
        'Managed and maintained on-premises and cloud-based servers, ensuring 99.95% uptime and optimal performance.',
        'Implemented disaster recovery strategies and conducted regular backup and restoration tests, reducing data recovery time by 60% during incidents.',
        'Automated routine tasks using Python scripts, improving operational efficiency and reducing manual intervention.',
      ],
      present: false,
    },
    {
      company: 'InnoTech Innovations',
      location: {
        city: 'Austin',
        countryCode: 'US',
        region: 'Texas',
      },
      position: 'Infrastructure Intern',
      url: 'https://www.innotechinnovations.com',
      startDate: '2017-05-15',
      endDate: '2017-08-15',
      summary: null,
      highlights: [
        'Assisted in monitoring and troubleshooting network and server issues, ensuring seamless operation of critical systems.',
        'Participated in the implementation of security measures, including firewall configuration and vulnerability scanning.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Systems',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2016-09-01',
      endDate: '2018-05-15',
      gpa: '3.9',
      courses: [
        'Distributed Systems',
        'Cloud Computing',
        'Network Security',
        'Advanced System Administration',
      ],
      summary: null,
      present: false,
    },
    {
      institution: 'University of Texas at Austin',
      degree: 'Bachelor of Science in Electrical and Computer Engineering',
      major: 'Computer Engineering',
      location: {
        city: 'Austin',
        countryCode: 'US',
        region: 'Texas',
      },
      startDate: '2012-08-01',
      endDate: '2016-05-15',
      gpa: '3.8',
      courses: [
        'Computer Networks',
        'Operating Systems',
        'Embedded Systems',
        'Digital Logic Design',
      ],
      summary: null,
      present: false,
    },
  ],
  awards: [
    {
      title: 'Excellence in Infrastructure Award',
      date: '2022-06-10',
      awarder: 'CloudScape Technologies',
      summary:
        'Recognized for outstanding contributions to infrastructure design and optimization.',
    },
    {
      title: 'Infrastructure Innovation Scholarship',
      date: '2017-03-20',
      awarder: 'Stanford University',
      summary:
        'Received a scholarship for innovative work in the field of computer systems and infrastructure.',
    },
  ],
  skills: [
    {
      skillGroup: 'Cloud Technologies',
      skill: [
        { name: 'Amazon Web Services (AWS)' },
        { name: 'Microsoft Azure' },
        { name: 'Google Cloud Platform (GCP)' },
      ],
    },
    {
      skillGroup: 'Automation',
      skill: [
        { name: 'Terraform' },
        { name: 'Ansible' },
        { name: 'Python Scripting' },
      ],
    },
    {
      skillGroup: 'Networking',
      skill: [
        { name: 'TCP/IP' },
        { name: 'Load Balancing' },
        { name: 'Network Security' },
      ],
    },
    {
      skillGroup: 'Operating Systems',
      skill: [{ name: 'Linux' }, { name: 'Windows Server' }],
    },
  ],
  projects: [
    {
      name: 'Cloud Migration and Optimization',
      description:
        'Led the migration of a legacy application to AWS, resulting in improved scalability and cost savings.',
      startDate: '2022-02-01',
      endDate: '2022-07-15',
      present: false,
    },
    {
      name: 'Infrastructure Automation Framework',
      description:
        'Developed a custom infrastructure automation framework using Terraform and Ansible, reducing provisioning time by 40%.',
      startDate: '2020-08-01',
      endDate: '2021-03-31',
      present: false,
    },
    {
      name: 'Network Security Enhancement',
      description:
        'Implemented network segmentation and firewall rules to enhance network security and protect sensitive data.',
      startDate: '2019-03-01',
      endDate: '2019-09-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Languages',
      details: [
        {
          title: 'English',
          subtitle: 'Native',
        },
        {
          title: 'Spanish',
          subtitle: 'Intermediate',
        },
      ],
    },
    {
      name: 'Certifications',
      details: [
        {
          title: 'Certified Kubernetes Administrator',
          subtitle: 'Cloud Native Computing Foundation',
          location: {
            city: 'Online',
            countryCode: 'US',
          },
          startDate: '2021-05-01',
          endDate: 'No Expiry',
          summary:
            'Demonstrated expertise in Kubernetes administration and orchestration.',
        },
        {
          title: 'AWS Certified Solutions Architect – Professional',
          subtitle: 'Amazon Web Services',
          location: {
            city: 'Online',
            countryCode: 'US',
          },
          startDate: '2020-07-15',
          endDate: 'No Expiry',
          summary:
            'Certification showcasing advanced knowledge of AWS architectural best practices.',
        },
      ],
    },
  ],
}
