/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
import {
  ArrowNarrowRightIcon,
  ChevronDownIcon,
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline'
import Logo from 'components/Core/Logo'
import { Fragment, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
// import {
//   Bars3Icon,
//   MagnifyingGlassIcon,
//   ShoppingBagIcon,
//   XMarkIcon,
// } from '@heroicons/react/outline'

const navigation = {
  categories: [
    // {
    //   id: 'templates',
    //   name: 'Templates',
    //   title: 'Resume Templates',
    //   href: '/resume-templates',
    //   layout: 1,
    //   featured: [
    //     {
    //       name: 'Sharp',
    //       href: '/resume-templates/sharp',
    //       imageSrc: require(`assets/img/Template${0}.png`),
    //       imageAlt:
    //         'Models sitting back to back, wearing Basic Tee in black and bone.',
    //     },
    //     {
    //       name: 'Clean',
    //       href: '/resume-templates/clean',
    //       imageSrc: require(`assets/img/Template${2}.png`),
    //       imageAlt:
    //         'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
    //     },
    //     {
    //       name: 'Portland',
    //       href: '/resume-templates/portland',
    //       imageSrc: require(`assets/img/Template${6}.png`),
    //       imageAlt:
    //         'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
    //     },
    //   ],
    //   sections: [
    //     {
    //       id: 'resume-template',
    //       name: 'Resume Templates',
    //       items: [
    //         { name: 'Sharp', href: '/resume-templates/sharp' },
    //         { name: 'Modern', href: '/resume-templates/modern' },
    //         { name: 'Clean', href: '/resume-templates/clean' },
    //         { name: 'Fresh', href: '/resume-templates/fresh' },
    //         { name: 'Pure', href: '/resume-templates/pure' },
    //         { name: 'Boston', href: '/resume-templates/boston' },
    //         { name: 'Portland', href: '/resume-templates/portland' },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: 'examples',
    //   name: 'Examples',
    //   title: 'Resume Examples',
    //   href: '/resume-examples',
    //   layout: 2,
    //   featured: [],
    //   sections: [
    //     {
    //       id: 'engineering',
    //       name: 'Engineering',
    //       items: [
    //         { name: 'Software Engineer', href: '#' },
    //         { name: 'Mobile Developer', href: '#' },
    //         { name: 'iOS Developer', href: '#' },
    //         { name: 'Android Developer', href: '#' },
    //         { name: 'Frontend Engineer', href: '#' },
    //         { name: 'Backend Engineer', href: '#' },
    //         { name: 'Full-Stack Engineer', href: '#' },
    //       ],
    //     },
    //     {
    //       id: 'designer',
    //       name: 'Designer',
    //       items: [
    //         { name: 'Watches', href: '#' },
    //         { name: 'Wallets', href: '#' },
    //         { name: 'Bags', href: '#' },
    //         { name: 'Sunglasses', href: '#' },
    //         { name: 'Hats', href: '#' },
    //         { name: 'Belts', href: '#' },
    //       ],
    //     },
    //     {
    //       id: 'sales',
    //       name: 'Sales',
    //       items: [
    //         { name: 'Re-Arranged', href: '#' },
    //         { name: 'Full Nelson', href: '#' },
    //         { name: 'My Way', href: '#' },
    //         { name: 'Bags', href: '#' },
    //         { name: 'Sunglasses', href: '#' },
    //         { name: 'Hats', href: '#' },
    //         { name: 'Belts', href: '#' },
    //       ],
    //     },
    //     {
    //       id: 'sales',
    //       name: 'Sales',
    //       items: [
    //         { name: 'Re-Arranged', href: '#' },
    //         { name: 'Full Nelson', href: '#' },
    //         { name: 'My Way', href: '#' },
    //         { name: 'Bags', href: '#' },
    //         { name: 'Sunglasses', href: '#' },
    //         { name: 'Hats', href: '#' },
    //         { name: 'Belts', href: '#' },
    //       ],
    //     },
    //     {
    //       id: 'sales',
    //       name: 'Sales',
    //       items: [
    //         { name: 'Re-Arranged', href: '#' },
    //         { name: 'Full Nelson', href: '#' },
    //         { name: 'My Way', href: '#' },
    //         { name: 'Bags', href: '#' },
    //         { name: 'Sunglasses', href: '#' },
    //         { name: 'Hats', href: '#' },
    //         { name: 'Belts', href: '#' },
    //       ],
    //     },
    //   ],
    // },
  ],
  pages: [
    { name: 'Templates', href: '/resume-templates' },
    { name: 'Examples', href: '/resume-examples' },
    { name: 'Career Guide', href: '/career-guide' },
    // {
    //   name: 'Chrome Extension',
    //   href: 'https://chrome.google.com/webstore/detail/save-linkedin-jobs-to-she/mkjedhephckpdnpadogmilejdbgbbdfm?utm_source=resumary&utm_medium=nav',
    //   external: true,
    // },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function LayoutOne({ category }) {
  return (
    <div className="mx-auto max-w-7xl px-8">
      <div className="grid grid-cols-5 gap-y-10 gap-x-8 py-16">
        <div className="col-start-2 col-span-4 grid grid-cols-3 gap-x-8">
          {category.featured.map((item) => (
            <div
              key={item.name}
              className="group relative text-base sm:text-sm group"
            >
              <div className="border border-gray-400 rounded bg-white transition duration-300 ease-in-out">
                <div className="aspect-w-17 aspect-h-22 overflow-hidden rounded-lg group-hover:opacity-50">
                  <img
                    src={item.imageSrc}
                    alt={item.imageAlt}
                    className="object-cover object-center"
                  />
                </div>
              </div>
              <Popover.Button
                as={Link}
                to={item.href}
                className="mt-4 block text-base font-medium text-gray-900"
              >
                <span className="absolute inset-0 z-10" aria-hidden="true" />
                {item.name}
              </Popover.Button>
              <p aria-hidden="true" className="mt-1 text-gray-500 text-sm">
                View template
              </p>
            </div>
          ))}
        </div>
        <div className="row-start-1 gap-12 text-sm">
          {category.sections.map((section) => (
            <div key={section.name} className="">
              <p
                id={`${section.name}-heading`}
                className="font-semibold text-xl text-gray-900"
              >
                {section.name}
              </p>
              <ul
                role="list"
                aria-labelledby={`${section.name}-heading`}
                className="mt-6 gap-y-4 grid grid-cols-1"
              >
                {section.items.map((item) => (
                  <li key={item.name} className="flex">
                    <Popover.Button
                      as={Link}
                      to={item.href}
                      className="text-gray-700 hover:text-gray-900 text-lg"
                    >
                      {item.name}
                    </Popover.Button>
                  </li>
                ))}
                <li className="flex">
                  <Popover.Button
                    as={Link}
                    to={category.href}
                    className="font-normal text-lg text-gray-700 hover:text-gray-900 flex items-center gap-2 hover:gap-4 transition-all"
                  >
                    View All
                    <ArrowNarrowRightIcon className="w-4 h-4" />
                  </Popover.Button>
                </li>
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function LayoutTwo({ category }) {
  return (
    <div className="mx-auto max-w-7xl px-8">
      <div className="grid grid-cols-5 py-16">
        <div className="col-span-1">
          <p className="font-semibold text-xl text-gray-900">
            {category.title}
          </p>
        </div>
        <div className="col-span-1">
          <Popover.Button
            as={Link}
            to={category.href}
            className="font-normal text-lg text-gray-700 flex items-center gap-2 hover:gap-4 transition-all"
          >
            View All
            <ArrowNarrowRightIcon className="w-4 h-4" />
          </Popover.Button>
        </div>
        <div className="col-span-5 gap-12 text-sm">
          <ul className="mt-6 gap-y-4 grid grid-rows-8 grid-flow-col auto-cols-fr">
            {category.sections.map((section) => (
              <>
                <p
                  id={`${section.name}-heading`}
                  className="font-semibold text-lg text-gray-900"
                >
                  {section.name}
                </p>
                {section.items.map((item) => (
                  <li key={item.name} className="flex">
                    <Popover.Button
                      as={Link}
                      to={`/resume-examples/${item.name
                        .toLowerCase()
                        .split(' ')
                        .join('-')}-resume`}
                      className="text-gray-700 text-lg"
                    >
                      {item.name}
                    </Popover.Button>
                  </li>
                ))}
              </>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

function LayoutSelector({ category }) {
  switch (category.layout) {
    case 1:
    default:
      return <LayoutOne category={category} />
    case 2:
      return <LayoutTwo category={category} />
  }
}

export default function NewHeader() {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const isHome = location.pathname === '/'

  return (
    <div
      className={classNames(
        isHome ? 'bg-warmGray-100' : 'bg-white',
        'relative z-20'
      )}
    >
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel
                className={classNames(
                  isHome ? 'bg-warmGray-100' : 'bg-white',
                  'relative flex w-full max-w-xs flex-col overflow-y-auto pb-12 shadow-xl'
                )}
              >
                <div className="flex px-4 pt-5 pb-2">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}
                <Tab.Group as="div" className="mt-2">
                  <div className="border-b border-gray-200">
                    <Tab.List className="-mb-px flex space-x-8 px-4">
                      {navigation.categories.map((category) => (
                        <Tab
                          key={category.name}
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? 'border-indigo-600 text-indigo-600'
                                : 'border-transparent text-gray-900',
                              'flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium'
                            )
                          }
                        >
                          {category.name}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>
                  <Tab.Panels as={Fragment}>
                    {navigation.categories.map((category) => (
                      <Tab.Panel
                        key={category.name}
                        className="space-y-10 px-4 pt-10 pb-8"
                      >
                        {/* <div className="grid grid-cols-2 gap-x-4">
                          {category.featured.map((item) => (
                            <div
                              key={item.name}
                              className="group relative text-sm"
                            >
                              <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                <img
                                  src={item.imageSrc}
                                  alt={item.imageAlt}
                                  className="object-cover object-center"
                                />
                              </div>
                              <a
                                href={item.href}
                                className="mt-6 block font-medium text-gray-900"
                              >
                                <span
                                  className="absolute inset-0 z-10"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                              <p aria-hidden="true" className="mt-1">
                                Shop now
                              </p>
                            </div>
                          ))}
                        </div> */}
                        {category.sections.map((section) => (
                          <div key={section.name}>
                            <p
                              id={`${category.id}-${section.id}-heading-mobile`}
                              className="font-medium text-gray-900"
                            >
                              {section.name}
                            </p>
                            <ul
                              role="list"
                              aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                              className="mt-6 flex flex-col space-y-6"
                            >
                              {section.items.map((item) => (
                                <li key={item.name} className="flow-root">
                                  <Link
                                    to={item.href}
                                    onClick={() => setOpen(false)}
                                    className="-m-2 block p-2 text-gray-500"
                                  >
                                    {item.name}
                                  </Link>
                                </li>
                              ))}
                              <li className="flow-root">
                                <Link
                                  to={category.href}
                                  onClick={() => setOpen(false)}
                                  className="-m-2 block p-2 text-gray-500"
                                >
                                  View All
                                </Link>
                              </li>
                            </ul>
                          </div>
                        ))}
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>

                <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                  {navigation.pages.map((page) => (
                    <div key={page.name} className="flow-root">
                      {page.external ? (
                        <a
                          href={page.href}
                          target="_blank"
                          onClick={() => setOpen(false)}
                          className="-m-2 block p-2 font-medium text-gray-900"
                          rel="noreferrer"
                        >
                          {page.name}
                        </a>
                      ) : (
                        <Link
                          to={page.href}
                          onClick={() => setOpen(false)}
                          className="-m-2 block p-2 font-medium text-gray-900"
                        >
                          {page.name}
                        </Link>
                      )}
                    </div>
                  ))}
                </div>

                <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                  <div className="flow-root">
                    <Link
                      to="/auth/login"
                      onClick={() => setOpen(false)}
                      className="-m-2 block p-2 font-medium text-gray-900"
                    >
                      Log in
                    </Link>
                  </div>
                  <div className="flow-root">
                    <Link
                      to="/auth/register"
                      onClick={() => setOpen(false)}
                      className="-m-2 block p-2 font-medium text-gray-900"
                    >
                      Sign up
                    </Link>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <header className="relative">
        {/* <p className="flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
          Get free delivery on orders over $100
        </p> */}

        <nav
          aria-label="Top"
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
        >
          <div className="border-b border-gray-200">
            <div className="flex h-16 items-center">
              <button
                type="button"
                className="rounded-md bg-warmGray-100 p-2 text-gray-500 lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                {/* <Bars3Icon className="h-6 w-6" aria-hidden="true" /> */}
                <MenuIcon className="h-6 w-6" />
              </button>

              {/* Logo */}
              <div className="ml-4 flex-1 flex lg:ml-0">
                <Logo />
              </div>

              {/* Flyout menus */}
              <Popover.Group className="hidden lg:mr-8 lg:flex lg:self-stretch">
                <div className="flex h-full space-x-8">
                  {navigation.categories.map((category) => (
                    <Popover key={category.name} className="flex">
                      {({ open }) => (
                        <>
                          <div className="relative flex">
                            <Popover.Button
                              className={classNames(
                                open
                                  ? 'border-indigo-600 text-indigo-600'
                                  : 'border-transparent text-gray-700 hover:text-gray-800',
                                'relative z-10 flex gap-1 items-center text-base font-medium transition-colors duration-200 ease-out'
                              )}
                            >
                              {category.name}
                              <ChevronDownIcon className="h-4 w-4" />
                            </Popover.Button>
                          </div>

                          <Popover.Panel className="absolute inset-x-0 top-full text-sm text-gray-500">
                            <div
                              className="absolute inset-0 top-1/2 bg-white shadow"
                              aria-hidden="true"
                            />

                            <div
                              className={classNames(
                                isHome ? 'bg-warmGray-100' : 'bg-white',
                                'relative'
                              )}
                            >
                              <LayoutSelector category={category} />
                            </div>
                          </Popover.Panel>
                        </>
                      )}
                    </Popover>
                  ))}

                  {navigation.pages.map((page) => {
                    return page.external ? (
                      <a
                        href={page.href}
                        target="_blank"
                        onClick={() => setOpen(false)}
                        className="flex items-center text-base font-medium text-gray-700 hover:text-gray-800"
                        rel="noreferrer"
                      >
                        {page.name}
                      </a>
                    ) : (
                      <Link
                        key={page.name}
                        to={page.href}
                        className="flex items-center text-base font-medium text-gray-700 hover:text-gray-800"
                      >
                        {page.name}
                      </Link>
                    )
                  })}
                </div>
              </Popover.Group>

              <div className="ml-auto flex items-center">
                <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-2">
                  <Link
                    to="/auth/login"
                    className="text-base font-medium text-indigo-600 hover:bg-indigo-100 border border-indigo-600 py-2 px-5 rounded-full"
                  >
                    Log in
                  </Link>
                  <Link
                    to="/auth/register"
                    className="text-base font-semibold text-white hover:bg-indigo-700 bg-indigo-600 py-2 px-5 rounded-full"
                  >
                    Sign up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}
