import { DotsVerticalIcon } from '@heroicons/react/solid'
import React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { classNames } from 'utils/helper'

const DraggableComponent = ({ item, index }) => {
  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div
          className={classNames(
            snapshot.isDragging ? 'shadow-lg' : '',
            'relative select-none py-2 border-2 border-dashed mb-3 bg-white rounded flex items-center justify-center'
          )}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <h4>{item.value}</h4>
          <div className="absolute right-2">
            <DotsVerticalIcon className="h-4 w-4" />
            <DotsVerticalIcon className="h-4 w-4 absolute top-0 left-1.5" />
          </div>
        </div>
      )}
    </Draggable>
  )
}

const DropComponent = (props) => {
  return (
    <Droppable droppableId={props.droppableId}>
      {(provided, snapshot) => (
        <div
          className="flex-1"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {props.items.map((item, idx) => (
            <DraggableComponent key={item.id} item={item} index={idx} />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default DropComponent
