import { Link } from 'react-router-dom'
import { classNames } from 'utils/helper'

const CTASection = ({ light = false, header, description }) => {
  const bgColor = light ? 'bg-transparent' : 'bg-blue-600'
  const color = light ? 'text-gray-900' : 'text-white'
  const secondaryColor = light ? 'text-gray-700' : 'text-blue-100'
  const buttonBgColor = light
    ? 'bg-indigo-500 border-indigo-500'
    : 'bg-white border-white'
  const buttonColor = light ? 'text-white' : 'text-blue-600'
  return (
    <div className={classNames(bgColor, 'relative')}>
      <div className="max-w-7xl mx-auto px-4 lg:px-16 py-24">
        <div className="flex flex-col items-center">
          <div className="flex flex-col sm:items-center sm:text-center">
            <h3
              className={classNames(
                color,
                'font-bold text-4xl sm:text-5xl mb-4'
              )}
            >
              Build with confidence
            </h3>
            <p
              className={classNames(
                secondaryColor,
                'max-w-4xl mx-auto text-xl mb-8'
              )}
            >
              Resumary templates have helped job seekers receive 3x more
              interviews at top companies.
            </p>
            <Link to="/auth/register">
              <div
                className={classNames(
                  buttonBgColor,
                  buttonColor,
                  'px-16 py-4 rounded text-sm border font-semibold w-full text-center'
                )}
              >
                Get Started Free
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CTASection
