import { View } from '@react-pdf/renderer'
import SkillValueMapper from './SkillValueMapper'
import SafeText from '../../SafeText'

class CondensedSkillValueMapper extends SkillValueMapper {
  title() {
    return null
  }

  children() {
    const { skill, skillGroup } = this.skillEntry
    return (
      <View>
        <SafeText val={skillGroup} style={this.styles.title}>
          {skillGroup}
        </SafeText>
        {skill &&
          skill.map((s, i) => (
            <SafeText val={s.name} key={i} style={this.styles.skillSubtitle}>
              {s.name}
            </SafeText>
          ))}
      </View>
    )
  }
}

export default CondensedSkillValueMapper
