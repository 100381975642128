export const resume = {
  basics: {
    firstName: 'Alex',
    lastName: 'Johnson',
    title: 'Automation Engineer',
    picture: 'https://example.com/alex-johnson.jpg',
    email: 'alex.johnson@example.com',
    phone: '987-654-3210',
    url: 'https://www.alexjohnson-automation.dev',
    summary:
      'Results-driven Automation Engineer with expertise in designing, developing, and implementing automated solutions to enhance operational efficiency and quality. Proficient in test automation, process optimization, and continuous improvement.',
    location: {
      city: 'Austin',
      countryCode: 'US',
      region: 'Texas',
    },
    linkedInUrl: 'https://www.linkedin.com/in/alexjohnson',
    portfolioUrl: 'https://portfolio.alexjohnson-automation.dev',
  },
  experience: [
    {
      company: 'TechVantage Solutions',
      location: {
        city: 'San Jose',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Senior Automation Engineer',
      url: 'https://www.techvantagesolutions.com',
      startDate: '2021-03-15',
      endDate: '2023-06-30',
      summary: null,
      highlights: [
        'Developed an end-to-end test automation framework using Selenium and Python, resulting in a 30% reduction in testing time and improved defect detection.',
        'Led a cross-functional team to implement robotic process automation (RPA) for data extraction, achieving a 40% reduction in data entry errors.',
        'Designed and executed performance testing for web applications using JMeter, identifying and resolving performance bottlenecks to enhance user experience.',
        'Mentored junior automation engineers, fostering a culture of innovation and knowledge sharing.',
      ],
      present: false,
    },
    {
      company: 'AutomateTech',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Automation Engineer',
      url: 'https://www.automatetech.com',
      startDate: '2019-07-01',
      endDate: '2021-01-31',
      summary: null,
      highlights: [
        'Developed PLC-based control systems for production lines, reducing equipment downtime by 25% and increasing overall equipment effectiveness (OEE) by 15%.',
        'Implemented real-time data monitoring and visualization using SCADA systems, enabling proactive maintenance and minimizing production disruptions.',
      ],
      present: false,
    },
    {
      company: 'AutomateSoft',
      location: {
        city: 'Boston',
        countryCode: 'US',
        region: 'Massachusetts',
      },
      position: 'Automation Intern',
      url: 'https://www.automatesoft.com',
      startDate: '2018-05-15',
      endDate: '2018-08-15',
      summary: null,
      highlights: [
        'Assisted in developing Python scripts to automate data collection and analysis, reducing data processing time by 50%.',
        'Participated in the installation and troubleshooting of automated machinery and equipment, gaining hands-on experience in automation technologies.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Mechanical Engineering',
      major: 'Automation and Robotics',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2017-09-01',
      endDate: '2019-05-15',
      gpa: '3.9',
      courses: [
        'Industrial Automation',
        'Robot Kinematics and Dynamics',
        'Advanced Control Systems',
        'Optimization Techniques',
      ],
      summary: null,
      present: false,
    },
    {
      institution: 'University of Michigan',
      degree: 'Bachelor of Science in Electrical Engineering',
      major: 'Electrical Engineering',
      location: {
        city: 'Ann Arbor',
        countryCode: 'US',
        region: 'Michigan',
      },
      startDate: '2013-09-01',
      endDate: '2017-05-15',
      gpa: '3.7',
      courses: [
        'Digital Control Systems',
        'Microcontrollers and Embedded Systems',
        'Linear Systems Analysis',
        'Signal Processing',
      ],
      summary: null,
      present: false,
    },
  ],
  awards: [
    {
      title: 'Automation Excellence Award',
      date: '2022-05-10',
      awarder: 'Automation Professionals Association',
      summary:
        'Recognized for outstanding contributions and achievements in the field of automation engineering.',
    },
    {
      title: 'Engineering Innovation Scholarship',
      date: '2018-03-20',
      awarder: 'Stanford University',
      summary:
        'Received a scholarship in recognition of innovative research and contributions to the field of automation and robotics.',
    },
  ],
  skills: [
    {
      skillGroup: 'Automation Technologies',
      skill: [
        { name: 'PLC Programming' },
        { name: 'SCADA Systems' },
        { name: 'Robotics' },
        { name: 'Industrial IoT' },
        { name: 'HMI Design' },
      ],
    },
    {
      skillGroup: 'Programming Languages',
      skill: [
        { name: 'Python' },
        { name: 'C++' },
        { name: 'Java' },
        { name: 'Ladder Logic' },
        { name: 'MATLAB' },
      ],
    },
    {
      skillGroup: 'Continuous Improvement',
      skill: [
        { name: 'Lean Six Sigma' },
        { name: 'Root Cause Analysis' },
        { name: 'Kaizen' },
        { name: 'Process Optimization' },
      ],
    },
  ],
  projects: [
    {
      name: 'Automated Production Line Optimization',
      description:
        'Designed and implemented an automated control system to optimize production line efficiency and reduce waste.',
      startDate: '2022-01-15',
      endDate: '2022-07-31',
      present: false,
    },
    {
      name: 'Smart Factory Dashboard',
      description:
        'Developed a real-time dashboard using SCADA to monitor key performance indicators (KPIs) and production metrics.',
      startDate: '2021-08-01',
      endDate: '2021-12-15',
      present: false,
    },
    {
      name: 'Automated Quality Inspection',
      description:
        'Led a team in designing an automated system for inspecting product quality, reducing defects by 20%.',
      startDate: '2020-03-01',
      endDate: '2020-09-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Languages',
      details: [
        {
          title: 'English',
          subtitle: 'Native',
        },
        {
          title: 'Spanish',
          subtitle: 'Intermediate',
        },
      ],
    },
    {
      name: 'Certifications',
      details: [
        {
          title: 'Certified Automation Professional (CAP)',
          subtitle: 'International Society of Automation (ISA)',
          location: {
            city: 'Online',
            countryCode: 'US',
          },
          startDate: '2021-04-01',
          endDate: 'No Expiry',
          summary:
            'Achieved certification demonstrating expertise in automation concepts, processes, and applications.',
        },
        {
          title: 'Six Sigma Green Belt',
          subtitle: 'American Society for Quality (ASQ)',
          location: {
            city: 'Online',
            countryCode: 'US',
          },
          startDate: '2019-11-01',
          endDate: 'No Expiry',
          summary:
            'Certified in Six Sigma methodologies for process improvement and defect reduction.',
        },
      ],
    },
  ],
}
