import { Link } from '@react-pdf/renderer'

export function formatLinkedInUrl(url) {
  return removeUrlProtocol(url).replace(/www\./g, '')
}

export function removeUrlProtocol(url) {
  return url.replace(/(^\w+:|^)\/\//, '')
}

export function formatSrcUrl(url) {
  if (!/^https?:\/\//i.test(url)) {
    url = 'http://' + url
  }
  return url
}

export function renderLink(url, styles) {
  return (
    <Link style={styles.link} src={formatSrcUrl(url)}>
      {removeUrlProtocol(url)}
    </Link>
  )
}

export function renderLinkedInLink(url, styles) {
  return (
    <Link style={styles.link} src={formatSrcUrl(url)}>
      {formatLinkedInUrl(url)}
    </Link>
  )
}

export function getContactInfoArray(basics, styles) {
  let contact = []
  if (basics.email) {
    contact.push(
      <Link style={styles.link} src={'mailto:' + basics.email}>
        {basics.email}
      </Link>
    )
  }
  if (basics.phone) {
    contact.push(
      <Link style={styles.link} src={'tel:' + basics.phone}>
        {basics.phone}
      </Link>
    )
  }
  if (basics.url) {
    contact.push(renderLink(basics.url, styles))
  }
  if (basics.portfolioUrl) {
    contact.push(renderLink(basics.portfolioUrl, styles))
  }
  if (basics.linkedInUrl) {
    contact.push(renderLinkedInLink(basics.linkedInUrl, styles))
  }
  return contact
}
