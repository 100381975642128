import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { classNames } from 'utils/helper'

export default function DropdownMenu({ dropdown }) {
  return (
    <Popover className="relative my-4">
      {({ open, close }) => (
        <>
          <Popover.Button className="px-2 h-full flex items-center gap-x-2 text-md font-medium leading-6 text-gray-900">
            <span>{dropdown.name}</span>
            {open ? (
              <ChevronUpIcon className="h-4 w-4" />
            ) : (
              <ChevronDownIcon className="h-4 w-4" />
            )}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 -mt-3 flex w-screen max-w-max -translate-x-1/2 px-4">
              <div
                className={classNames(
                  dropdown.nameOnly ? 'max-w-[16rem]' : 'max-w-xs',
                  'w-screen flex-auto overflow-hidden rounded-xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5'
                )}
              >
                <div className="p-2">
                  {dropdown.items.map((item) => (
                    <div
                      key={item.name}
                      className={classNames(
                        dropdown.nameOnly ? 'p-2' : 'p-3',
                        'group relative flex gap-x-4 rounded-lg hover:bg-gray-50'
                      )}
                    >
                      {item.icon && (
                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <item.icon
                            className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                      <div className="">
                        <Link
                          to={item.to}
                          onClick={() => close()}
                          className="font-semibold text-gray-900 inline-flex items-center justify-between gap-1"
                        >
                          {item.name}
                          {item.comingSoon && (
                            <span className="text-xs font-normal py-0.5 px-1.5 bg-yellow-200 text-gray-700 rounded">
                              Coming soon
                            </span>
                          )}
                          {item.new && (
                            <span className="text-xs font-normal py-0.5 px-1.5 bg-red-400 text-white rounded">
                              New
                            </span>
                          )}
                          <span className="absolute inset-0" />
                        </Link>
                        <p className="text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
