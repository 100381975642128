export const resume = {
  basics: {
    firstName: 'Emily',
    lastName: 'Chang',
    title: 'Integration Engineer',
    picture: 'https://example.com/emily-chang.jpg',
    email: 'emily.chang@example.com',
    phone: '123-456-7890',
    url: 'https://www.emilychang-integration.dev',
    summary:
      'Results-oriented Integration Engineer with a proven track record of designing and implementing seamless software and hardware integrations. Experienced in optimizing workflows and enhancing system performance to deliver exceptional user experiences.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/emilychang',
    portfolioUrl: 'https://portfolio.emilychang-integration.dev',
  },
  experience: [
    {
      company: 'TechFusion Solutions',
      location: {
        city: 'San Jose',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Senior Integration Engineer',
      url: 'https://www.techfusionsolutions.com',
      startDate: '2021-02-15',
      endDate: '2023-08-31',
      summary: null,
      highlights: [
        'Designed and implemented API integrations between proprietary and third-party systems, resulting in a 25% reduction in data transfer time.',
        'Led a cross-functional team to integrate hardware and software components for a robotics automation project, achieving a 15% increase in production efficiency.',
        'Collaborated with clients to define integration requirements, leading to a 40% improvement in data accuracy and synchronization.',
        'Developed automated test suites for integration validation, reducing testing time by 30% and improving software quality.',
      ],
      present: false,
    },
    {
      company: 'IntegrateTech',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Integration Engineer',
      url: 'https://www.integratetech.com',
      startDate: '2019-06-01',
      endDate: '2021-01-31',
      summary: null,
      highlights: [
        'Developed and deployed custom ETL pipelines to integrate data from various sources, resulting in a 30% reduction in data processing time.',
        'Collaborated with software development teams to integrate machine learning models into a real-time recommendation system, leading to a 20% increase in user engagement.',
        'Designed and implemented cross-platform integrations for mobile applications, ensuring consistent functionality across different devices and platforms.',
      ],
      present: false,
    },
    {
      company: 'IntelliConnect',
      location: {
        city: 'Austin',
        countryCode: 'US',
        region: 'Texas',
      },
      position: 'Integration Intern',
      url: 'https://www.intelliconnect.com',
      startDate: '2018-05-15',
      endDate: '2018-08-15',
      summary: null,
      highlights: [
        'Supported the development and testing of RESTful APIs for data exchange between internal and external systems.',
        'Collaborated with integration engineers to troubleshoot and resolve integration issues, contributing to a 25% reduction in issue resolution time.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Software Engineering',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2017-09-01',
      endDate: '2019-05-15',
      gpa: '3.8',
      courses: [
        'Software Architecture',
        'Distributed Systems',
        'Web Services and APIs',
        'Database Systems',
      ],
      summary: null,
      present: false,
    },
    {
      institution: 'University of Washington',
      degree: 'Bachelor of Science in Electrical Engineering',
      major: 'Electrical Engineering',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      startDate: '2013-09-01',
      endDate: '2017-05-15',
      gpa: '3.6',
      courses: [
        'Digital Signal Processing',
        'Microprocessors and Microcontrollers',
        'Embedded Systems Design',
        'Control Systems',
      ],
      summary: null,
      present: false,
    },
  ],
  awards: [
    {
      title: 'Integration Excellence Award',
      date: '2022-04-10',
      awarder: 'Integration Professionals Association',
      summary:
        'Recognized for outstanding contributions and achievements in the field of integration engineering.',
    },
    {
      title: 'Engineering Innovation Scholarship',
      date: '2018-03-20',
      awarder: 'Stanford University',
      summary:
        'Received a scholarship in recognition of innovative research and contributions to the field of software integration.',
    },
  ],
  skills: [
    {
      skillGroup: 'Integration Technologies',
      skill: [
        { name: 'API Design and Implementation' },
        { name: 'ETL Pipelines' },
        { name: 'Microservices Architecture' },
        { name: 'Message Queues' },
      ],
    },
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'Java' }, { name: 'Python' }, { name: 'JavaScript' }],
    },
    {
      skillGroup: 'Integration Tools',
      skill: [
        { name: 'MuleSoft Anypoint Platform' },
        { name: 'Apache Camel' },
        { name: 'Talend' },
        { name: 'IBM Integration Bus' },
      ],
    },
  ],
  projects: [
    {
      name: 'Enterprise System Integration',
      description:
        'Designed and implemented a comprehensive integration solution to connect multiple enterprise systems, resulting in streamlined data flow and improved decision-making processes.',
      startDate: '2022-01-15',
      endDate: '2022-08-31',
      present: false,
    },
    {
      name: 'Cloud Application Integration',
      description:
        'Led the integration of cloud-based applications with on-premises systems, enabling real-time data synchronization and enhancing user experience.',
      startDate: '2021-04-01',
      endDate: '2021-11-30',
      present: false,
    },
    {
      name: 'IoT Device Integration',
      description:
        'Developed a solution to integrate IoT devices with a centralized platform, enabling remote monitoring and control of devices from a single interface.',
      startDate: '2020-07-01',
      endDate: '2020-12-15',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Languages',
      details: [
        {
          title: 'English',
          subtitle: 'Native',
        },
        {
          title: 'Spanish',
          subtitle: 'Intermediate',
        },
      ],
    },
    {
      name: 'Certifications',
      details: [
        {
          title: 'Certified Integration Professional (CIP)',
          subtitle: 'Integration Professionals Association',
          location: {
            city: 'Online',
            countryCode: 'US',
          },
          startDate: '2022-03-01',
          endDate: 'No Expiry',
          summary:
            'Demonstrated expertise in integration concepts, practices, and technologies.',
        },
        {
          title: 'MuleSoft Certified Developer – Integration and API Associate',
          subtitle: 'MuleSoft',
          location: {
            city: 'Online',
            countryCode: 'US',
          },
          startDate: '2021-08-01',
          endDate: 'No Expiry',
          summary:
            'Achieved certification in designing, building, and managing integrations using MuleSoft Anypoint Platform.',
        },
      ],
    },
  ],
}
