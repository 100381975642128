import HtmlContent from '../../HtmlContent'
import SafeText from '../../SafeText'
import ProjectValueMapper from './ProjectValueMapper'

class InlineProjectValueMapper extends ProjectValueMapper {
  title() {
    return null
  }

  text() {
    return null
  }

  children() {
    const { name, description } = this.project
    return (
      <SafeText val={true} style>
        <SafeText val={name} style={this.styles.title}>
          {name}{' '}
        </SafeText>
        <SafeText val={description} style={this.styles.inlineSubtitle}>
          <HtmlContent styles={this.styles.htmlContent}>
            {description}
          </HtmlContent>
        </SafeText>
      </SafeText>
    )
  }
}

export default InlineProjectValueMapper
