import { Link } from 'react-router-dom'

const ResumeFeature = () => {
  return (
    <div className="relative bg-gray-50">
      <div className="max-w-6xl mx-auto px-4 lg:px-6">
        <div className="py-16">
          <div className="grid grid-cols-1 sm:grid-cols-2 items-center gap-24">
            <div className="order-last sm:order-first">
              <div className="overflow-hidden bg-blue-200 rounded-lg gap-8 border border-gray-900 pt-8">
                <img
                  className="rounded-t-lg w-3/4 mx-auto shadow-lg"
                  src={require(`assets/img/Template${2}.png`)}
                />
              </div>
            </div>
            <div>
              <h1 className="text-5xl font-bold mb-6 text-gray-900 leading-tight">
                Job-winning
                <br />
                Resume Builder
              </h1>
              <p className="text-lg font-medium mb-12 text-gray-900">
                Create your resume with ease, so you can focus on your job
                search, not the tools. We'll guide you along the way.
              </p>
              <Link
                to="/auth/register"
                className="text-md font-semibold py-4 px-8 bg-blue-600 rounded-lg text-white"
              >
                Get Started Free
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResumeFeature
