import { getDate, joinFields } from '../../Utils/helper'
import { getHighlights } from '../utils'
import CustomSectionValueMapper from './CustomSectionValueMapper'

class DotCustomSectionValueMapper extends CustomSectionValueMapper {
  title() {
    const { subtitle, title } = this.customSection
    return joinFields(' • ', subtitle, title)
  }

  subtitle() {
    const { location, startDate, endDate, present } = this.customSection
    const locationText =
      location && joinFields(', ', location.city, location.region)
    const dateText = getDate(startDate, endDate, present)
    return joinFields(' • ', locationText, dateText)
  }

  children() {
    return getHighlights(this.customSection.highlights, this.styles, false)
  }
}

export default DotCustomSectionValueMapper
