export const resume = {
  basics: {
    firstName: 'Emily',
    lastName: 'Liu',
    title: 'AI Engineer',
    picture: 'https://example.com/emily-liu.jpg',
    email: 'emily.liu@example.com',
    phone: '123-456-7890',
    url: 'https://www.emilyliu-ai.dev',
    summary:
      'Experienced AI Engineer with a passion for developing innovative machine learning solutions to solve complex problems. Skilled in model development, data preprocessing, and deployment.',
    location: {
      city: 'Seattle',
      countryCode: 'US',
      region: 'Washington',
    },
    linkedInUrl: 'https://www.linkedin.com/in/emilyliu',
    portfolioUrl: 'https://portfolio.emilyliu-ai.dev',
  },
  experience: [
    {
      company: 'InnoTech Labs',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Lead AI Engineer',
      url: 'https://www.innotechlabs.com',
      startDate: '2020-07-01',
      endDate: '2023-09-30',
      summary: null,
      highlights: [
        'Developed a recommendation system using collaborative filtering, increasing user engagement by 25% and revenue by 15%.',
        'Led the implementation of a natural language processing model for sentiment analysis, achieving 90% accuracy in predicting customer sentiment.',
        'Optimized neural network architectures for image recognition tasks, improving accuracy by 10% on benchmark datasets.',
        'Collaborated with cross-functional teams to integrate AI models into existing software products, enhancing user experience and functionality.',
      ],
      present: false,
    },
    {
      company: 'DataMind AI',
      location: {
        city: 'New York',
        countryCode: 'US',
        region: 'New York',
      },
      position: 'AI Researcher',
      url: 'https://www.datamindai.com',
      startDate: '2018-05-15',
      endDate: '2020-06-30',
      summary: null,
      highlights: [
        'Developed a deep reinforcement learning agent for automated stock trading, achieving a 20% increase in returns compared to traditional strategies.',
        'Designed and implemented an anomaly detection system for network security, reducing false positives by 30% and improving detection accuracy.',
        'Contributed to the development of a chatbot using transformer models for customer support, reducing response time by 40% and enhancing user satisfaction.',
      ],
      present: false,
    },
    {
      company: 'CogniTech Solutions',
      location: {
        city: 'Austin',
        countryCode: 'US',
        region: 'Texas',
      },
      position: 'Machine Learning Engineer',
      url: 'https://www.cognitechsolutions.com',
      startDate: '2016-08-01',
      endDate: '2018-04-30',
      summary: null,
      highlights: [
        'Built a predictive maintenance model for manufacturing equipment, reducing downtime by 15% and saving $500,000 annually in maintenance costs.',
        'Implemented a fraud detection system using random forest algorithms, achieving a 95% accuracy rate in identifying fraudulent transactions.',
        'Optimized feature engineering and hyperparameter tuning for customer segmentation, resulting in a 20% improvement in targeted marketing campaign performance.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Massachusetts Institute of Technology',
      degree: 'Master of Science in Computer Science',
      major: 'Artificial Intelligence',
      location: {
        city: 'Cambridge',
        countryCode: 'US',
        region: 'Massachusetts',
      },
      startDate: '2014-09-01',
      endDate: '2016-05-15',
      gpa: '4.0',
      courses: [
        'Machine Learning',
        'Deep Learning',
        'Computer Vision',
        'Reinforcement Learning',
      ],
      summary: null,
      present: false,
    },
    {
      institution: 'University of Toronto',
      degree: 'Bachelor of Science in Electrical Engineering',
      major: 'Electrical Engineering',
      location: {
        city: 'Toronto',
        countryCode: 'CA',
        region: 'Ontario',
      },
      startDate: '2010-09-01',
      endDate: '2014-06-15',
      gpa: '3.85',
      courses: [
        'Digital Signal Processing',
        'Control Systems',
        'Machine Learning Fundamentals',
        'Data Structures',
      ],
      summary: null,
      present: false,
    },
  ],
  awards: [
    {
      title: 'Outstanding AI Researcher Award',
      date: '2022-11-15',
      awarder: 'Association for Computing Machinery',
      summary:
        'Recognized for exceptional contributions to the field of artificial intelligence research and innovation.',
    },
    {
      title: 'Best Paper Award',
      date: '2019-07-20',
      awarder: 'International Conference on Machine Learning',
      summary:
        'Received the Best Paper Award for presenting groundbreaking research on reinforcement learning techniques.',
    },
  ],
  skills: [
    {
      skillGroup: 'Machine Learning',
      skill: [
        { name: 'Supervised Learning' },
        { name: 'Unsupervised Learning' },
        { name: 'Deep Learning' },
        { name: 'Reinforcement Learning' },
        { name: 'Natural Language Processing' },
      ],
    },
    {
      skillGroup: 'Programming Languages',
      skill: [
        { name: 'Python' },
        { name: 'Java' },
        { name: 'C++' },
        { name: 'Scala' },
        { name: 'SQL' },
      ],
    },
    {
      skillGroup: 'Tools & Frameworks',
      skill: [
        { name: 'TensorFlow' },
        { name: 'PyTorch' },
        { name: 'Scikit-learn' },
        { name: 'Keras' },
        { name: 'Jupyter' },
      ],
    },
  ],
  projects: [
    {
      name: 'Autonomous Drone Navigation',
      description:
        'Developed an AI-powered autonomous drone navigation system using computer vision and reinforcement learning techniques.',
      startDate: '2022-03-01',
      endDate: '2022-09-30',
      present: false,
    },
    {
      name: 'AI-Powered Medical Diagnosis',
      description:
        'Built a model for diagnosing medical conditions from X-ray images, achieving accuracy comparable to expert radiologists.',
      startDate: '2021-01-15',
      endDate: '2021-07-30',
      present: false,
    },
    {
      name: 'AI-Enhanced Financial Forecasting',
      description:
        'Developed a time series forecasting model for predicting stock prices using deep learning and sentiment analysis.',
      startDate: '2020-03-01',
      endDate: '2020-08-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Languages',
      details: [
        {
          title: 'English',
          subtitle: 'Native',
        },
        {
          title: 'Mandarin Chinese',
          subtitle: 'Intermediate',
        },
      ],
    },
    {
      name: 'Certifications',
      details: [
        {
          title: 'Deep Learning Specialization',
          subtitle: 'Coursera',
          location: {
            city: 'Online',
            countryCode: 'US',
          },
          startDate: '2019-09-01',
          endDate: '2019-12-01',
          summary:
            'Completed a comprehensive deep learning specialization covering neural networks, CNNs, RNNs, and generative models.',
        },
        {
          title: 'Reinforcement Learning Certification',
          subtitle: 'Udacity',
          location: {
            city: 'Online',
            countryCode: 'US',
          },
          startDate: '2018-03-01',
          endDate: '2018-05-01',
          summary:
            'Earned a certification in reinforcement learning, gaining hands-on experience in building and training RL agents.',
        },
      ],
    },
  ],
}
