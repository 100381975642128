import { View } from '@react-pdf/renderer'
import SafeText from '../SafeText'
import HtmlContent from '../HtmlContent'

export default function DefaultTemplateEntry({
  styles,
  title,
  subtitle,
  text,
  children,
}) {
  return (
    <View style={styles?.entryContainer}>
      <SafeText val={title} style={styles?.title}>
        {title}
      </SafeText>
      <SafeText val={subtitle} style={styles?.subtitle}>
        {subtitle}
      </SafeText>
      <SafeText val={text} style={styles?.text}>
        <HtmlContent styles={styles?.htmlContent}>{text}</HtmlContent>
      </SafeText>
      {children}
    </View>
  )
}
