import { useFormikContext } from 'formik'
import { ResumeContext } from 'layouts/Resume'
import _ from 'lodash'
import { useContext, useEffect, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const useDraggableInPortal = () => {
  const self = useRef({}).current

  useEffect(() => {
    const div = document.createElement('div')
    div.style.position = 'absolute'
    div.style.pointerEvents = 'none'
    div.style.top = '0'
    div.style.width = '100%'
    div.style.height = '100%'
    self.elt = div
    document.body.appendChild(div)
    return () => {
      document.body.removeChild(div)
    }
  }, [self])

  return (render) =>
    (provided, ...args) => {
      const element = render(provided, ...args)
      if (provided.draggableProps.style.position === 'fixed') {
        return createPortal(element, self.elt)
      }
      return element
    }
}

const renderSection = (section) => {
  if (section.startsWith('custom:')) {
    const name = section.slice(7, -25)
    return name
  }
  return capitalizeFirstLetter(section)
}

const DragDropComponent = ({ tmpOrder, onDragEnd }) => {
  const renderDraggable = useDraggableInPortal()
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div
        className={[
          'flex-1 grid gap-2',
          tmpOrder.length > 1 ? 'grid-cols-2' : 'grid-cols-1',
        ].join(' ')}
        aria-hidden="true"
      >
        {tmpOrder &&
          tmpOrder.map((column, index) => {
            if (index == 0) {
              return (
                <Droppable key="droppable-0" droppableId="droppable-0">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className="col-span-1 flex-1 flex flex-col"
                    >
                      {column.map((section, index) => (
                        <Draggable
                          key={section}
                          draggableId={section}
                          index={index}
                        >
                          {renderDraggable((provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="h-16 p-2 bg-gray-100 mb-2"
                            >
                              <div className="h-full border-2 border-dashed border-gray-300 flex justify-center items-center text-md bg-gray-100 rounded">
                                <div>{renderSection(section)}</div>
                              </div>
                            </div>
                          ))}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )
            }
            if (index === 1) {
              return (
                <Droppable key="droppable-1" droppableId="droppable-1">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className="col-span-1 flex flex-col"
                    >
                      {column.map((section, index) => (
                        <Draggable
                          key={section}
                          draggableId={section}
                          index={index}
                        >
                          {renderDraggable((provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="h-16 p-2 bg-gray-100 mb-2"
                            >
                              <div className="h-full border-2 border-dashed border-gray-300 flex justify-center items-center text-md bg-gray-100 rounded">
                                <div>{renderSection(section)}</div>
                              </div>
                            </div>
                          ))}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )
            }
          })}
      </div>
    </DragDropContext>
  )
}

export default function SectionOrder() {
  const formik = useFormikContext()
  const [tmpOrder, setTmpOrder] = useState(undefined)
  const { resume, updateOrder } = useContext(ResumeContext)
  const [loading, setLoading] = useState(false)

  const order = resume.order

  useEffect(() => {
    setTmpOrder(order)
  }, [order])

  const isDirty = !_.isEqual(order, tmpOrder)

  const onDragEnd = (result) => {
    const { draggableId, source, destination } = result
    if (!destination) {
      return
    }

    var orderCopy = tmpOrder.map(function (arr) {
      return arr.slice()
    })

    if (source.droppableId === destination.droppableId) {
      if (source.index == destination.index) {
        return
      }
      let id
      if (source.droppableId == 'droppable-0') id = 0
      else id = 1
      orderCopy[id].splice(source.index, 1)
      orderCopy[id].splice(destination.index, 0, draggableId)
    } else {
      let sourceId
      let destinationId
      if (source.droppableId == 'droppable-0') {
        sourceId = 0
        destinationId = 1
      } else {
        sourceId = 1
        destinationId = 0
      }
      orderCopy[sourceId].splice(source.index, 1)
      orderCopy[destinationId].splice(destination.index, 0, draggableId)
    }
    setTmpOrder(orderCopy)
  }

  return (
    <div className="px-4 py-5 sm:p-6 w-full">
      <div className="pt-2 pb-8 flex items-center">
        <h2 className="flex-1 text-xl font-bold leading-6 text-gray-900">
          Order sections
        </h2>
        <div className="flex gap-4">
          {isDirty && (
            <button
              type="button"
              className="inline-flex justify-center px-6 py-2 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md hover:bg-gray-100"
              onClick={() => setTmpOrder(order)}
            >
              Reset
            </button>
          )}
          <button
            type="button"
            disabled={loading}
            className="disabled:opacity-50 inline-flex justify-center px-6 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
            onClick={async () => {
              if (!isDirty) return
              setLoading(true)
              var updatedResume = await updateOrder(tmpOrder)
              formik.resetForm({ values: updatedResume })
              setLoading(false)
            }}
          >
            Save
          </button>
        </div>
      </div>
      <div>
        <div className="flex-grow mb-4 text-center">
          Drag and drop to rearrange the sections
        </div>
        <div className="border mx-auto w-96 min-h-[496px] p-4 rounded shadow-lg">
          <div className="h-16 p-2 bg-gray-100 mb-2">
            <div className="h-full border-2 border-gray-300 flex justify-center items-center text-md bg-gray-100 rounded">
              <div>Profile</div>
            </div>
          </div>
          {tmpOrder && (
            <DragDropComponent tmpOrder={tmpOrder} onDragEnd={onDragEnd} />
          )}
        </div>
      </div>
    </div>
  )
}
