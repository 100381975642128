import { ChevronLeftIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { classNames } from 'utils/helper'
import CustomizeTabContent from './CustomizeTabContent'
import EditTabContent from './EditTabContent'
import LoadingIcon from './LoadingIcon'
import PreviewResumeModal from './PreviewResumeModal'
import TemplatesTabContent from './TemplatesTabContent'

const MobileTabs = ({ tabs, selectedTab, setSelectedTab }) => {
  return (
    <div className="px-2 py-1 bg-gray-50">
      <div className="grid grid-cols-3 bg-gray-100 rounded gap-1 p-0.5">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            type="button"
            onClick={() => setSelectedTab(tab)}
            className={classNames(
              selectedTab.name === tab.name ? 'bg-white' : 'bg-transparent',
              'flex py-1.5 rounded text-xs font-semibold uppercase justify-center w-full items-center px-3 select-none'
            )}
          >
            {tab.name}
          </button>
        ))}
      </div>
    </div>
  )
}

const MobileLayoutContent = () => {
  const tabs = [
    {
      name: 'Edit',
      TabContent: EditTabContent,
    },
    {
      name: 'Templates',
      TabContent: TemplatesTabContent,
    },
    {
      name: 'Customize',
      TabContent: CustomizeTabContent,
    },
  ]
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [isOpen, setIsOpen] = useState(false)
  const { TabContent } = selectedTab
  return (
    <div className="relative flex-1 flex flex-col sm:hidden overflow-y-auto">
      <PreviewResumeModal isOpen={isOpen} closeModal={() => setIsOpen(false)} />
      <div
        style={{
          zIndex: 1,
        }}
        className="sticky top-0"
      >
        <div className="bg-gray-50 flex w-full h-12">
          <Link
            to="/dashboard/resumes"
            className="px-1 flex-none flex items-center justify-center"
          >
            <ChevronLeftIcon className="w-6 h-6 text-gray-400" />
            Back
          </Link>
          <div className="flex-1"></div>
          <div className="w-12 flex items-center justify-center">
            <LoadingIcon />
          </div>
        </div>
        <MobileTabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <div className="flex flex-1 flex-col bg-gray-100 pb-20">
        <TabContent />
      </div>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className="fixed bottom-4 right-4 border rounded-full text-white bg-indigo-600 text-lg font-medium py-3 px-6"
      >
        Preview
      </button>
    </div>
  )
}

export default MobileLayoutContent
