import { ResumeExamplesArrayNoItems } from 'marketing/resources/ResumeExamplesMap'
import { ResumeTemplatesArray } from 'marketing/resources/ResumeTemplatesMap'
import { Link } from 'react-router-dom'
import { FacebookIcon, LinkedinIcon, TwitterIcon } from 'react-share'

const Footer = () => {
  return (
    <div className="relative border-t bg-white">
      <div className="max-w-7xl mx-auto px-4 lg:px-6 py-20">
        <div className="w-full flex flex-col gap-y-12 sm:flex-row justify-between text-gray-900 mb-16">
          <div className="text-3xl font-bold">Resumary</div>
          <div className="flex flex-col gap-4">
            <span className="font-bold">Products</span>
            {/* <div>Resume Builder</div> */}
            <Link to="/resume-templates">
              <div>Resume Templates</div>
            </Link>
            <Link to="/resume-examples">
              <div>Resume Examples</div>
            </Link>
            <a
              href="https://chrome.google.com/webstore/detail/oneclick-job-tracker-for/mkjedhephckpdnpadogmilejdbgbbdfm?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              <div>OneClick Chrome Extension</div>
            </a>
            <Link to="/auth/login">
              <div>Sign In</div>
            </Link>
            <Link to="/auth/register">
              <div>Sign Up</div>
            </Link>
          </div>
          <div className="flex flex-col gap-4">
            <span className="font-bold">Examples</span>
            {ResumeExamplesArrayNoItems.slice(0, 10).map((example) => (
              <Link to={example.to}>
                <div>{example.name} Resume Examples</div>
              </Link>
            ))}
            <Link to="/resume-examples">
              <div>View All</div>
            </Link>
          </div>
          <div className="flex flex-col gap-4">
            <span className="font-bold">Templates</span>
            {ResumeTemplatesArray.slice(0, 10).map((template) => (
              <Link to={template.to}>
                <div>{template.name}</div>
              </Link>
            ))}
            <Link to="/resume-templates">
              <div>View All</div>
            </Link>
          </div>
          <div className="flex flex-col gap-4">
            <span className="font-bold">Company</span>
            <Link to="/terms">
              <div>Terms & Conditions</div>
            </Link>
            <Link to="/privacy">
              <div>Privacy Policy</div>
            </Link>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-y-4 justify-between items-center">
          <div className="order-last sm:order-first text-gray-900 text-sm font-medium">
            Copyright 2023 Resumary. All Rights Reserved
          </div>
          <div className="flex gap-6">
            <LinkedinIcon className="w-8 h-8 rounded-full" />
            <TwitterIcon className="w-8 h-8 rounded-full" />
            <FacebookIcon className="w-8 h-8 rounded-full" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
