import moment from 'moment'

export function getDate(startDate, endDate, present) {
  if (startDate && present) {
    return moment.utc(startDate).format('MMM YYYY') + ' - Present'
  } else if (startDate && endDate) {
    return (
      moment.utc(startDate).format('MMM YYYY') +
      ' - ' +
      moment.utc(endDate).format('MMM YYYY')
    )
  } else if (startDate) {
    return moment.utc(startDate).format('MMM YYYY')
  } else if (endDate) {
    return moment.utc(endDate).format('MMM YYYY')
  }
  return null
}

export function getLocationText(location) {
  return joinFields(', ', location?.city, location?.region)
}

export function getGpa(gpa) {
  if (gpa) {
    return `GPA: ${gpa}`
  }
  return null
}

export function joinFields(separator, ...fields) {
  const nonEmptyFields = fields.filter((field) => !!field)
  if (nonEmptyFields.length === 0) {
    return null
  }
  return nonEmptyFields.join(separator)
}

export function joinFieldsToArray(separator, ...fields) {
  const nonEmptyFields = fields.filter((field) => !!field)
  if (nonEmptyFields.length === 0) {
    return null
  }
  if (separator) {
    const copy = []
    nonEmptyFields.forEach((e, i) => {
      if (i !== nonEmptyFields.length - 1) {
        copy.push(e, separator)
      } else {
        copy.push(e)
      }
    })
    return copy
  }
  return nonEmptyFields
}

function isObject(val) {
  return typeof val === 'object' && !Array.isArray(val) && val !== null
}

export const mapIdToSection = (customSections) => {
  const results = {}
  customSections &&
    customSections.forEach((customSection) => {
      results[customSection._id] = customSection
    })
  return results
}

export function cleanObject(obj) {
  Object.keys(obj).forEach((k) => {
    if (isObject(obj[k])) {
      cleanObject(obj[k])
    } else {
      obj[k] = obj[k] === '' ? null : obj[k]
    }
  })
}

export function capitalizeWords(sentence) {
  const words = sentence.split(' ')
  for (let i = 0; i < words.length; i++) {
    const word = words[i]
    if (word) words[i] = word[0].toUpperCase() + word.substr(1)
  }
  return words.join(' ')
}
