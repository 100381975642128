export const resume = {
  basics: {
    firstName: 'Aisha',
    lastName: 'Chowdhury',
    title: 'Security Engineer',
    picture: 'https://example.com/aisha-chowdhury.jpg',
    email: 'aisha.chowdhury@example.com',
    phone: '123-456-7890',
    url: 'https://www.aishachowdhury.dev',
    summary:
      'Experienced Security Engineer with a strong background in designing and implementing robust security solutions. Skilled in identifying vulnerabilities, performing risk assessments, and implementing security measures to protect critical data and systems. Committed to maintaining the highest level of security standards.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/aishachowdhury',
    portfolioUrl: 'https://portfolio.aishachowdhury.dev',
  },
  experience: [
    {
      company: 'Palantir Technologies',
      location: {
        city: 'Palo Alto',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Security Engineer',
      url: 'https://www.palantir.com',
      startDate: '2020-03-15',
      endDate: '2023-06-30',
      summary: null,
      highlights: [
        'Led a team to perform regular penetration testing, identifying and addressing critical vulnerabilities, resulting in a 40% reduction in potential security risks.',
        'Implemented Security Information and Event Management (SIEM) systems, enabling real-time monitoring and detection of security incidents.',
        'Collaborated with cross-functional teams to develop and enforce security policies and procedures, ensuring compliance with industry standards.',
        'Conducted security training sessions for development teams, enhancing awareness of secure coding practices and reducing code vulnerabilities',
      ],
      present: false,
    },
    {
      company: 'Dropbox',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Security Analyst',
      url: 'https://www.dropbox.com',
      startDate: '2018-09-01',
      endDate: '2020-02-28',
      summary: null,
      highlights: [
        'Performed vulnerability assessments and security audits, leading to a 30% reduction in identified vulnerabilities over a one-year period.',
        'Collaborated with the incident response team to investigate and mitigate security incidents, minimizing potential data breaches.',
        "Designed and implemented security monitoring tools using Python and Splunk, enhancing the company's ability to detect and respond to security threats.",
      ],
      present: false,
    },
    {
      company: 'Square',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Security Intern',
      url: 'https://www.squareup.com',
      startDate: '2017-06-01',
      endDate: '2017-08-31',
      summary: null,
      highlights: [
        'Conducted code reviews and security assessments of applications, identifying and remediating security flaws before deployment.',
        'Developed and executed test plans for evaluating the effectiveness of security controls, contributing to the improvement of security processes.',
        'Assisted in the implementation of multi-factor authentication (MFA) for employee accounts, enhancing the overall security posture.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'University of Maryland, College Park',
      degree: 'Master of Science in Cybersecurity',
      major: 'Cybersecurity',
      location: {
        city: 'College Park',
        countryCode: 'US',
        region: 'Maryland',
      },
      startDate: '2016-09-01',
      endDate: '2018-05-31',
      gpa: '3.8',
      courses: ['Network Security', 'Cryptography', 'Security Risk Management'],
      summary: '',
      present: false,
    },
    {
      institution: 'University of Texas at Dallas',
      degree: 'Bachelor of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Richardson',
        countryCode: 'US',
        region: 'Texas',
      },
      startDate: '2012-08-01',
      endDate: '2016-05-15',
      gpa: '3.7',
      courses: [
        'Software Engineering',
        'Operating Systems',
        'Database Systems',
      ],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'ISC2 Certified Information Systems Security Professional (CISSP)',
      date: '2022-01-15',
      awarder:
        'International Information System Security Certification Consortium (ISC2)',
      summary:
        'Achieved CISSP certification, demonstrating comprehensive knowledge and expertise in information security.',
    },
    {
      title: 'CompTIA Security+',
      date: '2017-07-01',
      awarder: 'CompTIA',
      summary:
        'Earned CompTIA Security+ certification, validating skills in network security, compliance, and operational security.',
    },
  ],
  skills: [
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'Python' }, { name: 'Java' }, { name: 'C/C++' }],
    },
    {
      skillGroup: 'Security Tools',
      skill: [
        { name: 'Metasploit' },
        { name: 'Nessus' },
        { name: 'Wireshark' },
      ],
    },
    {
      skillGroup: 'Security Standards',
      skill: [
        { name: 'ISO 27001' },
        { name: 'NIST Cybersecurity Framework' },
        { name: 'OWASP Top Ten' },
      ],
    },
  ],
  projects: [
    {
      name: 'Security Incident Response Platform',
      description:
        'Designed and developed an internal incident response platform to streamline the process of identifying, reporting, and mitigating security incidents.',
      startDate: '2022-02-01',
      endDate: '2022-06-30',
      present: false,
    },
    {
      name: 'Secure Code Review Automation',
      description:
        'Automated the code review process to identify security vulnerabilities in the development pipeline, reducing the time to detect and fix issues.',
      startDate: '2021-03-01',
      endDate: '2021-07-31',
      present: false,
    },
    {
      name: 'Phishing Awareness Campaign',
      description:
        'Led a company-wide phishing awareness campaign, educating employees on recognizing and reporting phishing attempts, resulting in a 20% decrease in successful phishing attacks.',
      startDate: '2020-05-01',
      endDate: '2020-06-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Certifications',
      details: [
        {
          title: 'Certified Information Systems Security Professional (CISSP)',
          subtitle: 'Certification',
          location: {
            city: 'Online',
            countryCode: 'US',
            region: 'Virtual',
          },
          url: 'https://www.isc2.org/cissp',
          startDate: '2022-01-15',
          endDate: '2025-01-15',
          summary:
            'Certified Information Systems Security Professional (CISSP) with expertise in designing, implementing, and managing security solutions.',
        },
        {
          title: 'CompTIA Security+',
          subtitle: 'Certification',
          location: {
            city: 'Online',
            countryCode: 'US',
            region: 'Virtual',
          },
          url: 'https://www.comptia.org/certifications/security',
          startDate: '2017-07-01',
          endDate: '2022-07-01',
          summary:
            'CompTIA Security+ certified professional with a strong foundation in network security and risk management.',
        },
      ],
    },
  ],
}
