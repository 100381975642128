import { Field, FieldArray } from 'formik'

import { AccordionPage } from 'components/Forms/Editor/Accordion'

import DatePickerField from 'components/Forms/Editor/DatePickerField'
import QuillInput from './common/QuillInput'

const EducationForm = (index) => {
  return (
    <div className="m-4 grid grid-cols-6 md:grid-cols-12 gap-6">
      <div className="col-span-6 sm:col-span-12">
        <label
          htmlFor={`education-institution-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          School
        </label>
        <Field
          component="input"
          type="text"
          name={`education.${index}.institution`}
          id={`education-institution-${index}`}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
          placeholder="School Name"
        />
      </div>

      <div className="col-span-4 sm:col-span-9">
        <label
          htmlFor={`education-degree-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          Degree
        </label>
        <Field
          component="input"
          type="text"
          name={`education.${index}.degree`}
          id={`education-degree-${index}`}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
          placeholder="School Degree"
        />
      </div>

      <div className="col-span-2 sm:col-span-3">
        <label
          htmlFor={`education-gpa-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          GPA
        </label>
        <Field
          component="input"
          type="text"
          name={`education.${index}.gpa`}
          id={`education-gpa-${index}`}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
          placeholder="GPA"
        />
      </div>

      <div className="col-span-4 sm:col-span-4">
        <label
          htmlFor={`education-city-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          City
        </label>
        <Field
          component="input"
          type="text"
          name={`education.${index}.location.city`}
          id={`education-city-${index}`}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
          placeholder="City"
        />
      </div>

      <div className="col-span-2 sm:col-span-2">
        <label
          htmlFor={`education-region-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          State
        </label>
        <Field
          component="input"
          type="text"
          name={`education.${index}.location.region`}
          id={`education-region-${index}`}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
          placeholder="State"
        />
      </div>

      <div className="col-span-3 sm:col-span-3">
        <label
          htmlFor={`education-start_date-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          Start Date
        </label>
        <DatePickerField
          name={`education.${index}.startDate`}
          id={`education-start_date-${index}`}
          className="py-2 px-3 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md"
        />
      </div>

      <div className="col-span-3 sm:col-span-3">
        <label
          htmlFor={`education-end_date-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          End Date
        </label>
        <DatePickerField
          name={`education.${index}.endDate`}
          id={`education-end_date-${index}`}
          className="py-2 px-3 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md"
        />
      </div>

      <div className="col-span-6 sm:col-span-12">
        <label
          htmlFor={`education-summary-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          Summary
        </label>
        <div className="mt-1">
          <QuillInput
            id={`education-summary-${index}`}
            name={`education.${index}.summary`}
            placeholder="Include school courses, clubs, and/or accomplishments"
          />
        </div>
      </div>
    </div>
  )
}

export default function EducationsForm(props) {
  return (
    <>
      <div className="mt-5 md:mt-0 md:col-span-3">
        <div className="overflow-hidden sm:rounded-md">
          <div className="px-4 sm:px-6">
            <FieldArray
              name="education"
              render={(arrayHelpers) => (
                <AccordionPage
                  {...arrayHelpers}
                  render={EducationForm}
                  header="Education"
                  titleSelector="institution"
                  subtitleSelector="degree"
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  )
}
