import { View } from '@react-pdf/renderer'
import SafeText from '../SafeText'
import { capitalizeWords } from '../Utils/helper'

function DefaultTemplateSection({
  styles,
  entries,
  color,
  entryTransformer,
  sectionType,
  TemplateEntry,
  capitalizeSection = false,
}) {
  if (!entries || entries.length === 0) {
    return null
  }
  if (!entryTransformer) {
    return null
  }
  return (
    <View style={styles.container}>
      <View style={styles.innerRightColumn}>
        {entries.map((e, i) => {
          const { title, subtitle, text, children, subheader } =
            new entryTransformer(e, styles).values()
          return (
            <View key={i} wrap={false}>
              {i === 0 && (
                <SafeText
                  val={sectionType}
                  style={{ ...styles?.sectionTitle, color: color }}
                >
                  {capitalizeSection
                    ? capitalizeWords(sectionType)
                    : sectionType}
                </SafeText>
              )}
              <TemplateEntry
                styles={styles}
                key={i}
                title={title}
                subtitle={subtitle}
                text={text}
                subheader={subheader}
              >
                {children}
              </TemplateEntry>
            </View>
          )
        })}
      </View>
    </View>
  )
}

export default DefaultTemplateSection
