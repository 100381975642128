export const resume = {
  basics: {
    firstName: 'Jessica',
    lastName: 'Smith',
    title: 'QA Engineer',
    picture: 'https://example.com/jessica-smith.jpg',
    email: 'jessica.smith@example.com',
    phone: '987-654-3210',
    url: 'https://www.jessicasmithqa.com',
    summary:
      'Dedicated QA Engineer with a strong background in testing methodologies, test automation, and quality assurance practices. Proven track record of identifying defects and ensuring the delivery of high-quality software. Experienced in manual and automated testing across various domains.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/jessicasmithqa',
    portfolioUrl: 'https://portfolio.jessicasmithqa.com',
  },
  experience: [
    {
      company: 'Dropbox',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'QA Engineer',
      url: 'https://www.dropbox.com',
      startDate: '2020-02-15',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Developed and executed comprehensive test plans, test cases, and test scripts for new features and enhancements, resulting in a 30% reduction in post-release defects.',
        'Automated regression tests using Python and Selenium WebDriver, increasing test coverage by 40% and reducing manual testing efforts.',
        'Collaborated with cross-functional teams to identify and prioritize critical test scenarios, resulting in improved testing efficiency and more focused testing efforts.',
        'Led the adoption of exploratory testing techniques, uncovering critical defects that were missed by automated tests and improving overall software quality.',
      ],
      present: false,
    },
    {
      company: 'Slack Technologies',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Quality Assurance Analyst',
      url: 'https://www.slack.com',
      startDate: '2018-06-01',
      endDate: '2020-01-31',
      summary: null,
      highlights: [
        'Performed manual testing of new features and enhancements, identifying and reporting defects to development teams for resolution.',
        'Contributed to the development of an automated test suite using Java and TestNG, reducing regression testing time by 25% and improving test consistency.',
        'Conducted usability testing and provided user feedback to improve the user experience and user interface design.',
      ],
      present: false,
    },
    {
      company: 'Twilio',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'QA Intern',
      url: 'https://www.twilio.com',
      startDate: '2017-07-15',
      endDate: '2017-12-31',
      summary: null,
      highlights: [
        'Executed manual test cases to validate new features and functionality, ensuring software quality and reliability.',
        'Contributed to the development of an automated smoke testing suite using Python and Selenium WebDriver, enabling rapid validation of critical application functionality.',
        'Participated in daily stand-up meetings and provided updates on testing progress and defect status.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'University of California, Berkeley',
      degree: 'Bachelor of Arts in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2014-09-01',
      endDate: '2018-05-31',
      gpa: '3.6',
      courses: [
        'Software Engineering',
        'Data Structures and Algorithms',
        'Software Testing and Quality Assurance',
      ],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Excellence in Quality Assurance Award',
      date: '2022-06-15',
      awarder: 'QA Professionals Association',
      summary:
        'Recognized for outstanding contributions to quality assurance practices and commitment to delivering high-quality software.',
    },
  ],
  skills: [
    {
      skillGroup: 'Test Automation',
      skill: [
        { name: 'Selenium WebDriver' },
        { name: 'Python' },
        { name: 'Java' },
        { name: 'TestNG' },
      ],
    },
    {
      skillGroup: 'Manual Testing',
      skill: [
        { name: 'Test Planning' },
        { name: 'Test Execution' },
        { name: 'Defect Reporting' },
      ],
    },
    {
      skillGroup: 'Bug Tracking Tools',
      skill: [{ name: 'Jira' }, { name: 'Bugzilla' }, { name: 'Trello' }],
    },
    {
      skillGroup: 'Usability Testing',
      skill: [
        { name: 'User Experience (UX) Testing' },
        { name: 'Usability Evaluation' },
      ],
    },
  ],
  projects: [
    {
      name: 'Automated Regression Test Suite',
      description:
        'Designed and implemented an automated regression test suite using Selenium WebDriver and Python, covering critical user workflows and reducing regression testing time by 40%.',
      startDate: '2021-01-15',
      endDate: '2021-06-30',
      present: false,
    },
    {
      name: 'API Integration Testing',
      description:
        "Led the testing efforts for integrating a third-party API into the company's platform. Developed test cases and executed thorough API testing to ensure data accuracy and system compatibility.",
      startDate: '2019-08-01',
      endDate: '2019-11-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Professional Certifications',
      details: [
        {
          title: 'Certified Software Quality Analyst (CSQA)',
          subtitle: 'Quality Assurance Institute',
          location: {
            city: 'Online',
            countryCode: 'US',
            region: 'Virtual',
          },
          url: 'https://www.qualityassuranceinstitute.com',
          startDate: '2022-02-01',
          endDate: '2022-02-28',
          summary:
            'Demonstrated proficiency in software quality assurance principles by obtaining the Certified Software Quality Analyst certification.',
        },
      ],
    },
  ],
}
