import { getDate, joinFields } from '../../Utils/helper'
import { getHighlights } from '../utils'
import ExperienceValueMapper from './ExperienceValueMapper'

class HyphenatedExperienceValueMapper extends ExperienceValueMapper {
  title() {
    const { company, position } = this.experience
    return joinFields(' - ', company, position)
  }
  subtitle() {
    const { location, startDate, endDate, present } = this.experience
    const locationText =
      location && joinFields(', ', location.city, location.region)
    const dateText = getDate(startDate, endDate, present)
    return joinFields(' • ', locationText, dateText)
  }

  children() {
    return getHighlights(this.experience.highlights, this.styles, false)
  }
}

export default HyphenatedExperienceValueMapper
