import { ArrowNarrowRightIcon } from '@heroicons/react/outline'
import { CheckIcon } from '@heroicons/react/solid'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { TypeAnimation } from 'react-type-animation'
import { classNames } from 'utils/helper'

const handleScroll = (view, setView, id) => {
  var elementTarget = document.getElementById(id)
  if (
    window.scrollY >
    elementTarget.offsetTop + 2 * elementTarget.offsetHeight + 200
  ) {
    if (view) setView(false)
  } else {
    if (!view) setView(true)
  }
}

const handleScroll2 = (view, setView, id) => {
  var elementTarget = document.getElementById(id)
  if (
    window.scrollY <=
    elementTarget.offsetTop + 2 * elementTarget.offsetHeight + 200
  ) {
    if (view) setView(false)
  } else if (
    window.scrollY >
      elementTarget.offsetTop + 2 * elementTarget.offsetHeight + 200 &&
    window.scrollY <=
      elementTarget.offsetTop + 2.5 * elementTarget.offsetHeight + 200
  ) {
    if (!view) setView(true)
  } else {
    if (view) setView(false)
  }
}

const handleScroll3 = (view, setView, id) => {
  var elementTarget = document.getElementById(id)
  if (
    window.scrollY <=
    elementTarget.offsetTop + 2.5 * elementTarget.offsetHeight + 200
  ) {
    if (view) setView(false)
  } else if (
    window.scrollY >
      elementTarget.offsetTop + 2.5 * elementTarget.offsetHeight + 200 &&
    window.scrollY <=
      elementTarget.offsetTop + 3 * elementTarget.offsetHeight + 200
  ) {
    if (!view) setView(true)
  } else {
    if (view) setView(false)
  }
}

const Feature = ({ idx, handleScroll, color, title, text }) => {
  const [view, setView] = useState(false)

  const listenerCallback = () => handleScroll(view, setView, `feature-${idx}`)

  useEffect(() => {
    window.addEventListener('scroll', listenerCallback)
    return () => window.removeEventListener('scroll', listenerCallback)
  })

  return (
    <div
      id={`feature-${idx}`}
      className={classNames(
        view
          ? 'border-transparent bg-blueGray-100 sm:border-gray-900 sm:shadow-xl'
          : 'border-transparent bg-blueGray-100 sm:bg-transparent',
        'flex flex-col justify-start border rounded-xl p-8 transition-all duration-50'
      )}
    >
      <div
        className={classNames(
          color,
          'w-20 h-20 rounded-2xl flex items-center justify-center mb-8 text-4xl'
        )}
      >
        {idx}.
      </div>
      <div className="text-lg font-semibold mb-4">{title}</div>
      <p className="text-lg">{text}</p>
    </div>
  )
}

const FeaturesSection = () => {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-16">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <div className="px-6 hidden sm:block">
              <div className="aspect-w-1 aspect-h-1 bg-gray-100">
                <div className="p-6 flex items-center">
                  <div className="w-full bg-white border rounded p-6 grid grid-cols-4 gap-x-3 gap-y-4">
                    <div className="col-span-2">
                      <p className="text-sm mb-2 text-gray-900">Company</p>
                      <div className="h-12 border rounded bg-blueGray-100 px-2 flex items-center">
                        Top Company
                      </div>
                    </div>
                    <div className="col-span-2">
                      <p className="text-sm mb-2 text-gray-900">Position</p>
                      <div className="h-12 border rounded bg-blueGray-100 px-2 flex items-center leading-tight">
                        <TypeAnimation
                          sequence={[
                            'Marketing Manager',
                            1000,
                            'Software Developer',
                            1000,
                            'Registered Nurse',
                            1000,
                            'Sales Associate',
                            1000,
                            'Financial Analyst',
                            1000,
                            'Graphic Designer',
                            1000,
                            'Human Resources Specialist',
                            1000,
                            'Project Manager',
                            1000,
                            'Customer Service Representative',
                            1000,
                            'Data Scientist',
                            1000,
                            'Teacher',
                            1000,
                            'Social Media Coordinator',
                            1000,
                            'Administrative Assistant',
                            1000,
                            'Mechanical Engineer',
                            1000,
                            'Accountant',
                            1000,
                            'Web Designer',
                            1000,
                            'Healthcare Administrator',
                            1000,
                            'Content Writer',
                            1000,
                            'Legal Assistant',
                            1000,
                            'Electrician',
                            1000,
                            'Event Planner',
                            1000,
                            'Operations Manager',
                            1000,
                            'Research Scientist',
                            1000,
                            'Chef',
                            1000,
                            'Retail Manager',
                            1000,
                            'Physical Therapist',
                            1000,
                            'IT Support Specialist',
                            1000,
                            'Public Relations Specialist',
                            1000,
                            'Real Estate Agent',
                            1000,
                            'Dental Hygienist',
                            1000,
                          ]}
                          preRenderFirstString
                          repeat={Infinity}
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <p className="text-sm mb-2 text-gray-900">Start Date</p>
                      <div className="h-12 border rounded bg-blueGray-100 px-2 flex items-center">
                        {moment(
                          new Date().setMonth(new Date().getMonth() + 1)
                        ).format('MM/YYYY')}
                      </div>
                    </div>
                    <div className="col-span-1">
                      <p className="text-sm mb-2 text-gray-900">End Date</p>
                      <div className="h-12 border rounded bg-blueGray-100 opacity-60 px-2 flex items-center">
                        MM/YYYY
                      </div>
                    </div>
                    <div className="col-span-2 grid grid-cols-3 gap-x-6">
                      <div className="col-span-2">
                        <p className="text-sm mb-2 text-gray-900">City</p>
                        <div className="h-12 border rounded bg-blueGray-100 px-2 flex items-center">
                          Austin
                        </div>
                      </div>
                      <div className="col-span-1">
                        <p className="text-sm mb-2 text-gray-900">State</p>
                        <div className="h-12 border rounded bg-blueGray-100 px-2 flex items-center">
                          TX
                        </div>
                      </div>
                    </div>
                    <div className="col-span-4 text-sm font-medium inline-flex gap-2 items-center">
                      <div className="h-4 w-4 border border-blue-600 rounded inline-flex items-center justify-center bg-blue-600">
                        <CheckIcon className="w-3.5 h-3.5 text-white" />
                      </div>
                      I currently work here
                    </div>
                    <div className="col-span-4">
                      <p className="text-sm mb-2 text-gray-900">Description</p>
                      <div className="h-32 border rounded bg-blueGray-100 p-2 flex items-start">
                        Started a new job! Will be working on developing and
                        implementing strategic marketing campaigns to promote
                        brand awareness and drive customer engagement.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-6">
              <div className="sm:px-8">
                <p className="mt-3 mb-3 text-sm font-semibold uppercase text-indigo-600">
                  How It Works
                </p>
                <h2 className="text-6xl text-gray-800 font-bold mb-1">
                  3 Steps.
                </h2>
                <h2 className="text-6xl text-gray-800 font-bold mb-6">
                  15 Minutes or Less.
                </h2>
                <p className="text-xl leading-relaxed mb-6">
                  Resumary helps job seekers convey their professional career
                  through engaging resumes. With Resumary's guidance, your
                  resume becomes a powerful tool to stand out and excel in your
                  job search.
                </p>
                <Link to="/auth/register">
                  <button className="py-3 px-8 bg-blue-500 rounded text-white font-semibold inline-flex gap-2 items-center">
                    Try it now
                    <ArrowNarrowRightIcon className="w-4 h-4" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="py-16">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
            <Feature
              idx="1"
              color="bg-blue-200"
              handleScroll={handleScroll}
              title="Define Your Story"
              text="Share your professional journey, from work history to awards and accomplishments. Let us transform your experience into a compelling story."
            />
            <Feature
              idx="2"
              color="bg-orange-200"
              handleScroll={handleScroll2}
              title="Customize Your Style"
              text="Select from a range of professionally designed templates that match your industry and aspirations. Personalize colors, layouts, and more."
            />
            <Feature
              idx="3"
              color="bg-green-200"
              handleScroll={handleScroll3}
              title="Download & Start Applying"
              text="Once you're ready, download a sleek, attention-grabbing PDF resume, ready to impress employers. Start landing interviews with your new & improved resume."
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturesSection
