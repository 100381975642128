import Helmet from 'react-helmet'
import { Navigate, Outlet } from 'react-router-dom'

// components

import FooterSmall from 'components/Footers/FooterSmall.js'
import Navbar from 'components/Navbars/AuthNavbar.js'
import { useSelector } from 'react-redux'

export function RedirectAuthenticated() {
  const { isAuthenticated } = useSelector((state) => state.auth)

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />
  }
  return <Outlet />
}

export default function Auth() {
  return (
    <>
      <main>
        <section className="relative w-full h-full py-16 md:py-32 min-h-screen">
          <Helmet>
            <title>Resume Builder - Resumary</title>
            <meta
              name="description"
              content="Create a job-winning resume with our powerful and easy-to-use resume builder. Choose from our beautiful templates, customize your layout, and showcase your skills and expertise to land your dream job. Our users have seen an 3x increase in interview requests after switching to Resumary. Start building your resume today!"
            />
          </Helmet>
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                'url(' + require('assets/img/register_bg_2.png') + ')',
            }}
          >
            <Navbar />
          </div>
          <Outlet />
          <FooterSmall absolute />
        </section>
      </main>
    </>
  )
}
