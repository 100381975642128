import { PlusIcon } from '@heroicons/react/outline'
import { SparklesIcon } from '@heroicons/react/solid'
import CardResume from 'components/Cards/CardResume'
import ThreeDotsLoadingScreen from 'components/LoadingScreens/ThreeDotsLoadingScreen'
import CreateResumeModal from 'components/Modals/CreateResumeModal'
import { useState } from 'react'

export default function ViewLayout({
  items = [],
  loading,
  isAllowed,
  createResume,
  uploadResume,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const goToCreate = () => {
    if (!isAllowed('create_new')) {
      return
    } else {
      setIsModalOpen(true)
    }
  }

  if (loading) {
    return (
      <div className="w-full h-full">
        <ThreeDotsLoadingScreen />
      </div>
    )
  }
  return (
    <div className="flex flex-col flex-1 bg-warmGray-50 overflow-y-scroll">
      <CreateResumeModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        createResume={createResume}
        uploadResume={uploadResume}
      />
      <div className="w-full flex-1 flex flex-col max-w-6xl mx-auto py-4 px-4 sm:py-12 sm:px-6">
        <div className="flex-none flex h-12 sm:h-16 gap-6 w-full items-center bg-warmGray-50 mb-4 justify-between">
          <div className="text-2xl sm:text-3xl font-semibold">Resumes</div>
          <button
            onClick={goToCreate}
            className="py-1.5 px-2.5 sm:py-2 sm:px-4 text-sm sm:text-base rounded-md flex items-center gap-1 font-semibold bg-blue-600 hover:bg-blue-700 text-white"
          >
            <PlusIcon className="w-4 h-4" />
            <div className="text-sm">Create Resume</div>
          </button>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:pt-4">
          {items.length > 0 ? (
            items.map((item, index) => {
              return (
                <CardResume
                  key={item.id}
                  editUrl={item.editUrl}
                  duplicateResume={item.duplicateFn}
                  deleteResume={item.deleteFn}
                  downloadResume={item.downloadFn}
                  resume={item}
                  index={index}
                  name={item.name}
                  updatedAt={item.updatedAt}
                  id={item.id}
                  isAllowed={item.isAllowed}
                />
              )
            })
          ) : (
            <div className="col-span-1 sm:col-span-2 lg:col-span-3 h-72 rounded border border-gray-300 flex flex-col items-center justify-center p-4 bg-white">
              <div className="p-2 bg-blue-100 rounded-full mb-2">
                <SparklesIcon className="w-6 h-6 text-blue-600" />
              </div>
              <h3 className="text-lg font-semibold mb-0.5 text-center">
                Showcase yourself
              </h3>
              <p className="mb-6">Build your job winning resume now</p>
              <button
                onClick={goToCreate}
                className="py-1.5 px-4 rounded-md flex items-center gap-1 font-medium bg-blue-600 hover:bg-blue-700 text-white"
              >
                <PlusIcon className="w-4 h-4" />
                <div className="text-base">Create Resume</div>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
