import { Link, StyleSheet, View } from '@react-pdf/renderer'
import HtmlContent from '../HtmlContent'

import SafeText from '../SafeText'
import {
  getContactInfoArray,
  renderLink,
  renderLinkedInLink,
} from '../Utils/linkHelper'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    marginBottom: '15',
  },
  contactContainer: {
    // flexDirection: 'row',
  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 1,
  },
  contactColumn: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  linkColumn: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  name: {
    fontSize: 24,
    lineHeight: 1.1,
    fontFamily: 'Open Sans',
    marginBottom: 6,
    marginLeft: -1,
    fontWeight: '700',
  },
  subtitle: {
    fontSize: 10,
    lineHeight: 1.1,
    marginBottom: 6,
    justifySelf: 'flex-end',
    textTransform: 'uppercase',
    fontFamily: 'Open Sans',
    fontWeight: '700',
  },
  summary: {
    justifySelf: 'flex-end',
    lineHeight: 1.5,
    marginTop: 9,
  },
  link: {
    fontSize: 9,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Open Sans',
    alignSelf: 'flex-end',
  },
  emailPhoneGroup: {
    fontSize: 9,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Open Sans',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  subtitleSeparator: {
    marginRight: 4,
    marginLeft: 4,
  },
  htmlContent: {
    fontFamily: 'Open Sans',
    fontSize: 9,
  },
})

function getName(basics) {
  if (basics.firstName && basics.lastName) {
    return `${basics.firstName} ${basics.lastName}`
  } else if (basics.firstName) {
    return basics.firstName
  } else if (basics.lastName) {
    return basics.lastName
  }
  return undefined
}

function sliceIntoChunks(arr, chunkSize) {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

function getContactInfo(basics) {
  const contact = getContactInfoArray(basics, styles)
  var infoArray = sliceIntoChunks(contact, 5)
  return (
    <View style={styles.contactColumn}>
      {infoArray.map((line) => {
        line = line.flatMap((value, index, array) =>
          array.length - 1 !== index // check for the last item
            ? [
                value,
                <SafeText val={true} style={styles.subtitleSeparator}>
                  •
                </SafeText>,
              ]
            : value
        )
        return <View style={styles.emailPhoneGroup}>{line}</View>
      })}
    </View>
  )
}

export default function Header({ basics, color }) {
  var name = getName(basics)
  var title = basics.title
  var summary = basics.summary
  return (
    <View style={styles.container}>
      <View style={styles.contactContainer}>
        <View style={styles.detailColumn}>
          <SafeText val={name} style={{ ...styles.name, color: color }}>
            {name}
          </SafeText>
          <SafeText val={title} style={styles.subtitle}>
            {title}
          </SafeText>
        </View>
        {getContactInfo(basics)}
      </View>
      <SafeText val={summary} style={styles.summary}>
        <HtmlContent styles={styles.htmlContent}>{summary}</HtmlContent>
      </SafeText>
    </View>
  )
}
