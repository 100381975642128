import { CheckCircleIcon } from '@heroicons/react/solid'
import TemplateDisplay from 'components/Cards/TemplateDisplay'
import { ResumeContext } from 'layouts/Resume'
import { useContext } from 'react'

const templatesList = [
  {
    name: 'Sharp',
    color: 'bg-warmGray-200',
    resumeColor: '#38BDF8',
    isPremium: false,
    template: 0,
  },
  {
    name: 'Modern',
    color: 'bg-warmGray-200',
    resumeColor: '#FBBF24',
    isPremium: false,
    template: 1,
  },
  {
    name: 'Clean',
    color: 'bg-warmGray-200',
    resumeColor: '#A8A29E',
    isPremium: true,
    template: 2,
  },
  {
    name: 'Fresh',
    color: 'bg-warmGray-200',
    resumeColor: '#60A5FA',
    isPremium: true,
    template: 3,
  },
  {
    name: 'Pure',
    color: 'bg-warmGray-200',
    resumeColor: '#F472B6',
    isPremium: true,
    template: 4,
  },
  {
    name: 'Boston',
    color: 'bg-warmGray-200',
    resumeColor: '#C084FC',
    isPremium: true,
    template: 5,
  },
  {
    name: 'Portland',
    color: 'bg-warmGray-200',
    resumeColor: '#34D399',
    isPremium: true,
    template: 6,
  },
]

export default function Template(props) {
  const { resume, selectTemplate } = useContext(ResumeContext)
  const template = resume.template

  return (
    <div className="px-4 py-5 sm:p-6 w-full">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="pt-2 pb-8">
            <h2 className="text-xl font-bold leading-6 text-gray-900">
              Select Template
            </h2>
          </div>
        </div>
      </div>
      <div className="grid sm:grid-cols-2 gap-8">
        {templatesList.map((t, i) => (
          <button
            onClick={(e) => {
              e.preventDefault()
              selectTemplate(resume._id, i)
            }}
            className={`relative max-w-xs sm:max-w-xl w-full mx-auto group ${
              template === i ? '' : 'hover:opacity-70'
            }`}
          >
            {template === i ? (
              <div className="z-10 absolute inset-0 flex items-center justify-center visible">
                <CheckCircleIcon className="h-10 w-10 bg-white rounded-full text-indigo-600" />
              </div>
            ) : (
              <div className="z-10 absolute inset-0 flex items-center justify-center invisible group-hover:visible">
                <CheckCircleIcon className="h-10 w-10 bg-white rounded-full text-indigo-600" />
              </div>
            )}
            <TemplateDisplay
              onClick={selectTemplate}
              name={t.name}
              color={t.color}
              resumeColor={t.resumeColor}
              template={t.template}
              isPremium={t.isPremium}
              showLabel
            />
          </button>
        ))}
      </div>
    </div>
  )
}
