import Modal from 'react-modal'

const ConfirmModal = ({ isOpen, closeModal, confirmAction, idx }) => {
  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: 'rgb(0,0,0,.6)',
          zIndex: 10,
        },
      }}
      isOpen={isOpen}
      className="overflow-auto outline-none"
      overlayClassName="fixed w-full inset-0 bg-gray-300 flex items center justify-center items-center"
      onRequestClose={closeModal}
      contentLabel="Confirm Modal"
    >
      <div className="w-64 rounded-lg bg-gray-100">
        <div className="gap-0.5 flex flex-col items-center justify-center p-4 text-center">
          <h3 className="text-lg font-medium">Confirm</h3>
          <p className="text-sm">Are you sure you want to delete this?</p>
        </div>
        <div className="h-12 w-full grid grid-cols-2 divide-x border-t">
          <div
            onClick={closeModal}
            className="flex items-center justify-center text-blue-500"
          >
            Cancel
          </div>
          <div
            onClick={() => {
              confirmAction(idx)
            }}
            className="flex items-center justify-center text-red-500"
          >
            Delete
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmModal
