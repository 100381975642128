import {
  AdjustmentsIcon,
  CheckIcon,
  DocumentDuplicateIcon,
  SparklesIcon,
} from '@heroicons/react/solid'
import axios from 'axios'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import Helmet from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'

import { clearErrors, setBannerMessage, setReferralCode } from 'actions/actions'

import { setIsAuthenticated } from 'actions/authActions'
import ErrorMessage from 'components/Core/ErrorMessage'
import Form from 'components/Forms/Auth/RegisterForm'
import setAuthToken from 'utils/setAuthToken'

export default function Register(props) {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const app = useSelector((state) => state.app)
  const [validReferral, setValidReferral] = useState(false)
  let referralCode
  let params = useParams()
  const state = useLocation().state
  const navigate = useNavigate()

  if (params.referralCode) {
    referralCode = params.referralCode
  } else if (app.referralCode) {
    referralCode = app.referralCode
  }

  useEffect(() => {
    const fetchData = async (referralCode) => {
      try {
        const { data: response } = await axios.get(
          `/referrals/code/${referralCode}`
        )
        setValidReferral(true)
        const firstName = response.name.replace(/ .*/, '')
        const bannerDetails = {
          message: `${firstName} referred you to Resumary. Sign up and claim your free month of Premium!`,
          mobileMessage: `${firstName} gave you a free month of Premium!`,
        }
        dispatch(setBannerMessage(bannerDetails))
        dispatch(setReferralCode(referralCode))
      } catch (error) {
        // console.error(error);
      }
    }
    if (!auth.isAuthenticated && referralCode) {
      fetchData(referralCode)
    }
    return function cleanup() {
      dispatch(clearErrors())
    }
  }, [referralCode])

  const register = (userData) => {
    axios
      .post('/signup', userData)
      .then((res) => {
        const { token } = res.data
        ReactGA.event('sign_up')
        localStorage.setItem('jwtToken', token)
        setAuthToken(token)
        dispatch(setIsAuthenticated(true))
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 302:
              navigate(err.response.data.url)
              break
            case 400:
              navigate('.', {
                replace: true,
                state: {
                  errors: err.response.data.map((v) => {
                    return {
                      msg: v.msg,
                    }
                  }),
                },
              })
              break
            case 401:
              navigate('.', {
                replace: true,
                state: { errors: [{ msg: err.response.data.msg }] },
              })
              break
            default:
              break
          }
        }
      })
  }

  return (
    <>
      <Helmet>
        <title>Sign up | Resume Builder - Resumary</title>
        <meta
          name="description"
          content="Create a job-winning resume with our powerful and easy-to-use resume builder. Choose from our beautiful templates, customize your layout, and showcase your skills and expertise to land your dream job. Our users have seen an 3x increase in interview requests after switching to Resumary. Start building your resume today!"
        />
      </Helmet>
      <div className="container mx-auto px-4 h-full">
        <div className="flex flex-col md:flex-row content-center items-top justify-center h-full">
          {validReferral && referralCode && (
            <div className="order-last md:order-first mb-16 md:mb-0 w-full lg:w-4/12 px-4">
              <div className="inline-block border-4 border-blue-600 md:mt-[62px] w-full max-w-3xl overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="flex">
                  <div className="w-full px-6 py-8">
                    <h2 className="text-lg md:text-xl font-extrabold text-gray-800 dark:text-gray-100">
                      Sign up and claim your free month of Premium!
                    </h2>
                    <p className="mt-2">
                      Create a beautiful and effective resume in minutes using
                      our professional resume builder. We've helped many
                      jobseekers like you find new jobs.
                    </p>
                    <div className="mt-4 mb-6 grid gap-4">
                      <div className="flex items-center">
                        <div className="w-6 h-6 bg-blue-500 flex items-center justify-center rounded">
                          <SparklesIcon
                            className="h-4 w-4 text-white"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-4">
                          <h2 className="text-sm font-bold">
                            Easy-to-use Editor
                          </h2>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="w-6 h-6 bg-yellow-500 flex items-center justify-center rounded">
                          <DocumentDuplicateIcon
                            className="h-4 w-4 text-white"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-4">
                          <h2 className="text-sm font-bold">Free Templates</h2>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="w-6 h-6 bg-orange-500 flex items-center justify-center rounded">
                          <AdjustmentsIcon
                            className="h-4 w-4 text-white"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-4">
                          <h2 className="text-sm font-bold">
                            Unlimited PDF Downloads
                          </h2>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="w-6 h-6 bg-green-500 flex items-center justify-center rounded">
                          <CheckIcon
                            className="h-4 w-4 text-white"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-4">
                          <h2 className="text-sm font-bold">
                            Find your next job faster
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="w-full lg:w-6/12 px-4">
            <ErrorMessage
              errors={state?.errors}
              notifications={state?.notifications}
            />
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Sign up for Resumary
                  </h6>
                </div>
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <Form
                  onSubmit={
                    (values) => register({ referralCode, ...values })
                    // dispatch(registerUser({ referralCode, ...values }, props))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
