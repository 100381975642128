import axios from 'axios'
import { setCurrentUser } from './authActions'

import ActionTypes from './types'

export const updateProfile = (params) => (dispatch) => {
  axios
    .put('/account', params)
    .then((res) => {
      dispatch(setCurrentUser(res.data.user))
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.GET_ERRORS,
        payload: err.response.data,
      })
      if (Array.isArray(err.response.data)) {
        for (const i in err.response.data) {
          // toast.error(err.response.data[i].msg);
        }
      }
    })
}

export const updatePassword = (params) => (dispatch) => {
  axios
    .put('/account/password', params)
    .then((res) => {
      // console.log(res.data)
      // toast.success(res.data.msg);
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.GET_ERRORS,
        payload: err.response.data,
      })
    })
}

export const createPortalSession = () => (dispatch) => {
  axios
    .post('/createPortalSession')
    .then((res) => {
      window.location.href = res.data.redirect_url
    })
    .catch((err) =>
      dispatch({
        type: ActionTypes.GET_ERRORS,
        payload: err.response.data,
      })
    )
}

export const fetchStripeSession = (coupon) => (dispatch) => {
  axios
    .get('/payment/createSessionV2', {})
    .then((res) => {
      if (res.data.isSubscribed) {
        dispatch(setStripeIsSubscribed(res.data.isSubscribed))
      } else {
        dispatch(setStripeSession(res.data.session.id))
        dispatch(setStripeIsSubscribed(res.data.isSubscribed))
      }
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.GET_ERRORS,
        payload: err.response.data,
      })
    })
}

export const setStripeSession = (stripeSessionId) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_STRIPE_SESSION,
    payload: stripeSessionId,
  })
}

export const setStripeIsSubscribed = (isSubscribed) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_IS_SUBSCRIBED,
    payload: isSubscribed,
  })
}

export const clearStripe = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_STRIPE,
  })
}

export const clearErrors = () => {
  return {
    type: ActionTypes.CLEAR_ERRORS,
  }
}

export const clearNotifications = () => {
  return {
    type: ActionTypes.CLEAR_NOTIFICATIONS,
  }
}

export const showModal = (modalType, props) => (dispatch) => {
  dispatch({
    type: ActionTypes.SHOW_MODAL,
    modalType: modalType,
    props: props,
  })
}

export const hideModal = (modalType) => (dispatch) => {
  dispatch({
    type: ActionTypes.HIDE_MODAL,
    modalType: modalType,
  })
}

export const setBannerMessage = (bannerDetails) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_BANNER_MESSAGE,
    payload: bannerDetails,
  })
}

export const setReferralCode = (referralCode) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_REFERRAL_CODE,
    payload: referralCode,
  })
}
