import { XIcon } from '@heroicons/react/outline'
import axios from 'axios'
import DownloadResumeButton from 'components/Buttons/DownloadResumeButton'
import Form from 'components/Forms/Editor/Form'
import ThreeDotsLoadingScreen from 'components/LoadingScreens/ThreeDotsLoadingScreen'
import Viewer from 'components/Viewer/Viewer'
import { useResume } from 'hooks/resumes'
import { createContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'

export const ResumeContext = createContext()

const RightSection = (props) => {
  const { resume, user } = props
  return (
    <div className="relative h-full w-full flex flex-col gap-8 overflow-y-auto lg:p-16 lg:pt-3">
      <div className="sticky top-0 z-10 hidden lg:flex flex-none justify-between gap-3 bg-white p-3 rounded-md shadow-md border border-gray-300">
        <a
          href={`/resumes/${resume._id}/view`}
          target="_blank"
          className="py-2 px-4 rounded bg-white hover:bg-gray-100 text-gray-900 border font-semibold"
          rel="noreferrer"
        >
          View Resume Link
        </a>
        <DownloadResumeButton
          hideIcon
          hideTooltip
          text="Download PDF"
          resume={resume}
          isVerified={user.isVerified}
          className="py-2 px-4 rounded bg-indigo-600 hover:bg-indigo-700 text-white font-semibold"
        />
      </div>
      <div className="lg:px-6">
        <Viewer resume={resume} />
      </div>
    </div>
  )
}

export default function Resume(props) {
  const [user, setUser] = useState(null)
  const [open, setOpen] = useState(false)
  const { id } = useParams()
  const { resume, setResume, updateResume, updateOrder, selectTemplate } =
    useResume(id)

  useEffect(() => {
    const fetchUser = () => {
      axios
        .get('/user')
        .then((res) => {
          setUser(res.data)
        })
        .catch((err) => {
          console.log('error', err)
        })
    }
    fetchUser()
  }, [])

  if (!resume || !user) {
    return <ThreeDotsLoadingScreen />
  }

  return (
    <ResumeContext.Provider
      value={{ resume, setResume, updateOrder, selectTemplate }}
    >
      <div className="absolute w-full inset-y-0 grid lg:grid-cols-2 overflow-y-hidden overscroll-x-none">
        {!open && (
          <div className="fixed lg:hidden bottom-6 right-9 z-20">
            <button
              type="button"
              className="px-8 py-3 flex font-semibold items-center border bg-indigo-600 hover:bg-indigo-700 rounded-full shadow-md text-white"
              onClick={() => setOpen(true)}
            >
              <span className="sr-only">Open panel</span>
              Preview
            </button>
          </div>
        )}
        {resume && (
          <Form
            setOpen={setOpen}
            initialValues={resume}
            onSubmit={(values) => updateResume(values)}
          />
        )}
        <div className="h-full hidden lg:flex lg:flex-col bg-gray-500 overflow-y-hidden">
          <RightSection resume={resume} user={user} />
        </div>
        {open && (
          <div className="fixed lg:hidden inset-0 bg-gray-500 p-6 pt-16 overflow-y-auto z-20">
            <div className="fixed lg:hidden top-3 right-6">
              <button
                type="button"
                className="px-3 py-2 flex font-semibold items-center text-gray-900 bg-white hover:bg-gray-100 border rounded-md shadow-md"
                onClick={() => setOpen(false)}
              >
                <span className="sr-only">Close panel</span>
                <XIcon className="w-6 h-6" />
              </button>
            </div>
            <RightSection resume={resume} user={user} />
          </div>
        )}
      </div>
    </ResumeContext.Provider>
  )
}
