import { getDate, joinFields } from '../../Utils/helper'
import CustomSectionValueMapper from './CustomSectionValueMapper'

class SplitCustomSectionValueMapper extends CustomSectionValueMapper {
  subtitle() {
    const { location, startDate, endDate, present } = this.customSection
    const locationText =
      location && joinFields(', ', location.city, location.region)
    const dateText = getDate(startDate, endDate, present)
    return joinFields(' • ', locationText, dateText)
  }
}

export default SplitCustomSectionValueMapper
