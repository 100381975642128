import { Link, StyleSheet, View } from '@react-pdf/renderer'
import HtmlContent from '../../common/HtmlContent'
import SafeText from '../../common/SafeText'
import {
  getContactInfoArray,
  renderLink,
  renderLinkedInLink,
} from '../../common/Utils/linkHelper'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingBottom: '15',
  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9,
    alignItems: 'center',
  },
  linkColumn: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  name: {
    fontSize: 16,
    lineHeight: 1.2,
    fontFamily: 'Open Sans',
    marginBottom: 0.5,
    fontWeight: '700',
  },
  subtitle: {
    fontSize: 9,
    justifySelf: 'flex-end',
    textTransform: 'uppercase',
    fontFamily: 'Open Sans',
    marginBottom: 3,
    fontWeight: '700',
  },
  summary: {
    justifySelf: 'flex-end',
    marginTop: 10,
  },
  linkGroup: {
    fontSize: 9,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Open Sans',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  link: {
    fontSize: 9,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Open Sans',
  },
  subtitleSeparator: {
    marginRight: 2,
    marginLeft: 2,
  },
  htmlContent: {
    fontFamily: 'Open Sans',
    fontSize: 9,
  },
})

function getName(basics) {
  if (basics.firstName && basics.lastName) {
    return `${basics.firstName} ${basics.lastName}`
  } else if (basics.firstName) {
    return basics.firstName
  } else if (basics.lastName) {
    return basics.lastName
  }
  return undefined
}

function getContactInfo(basics) {
  let contact = getContactInfoArray(basics, styles)
  contact = contact.flatMap((value, index, array) =>
    array.length - 1 !== index // check for the last item
      ? [
          value,
          <SafeText val={true} style={styles.subtitleSeparator}>
            •
          </SafeText>,
        ]
      : value
  )
  return <View style={styles.linkGroup}>{contact}</View>
}

export default function Header({ basics, color }) {
  var name = getName(basics)
  var title = basics.title
  var summary = basics.summary
  return (
    <View style={styles.container}>
      <View style={styles.detailColumn}>
        <SafeText val={name} style={styles.name}>
          {name}
        </SafeText>
        <SafeText val={title} style={{ ...styles.subtitle, color: color }}>
          {title}
        </SafeText>
        {getContactInfo(basics)}
        <SafeText val={summary} style={styles.summary}>
          <HtmlContent styles={styles.htmlContent}>{summary}</HtmlContent>
        </SafeText>
      </View>
    </View>
  )
}
