import { Field, FieldArray } from 'formik'

import {
  PencilIcon,
  XIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DotsVerticalIcon,
  SelectorIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/solid'

import { AccordionPage } from 'components/Forms/Editor/Accordion'

const sections = ['Custom Section']

export default function AddSection() {
  return (
    <div className="mt-10">
      <div className="overflow-hidden sm:rounded-md">
        <div className="px-4 sm:px-6">
          <div className="pb-4 flex">
            <h2 className="text-xl font-semibold leading-6 text-gray-900">
              Add Section
            </h2>
            <div>
              <span className="text-xs text-yellow-600 bg-yellow-200 py-0.5 px-1 ml-1">
                Premium
              </span>
            </div>
          </div>
          <ul className="grid gap-4" aria-disabled="true">
            <FieldArray
              name="customSections"
              render={(arrayHelpers) => (
                <li className="focus:outline-none border rounded block bg-white">
                  <div className="px-4 py-4 sm:px-6 sm:py-8">
                    <div className="flex items-center justify-between">
                      <div className="flex-grow min-w-0">
                        <div className="md:flex items-center overflow-hidden">
                          <div className="text-lg font-semibold text-indigo-600 truncate">
                            Custom Section
                          </div>
                        </div>
                      </div>
                      <div className="flex-none flex items-center">
                        <div
                          onClick={() => {
                            arrayHelpers.push({
                              name: 'Custom Section',
                              details: [],
                            })
                          }}
                          className="cursor-pointer inline-flex items-center px-1.5 md:px-3 py-2 rounded-md border text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                        >
                          <PlusIcon
                            className="-ml-1 -mr-1 h-5 w-5 text-gray-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )}
            />
            {/*sections.map((section) => (
              <li className="focus:outline-none border rounded block bg-white">
                <div className="px-4 py-4 sm:px-6 sm:py-8">
                  <div className="flex items-center justify-between">
                    <div className="flex-grow min-w-0">
                      <div className="md:flex items-center overflow-hidden">
                        <div className="text-lg font-semibold text-indigo-600 truncate">
                          {section}
                        </div>
                      </div>
                    </div>
                    <div className="flex-none flex items-center">
                      <div
                        className="cursor-pointer inline-flex items-center px-1.5 md:px-3 py-2 rounded-md border text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                      >
                        <PlusIcon className="-ml-1 -mr-1 h-5 w-5 text-gray-500" aria-hidden="true" />
                      </div>
                    </div>
                  </div>
                </div>
              </li>))*/}
          </ul>
        </div>
      </div>
    </div>
  )
}
