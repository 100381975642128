import CTASection from 'marketing/components/CTASection'
import ExamplesList from 'marketing/components/sections/examples/ExamplesList'
import ResumeFeature from 'marketing/components/sections/feature/ResumeFeature'
import TemplatesList from 'marketing/components/sections/templates/TemplatesList'
import { ResumeTemplatesMap } from 'marketing/resources/ResumeTemplatesMap'
import { Link, useParams } from 'react-router-dom'

const templates = [
  {
    name: 'Sharp',
    id: 'account-executive-example-resume',
  },
  {
    name: 'Modern',
    id: 'software-engineer-example-resume',
  },
  {
    name: 'Clean',
    id: 'software-engineer-example-resume',
  },
  {
    name: 'Fresh',
    id: 'software-engineer-example-resume',
  },
  {
    name: 'Pure',
    id: 'software-engineer-example-resume',
  },
  {
    name: 'Boston',
    id: 'software-engineer-example-resume',
  },
  {
    name: 'Portland',
    id: 'software-engineer-example-resume',
  },
]

const TemplateCard = ({ template, idx }) => {
  const { name, id } = template
  const to = `/resume-templates/${id}`
  return (
    <div>
      <Link to="/auth/register">
        <div className="border hover:border-gray-900 mb-4">
          <img className="" src={require(`assets/img/Template${idx}.png`)} />
        </div>
      </Link>
      <div className="flex justify-between items-center">
        <Link to={to} className="text-lg font-semibold hover:underline">
          {name}
        </Link>
        {/* <Link
          to=""
          className="py-1.5 px-6 rounded border border-blue-500 bg-blue-500 text-white shadow"
        >
          Select
        </Link> */}
      </div>
    </div>
  )
}

const ResumeTemplates = () => {
  const params = useParams()
  const slug = params.slug
  const template = ResumeTemplatesMap[slug]
  return (
    <>
      <div className="relative bg-indigo-50">
        <div className="max-w-6xl mx-auto py-24 px-4">
          <div className="flex flex-col sm:items-center sm:text-center">
            <h1 className="mb-8 text-5xl font-bold text-gray-900">
              {template ? template.name : 'Resume Templates'}
            </h1>
            <div className="max-w-2xl mx-auto mb-6">
              <p className="text-xl text-gray-900">
                Discover your favorite Resumary template and start building your
                job-winning resume.
              </p>
            </div>
            <Link
              to="/auth/register"
              className="py-3 px-8 border rounded bg-blue-500 text-white font-medium text-lg"
            >
              Try Resumary for Free
            </Link>
          </div>
        </div>
      </div>
      <div className="relative bg-white">
        <div className="max-w-6xl mx-auto py-16 sm:py-32 px-4">
          <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-8 sm:gap-y-12">
            {templates.map((template, idx) => (
              <TemplateCard template={template} idx={idx} />
            ))}
          </div>
        </div>
      </div>
      <TemplatesList />
      <ResumeFeature />
      {/* <ResumeFeature /> */}
      {/* <FeatureBlocks /> */}
      <ExamplesList />
      <CTASection />
    </>
  )
}

export default ResumeTemplates
