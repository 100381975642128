import React from 'react'
import { Link } from 'react-router-dom'

export default function Logo(props) {
  return (
    <Link className="inline-flex items-center" to="/">
      <span className="sr-only">Resumary</span>
      {props.compact && (
        <img
          className="h-8 w-auto md:hidden"
          src={require('assets/img/R_logo_round.png')}
          alt="Resumary"
        />
      )}
      <span
        className={[
          'text-2xl font-black font-raleway',
          props.light ? 'text-white' : '',
          props.compact ? 'hidden md:block' : '',
        ].join(' ')}
      >
        Resumary
      </span>
    </Link>
  )
}
