import { StyleSheet } from '@react-pdf/renderer'
import TwoColumnHeader from '../common/header/TwoColumnHeader'
import TwoColumnLayout from '../common/layouts/TwoColumnLayout'
import DefaultTemplateEntry from '../common/template-entry/DefaultTemplateEntry'
import DefaultTemplateSection from '../common/template-section/DefaultTemplateSection'
import CustomSectionValueMapper from '../common/value-mappers/custom-section/CustomSectionValueMapper'
import EducationValueMapper from '../common/value-mappers/education/EducationValueMapper'
import ExperienceValueMapper from '../common/value-mappers/experience/ExperienceValueMapper'
import ProjectValueMapper from '../common/value-mappers/project/ProjectValueMapper'
import StackedSkillValueMapper from '../common/value-mappers/skill/StackedSkillValueMapper'

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Open Sans',
    paddingHorizontal: 40,
    paddingVertical: 40,
  },
  leftColumn: {
    flexDirection: 'column',
    flex: 3,
    display: 'flex',
    paddingRight: 30,
  },
  rightColumn: {
    paddingTop: 58,
    flexDirection: 'column',
    flex: 4,
    display: 'flex',
    paddingLeft: 30,
  },
  backgroundColor: {
    position: 'absolute',
    height: '100vh',
    width: `${300 / 7}vw`,
    top: 0,
    left: 0,
    backgroundColor: '#faf8f9',
  },
  sectionTitle: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    marginBottom: 10,
    fontWeight: 700,
  },
  entryContainer: {
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 8,
    fontFamily: 'Open Sans',
    marginBottom: 4,
  },
  text: {
    lineHeight: 1.5,
    marginBottom: 1,
  },
  title: {
    fontFamily: 'Open Sans',
    fontSize: 11,
    marginBottom: 1,
    fontWeight: 600,
  },
  titleSecondary: {
    fontFamily: 'Open Sans',
    marginLeft: 2,
    fontWeight: 400,
  },
  highlights: {
    lineHeight: 1.5,
  },
  htmlContent: {
    fontFamily: 'Open Sans',
    fontSize: 8.5,
  },
  item: {
    marginBottom: 1,
  },
  itemContent: {
    lineHeight: 1.5,
  },
})

const PortlandConfigs = {
  styles: styles,
  Layout: TwoColumnLayout,
  Header: TwoColumnHeader,
  entryTransformerMap: {
    education: EducationValueMapper,
    experience: ExperienceValueMapper,
    projects: ProjectValueMapper,
    skills: StackedSkillValueMapper,
    custom: CustomSectionValueMapper,
  },
  TemplateSection: DefaultTemplateSection,
  TemplateEntry: DefaultTemplateEntry,
  settings: {
    capitalizeSection: true,
    headerLeftColumn: true,
    leftColumnBackground: true,
  },
}

export default PortlandConfigs
