import Modal from 'react-modal'
import { classNames } from 'utils/helper'

const ActionMenuModal = ({ isOpen, closeModal, actions }) => {
  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: 'rgb(0,0,0,.6)',
          zIndex: 10,
        },
      }}
      isOpen={isOpen}
      className="overflow-auto outline-none w-full m-2"
      overlayClassName="fixed w-full inset-0 bg-gray-300 flex items center justify-center items-end"
      onRequestClose={closeModal}
      contentLabel="Confirm Modal"
    >
      <div className="w-full rounded-lg bg-white">
        <div className="flex flex-col items-center justify-center p-4 text-center">
          <p className="text-sm">Custom section actions</p>
        </div>
        <div className="w-full grid border-t divide-y">
          {actions.map((action, idx) => {
            return (
              <div
                key={idx}
                onClick={() => action.action()}
                className={classNames(
                  action.color,
                  'h-14 flex items-center text-xl justify-center'
                )}
              >
                {action.name}
              </div>
            )
          })}
          <div
            onClick={closeModal}
            className="h-14 flex items-center font-medium text-xl justify-center text-blue-500"
          >
            Cancel
          </div>
          {/* <div
            onClick={() => {
              confirmAction(idx)
            }}
            className="flex items-center justify-center text-red-500"
          >
            Delete
          </div> */}
        </div>
      </div>
    </Modal>
  )
}

export default ActionMenuModal
