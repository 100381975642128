import ReactGA from 'react-ga4'
import Helmet from 'react-helmet'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { setIsAuthenticated } from 'actions/authActions'
import axios from 'axios'
import ErrorMessage from 'components/Core/ErrorMessage'
import Form from 'components/Forms/Auth/LoginForm'
import setAuthToken from 'utils/setAuthToken'

function Login(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const state = useLocation().state

  const loginUser = (userData) => (dispatch) => {
    axios
      .post('/login', userData)
      .then((res) => {
        ReactGA.event('login')
        const { token } = res.data
        localStorage.setItem('jwtToken', token)
        setAuthToken(token)
        dispatch(setIsAuthenticated(true))
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 302:
              navigate(err.response.data.url)
              break
            case 400:
              navigate('.', {
                replace: true,
                state: {
                  errors: err.response.data.map((v) => {
                    return {
                      msg: v.msg,
                    }
                  }),
                },
              })
              break
            case 401:
              navigate('.', {
                replace: true,
                state: { errors: [{ msg: err.response.data.msg }] },
              })
              break
            default:
              break
          }
        }
      })
  }

  return (
    <>
      <Helmet>
        <title>Sign in | Resume Builder - Resumary</title>
        <meta
          name="description"
          content="Create a job-winning resume with our powerful and easy-to-use resume builder. Choose from our beautiful templates, customize your layout, and showcase your skills and expertise to land your dream job. Our users have seen an 3x increase in interview requests after switching to Resumary. Start building your resume today!"
        />
      </Helmet>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <ErrorMessage
              errors={state?.errors}
              notifications={state?.notifications}
            />
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Sign into Resumary
                  </h6>
                </div>
                {/*<div className="btn-wrapper text-center">
                    <button
                      className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                      type="button"
                    >
                      <img
                        alt="..."
                        className="w-5 mr-1"
                        src={require("assets/img/github.svg")}
                      />
                      Github
                    </button>
                    <button
                      className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                      type="button"
                    >
                      <img
                        alt="..."
                        className="w-5 mr-1"
                        src={require("assets/img/google.svg")}
                      />
                      Google
                    </button>
                  </div>
                  <hr className="mt-6 border-b-1 border-blueGray-300" />*/}
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                {/*<div className="text-blueGray-400 text-center mb-3 font-bold">
                    <small>Or sign in with credentials</small>
                  </div>*/}
                <Form
                  onSubmit={(values) => dispatch(loginUser(values, props))}
                />
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <Link to="/auth/forgot" className="text-blueGray-200">
                  <span className="text-sm">Forgot password?</span>
                </Link>
              </div>
              <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-blueGray-200">
                  <span className="text-sm">Create new account</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
