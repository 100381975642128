export const resume = {
  basics: {
    firstName: 'Alex',
    lastName: 'Johnson',
    title: 'Performance Engineer',
    picture: 'https://example.com/alex-johnson.jpg',
    email: 'alex.johnson@example.com',
    phone: '555-123-4567',
    url: 'https://www.alexjohnson-performance.dev',
    summary:
      'Experienced Performance Engineer with a proven track record of optimizing software applications for exceptional user experiences. Skilled in performance testing, analysis, and optimization across various platforms and technologies.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/alexjohnson',
    portfolioUrl: 'https://portfolio.alexjohnson-performance.dev',
  },
  experience: [
    {
      company: 'Amazon Web Services',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Senior Performance Engineer',
      url: 'https://aws.amazon.com',
      startDate: '2020-02-15',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Designed and executed performance tests for AWS cloud services, identifying and resolving bottlenecks to improve response time by 40%.',
        'Collaborated with development teams to implement performance tuning strategies, resulting in enhanced system architecture and reduced resource utilization.',
        'Automated performance testing and reporting processes, saving 15 hours per week and improving test accuracy.',
        'Received recognition for maintaining 99.9% uptime during high-traffic events, ensuring a seamless user experience.',
      ],
      present: false,
    },
    {
      company: 'Google',
      location: {
        city: 'Mountain View',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Performance Test Engineer',
      url: 'https://www.google.com',
      startDate: '2018-07-01',
      endDate: '2020-01-31',
      summary: null,
      highlights: [
        'Developed and executed performance test scenarios for Google Cloud APIs, identifying and addressing performance bottlenecks.',
        'Collaborated with cross-functional teams to define performance requirements and baselines for new product releases.',
        'Contributed to the development of an automated performance testing pipeline, reducing testing cycle time by 25%.',
        'Assisted in optimizing service scalability, resulting in a 30% increase in concurrent user capacity.',
      ],
      present: false,
    },
    {
      company: 'Facebook',
      location: {
        city: 'Menlo Park',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Software Development Intern',
      url: 'https://www.facebook.com',
      startDate: '2017-06-01',
      endDate: '2017-08-31',
      summary: null,
      highlights: [
        'Contributed to the performance testing of a mobile application, identifying and addressing issues related to response time and resource utilization.',
        'Developed and executed load tests using JMeter, simulating real-world scenarios to assess application performance.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Software Engineering',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2016-09-01',
      endDate: '2018-05-15',
      gpa: '3.9',
      courses: [
        'Software Performance Testing',
        'Distributed Systems',
        'Software Architecture',
        'Web Application Performance Optimization',
      ],
      summary: null,
      present: false,
    },
    {
      institution: 'University of California, Berkeley',
      degree: 'Bachelor of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2012-08-01',
      endDate: '2016-05-15',
      gpa: '3.8',
      courses: [
        'Data Structures and Algorithms',
        'Software Testing and Quality Assurance',
        'Operating Systems',
        'Database Systems',
      ],
      summary: null,
      present: false,
    },
  ],
  awards: [
    {
      title: 'Outstanding Performance Engineer Award',
      date: '2022-05-15',
      awarder: 'Amazon Web Services',
      summary:
        'Recognized for exceptional contributions to enhancing application performance and user experience on AWS cloud services.',
    },
    {
      title: 'Performance Engineering Scholarship',
      date: '2017-04-10',
      awarder: 'Stanford University',
      summary:
        'Received a scholarship for excellence in performance engineering studies.',
    },
  ],
  skills: [
    {
      skillGroup: 'Performance Testing Tools',
      skill: [
        { name: 'JMeter' },
        { name: 'Gatling' },
        { name: 'Locust' },
        { name: 'New Relic' },
      ],
    },
    {
      skillGroup: 'Performance Optimization',
      skill: [
        { name: 'Code Profiling' },
        { name: 'Caching Strategies' },
        { name: 'Load Balancing' },
      ],
    },
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'Java' }, { name: 'Python' }, { name: 'C++' }],
    },
    {
      skillGroup: 'Cloud Platforms',
      skill: [
        { name: 'Amazon Web Services (AWS)' },
        { name: 'Google Cloud Platform (GCP)' },
      ],
    },
  ],
  projects: [
    {
      name: 'E-Commerce Platform Performance Optimization',
      description:
        'Led a performance optimization project for a large-scale e-commerce platform, improving page load times and user experience.',
      startDate: '2021-01-15',
      endDate: '2021-06-30',
      present: false,
    },
    {
      name: 'Microservices Performance Benchmarking',
      description:
        'Designed and executed a benchmarking study to evaluate the performance of microservices architecture on cloud platforms.',
      startDate: '2019-11-01',
      endDate: '2020-03-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Professional Certifications',
      details: [
        {
          title: 'Certified Performance Testing Professional',
          subtitle:
            'Certified by the International Software Testing Qualifications Board (ISTQB)',
          location: {
            city: 'San Francisco',
            countryCode: 'US',
            region: 'California',
          },
          startDate: '2022-03-15',
          endDate: null,
          summary:
            'Achieved certification demonstrating expertise in performance testing and optimization.',
          highlights: [],
          present: true,
        },
      ],
    },
  ],
}
