import CTASection from 'marketing/components/CTASection'
import CompaniesSection from 'marketing/components/CompaniesSection'
import FeaturesSection from 'marketing/components/sections/feature/FeaturesSection'
import Hero from 'marketing/components/Hero'
// import JobTitleSection from 'marketing/components/JobTitleSection'
import TemplatesDisplay from 'marketing/components/TemplateDisplay'
import TestimonialSection from 'marketing/components/sections/testimonial/TestimonialSection'

export default function Home() {
  return (
    <div>
      <Hero />
      <CompaniesSection />
      <FeaturesSection />
      {/* <JobTitleSection /> */}
      <TemplatesDisplay />
      <TestimonialSection />
      <CTASection light />
      {/* <Footer /> */}
    </div>
  )
}
