import keyMirror from 'keymirror'

const ActionTypes = keyMirror({
  HIDE_MODAL: null,
  SHOW_MODAL: null,
  SET_STRIPE_SESSION: null,
  SET_BILLING: null,
  SET_PLAN: null,
  GET_ERRORS: null,
  CLEAR_ERRORS: null,
  SET_CURRENT_USER: null,
  SET_RESUME: null,
  SET_RESUMES: null,
  SET_RESUME_LOADING: null,
  SET_MODAL: null,
  SET_IS_AUTHENTICATED: null,
  SET_IS_SUBSCRIBED: null,
  SET_NOTIFICATIONS: null,
  CLEAR_NOTIFICATIONS: null,
  CLEAR_STRIPE: null,
  SET_REFERRAL_CODE: null,
  SET_BANNER_MESSAGE: null,
})

export default ActionTypes
