import React, { useState } from 'react'
import { saveAs } from 'file-saver'
import { pdf } from '@react-pdf/renderer'
import TemplateDecider from 'components/Generator/Templates/TemplateDecider'
import ReactTooltip from 'react-tooltip'
import { DownloadIcon } from '@heroicons/react/solid'
import ReactGA from 'react-ga4'

import { useDispatch } from 'react-redux'

import { allowedToDownload } from 'utils/helper'
import { showModal } from 'actions/actions'
import VerifiedRequiredModal from 'components/Modals/VerifiedRequiredModal'

function DownloadResumeButton(props) {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)

  async function downloadPdf() {
    // if (!props.isVerified) {
    //   setIsModalOpen(true)
    //   return
    // }
    if (allowedToDownload(props.resume)) {
      const template = TemplateDecider({ ...props.resume })
      const blob = await pdf(template).toBlob()
      ReactGA.event('file_download')
      saveAs(blob, props.resume.name)
    } else {
      dispatch(
        showModal('premiumDownloadModal', {
          resume: props.resume,
          type: 'download',
          width: 'max-w-4xl',
        })
      )
    }
  }

  return (
    <>
      <VerifiedRequiredModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
      {!props.hideTooltip && <ReactTooltip effect="solid" />}
      <button
        data-tip="Download"
        onClick={downloadPdf}
        className={props.className}
      >
        {!props.hideIcon && (
          <DownloadIcon
            className="-ml-2 -mr-2 h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        )}
        {props.text}
      </button>
    </>
  )
}

export default DownloadResumeButton
