import List, { Item } from '../List'
import SafeText from '../SafeText'
import { getDate, getGpa, getLocationText, joinFields } from '../Utils/helper'

export const getHighlights = (highlights, styles, bullet = true) => {
  return (
    highlights && (
      <List>
        {highlights.map((detail, i) => (
          <Item styles={styles} bullet={bullet} key={i}>
            {detail}
          </Item>
        ))}
      </List>
    )
  )
}

export const getExperienceTitle = (title, subtitle) => {
  return joinFields(' at ', title, subtitle)
}

export const getSubtitle = (
  location,
  startDate,
  endDate,
  present,
  gpa = null
) => {
  const locationText = getLocationText(location)
  const dateText = getDate(startDate, endDate, present)
  let subtitleText
  if (gpa) {
    const gpaText = getGpa(gpa)
    subtitleText = joinFields(' • ', dateText, locationText, gpaText)
  } else {
    subtitleText = joinFields(' • ', dateText, locationText)
  }
  return subtitleText
}

export function getCustomSectionTitle(position, company, styles) {
  return [
    position,
    <SafeText val={company} style={styles.titleSecondary}>
      {' '}
      {company}
    </SafeText>,
  ]
}
