import { Font } from '@react-pdf/renderer'
import jwt_decode from 'jwt-decode'
import React from 'react'
import Helmet from 'react-helmet'
import Modal from 'react-modal'
import { pdfjs } from 'react-pdf'
import { Provider } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import '@fortawesome/fontawesome-free/css/all.min.css'
import 'assets/styles/tailwind.css'

// layouts

import Auth, { RedirectAuthenticated } from 'layouts/Auth.js'
import Resume from 'layouts/Resume.js'

// views without layouts

import Preview from 'views/resume/Preview.js'
// import Resume from 'views/resume/Resume.js'

// components

import Banner from 'components/Core/Banner'
import PrivateRoute from 'components/Core/PrivateRoute.js'
import ScrollToTop from 'components/Core/ScrollToTop'

import setAuthToken from './utils/setAuthToken'

import { logoutUser, setIsAuthenticated } from 'actions/authActions'
import Modals from 'components/Modals/Modals'
import Blog from 'layouts/Blog'
import Dashboard from 'layouts/Dashboard'
import EditorLayout from 'layouts/Dashboard/EditorLayout'
import ResumesDashboard from 'layouts/Dashboard/ResumesDashboard'
import ReviewPage from 'layouts/Dashboard/ReviewPage'
import SettingsPage from 'layouts/Dashboard/SettingsPage'
import StandardDashboardLayout from 'layouts/Dashboard/StandardDashboardLayout'
import UpgradePage from 'layouts/Dashboard/UpgradePage'
import store from 'store.js'
import Profile from 'views/Profile'
import EmailVerification from 'views/auth/EmailVerification'
import ForgotPassword from 'views/auth/ForgotPassword'
import Login from 'views/auth/Login'
import Logout from 'views/auth/Logout'
import Register from 'views/auth/Register'
import ResetPassword from 'views/auth/ResetPassword'
// import ResumeTemplates from 'views/landing/ResumeTemplates'
import Landing from 'marketing/layouts/Landing'
import Home from 'marketing/views/Home'
import ResumeExample from 'marketing/views/ResumeExample'
import ResumeExamples from 'marketing/views/ResumeExamples'
import ResumeTemplates from 'marketing/views/ResumeTemplates'
import Privacy from 'views/legal/Privacy'
import Terms from 'views/legal/Terms'
import ResumeExampleCategory from 'marketing/views/ResumeExampleCategory'

const register = () => {
  Font.register({
    family: 'Open Sans',
    fonts: [
      { src: '/OpenSans-Regular.ttf', fontWeight: 'normal' },
      {
        src: '/OpenSans-Italic.ttf',
        fontWeight: 'normal',
        fontStyle: 'italic',
      },
      { src: '/OpenSans-Light.ttf', fontWeight: 'light' },
      { src: '/OpenSans-SemiBold.ttf', fontWeight: 'semibold' },
      { src: '/OpenSans-Bold.ttf', fontWeight: 'bold' },
      {
        src: '/OpenSans-BoldItalic.ttf',
        fontWeight: 'bold',
        fontStyle: 'italic',
      },
    ],
  })
  Font.registerHyphenationCallback((word) => [word])
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

Modal.setAppElement('#root')

class App extends React.Component {
  constructor(props) {
    super(props)
    register()
    if (localStorage.jwtToken) {
      // Set auth token header auth
      const token = localStorage.jwtToken
      setAuthToken(token)
      // Decode token and get user info and exp
      const decoded = jwt_decode(token)
      // Check for expired token
      const currentTime = Date.now() / 1000 // to get in milliseconds
      if (decoded.exp < currentTime) {
        // Logout user
        store.dispatch(logoutUser())
        return
      }
      // Set isAuthenticated
      store.dispatch(setIsAuthenticated(true))
    }
  }

  render() {
    return (
      <Provider store={store}>
        <Helmet>
          <title>Resumary - Free Online Resume Builder</title>
          <meta
            name="description"
            content="Create a job-winning resume with our powerful and easy-to-use resume builder. Choose from our beautiful templates, customize your layout, and showcase your skills and expertise to land your dream job. Our users have seen an 3x increase in interview requests after switching to Resumary. Start building your resume today!"
          />
        </Helmet>
        <BrowserRouter>
          <ScrollToTop />
          <Banner />
          <Modals />
          <Routes>
            <Route path="/auth">
              <Route path="/auth/logout" Component={Logout} />
              <Route Component={Auth}>
                <Route path="/auth/verify" Component={EmailVerification} />
                <Route Component={RedirectAuthenticated}>
                  <Route path="/auth/login" Component={Login} />
                  <Route path="/auth/register" Component={Register} />
                  <Route
                    path="/auth/register/:referralCode"
                    Component={Register}
                  />
                  <Route path="/auth/forgot" Component={ForgotPassword} />
                  <Route path="/auth/reset" Component={ResetPassword} />
                </Route>
                <Route path="*" element={<Navigate to="/auth/login" />} />
              </Route>
            </Route>
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            >
              <Route path="resumes/:id/edit" Component={EditorLayout} />
              <Route Component={StandardDashboardLayout}>
                <Route path="resumes" Component={ResumesDashboard} />
                {/* <Route path="resumes/create" Component={ResumesCreatePage} /> */}
                <Route path="settings" Component={SettingsPage} />
                {/* <Route path="profile" Component={ProfilePage} /> */}
                {/* <Route path="jobs" Component={JobsPage} /> */}
                {/* <Route path="jobs/:companyId" Component={JobsPage} /> */}
                {/* <Route path="jobs/:companyId/:jobId" Component={JobPage} /> */}
                {/* <Route path="companies" Component={CompaniesPage} /> */}
                {/* <Route path="companies/:company" Component={CompanyPage} /> */}
                {/* <Route path="track" Component={TrackPage} /> */}
                {/* <Route path="refer" Component={ReferPage} /> */}
                <Route path="upgrade" Component={UpgradePage} />
              </Route>
              <Route path="review/:idx" Component={ReviewPage} />
              <Route path="" element={<Navigate to="/dashboard/resumes" />} />
            </Route>
            {/* <Route path="/get-started" component={Onboarding} /> */}
            {/* add routes without layouts */}
            <Route
              path="/resumes/:id/edit"
              element={
                <PrivateRoute>
                  <Resume />
                </PrivateRoute>
              }
            />
            {/* <PrivateRoute path="/resumes/:id/edit" exact component={Resume} /> */}
            <Route path="/resumes/:id/view" Component={Preview} />
            {/* <Route path="/r/:referralCode" Component={ReferralRedirect} /> */}
            <Route path="/career-guide*" Component={Blog} />
            <Route path="/" Component={Landing}>
              <Route path="/" Component={Home} />
              {/* <Route path="/resume-builder" Component={ResumeBuilder} /> */}
              <Route
                path="/resume-templates/:slug?"
                Component={ResumeTemplates}
              />
              <Route path="/resume-examples" Component={ResumeExamples} />
              <Route
                path="/resume-examples/:category"
                Component={ResumeExampleCategory}
              />
              <Route
                path={`/resume-examples/:category/:slug`}
                Component={ResumeExample}
              />
              {/* <Route path="/students" Component={Students} /> */}
              {/* <Route path="/pricing" Component={Pricing} /> */}
              <Route path="/terms" Component={Terms} />
              <Route path="/privacy" Component={Privacy} />
            </Route>
            <Route path="/:id" Component={Profile} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    )
  }
}
export default App
