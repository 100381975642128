import { ResumeTemplatesArray } from 'marketing/resources/ResumeTemplatesMap'
import { Link } from 'react-router-dom'

const TemplatesList = () => {
  return (
    <div className="relative">
      <div className="max-w-6xl mx-auto py-32 px-4">
        <div className="flex flex-col sm:text-center">
          <h1 className="mb-16 text-3xl font-bold text-gray-900">
            Browse templates by resume style
          </h1>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-y-4 gap-x-6">
            {ResumeTemplatesArray.map((template) => (
              <div>
                <Link to={template.to} key={template.id}>
                  <span className="text-lg hover:underline">
                    {template.name}
                  </span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplatesList
