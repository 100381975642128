import { CheckCircleIcon } from '@heroicons/react/solid'
import { setCurrentUser } from 'actions/authActions'
import axios from 'axios'
import ThreeDotsLoadingScreen from 'components/LoadingScreens/ThreeDotsLoadingScreen'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { classNames } from 'utils/helper'
import ContentLayout from './ContentLayout'

const settings = {
  personal: {
    firstName: {
      label: 'First name',
      className: 'col-span-12 sm:col-span-6',
    },
    lastName: {
      label: 'Last name',
      className: 'col-span-12 sm:col-span-6',
    },
    email: {
      label: 'Email address',
      className: 'col-span-12 sm:col-span-6',
      isDisabled: true,
    },
    phone: {
      label: 'Phone number',
      className: 'col-span-12 sm:col-span-6',
    },
    jobtitle: {
      label: 'Current job title',
      className: 'col-span-12 sm:col-span-6',
    },
    company: {
      label: 'Current company',
      className: 'col-span-12 sm:col-span-6',
    },
    location: {
      label: 'Current location',
      className: 'col-span-12 sm:col-span-6',
    },
  },
  password: {
    current: {
      label: 'Current password',
      className: 'col-span-12',
      type: 'password',
      autoComplete: 'current-password',
    },
    password: {
      label: 'New password',
      className: 'col-span-12',
      type: 'password',
      autoComplete: 'new-password',
    },
    confirm: {
      label: 'Confirm new password',
      className: 'col-span-12',
      type: 'password',
      autoComplete: 'new-password',
    },
  },
}

const Input = ({
  register,
  name,
  className,
  label,
  isDisabled = false,
  type = 'text',
  autoComplete = 'off',
  placeholder = '',
  handleKeyDown = () => {},
}) => (
  <div className={className}>
    {label && (
      <label
        htmlFor={name}
        className="block text-sm font-medium pb-2 dark:text-white"
      >
        {label}
      </label>
    )}
    <input
      {...register(name)}
      onKeyDown={handleKeyDown}
      disabled={isDisabled}
      type={type}
      id={name}
      autoComplete={autoComplete}
      className="disabled:bg-gray-200 disabled:cursor-not-allowed py-2.5 px-3 block w-full border-gray-200 bg-blueGray-100 rounded-md text focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
      placeholder={placeholder || label}
    />
  </div>
)

const SectionFooter = ({ loading, saved }) => {
  return (
    <div className="border-t flex gap-4 items-center justify-end px-6 py-3">
      {saved && (
        <div className="inline-flex items-center space-x-1">
          <CheckCircleIcon className="w-5 h-5 text-green-500" />
          <span className="font-medium">Saved</span>
        </div>
      )}
      <button
        type="submit"
        disabled={loading}
        className="disabled:opacity-60 w-32 h-12 border rounded flex items-center justify-center bg-blue-600 text-white"
      >
        {loading ? (
          <svg
            className="animate-spin h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : (
          <span className="font-medium">Update</span>
        )}
      </button>
    </div>
  )
}

const SubscriptionSection = () => {
  const [isSubscribed, setIsSubscribed] = useState(undefined)
  const [stripeSessionId, setStripeSessionId] = useState(undefined)

  const createPortalSession = () => {
    axios
      .post('/createPortalSession')
      .then((res) => {
        window.location.href = res.data.redirect_url
      })
      .catch((err) => console.log(err))
  }

  function redirectToStripe(stripeSessionId) {
    if (!stripeSessionId) {
      return
    }
    var stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC)
    stripe
      .redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: stripeSessionId,
      })
      .then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      })
  }

  const handleOnClick = () => {
    if (isSubscribed) {
      createPortalSession()
    } else {
      redirectToStripe(stripeSessionId)
    }
  }

  useEffect(() => {
    const fetchStripeSession = () => {
      axios
        .get('/payment/createSessionV2?cancel_path=/dashboard/settings', {})
        .then((res) => {
          setIsSubscribed(res.data.isSubscribed)
          if (!res.data.isSubscribed) setStripeSessionId(res.data.session.id)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    fetchStripeSession()
  }, [])

  const Cards = () => {
    if (isSubscribed === undefined)
      return (
        <div className="h-32 w-full border border-gray-300 rounded">
          <ThreeDotsLoadingScreen />
        </div>
      )
    return (
      <>
        {!isSubscribed && (
          <div
            className={classNames(
              isSubscribed
                ? 'border-gray-700 bg-white'
                : 'border-blue-400 bg-blue-50',
              'flex items-center gap-6 border rounded p-6'
            )}
          >
            <div className="flex-1">
              <h3 className="font-medium text-lg">Starter account</h3>
              <p className="mb-2.5 text-sm">Get started without commitment</p>
              <span className="text-sm">
                <span className="font-medium">Plan includes:</span> Two Starter
                templates, watermark-free PDFs, and unlimited PDF downloads
              </span>
            </div>
          </div>
        )}
        <div
          onClick={handleOnClick}
          className={classNames(
            isSubscribed
              ? 'border-blue-400 bg-blue-50'
              : 'border-gray-700 bg-white hover:border-blue-400 hover:bg-blue-50',
            'cursor-pointer flex flex-col border rounded p-6'
          )}
        >
          <div className="flex flex-col sm:flex-row sm:gap-6">
            <div className="flex-1">
              <h3 className="font-medium text-lg">Premium account</h3>
              <p className="sm:mb-2.5 text-sm">
                Best for job seekers actively job searching
              </p>
            </div>
            {isSubscribed ? (
              <div className="my-3 sm:mt-1 text-md text-blue-600">
                Manage Subscription
              </div>
            ) : (
              <div className="my-3 sm:mt-1 text-md text-blue-600">
                Upgrade to Premium
              </div>
            )}
          </div>
          <span className="text-sm">
            <span className="font-medium">Plan includes:</span> Starter plan
            features, Premium templates, customizations, and{' '}
            <span className="font-bold">∞</span> resumes
          </span>
        </div>
      </>
    )
  }

  return (
    <div className="bg-white border rounded">
      <div className="p-6">
        <h2 className="font-medium text-lg">Manage subscription</h2>
        <p className="mb-6">View and manage your subscription.</p>
        <div className="flex flex-col gap-6">
          <Cards />
        </div>
      </div>
    </div>
  )
}

const PersonalInfoSection = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [saved, setSaved] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const { register, handleSubmit } = useForm({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      company: user.company,
      jobtitle: user.jobtitle,
      email: user.email,
      phone: user.phone,
      location: user.location,
    },
  })
  const updateProfile = (data) => {
    setLoading(true)
    setSaved(false)
    axios
      .put('/account', data)
      .then((res) => {
        console.log(res)
        dispatch(setCurrentUser(res.data.user))
        setLoading(false)
        setSaved(true)
        setTimeout(() => {
          setSaved(false)
        }, 2000)
      })
      .catch((err) => {})
  }
  const onSubmit = (data) => {
    updateProfile(data)
  }
  return (
    <form className="bg-white border rounded" onSubmit={handleSubmit(onSubmit)}>
      <div className="p-6">
        <h2 className="font-medium text-lg">Personal Information</h2>
        <p className="">View and update your personal information.</p>
        <div className="grid grid-cols-12 gap-6 my-6">
          {Object.entries(settings.personal).map((entry, idx) => {
            return (
              <Input
                key={idx}
                isDisabled={entry[1].isDisabled}
                register={register}
                name={entry[0]}
                className={entry[1].className}
                label={entry[1].label}
              />
            )
          })}
        </div>
      </div>
      <SectionFooter loading={loading} saved={saved} />
    </form>
  )
}

const PasswordSection = () => {
  const [loading, setLoading] = useState(false)
  const [saved, setSaved] = useState(false)
  const [errors, setErrors] = useState([])
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      current: '',
      password: '',
      confirm: '',
    },
  })
  const updatePassword = (data) => {
    setErrors([])
    setLoading(true)
    setSaved(false)
    axios
      .put('/account/password', data)
      .then((res) => {
        setLoading(false)
        setSaved(true)
        setTimeout(() => {
          setSaved(false)
        }, 2000)
        reset()
      })
      .catch((err) => {
        if (axios.isAxiosError(err)) {
          if (Array.isArray(err.response.data)) {
            const errorMessages = err.response.data.map((error) => error.msg)
            setErrors(errorMessages)
          } else {
            setErrors([err.response.data.msg])
          }
        } else {
          console.error(err)
        }
        setLoading(false)
      })
  }
  const onSubmit = (data) => updatePassword(data)
  return (
    <form className="bg-white border rounded" onSubmit={handleSubmit(onSubmit)}>
      <div className="p-6">
        <h2 className="font-medium text-lg">Change password</h2>
        <p className="">Ensure your account is secure.</p>
        <div className="grid grid-cols-12 gap-6 my-6">
          {Object.entries(settings.password).map((entry, idx) => {
            return (
              <Input
                key={idx}
                register={register}
                name={entry[0]}
                className={entry[1].className}
                label={entry[1].label}
                type={entry[1].type}
                autoComplete={entry[1].autoComplete}
              />
            )
          })}
        </div>
        <div className="flex flex-col gap-1">
          {errors.map((error, idx) => {
            return (
              <span key={idx} className="text-red-500 italic text-sm">
                {error}
              </span>
            )
          })}
        </div>
      </div>
      <SectionFooter loading={loading} saved={saved} />
    </form>
  )
}

const SettingsContent = () => {
  return (
    <ContentLayout maxWidth="max-w-3xl" name="Account Settings">
      <div className="w-full max-w-3xl mx-auto pt-4">
        <div className="flex flex-col gap-6">
          <SubscriptionSection />
          <PersonalInfoSection />
          <PasswordSection />
        </div>
      </div>
    </ContentLayout>
  )
}

const SettingsPage = () => {
  return <SettingsContent />
}

export default SettingsPage
