import useResumes from 'hooks/resumes'
import ViewLayout from './ViewLayout'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'

export default function ResumesDashboard() {
  const [searchParams, setSearchParams] = useSearchParams()
  const downloadParam = searchParams.get('download') === 'true'
  const duplicateParam = searchParams.get('duplicate') === 'true'
  const {
    loading,
    resumes,
    deleteResume,
    duplicateResume,
    downloadResume,
    isAllowed,
    createResume,
    uploadResume,
  } = useResumes()
  const items = resumes
    ? resumes.map((resume) => {
        return {
          id: resume.id,
          name: resume.name,
          premiumResume: resume.premiumResume,
          premium: resume.premium,
          url: resume.url,
          blob: resume.blob,
          viewUrl: `${window.location.origin}/resumes/${resume.id}/view`,
          editUrl: `/dashboard/resumes/${resume.id}/edit`,
          updatedAt: resume.updatedAt,
          template: resume.template,
          deleteFn: deleteResume,
          duplicateFn: duplicateResume,
          downloadFn: () => downloadResume(resume),
          isAllowed: isAllowed,
        }
      })
    : []

  useEffect(() => {
    if (resumes?.length > 0) {
      if (downloadParam) {
        // console.log('download', downloadParam)
        searchParams.delete('download')
        downloadResume(resumes[0])
      }
      if (duplicateParam) {
        // console.log('duplicate', duplicateParam)
        searchParams.delete('duplicate')
        duplicateResume(resumes[0].id)
      }
      setSearchParams(searchParams)
    }
  }, [resumes, downloadParam, duplicateParam])

  return (
    <ViewLayout
      loading={loading}
      items={items}
      isAllowed={isAllowed}
      createResume={createResume}
      uploadResume={uploadResume}
    />
  )
}
