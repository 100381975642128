import { BellIcon } from '@heroicons/react/solid'
import axios from 'axios'
import ResendVerificationEmailModal from 'components/Modals/ResendVerificationEmailModal'
import { useState } from 'react'
import { useSelector } from 'react-redux'

export default function Alert() {
  const user = useSelector((state) => state.auth.user)
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const resendVerificationEmail = () => {
    setLoading(true)
    axios
      .post('/confirmation/token/resend')
      .then((res) => {
        setSent(true)
        setLoading(false)
      })
      .catch((err) => {
        console.log('error', err)
        setLoading(false)
      })
  }

  let resendLink
  if (sent) {
    resendLink = 'Sent'
  } else if (loading) {
    resendLink = 'Sending...'
  } else {
    resendLink = (
      <span
        className="text-blue-400 cursor-pointer"
        onClick={() => setIsModalOpen(true)}
      >
        Resend Link
      </span>
    )
  }

  if (user && Object.keys(user).length === 0) {
    return null
  }

  if (user.isVerified) return null
  return (
    <div className="bg-indigo-600">
      <ResendVerificationEmailModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        submitModal={resendVerificationEmail}
      />
      <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex w-0 flex-1 items-center">
            <span className="flex rounded-lg">
              <BellIcon className="h-4 w-4 text-white" aria-hidden="true" />
            </span>
            <p className="ml-2 truncate text-sm font-medium text-white">
              <span className="sm:hidden">
                Verify your email address.{' '}
                <span className="text-blue-400">{resendLink}</span>
              </span>
              <span className="hidden sm:inline lg:hidden">
                Verify your email address. Haven't received our email?{' '}
                <span className="text-blue-400">{resendLink}</span>
              </span>
              <span className="hidden lg:inline">
                Verify your email address. A verification email has been sent to{' '}
                {user.email}. Haven't received our email?{' '}
                <span className="text-blue-400">{resendLink}</span>
              </span>
            </p>
          </div>
          {/* <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
            <a
              href="#"
              className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-50"
            >
              Learn more
              </a>
          </div>
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              className="-mr-1 flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div> */}
        </div>
      </div>
    </div>
  )
}
