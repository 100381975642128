import { Document } from '@react-pdf/renderer'
import TemplateConfigs from './configs'

const TemplateDecider = (props) => {
  const template = props.template
  const configs = TemplateConfigs[template]
  const templateContent = configs ? (
    <configs.Layout
      configs={configs}
      resume={props}
      headerLeftColumn={configs.settings.headerLeftColumn}
      leftColumnBackground={configs.settings.leftColumnBackground}
    />
  ) : (
    <configs.Layout
      configs={TemplateConfigs[0]}
      resume={props}
      headerLeftColumn={TemplateConfigs[0].settings.headerLeftColumn}
      leftColumnBackground={TemplateConfigs[0].settings.leftColumnBackground}
    />
  )
  return <Document>{templateContent}</Document>
}

export default TemplateDecider
