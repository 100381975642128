import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import authReducer from './authReducer'
import appReducer from './appReducer'
import errorReducer from './errorReducer'
import jobsReducer from './jobsReducer'
import resumeReducer from './resumeReducer'
import modalReducer from './modalReducer'
import stripeReducer from './stripeReducer'

export default combineReducers({
  app: appReducer,
  auth: authReducer,
  errors: errorReducer,
  jobs: jobsReducer,
  form: formReducer,
  resume: resumeReducer,
  modal: modalReducer,
  stripe: stripeReducer,
})
