import React from 'react'
import { Formik, Field } from 'formik'

const Form = (props) => {
  return (
    <Formik
      initialValues={{
        name: undefined,
        email: undefined,
        password: undefined,
      }}
      onSubmit={(values, actions) => {
        props.onSubmit(values)
      }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-3 mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="name"
              >
                First Name
              </label>
              <div>
                <Field
                  autoFocus
                  component="input"
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  autoComplete="given-name"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>
            </div>
            <div className="col-span-3 mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="name"
              >
                Last Name
              </label>
              <div>
                <Field
                  component="input"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  autoComplete="family-name"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>
            </div>
          </div>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <div>
              <Field
                component="input"
                name="email"
                type="email"
                placeholder="Email"
                autoComplete="username"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <Field
              name="password"
              component="input"
              type="password"
              placeholder="Password"
              autoComplete="new-password"
              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            />
          </div>

          <div>
            <label className="inline-flex items-center mt-2">
              <span className="text-sm font-semibold text-blueGray-600">
                By continuing, I agree to Resumary's{' '}
                <a
                  href="/privacy"
                  className="text-lightBlue-500"
                  target="_blank"
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a href="/terms" className="text-lightBlue-500" target="_blank">
                  Terms of Service
                </a>
                .
              </span>
            </label>
          </div>
          <div className="text-center mt-4">
            <button
              className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
              type="submit"
            >
              Create Account
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default Form
