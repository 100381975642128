import SkillValueMapper from './SkillValueMapper'

class StackedSkillValueMapper extends SkillValueMapper {
  text() {
    const { skill } = this.skillEntry
    return skill && skill.map((s) => s.name).join(', ')
  }

  children() {
    return null
  }
}

export default StackedSkillValueMapper
