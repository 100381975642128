export const resume = {
  basics: {
    firstName: 'Alex',
    lastName: 'Smith',
    title: 'Senior Software Engineer',
    picture: 'https://example.com/alex-smith.jpg',
    email: 'alex.smith@example.com',
    phone: '123-456-7890',
    url: 'https://www.alexsmith.com',
    summary:
      'Experienced software engineer with a proven track record of designing and delivering scalable and high-performance solutions. Passionate about driving innovation and solving complex technical challenges.',
    location: {
      city: 'San Jose',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/alexsmith',
    portfolioUrl: 'https://portfolio.alexsmith.com',
  },
  experience: [
    {
      company: 'Adobe',
      location: {
        city: 'San Jose',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Senior Software Engineer',
      url: 'https://www.adobe.com',
      startDate: '2019-08-01',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Architected and implemented a real-time collaborative editing feature for a cloud-based design application, resulting in a 30% increase in user engagement.',
        'Optimized critical algorithms in a graphics rendering engine, achieving a 25% reduction in processing time for complex visual effects.',
        'Collaborated with UX/UI designers to revamp the user interface of a flagship product, leading to a 40% improvement in user satisfaction.',
        'Mentored junior engineers, providing technical guidance and fostering skill development.',
      ],
      present: false,
    },
    {
      company: 'Qualcomm',
      location: {
        city: 'San Diego',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Software Engineer III',
      url: 'https://www.qualcomm.com',
      startDate: '2016-05-15',
      endDate: '2019-07-31',
      summary: null,
      highlights: [
        'Led a team of 5 engineers in designing and implementing a power management tool for a mobile chipset, resulting in a 20% gain in battery life.',
        'Collaborated with hardware engineers to identify and resolve performance bottlenecks in a high-throughput data processing module, achieving a 30% improvement in data throughput.',
        'Contributed to the development of a software-defined radio framework, enabling rapid prototyping of new wireless communication protocols.',
      ],
      present: false,
    },
    {
      company: 'AMD',
      location: {
        city: 'Santa Clara',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Software Engineer',
      url: 'https://www.amd.com',
      startDate: '2014-09-01',
      endDate: '2016-04-30',
      summary: null,
      highlights: [
        'Optimized memory management algorithms in a graphics driver, reducing memory consumption by 15% and improving overall system stability.',
        'Collaborated with cross-functional teams to implement support for new graphics APIs, enabling users to take advantage of the latest graphics technologies.',
        'Designed and developed a profiling tool for analyzing GPU performance bottlenecks, resulting in a 20% improvement in graphics rendering efficiency.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2012-09-01',
      endDate: '2014-06-30',
      gpa: '3.9',
      courses: [
        'Advanced Algorithms',
        'Machine Learning',
        'Parallel Computing',
      ],
      summary: '',
      present: false,
    },
    {
      institution: 'University of California, Berkeley',
      degree:
        'Bachelor of Science in Electrical Engineering and Computer Science',
      major: 'Electrical Engineering and Computer Science',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2008-09-01',
      endDate: '2012-06-30',
      gpa: '3.8',
      courses: ['Operating Systems', 'Computer Networks', 'Database Systems'],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Adobe Technical Excellence Award',
      date: '2022-02-15',
      awarder: 'Adobe',
      summary:
        'Recognized for outstanding technical contributions and leadership in software development.',
    },
    {
      title: 'Qualcomm Engineering Achievement Award',
      date: '2018-07-10',
      awarder: 'Qualcomm',
      summary:
        'Received for exceptional performance in driving impactful projects and delivering innovative solutions.',
    },
  ],
  skills: [
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'C++' }, { name: 'Python' }, { name: 'Java' }],
    },
    {
      skillGroup: 'Software Development',
      skill: [
        { name: 'Agile Development' },
        { name: 'Design Patterns' },
        { name: 'Code Optimization' },
      ],
    },
    {
      skillGroup: 'Graphics and Visualization',
      skill: [{ name: 'OpenGL' }, { name: 'DirectX' }, { name: 'OpenGL ES' }],
    },
  ],
  projects: [
    {
      name: 'Real-time 3D Rendering Engine',
      description:
        'Led the development of a high-performance real-time 3D rendering engine, optimized for interactive graphics applications.',
      startDate: '2021-01-01',
      endDate: '2022-06-30',
      present: false,
    },
    {
      name: 'Wireless Network Simulator',
      description:
        'Designed and implemented a network simulation tool to analyze the performance of wireless communication protocols.',
      startDate: '2017-03-01',
      endDate: '2017-08-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Open Source Contributions',
      details: [
        {
          title: 'GitHub',
          subtitle: 'Active Contributor',
          location: {
            city: 'Online',
            countryCode: 'US',
            region: 'Global',
          },
          url: 'https://github.com/alexsmith',
          startDate: '2015-01-01',
          endDate: '2023-07-31',
          summary:
            'Contributed to various open-source projects and collaborated with developers worldwide.',
          highlights: [
            'Implemented a feature for real-time ray tracing in a popular graphics library, attracting over 500 stars on GitHub.',
            'Participated in code reviews and provided feedback to improve code quality and maintainability.',
          ],
          present: true,
        },
      ],
    },
  ],
}
