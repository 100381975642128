import DefaultTemplateEntry from '../template-entry/DefaultTemplateEntry'
import DefaultTemplateSection from '../template-section/DefaultTemplateSection'

const Column = ({
  styles,
  TemplateSection = DefaultTemplateSection,
  TemplateEntry = DefaultTemplateEntry,
  sections,
  entryTransformer,
  resume,
  customSectionEntriesMap,
  settings,
}) => {
  return sections.map((section) => {
    if (section.startsWith('custom:')) {
      const id = section.slice(-24)
      const customSection = customSectionEntriesMap[id]
      return (
        <TemplateSection
          TemplateEntry={TemplateEntry}
          styles={styles}
          entryTransformer={entryTransformer['custom']}
          sectionType={customSection.name}
          entries={customSection.details}
          color={resume.color}
          capitalizeSection={settings.capitalizeSection}
        />
      )
    }
    return (
      <TemplateSection
        TemplateEntry={TemplateEntry}
        styles={styles}
        sectionType={section}
        entryTransformer={entryTransformer[section]}
        entries={resume[section]}
        color={resume.color}
        capitalizeSection={settings.capitalizeSection}
      />
    )
  })
}

export default Column
