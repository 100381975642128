import { ArrowRightIcon, HeartIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { classNames } from 'utils/helper'

const TestimonialSection = ({ large = true }) => {
  return (
    <div className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 py-16">
        <div className="max-w-6xl mx-auto sm:text-center">
          <h2
            className={classNames(
              large
                ? 'text-4xl sm:text-6xl mb-16'
                : 'text-3xl sm:text-4xl mb-12',
              'text-gray-800 font-bold inline-flex gap-2 items-center'
            )}
          >
            Made for job seekers{' '}
            <HeartIcon className="w-8 h-8 sm:w-12 sm:h-12 text-red-500" />
          </h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-10">
          <div className="bg-white rounded-lg border border-gray-900 p-8 flex-col flex">
            <p className="text-lg text-gray-900 mb-8 flex-1">
              This absolutely works as advertised. My Resumary résumé netted me
              a $65,000 raise. Even though I am happily employed, I will
              continue paying for Resumary. As I prepared to go back on the
              market, I had planned to pay to get it professionally rewritten,
              my wife found Resumary and I decided to give it a shot as it was
              $5/month versus several thousand.
            </p>
            <div className="flex items-end justify-between">
              <div>
                <p className="text-sm font-medium">Dan K.</p>
                <p className="text-sm font-medium">Engineer</p>
              </div>
              <div className="flex items-center justify-center rounded-full border">
                <img
                  className="flex-shrink-0 object-cover w-10 h-10 rounded-full"
                  src={require('assets/img/dan-k.png')}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg border border-gray-900 p-8 flex flex-col hidden sm:flex">
            <p className="text-lg text-gray-900 mb-8 flex-1">
              I made a resume using your site yesterday and actually got a reply
              from Google. So, in my book, best product I've used!
            </p>
            <div className="flex items-end justify-between">
              <div>
                <p className="text-sm font-medium">@m'flippinguy</p>
                <p className="text-sm font-medium">Software Engineer</p>
              </div>
              <div className="flex items-center justify-center rounded-full border">
                <img
                  className="flex-shrink-0 object-cover w-10 h-10 rounded-full"
                  src={require('assets/img/motherflippinguy.png')}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg border border-gray-900 p-8 flex flex-col hidden sm:flex">
            <p className="text-lg text-gray-900 mb-8 flex-1">
              I signed up for your website a couple months ago and have loved it
              so far. This is a fantastic tool that really saves you the
              headache of formatting on word. I signed up for your website a
              couple months ago and have loved it so far. This is a fantastic
              tool that really saves you the headache of formatting on word.
            </p>
            <div className="flex items-end justify-between">
              <div>
                <p className="text-sm font-medium">Deniz G.</p>
                <p className="text-sm font-medium">Medical Student</p>
              </div>
              <div className="flex items-center justify-center rounded-full border">
                <img
                  className="flex-shrink-0 object-cover w-10 h-10 rounded-full"
                  src={require('assets/img/deniz-g.png')}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg border border-gray-900 p-8 flex flex-col">
            <p className="text-lg text-gray-900 mb-8 flex-1">
              The site is super useful! I was having constant formatting issues
              with resume templates in ms word and was looking for something
              like what you provided.
            </p>
            <div className="flex items-end justify-between">
              <div>
                <p className="text-sm font-medium">John S.</p>
                <p className="text-sm font-medium">Data Analyst</p>
              </div>
              <div className="flex items-center justify-center rounded-full border">
                <img
                  className="flex-shrink-0 object-cover w-10 h-10 rounded-full"
                  src={require('assets/img/john-s.png')}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg border border-gray-900 p-8 flex flex-col">
            <p className="text-lg text-gray-900 mb-8 flex-1">
              Love the website. It's helped a few of my friends land career
              opportunities.
            </p>
            <div className="flex items-end justify-between">
              <div>
                <p className="text-sm font-medium">Philip C.</p>
                <p className="text-sm font-medium">Product Operations</p>
              </div>
              <div className="flex items-center justify-center rounded-full border">
                <img
                  className="flex-shrink-0 object-cover w-10 h-10 rounded-full"
                  src={require('assets/img/philip-c.png')}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="border border-transparent p-8 flex items-end justify-end">
            <Link
              to="/auth/register"
              className="flex items-center text-gray-900 gap-1"
            >
              <div className="text-lg">Try Resumary now</div>
              <ArrowRightIcon className="w-4 h-4" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestimonialSection
