import { Page, View } from '@react-pdf/renderer'
import { mapIdToSection } from '../Utils/helper'
import Column from './Column'

const TwoColumnLayout = (props) => {
  const {
    configs = { styles: {} },
    resume,
    headerLeftColumn = false,
    leftColumnBackground = false,
  } = props
  const { styles, entryTransformerMap, Header } = configs
  const { basics, color, customSections, order } = resume
  const customSectionEntriesMap = mapIdToSection(customSections)
  return (
    <Page style={styles.page} size="LETTER">
      {!headerLeftColumn && basics && <Header basics={basics} color={color} />}
      {leftColumnBackground && <View style={styles.backgroundColor} fixed />}
      <View style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}>
        <View style={styles.leftColumn}>
          {headerLeftColumn && basics && (
            <Header basics={basics} color={color} />
          )}
          {order && order.length > 0 && (
            <Column
              settings={configs.settings}
              styles={configs.styles}
              TemplateEntry={configs.TemplateEntry}
              TemplateSection={configs.TemplateSection}
              sections={order[0]}
              entryTransformer={entryTransformerMap}
              resume={resume}
              customSectionEntriesMap={customSectionEntriesMap}
            />
          )}
        </View>
        <View style={styles.rightColumn}>
          {order && order.length > 1 && (
            <Column
              settings={configs.settings}
              styles={configs.styles}
              TemplateEntry={configs.TemplateEntry}
              TemplateSection={configs.TemplateSection}
              sections={order[1]}
              entryTransformer={entryTransformerMap}
              resume={resume}
              customSectionEntriesMap={customSectionEntriesMap}
            />
          )}
        </View>
      </View>
    </Page>
  )
}

export default TwoColumnLayout
