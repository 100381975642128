import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { ResumeExamplesArrayNoItems } from 'marketing/resources/ResumeExamplesMap'
import { ResumeTemplatesArray } from 'marketing/resources/ResumeTemplatesMap'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import DropdownMenu from './DropdownMenu'

const tabs = {
  dropdowns: [
    // {
    //   name: 'Features',
    //   items: [
    //     {
    //       name: 'Resume Builder',
    //       description: 'Get a better understanding',
    //       to: '/resume-builder',
    //       icon: ChartPieIcon,
    //     },
    //     {
    //       name: 'Chrome Extension',
    //       description: "Your customers' data will",
    //       to: '#',
    //       icon: FingerPrintIcon,
    //       new: true,
    //     },
    //     {
    //       name: 'Cover Letter',
    //       description: 'Speak directly to',
    //       to: '#',
    //       icon: CursorClickIcon,
    //       comingSoon: true,
    //     },
    //     {
    //       name: 'Job Search',
    //       description: 'Connect with third-party tools',
    //       to: '#',
    //       icon: ViewGridAddIcon,
    //       comingSoon: true,
    //     },
    //     {
    //       name: 'Auto Apply',
    //       description: 'Connect with third-party tools',
    //       to: '#',
    //       icon: ViewGridAddIcon,
    //       comingSoon: true,
    //     },
    //   ],
    // },
    {
      id: 'templates',
      name: 'Templates',
      nameOnly: true,
      items: [
        ...ResumeTemplatesArray.slice(0, 10),
        {
          id: 'view-all-templates',
          name: 'View All Templates',
          to: '/resume-templates',
        },
      ],
    },
    {
      id: 'examples',
      name: 'Examples',
      nameOnly: true,
      items: [
        ...ResumeExamplesArrayNoItems.slice(0, 10).map((item) => {
          return { ...item, name: `${item.size}+ ${item.name} Resume Examples` }
        }),
        {
          id: 'view-all-examples',
          name: 'View All Examples',
          to: '/resume-examples',
        },
      ],
    },
  ],
  pages: [
    // { name: 'Students', to: '/students' },
    // { name: 'Pricing', to: '/pricing' },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar({ bgColor }) {
  const [open, setOpen] = useState(false)

  return (
    <div>
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-y-full"
              enterTo="translate-y-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-y-0"
              leaveTo="-translate-y-full"
            >
              <Dialog.Panel className="relative flex w-full flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex justify-end px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}
                <Tab.Group as="div" className="mt-2">
                  <div className="border-b border-gray-200">
                    <Tab.List className="-mb-px flex space-x-8 px-4">
                      {tabs.dropdowns.map((dropdown) => (
                        <Tab
                          key={dropdown.name}
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? 'border-indigo-600 text-indigo-600'
                                : 'border-transparent text-gray-900',
                              'flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-base font-medium'
                            )
                          }
                        >
                          {dropdown.name}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>
                  <Tab.Panels as={Fragment}>
                    {tabs.dropdowns.map((dropdown) => (
                      <Tab.Panel
                        key={dropdown.name}
                        className="space-y-10 px-4 pb-8 pt-8"
                      >
                        {/* {category.sections.map((section) => ( */}
                        <div key={dropdown.name}>
                          <p
                            id={`${dropdown.id}-heading-mobile`}
                            className="font-medium text-gray-900"
                          >
                            {dropdown.name}
                          </p>
                          <ul
                            role="list"
                            aria-labelledby={`${dropdown.id}-heading-mobile`}
                            className="mt-6 flex flex-col space-y-6"
                          >
                            {dropdown.items.map((item) => (
                              <li key={item.name} className="flow-root">
                                <Link
                                  onClick={() => setOpen(false)}
                                  to={item.to}
                                  className="-m-2 block p-2 text-gray-500"
                                >
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                        {/* ))} */}
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>

                {/* <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                  {tabs.pages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <Link
                        onClick={() => setOpen(false)}
                        to={page.to}
                        className="-m-2 block p-2 font-medium text-gray-900"
                      >
                        {page.name}
                      </Link>
                    </div>
                  ))}
                </div> */}

                <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                  <div className="flow-root">
                    <Link
                      to="/auth/login"
                      className="-m-2 block p-2 font-medium text-gray-900"
                    >
                      Sign In
                    </Link>
                  </div>
                  <div className="flow-root">
                    <Link
                      to="/auth/register"
                      className="-m-2 block p-2 font-medium text-gray-900"
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <header className={classNames(bgColor, 'relative')}>
        {/* <p className="flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
          Get free delivery on orders over $100
        </p> */}

        <nav
          aria-label="Top"
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
        >
          <div className="">
            <div className="flex h-20 items-center">
              <button
                type="button"
                className="relative rounded-md p-2 text-gray-900 order-last lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Logo */}
              <div className="flex">
                <Link to="/">
                  {/* <div className="h-10 w-10 flex flex-col items-start border border-indigo-600 p-1 gap-0.5 rounded-lg">
                    <div className="h-1 w-full bg-indigo-600 rounded"></div>
                    <div className="h-1 w-full bg-indigo-600 rounded"></div>
                    <div className="h-1 w-full bg-indigo-600 rounded"></div>
                    <div className="h-1 w-full bg-indigo-600 rounded"></div>
                  </div> */}
                  <div className="h-8 w-8 flex items-center justify-center">
                    {/* <span className="text-2xl font-bold text-white">R</span> */}
                    <img
                      className=""
                      src={require('assets/img/R_logo_round.png')}
                      alt="Resumary"
                    />
                  </div>
                </Link>
              </div>

              <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch z-10">
                <div className="flex h-full gap-4">
                  {tabs.dropdowns.map((dropdown) => (
                    <DropdownMenu dropdown={dropdown} />
                  ))}

                  {tabs.pages.map((page) => (
                    <Link
                      key={page.name}
                      to={page.to}
                      className="flex items-center text-md font-medium text-gray-700 hover:text-gray-800"
                    >
                      {page.name}
                    </Link>
                  ))}
                </div>
              </Popover.Group>

              <div className="ml-auto flex items-center">
                <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                  <Link to="/auth/login">
                    <button className="text-md font-medium text-gray-700 hover:text-white border-2 border-gray-800 hover:bg-gray-800 rounded py-3 px-6 transition-all duration-300">
                      Sign in
                    </button>
                  </Link>
                  <Link to="/auth/register">
                    <button className="text-md font-medium text-white hover:text-gray-800 border-2 border-gray-800 bg-gray-800 hover:bg-white rounded py-3 px-6 transition-all duration-300">
                      Get Started Free
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}
