/*eslint-disable*/
import { Link } from 'react-router-dom'

// components
import Logo from 'components/Core/Logo'

export default function Navbar() {
  return (
    <>
      <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="">
            <Logo light compact />
            {/*<button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="text-white fas fa-bars"></i>
            </button>*/}
          </div>
          <div
            className={
              'flex flex-grow items-center bg-opacity-0 shadow-none' +
              ' block rounded shadow-lg'
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-row list-none mr-auto">
              {/*<li className="flex items-center">
                <a
                  className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="https://www.creative-tim.com/learning-lab/tailwind/react/overview/notus?ref=nr-auth-navbar"
                >
                  <i className="lg:text-blueGray-200 text-blueGray-400 far fa-file-alt text-lg leading-lg mr-2" />{" "}
                  Docs
                </a>
              </li>*/}
            </ul>
            <ul className="flex items-center">
              {/*<li className="flex items-center">
                <PagesDropdown />
              </li>*/}
              <li>
                <Link to="/auth/login">
                  <button
                    className="bg-blueGray-700 text-white active:bg-blueGray-600 text-sm font-bold px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Sign in
                  </button>
                </Link>
              </li>

              <li className="ml-3">
                <Link to="/auth/register">
                  <button
                    className="bg-white text-blueGray-700 active:bg-blueGray-50 text-sm font-bold px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Sign up
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}
