export const resume = {
  basics: {
    firstName: 'Maria',
    lastName: 'Gonzalez',
    title: 'Technical Lead',
    picture: 'https://example.com/profile_picture.jpg',
    email: 'mariagonzalez@email.com',
    phone: '123-456-7890',
    url: 'https://mariagonzalez.com',
    summary:
      'Accomplished Technical Lead with a proven history of guiding cross-functional teams to deliver high-quality software solutions. Expertise in architectural design, team coordination, and project management.',
    location: {
      city: 'San Jose',
      countryCode: 'US',
      region: 'CA',
    },
    linkedInUrl: 'https://www.linkedin.com/in/mariagonzalez',
    portfolioUrl: 'https://github.com/mariagonzalez',
  },
  experience: [
    {
      company: 'Google',
      location: {
        city: 'Mountain View',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'Technical Lead',
      url: 'https://www.google.com',
      startDate: '2020-01-01',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Defined architecture and technology stack, resulting in a scalable and maintainable system handling petabytes of data.',
        'Mentored junior developers, improving code quality and fostering skill development within the team.',
        'Coordinated with product managers and stakeholders to prioritize features and successfully deliver quarterly releases.',
      ],
      present: false,
    },
    {
      company: 'Microsoft Corporation',
      location: {
        city: 'Redmond',
        countryCode: 'US',
        region: 'WA',
      },
      position: 'Software Development Engineer III',
      url: 'https://www.microsoft.com',
      startDate: '2017-06-01',
      endDate: '2019-12-31',
      summary: null,
      highlights: [
        'Designed and implemented scalable microservices architecture, reducing system latency by 25%.',
        'Facilitated knowledge sharing sessions and workshops, improving team collaboration and technical expertise.',
        'Collaborated with cross-functional teams to address performance bottlenecks, resulting in a 40% improvement in application response time.',
      ],
      present: false,
    },
    {
      company: 'Tesla, Inc.',
      location: {
        city: 'Palo Alto',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'Senior Software Engineer',
      url: 'https://www.tesla.com',
      startDate: '2014-03-15',
      endDate: '2017-05-31',
      summary: null,
      highlights: [
        "Led a team of engineers in designing and implementing critical components for Tesla's Autopilot system.",
        'Collaborated with research teams to integrate machine learning algorithms into the software, enhancing autonomous driving capabilities.',
        'Achieved a 30% reduction in system errors through rigorous testing and code reviews.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'CA',
      },
      startDate: '2010-09-01',
      endDate: '2012-05-15',
      gpa: '3.9',
      courses: [
        'Advanced Software Architecture',
        'Distributed Systems',
        'Project Management',
      ],
      summary: 'Graduated with Honors',
      present: false,
    },
    {
      institution: 'University of California, Berkeley',
      degree: 'Bachelor of Science in Computer Engineering',
      major: 'Computer Engineering',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'CA',
      },
      startDate: '2006-09-01',
      endDate: '2010-05-15',
      gpa: '3.8',
      courses: [
        'Embedded Systems',
        'Digital Logic Design',
        'Operating Systems',
      ],
      summary: "Dean's List",
      present: false,
    },
  ],
  skills: [
    {
      skillGroup: 'Languages',
      skill: [
        {
          name: 'Java',
        },
        {
          name: 'Python',
        },
        {
          name: 'JavaScript',
        },
      ],
    },
    {
      skillGroup: 'Frameworks',
      skill: [
        {
          name: 'Spring Boot',
        },
        {
          name: 'React',
        },
        {
          name: 'Node.js',
        },
      ],
    },
    {
      skillGroup: 'Databases',
      skill: [
        {
          name: 'MySQL',
        },
        {
          name: 'MongoDB',
        },
        {
          name: 'Redis',
        },
      ],
    },
    {
      skillGroup: 'Cloud',
      skill: [
        {
          name: 'AWS',
        },
        {
          name: 'Azure',
        },
        {
          name: 'Google Cloud Platform',
        },
      ],
    },
    {
      skillGroup: 'DevOps',
      skill: [
        {
          name: 'Docker',
        },
        {
          name: 'Kubernetes',
        },
        {
          name: 'Jenkins',
        },
      ],
    },
  ],
  projects: [
    {
      name: 'Smart Home Automation System',
      description:
        'Led the design and implementation of a smart home automation system, integrating IoT devices for seamless control and monitoring.',
      startDate: '2019-02-01',
      endDate: '2020-05-31',
      present: false,
    },
    {
      name: 'E-Commerce Platform Enhancement',
      description:
        'Oversaw the enhancement of an existing e-commerce platform, optimizing performance and integrating new payment gateways.',
      startDate: '2017-08-15',
      endDate: '2018-12-31',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Outstanding Technical Achievement Award',
      date: '2022-03-15',
      awarder: 'Google',
      summary:
        'Recognized for leadership and technical excellence in driving the successful completion of a critical project.',
    },
    {
      title: 'Innovation Excellence Award',
      date: '2019-09-20',
      awarder: 'Microsoft Corporation',
      summary:
        'Received for innovative contributions to software design and development, resulting in enhanced product capabilities.',
    },
  ],
  customSections: [
    {
      name: 'Publications',
      details: [
        {
          title: 'Advances in Software Architecture',
          subtitle: 'Conference Paper',
          location: {
            city: 'San Francisco',
            countryCode: 'US',
            region: 'CA',
          },
          url: 'https://www.example.com/paper',
          startDate: '2021-06-01',
          endDate: '2021-06-03',
          summary:
            'Presented findings on scalable microservices architecture at an international software architecture conference.',
          highlights: [
            'Discussed best practices and real-world implementation challenges.',
          ],
          present: false,
        },
      ],
    },
  ],
}
