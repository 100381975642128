import { MinusIcon, PlusIcon } from '@heroicons/react/solid'
import { AccordionPage } from 'components/Forms/Editor/Accordion'
import { Field, FieldArray } from 'formik'

function renderSkill(props, groupIndex) {
  const { values } = props.form

  function onKeyUp(keyEvent) {
    console.log('test')
  }

  return (
    <div className="col-span-6">
      <label
        htmlFor={`skill-skill-${groupIndex}`}
        className="block text-sm font-medium text-gray-700"
      >
        Skill
      </label>
      <div className="gap-4 grid grid-cols-6">
        {values &&
          values.skills[groupIndex] &&
          values.skills[groupIndex].skill &&
          values.skills[groupIndex].skill.map((field, index) => {
            return (
              <div key={index} className="col-span-6 flex justify-between">
                <Field
                  component="input"
                  type="text"
                  name={`skills.${groupIndex}.skill.${index}.name`}
                  id="skill_group"
                  autoComplete="skill_group"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-3/4 shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
                  placeholder="i.e. AWS, Canva, etc."
                  onKeyUp={onKeyUp}
                />
                <div className="mt-1 ml-2 flex flex-col">
                  <div>
                    <button
                      type="button"
                      onClick={() => props.remove(index)}
                      className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <MinusIcon
                        className="-ml-2 -mr-2 h-5 w-5 text-gray-500"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              </div>
            )
          })}
        <div className="col-span-3 col-start-1 mt-1">
          <div>
            <button
              type="button"
              onClick={() => props.push({})}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PlusIcon
                className="-ml-2 mr-1 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
              Add Skill
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function SkillForm(index) {
  return (
    <div className="m-4 grid grid-cols-6 gap-6">
      <div className="col-span-6">
        <label
          htmlFor={`skill-skill_group-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          Skill Category (optional)
        </label>
        <Field
          component="input"
          type="text"
          name={`skills.${index}.skillGroup`}
          id={`skill-skill_group-${index}`}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
          placeholder="Group your skills - i.e. Technical, Languages, Hobbies"
        />
      </div>
      <FieldArray
        name={`skills.${index}.skill`}
        render={(arrayHelpers) => renderSkill(arrayHelpers, index)}
      />
    </div>
  )
}

export default function SkillsForm() {
  return (
    <>
      <div className="mt-5 md:mt-0 md:col-span-3">
        <div className="overflow-hidden sm:rounded-md">
          <div className="px-4 sm:px-6">
            <FieldArray
              name="skills"
              render={(arrayHelpers) => (
                <AccordionPage
                  {...arrayHelpers}
                  render={SkillForm}
                  nestedArray="skill"
                  header="Skills"
                  titleSelector="skillGroup"
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  )
}
