export const resume = {
  basics: {
    firstName: 'Emily',
    lastName: 'Smith',
    title: 'Data Engineer',
    picture: 'https://example.com/emily-smith.jpg',
    email: 'emily.smith@example.com',
    phone: '987-654-3210',
    url: 'https://www.emilysmithdata.com',
    summary:
      'Experienced Data Engineer with a strong background in designing and implementing scalable data pipelines and architectures. Skilled in transforming raw data into valuable insights and driving data-driven decision-making. Passionate about optimizing data processes and enhancing data quality to support analytics and business objectives.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/emilysmith',
    portfolioUrl: 'https://portfolio.emilysmithdata.com',
  },
  experience: [
    {
      company: 'Lyft',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Data Engineer',
      url: 'https://www.lyft.com',
      startDate: '2020-02-01',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Led the development of a real-time data ingestion pipeline using Apache Kafka and Apache Spark, reducing data latency by 30% and enabling timely decision-making.',
        'Optimized ETL processes for large-scale data processing using Apache Airflow, resulting in a 25% reduction in processing time and improved data accuracy.',
        "Collaborated with cross-functional teams to define data requirements and implement data models for Lyft's analytics platform, enhancing data accessibility and usability.",
        'Implemented data quality checks and monitoring processes, ensuring data accuracy and reliability and reducing data-related incidents by 20%.',
      ],
      present: false,
    },
    {
      company: 'Twitter',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Data Analyst',
      url: 'https://www.twitter.com',
      startDate: '2018-05-15',
      endDate: '2020-01-31',
      summary: null,
      highlights: [
        'Designed interactive dashboards using Tableau to visualize key performance metrics, facilitating data-driven discussions and strategic planning.',
        'Conducted A/B tests and statistical analysis to evaluate feature performance and user behavior, leading to a 15% increase in user engagement.',
      ],
      present: false,
    },
    {
      company: 'Dropbox',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Data Analyst Intern',
      url: 'https://www.dropbox.com',
      startDate: '2017-08-01',
      endDate: '2018-01-31',
      summary: null,
      highlights: [
        'Analyzed user behavior data to identify patterns and trends, resulting in actionable insights that contributed to the optimization of the user onboarding process.',
        'Assisted in the design and implementation of data visualizations and dashboards using Python and Tableau, improving data accessibility and communication.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'University of Texas at Austin',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Austin',
        countryCode: 'US',
        region: 'Texas',
      },
      startDate: '2016-08-15',
      endDate: '2018-05-31',
      gpa: '3.9',
      courses: [
        'Big Data Management',
        'Database Systems',
        'Distributed Systems',
      ],
      summary: '',
      present: false,
    },
    {
      institution: 'University of California, Irvine',
      degree: 'Bachelor of Science in Information and Computer Science',
      major: 'Information and Computer Science',
      location: {
        city: 'Irvine',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2012-09-01',
      endDate: '2016-05-31',
      gpa: '3.8',
      courses: [
        'Data Structures',
        'Database Management',
        'Programming in Python',
      ],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Data Engineering Excellence Award',
      date: '2022-04-15',
      awarder: 'Data Engineering Association',
      summary:
        'Recognized for outstanding contributions and leadership in the field of data engineering, demonstrating excellence in designing and implementing data solutions.',
    },
  ],
  skills: [
    {
      skillGroup: 'Data Engineering',
      skill: [
        { name: 'ETL Development' },
        { name: 'Apache Spark' },
        { name: 'Apache Airflow' },
      ],
    },
    {
      skillGroup: 'Database Systems',
      skill: [{ name: 'SQL' }, { name: 'NoSQL' }],
    },
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'Python' }, { name: 'Java' }, { name: 'Scala' }],
    },
    {
      skillGroup: 'Big Data Technologies',
      skill: [
        { name: 'Hadoop' },
        { name: 'AWS EMR' },
        { name: 'Google BigQuery' },
      ],
    },
  ],
  projects: [
    {
      name: 'Clickstream Data Pipeline',
      description:
        'Designed and implemented a scalable clickstream data pipeline using Apache Spark and Apache Kafka, processing and analyzing terabytes of data daily.',
      startDate: '2022-01-01',
      endDate: '2022-06-30',
      present: false,
    },
    {
      name: 'Real-time Data Processing',
      description:
        'Developed a real-time data processing solution using Apache Kafka and Python, enabling instant data updates and improving operational efficiency.',
      startDate: '2021-04-15',
      endDate: '2021-09-30',
      present: false,
    },
    {
      name: 'Data Quality Monitoring',
      description:
        'Implemented a data quality monitoring system using Apache Airflow and SQL, detecting and alerting anomalies to ensure high data accuracy.',
      startDate: '2020-09-01',
      endDate: '2020-12-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Technical Publications',
      details: [
        {
          title: 'Best Practices in Data Engineering',
          subtitle: 'Data Engineering Journal',
          location: {
            city: 'San Francisco',
            countryCode: 'US',
            region: 'California',
          },
          url: 'https://www.dataengineeringjournal.com/issues/...',
          startDate: '2021-08-15',
          endDate: '2021-08-31',
          summary:
            'Authored an article outlining best practices in data engineering, focusing on optimizing ETL processes and ensuring data quality.',
          highlights: [
            'Discussed strategies for designing efficient ETL pipelines and overcoming common data engineering challenges.',
            'Highlighted the importance of data lineage, metadata management, and version control in maintaining data quality and traceability.',
            'Shared real-world case studies showcasing the impact of data engineering best practices on business outcomes.',
          ],
          present: false,
        },
      ],
    },
  ],
}
