import { Link, useLocation, useNavigate } from 'react-router-dom'

import axios from 'axios'
import Form from 'components/Forms/Auth/ForgotPasswordForm'
import { ArrowSmLeftIcon } from '@heroicons/react/outline'
import ErrorMessage from 'components/Core/ErrorMessage'

export default function ForgotPassword() {
  const navigate = useNavigate()
  const state = useLocation().state

  const forgotPassword = (userData) => {
    axios
      .post('/forgot', userData)
      .then((res) => {
        navigate('/auth/login', {
          state: { notifications: [{ msg: res.data.msg }] },
        })
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 302:
              navigate(err.response.data.url)
              break
            case 400:
              navigate(
                {
                  pathname: '.',
                },
                {
                  replace: true,
                  state: {
                    errors: err.response.data.map((v) => {
                      return {
                        msg: v.msg,
                      }
                    }),
                  },
                }
              )
              break
            case 401:
              navigate(
                {
                  pathname: '.',
                },
                {
                  replace: true,
                  state: { errors: [{ msg: err.response.data.msg }] },
                }
              )
              break
            default:
              break
          }
        }
      })
  }

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <ErrorMessage
              errors={state?.errors}
              notifications={state?.notifications}
            />
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Forgot password?
                  </h6>
                </div>
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <Form onSubmit={(values) => forgotPassword(values)} />
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <Link
                  to="/auth/login"
                  className="text-blueGray-200 inline-flex gap-0.5 items-center"
                >
                  <ArrowSmLeftIcon className="w-4 h-4" />
                  <span className="text-sm">Go back</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
