import ActionTypes from '../actions/types'

const initialState = {
  isAuthenticated: false,
  user: {},
  stripeSessionId: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      }
    case ActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        loading: false,
        user: action.payload,
      }
    case ActionTypes.SET_STRIPE_SESSION:
      return {
        ...state,
        stripeSessionId: action.payload,
      }
    default:
      return state
  }
}
