import { Link } from 'react-router-dom'

export default function TemplateDisplay(props) {
  return (
    <Link
      to={`/resume-templates/${props.name.toLowerCase()}`}
      className="col-span-2 bg-white rounded-lg"
    >
      <div
        className={`border border-gray-300 rounded-lg transition duration-300 overflow-hidden`}
      >
        <div className="rounded overflow-hidden aspect-w-17 aspect-h-22">
          <img
            className="hover:scale-105 transition-all duration-300"
            src={require(`assets/img/Template${props.template}.png`)}
          />
        </div>
      </div>
      {!props.hideText && (
        <div className="mt-3 flex flex-col">
          <h3 className="text-lg font-semibold">{props.name}</h3>
          <p className="mt-1 text-base text-gray-500">{props.description}</p>
          {props.showLabel && (
            <div className="flex justify-center mt-1">
              {props.isPremium ? (
                <p className="text-sm py-0.5 px-3 rounded bg-yellow-100 text-yellow-500">
                  Premium
                </p>
              ) : (
                <p className="text-sm py-0.5 px-3 rounded bg-gray-200 text-gray-500">
                  Free
                </p>
              )}
            </div>
          )}
        </div>
      )}
    </Link>
  )
}
