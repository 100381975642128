function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ErrorMessage({ errors, notifications }) {
  const errorMessage = errors?.map((e) => e.msg).join('. ')
  if (errorMessage) {
    return (
      <div
        className={classNames(
          errorMessage ? 'visible' : 'invisible',
          'bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-3 rounded relative'
        )}
        role="alert"
      >
        <span className="block sm:inline">{errorMessage}</span>
      </div>
    )
  }

  const notificationMessage = notifications?.map((n) => n.msg).join('. ')
  if (notificationMessage) {
    return (
      <div
        className={classNames(
          notificationMessage ? 'visible' : 'invisible',
          'bg-green-100 border border-green-400 text-green-700 px-4 py-3 mb-3 rounded relative'
        )}
        role="alert"
      >
        <span className="block sm:inline">
          {notificationMessage || 'Notification'}
        </span>
      </div>
    )
  }
  return null
}
