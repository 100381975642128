export const resume = {
  basics: {
    firstName: 'Aria',
    lastName: 'Lopez',
    title: 'UI/UX Designer and Developer',
    picture: 'https://example.com/profile_picture.jpg',
    email: 'arialopez@email.com',
    phone: '123-456-7890',
    url: 'https://arialopez.com',
    summary:
      'Creative and skilled UI/UX Designer and Developer with a passion for crafting visually appealing and user-friendly digital experiences. Proficient in design tools, front-end development, and user-centered design principles.',
    location: {
      city: 'New York',
      countryCode: 'US',
      region: 'NY',
    },
    linkedInUrl: 'https://www.linkedin.com/in/arialopez',
    portfolioUrl: 'https://dribbble.com/arialopez',
  },
  experience: [
    {
      company: 'Adobe Systems',
      location: {
        city: 'San Jose',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'UI/UX Designer and Developer',
      url: 'https://www.adobe.com',
      startDate: '2020-01-01',
      endDate: '2023-07-31',
      summary:
        "Designed and developed innovative UI/UX solutions for Adobe's creative software applications, focusing on user-centric design and front-end development.",
      highlights: [
        'Collaborated with cross-functional teams to gather requirements and create intuitive and visually appealing user interfaces.',
        'Designed wireframes and prototypes using Adobe XD, ensuring efficient and consistent user flows.',
        'Implemented responsive and interactive designs using HTML, CSS, and JavaScript, resulting in a seamless user experience across devices.',
      ],
      present: false,
    },
    {
      company: 'Microsoft Corporation',
      location: {
        city: 'Redmond',
        countryCode: 'US',
        region: 'WA',
      },
      position: 'UI/UX Designer',
      url: 'https://www.microsoft.com',
      startDate: '2017-06-01',
      endDate: '2019-12-31',
      summary:
        "Contributed to the design and enhancement of user interfaces for Microsoft's productivity software and cloud services.",
      highlights: [
        'Worked closely with product managers to define design requirements and create user-centered design solutions.',
        'Designed and presented high-fidelity mockups and prototypes using Sketch and InVision, facilitating clear communication and feedback.',
        'Conducted usability testing and gathered user feedback to inform iterative design improvements and enhance user satisfaction.',
      ],
      present: false,
    },
    {
      company: 'Startup Innovations',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'UI/UX Developer Intern',
      url: 'https://www.startupinnovations.com',
      startDate: '2015-02-15',
      endDate: '2015-05-31',
      summary:
        'Contributed to the design and development of web and mobile applications, focusing on creating engaging and user-friendly interfaces.',
      highlights: [
        'Collaborated with designers to implement UI/UX designs using HTML, CSS, and JavaScript, ensuring pixel-perfect implementation.',
        'Assisted in front-end development tasks, including responsive design and cross-browser compatibility testing.',
        'Participated in design discussions and provided insights into enhancing user interactions and experiences.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Parsons School of Design',
      degree: 'Bachelor of Fine Arts in Design',
      major: 'Communication Design',
      location: {
        city: 'New York',
        countryCode: 'US',
        region: 'NY',
      },
      startDate: '2013-09-01',
      endDate: '2017-05-15',
      gpa: '3.7',
      courses: [
        'User-Centered Design',
        'Interaction Design',
        'Web Development',
      ],
      summary: "Dean's List",
      present: false,
    },
  ],
  skills: [
    {
      skillGroup: 'Design Tools',
      skill: [
        {
          name: 'Adobe XD',
        },
        {
          name: 'Sketch',
        },
        {
          name: 'InVision',
        },
      ],
    },
    {
      skillGroup: 'Front-End Development',
      skill: [
        {
          name: 'HTML',
        },
        {
          name: 'CSS',
        },
        {
          name: 'JavaScript',
        },
      ],
    },
    {
      skillGroup: 'Responsive Design',
      skill: [
        {
          name: 'Responsive Web Design',
        },
        {
          name: 'Mobile-First Design',
        },
      ],
    },
    {
      skillGroup: 'User-Centered Design',
      skill: [
        {
          name: 'Wireframing',
        },
        {
          name: 'Prototyping',
        },
        {
          name: 'Usability Testing',
        },
      ],
    },
    {
      skillGroup: 'Version Control',
      skill: [
        {
          name: 'Git',
        },
        {
          name: 'SVN',
        },
      ],
    },
    {
      skillGroup: 'UI/UX Principles',
      skill: [
        {
          name: 'Typography',
        },
        {
          name: 'Color Theory',
        },
        {
          name: 'Visual Hierarchy',
        },
      ],
    },
  ],
  projects: [
    {
      name: 'E-commerce Website Redesign',
      description:
        'Revamped the user interface and navigation of an e-commerce website, resulting in a 30% increase in user engagement.',
      startDate: '2019-02-01',
      endDate: '2020-05-31',
      present: false,
    },
    {
      name: 'Mobile App User Interface',
      description:
        'Designed and developed the user interface for a mobile app, focusing on creating a clean and intuitive design.',
      startDate: '2017-08-15',
      endDate: '2018-12-31',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Excellence in UI/UX Design',
      date: '2022-03-10',
      awarder: 'Adobe Systems',
      summary:
        'Recognized for outstanding contributions to UI/UX design and innovative user experiences.',
    },
    {
      title: 'Innovation Award',
      date: '2018-10-18',
      awarder: 'Microsoft Corporation',
      summary:
        'Received for creative and impactful contributions to the design of user interfaces and experiences.',
    },
  ],
  customSections: [],
}
