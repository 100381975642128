import { useContext } from 'react'
import { EditorContext } from '../EditorLayout'
import { templates } from 'utils/defs'
import { classNames } from 'utils/helper'

const TemplatesTabContent = () => {
  const { resume, selectTemplate } = useContext(EditorContext)
  return (
    <div className="my-4 grid grid-cols-2">
      {templates.map((template, idx) => {
        const isSelected = resume.template === idx
        return (
          <div
            key={idx}
            onClick={() => selectTemplate(resume.id, idx)}
            className={classNames(
              isSelected ? 'bg-gray-200' : '',
              'p-4 flex flex-col items-center gap-2'
            )}
          >
            <img
              className="rounded w-32 transition-all duration-300"
              src={require(`assets/img/Template${idx}.png`)}
            />
            <div className="grid gap-2">
              <h3 className={classNames('text-md text-center font-medium')}>
                {template.name} Template
              </h3>
              <div className="flex gap-1 flex-wrap">
                <span className="bg-gray-200 py-0.5 px-2 rounded-full text-xs">
                  {template.twoColumn ? 'Two-column' : 'One-column'}
                </span>
                {template.premium ? (
                  <span className="bg-yellow-200 py-0.5 px-2 rounded-full text-xs">
                    Premium
                  </span>
                ) : (
                  <span className="bg-blue-200 py-0.5 px-2 rounded-full text-xs">
                    Starter
                  </span>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TemplatesTabContent
