function ThreeDotsLoadingScreen(props) {
  return (
    <div className="h-full flex justify-center items-center">
      <div className="flex">
        <span className="circle animate-loader"></span>
        <span className="circle animate-loader animation-delay-200"></span>
        <span className="circle animate-loader animation-delay-400"></span>
      </div>
    </div>
  )
}

export default ThreeDotsLoadingScreen
