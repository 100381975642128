import { LinkIcon } from '@heroicons/react/outline'
import axios from 'axios'
import { getDate } from 'components/Generator/Templates/common/Utils/helper'
import ThreeDotsLoadingScreen from 'components/LoadingScreens/ThreeDotsLoadingScreen'
import { useEffect, useState } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import { EmailIcon, LinkedinIcon } from 'react-share'

const Entry = ({
  title,
  subtitle,
  startDate,
  endDate,
  location,
  isPresent = false,
  logoUrl = null,
}) => {
  return (
    <div className="flex gap-6">
      {/* {logoUrl ? (
        <img
          alt="team"
          className="mt-1 w-14 h-14 object-contain object-center flex-shrink-0"
          // src={`https://logo.clearbit.com/${logoUrl}?size=80`}
          src={`https://logo.uplead.com/${logoUrl}`}
        />
      ) : ( */}
      <div className="mt-1 w-14 h-14 bg-gray-100 flex items-center justify-center">
        <div className="text-4xl font-bold text-gray-600">
          {subtitle ? subtitle[0] : 'R'}
        </div>
      </div>
      {/* )} */}
      <div>
        <div className="font-bold text-lg">{title}</div>
        <div className="font-medium text-base">{subtitle} · Full-time</div>
        <div className="text-gray-500">
          {getDate(startDate, endDate, isPresent)} · 1 yr 6 mos
        </div>
        <div className="text-gray-500">{location}</div>
      </div>
    </div>
  )
}

const IconLink = ({ children, href }) => {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}

const Skill = ({ value }) => {
  return (
    <span className="text-xs font-semibold inline-block py-1 px-2 rounded text-gray-600 bg-gray-200">
      {value}
    </span>
  )
}

const Profile = (props) => {
  const [resume, setResume] = useState(null)
  const [err, setErr] = useState(null)
  const params = useParams()
  useEffect(() => {
    const fetchProfile = () => {
      axios
        .get(`/user/profile/${params.id}`)
        .then((res) => setResume(res.data))
        .catch((err) => setErr(err))
    }
    fetchProfile()
  }, [])

  if (err) return <Navigate to="/" />
  if (!resume) return <ThreeDotsLoadingScreen />
  return (
    <div className="bg-warmGray-50">
      <div className="h-14 border-b bg-white z-10 sticky top-0">
        <div className="max-w-4xl mx-auto px-4 py-3 flex justify-between">
          <Link to="/">
            <img
              className="h-8 w-auto flex-none"
              src={require('assets/img/R_logo_round.png')}
              alt="Resumary"
            />
          </Link>
          <div className="flex gap-3 items-center">
            <Link to="/auth/login" className="px-4 py-1 border rounded-full">
              Log in
            </Link>
            <Link to="/auth/register" className="px-4 py-1 border rounded-full">
              Sign up
            </Link>
          </div>
        </div>
      </div>
      <div className="max-w-3xl mx-auto flex flex-col md:gap-6 text-black md:py-8">
        <div className="border-b md:border md:rounded-md bg-white">
          <div className="h-40 md:rounded-t-md bg-indigo-600 flex"></div>
          <div className="relative p-6 pt-0 flex flex-col md:flex-row items-start justify-between">
            <div className="-top-24 absolute p-0.5 border rounded-full bg-white">
              <img
                alt="profile"
                src="https://media.licdn.com/dms/image/C4D03AQGKymQiw8NMcw/profile-displayphoto-shrink_400_400/0/1605744732598?e=1689206400&v=beta&t=Epi57YATdtyG_Uz3QmXm_hPuYinlfNUu5BXYl00pGD4"
                className="rounded-full border w-32 h-32"
              ></img>
            </div>
            <div>
              <div className="font-bold text-3xl pt-12">
                {`${resume.basics.firstName} ${resume.basics.lastName}`}
              </div>
              <div className="mb-1">{resume.basics.title} at Affirm</div>
              <div className="text-gray-500 text-base">
                {resume.basics.location || 'Austin, TX'}
              </div>
              <div className="flex gap-3 mt-6">
                {resume.basics.linkedInUrl && (
                  <IconLink href={resume.basics.linkedInUrl}>
                    <LinkedinIcon className="h-8 w-8 rounded-full" />
                  </IconLink>
                )}
                {/* <IconLink href="https://www.reddit.com/u/dawgyo">
                  <RedditIcon className="h-8 w-8 rounded-full" />
                </IconLink>
                <IconLink href="https://www.facebook.com/dandiferr">
                  <FacebookIcon className="h-8 w-8 rounded-full" />
                </IconLink>
                <IconLink href="https://www.twitter.com/dandiferr">
                  <TwitterIcon className="h-8 w-8 rounded-full" />
                </IconLink> */}
                {/* <IconLink href="https://www.linkedin.com/in/fengandrew">
                  <EmailIcon className="h-8 w-8 rounded-full" />
                </IconLink> */}
                {resume.basics.portfolioUrl && (
                  <IconLink href={resume.basics.portfolioUrl}>
                    <div className="h-8 border rounded-full flex items-center justify-center px-2 gap-1.5 group">
                      <LinkIcon className="h-4 w-4 text-gray-600" />
                      <div className="text-sm group-hover:underline">
                        {resume.basics.portfolioUrl}
                      </div>
                    </div>
                  </IconLink>
                )}
                {/* <IconLink href="https://www.resumary.com">
                  <div className="h-8 w-8 border rounded-full flex items-center justify-center">
                    <LinkIcon className="h-5 w-5 text-gray-600" />
                  </div>
                </IconLink> */}
              </div>
            </div>
            <div className="w-full md:w-auto flex-0 flex justify-end gap-2 mt-6">
              {/* <div className="p-2 border rounded-full bg-white text-gray-400 font-bold cursor-pointer">
                <DotsHorizontalIcon className="h-5 w-5" />
              </div> */}
              <IconLink href={`mailto: ${resume.basics.email}`}>
                <EmailIcon className="h-10 w-10 rounded-full" />
              </IconLink>
              {/* <div className="px-4 py-1.5 border border-blue-500 rounded-full bg-white text-blue-500 font-bold cursor-pointer flex items-center justify-center gap-2">
                Download Resume
                <DownloadIcon className="h-5 w-5" />
              </div> */}
              {/* <div className="px-4 py-1.5 border rounded-full bg-blue-600 text-white font-bold cursor-pointer">
                Connect
              </div> */}
            </div>
          </div>
        </div>
        <div className="p-6 border-b md:border md:rounded-md bg-white">
          <div className="font-bold text-2xl mb-2">About</div>
          <div>
            I am a software engineer working at Affirm for the last four years.
          </div>
        </div>
        <div className="p-6 border-b md:border md:rounded-md bg-white">
          <div className="font-bold text-2xl mb-2">Experience</div>
          <div className="flex flex-col py-6 gap-6">
            {resume.experience.map((experience) => (
              <Entry
                title={experience.position}
                subtitle={experience.company}
                location={`${experience.location.city}, ${experience.location.region}`}
                startDate={experience.startDate}
                endDate={experience.endDate}
                isPresent={experience.present}
                logoUrl={`${experience.company.toLowerCase()}.com`}
              />
            ))}
          </div>
        </div>
        <div className="p-6 border-b md:border md:rounded-md bg-white">
          <div className="font-bold text-2xl mb-2">Education</div>
          <div className="flex flex-col py-6 gap-6">
            {resume.education.map((education) => (
              <Entry
                title={education.institution}
                subtitle={education.degree}
                location={`${education.location.city}, ${education.location.region}`}
                startDate={education.startDate}
                endDate={education.endDate}
                logoUrl={`stanford.edu`}
              />
            ))}
          </div>
        </div>
        <div className="p-6 border-b md:border md:rounded-md bg-white">
          <div className="font-bold text-2xl mb-2">Projects</div>
          <div className="flex flex-col py-6 gap-6">
            {resume.projects.map((project) => (
              <Entry
                title={project.name}
                subtitle="B.S. in Electrical and Computer Engineering"
                location="Pittsburgh, PA"
                startDate="Aug 2013"
                endDate="May 2017"
              />
            ))}
          </div>
        </div>
        <div className="p-6 border-b md:border md:rounded-md bg-white">
          <div className="font-bold text-2xl mb-2">Skills</div>
          <div className="flex flex-col py-6 gap-6">
            {resume.skills.map((skills) => (
              <>
                {skills.skillGroup && skills.skill && (
                  <div>
                    <div className="font-semibold text-md mb-2">
                      {skills.skillGroup}
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {skills.skill.map((skill) => {
                        return <Skill value={skill.name} />
                      })}
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
