export const resume = {
  basics: {
    firstName: 'Gabriel',
    lastName: 'Lopez',
    title: 'Application Developer',
    picture: 'https://example.com/gabriel-lopez.jpg',
    email: 'gabriel.lopez@example.com',
    phone: '123-456-7890',
    url: 'https://www.gabriellopez.dev',
    summary:
      'Results-driven Application Developer with a passion for creating user-centric software solutions. Proven expertise in full-stack development, agile methodologies, and delivering high-quality applications. Adept at collaborating with cross-functional teams to meet project goals and exceed user expectations.',
    location: {
      city: 'Austin',
      countryCode: 'US',
      region: 'Texas',
    },
    linkedInUrl: 'https://www.linkedin.com/in/gabriellopez',
    portfolioUrl: 'https://portfolio.gabriellopez.dev',
  },
  experience: [
    {
      company: 'Dell Technologies',
      location: {
        city: 'Round Rock',
        countryCode: 'US',
        region: 'Texas',
      },
      position: 'Application Developer',
      url: 'https://www.delltechnologies.com',
      startDate: '2021-03-15',
      endDate: '2023-02-28',
      summary: null,
      highlights: [
        'Led the design and implementation of a customer portal, reducing user onboarding time by 25% and increasing customer satisfaction.',
        'Collaborated with UX/UI designers to create responsive and visually appealing front-end interfaces, resulting in a 20% increase in user engagement.',
        'Optimized application performance by identifying and addressing performance bottlenecks, resulting in a 15% reduction in page load times.',
        'Integrated third-party APIs and services to enhance application functionality and extend feature capabilities.',
      ],
      present: false,
    },
    {
      company: 'Oracle Corporation',
      location: {
        city: 'Redwood City',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Software Engineer',
      url: 'https://www.oracle.com',
      startDate: '2019-07-01',
      endDate: '2021-01-31',
      summary: null,
      highlights: [
        'Designed and implemented RESTful APIs to enable seamless communication between microservices, resulting in a 30% reduction in API response time.',
        'Implemented unit and integration tests, resulting in a 40% decrease in the number of defects and enhancing overall software quality.',
      ],
      present: false,
    },
    {
      company: 'Cisco Systems',
      location: {
        city: 'San Jose',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Junior Software Developer',
      url: 'https://www.cisco.com',
      startDate: '2018-01-15',
      endDate: '2019-06-30',
      summary: null,
      highlights: [
        'Participated in the implementation of a network monitoring tool, enabling real-time analysis of network traffic and enhancing troubleshooting capabilities.',
        'Collaborated with senior developers to refactor and optimize legacy code, resulting in a 20% reduction in memory usage and improved application stability.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'University of Washington',
      degree: 'Master of Science in Computer Science',
      major: 'Software Engineering',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      startDate: '2017-09-01',
      endDate: '2019-06-15',
      gpa: '3.85',
      courses: [
        'Advanced Web Technologies',
        'Database Systems',
        'Software Design Patterns',
        'Cloud Computing',
      ],
      summary:
        'Completed rigorous coursework and research in software engineering, gaining expertise in modern software development methodologies and technologies.',
      present: false,
    },
    {
      institution: 'University of Texas at Austin',
      degree: 'Bachelor of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Austin',
        countryCode: 'US',
        region: 'Texas',
      },
      startDate: '2013-08-01',
      endDate: '2017-05-15',
      gpa: '3.70',
      courses: [
        'Data Structures and Algorithms',
        'Operating Systems',
        'Web Development',
        'Software Engineering',
      ],
      summary:
        'Developed a strong foundation in computer science principles and gained practical experience in software development through challenging coursework and projects.',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Outstanding Application Developer Award',
      date: '2022-03-01',
      awarder: 'Tech Innovators Association',
      summary:
        'Recognized for exceptional contributions to application development, including innovative solutions and consistent delivery of high-quality software.',
    },
    {
      title: 'Excellence in Software Engineering',
      date: '2018-06-01',
      awarder: 'University of Texas at Austin',
      summary:
        'Received academic recognition for excellence in software engineering coursework and projects.',
    },
  ],
  skills: [
    {
      skillGroup: 'Programming Languages',
      skill: [
        { name: 'JavaScript' },
        { name: 'Python' },
        { name: 'Java' },
        { name: 'C#' },
      ],
    },
    {
      skillGroup: 'Web Development',
      skill: [
        { name: 'HTML5' },
        { name: 'CSS3' },
        { name: 'React' },
        { name: 'Node.js' },
      ],
    },
    {
      skillGroup: 'Database Technologies',
      skill: [{ name: 'SQL' }, { name: 'MongoDB' }, { name: 'Oracle' }],
    },
    {
      skillGroup: 'Agile Methodologies',
      skill: [
        { name: 'Scrum' },
        { name: 'Kanban' },
        { name: 'Sprint Planning' },
      ],
    },
  ],
  projects: [
    {
      name: 'E-Commerce Platform',
      description:
        'Developed a full-stack e-commerce platform featuring user authentication, product catalog, and shopping cart functionality.',
      startDate: '2022-01-01',
      endDate: '2022-06-30',
      present: false,
    },
    {
      name: 'Task Management App',
      description:
        'Designed and built a task management application with user-friendly interfaces and real-time collaboration features.',
      startDate: '2021-05-01',
      endDate: '2021-10-31',
      present: false,
    },
    {
      name: 'Online Banking Portal',
      description:
        'Led a team in the development of an online banking portal with secure account management and transaction capabilities.',
      startDate: '2020-03-15',
      endDate: '2020-09-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Volunteer Work',
      details: [
        {
          title: 'STEM Outreach Volunteer',
          subtitle: 'Tech for Kids Foundation',
          location: {
            city: 'Austin',
            countryCode: 'US',
            region: 'Texas',
          },
          url: 'https://www.techforkidsfoundation.org',
          startDate: '2019-01-15',
          endDate: '2022-02-28',
          summary:
            'Inspired and educated young minds in STEM fields through interactive workshops and coding events.',
        },
        {
          title: 'Software Development Mentor',
          subtitle: 'CodePath',
          location: {
            city: 'Online',
            countryCode: 'US',
            region: 'Virtual',
          },
          url: 'https://www.codepath.org',
          startDate: '2020-09-15',
          endDate: '2022-06-30',
          summary:
            'Guided aspiring software developers through the learning process, providing mentorship and code reviews.',
        },
      ],
    },
  ],
}
