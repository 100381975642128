export const resume = {
  basics: {
    firstName: 'Alex',
    lastName: 'Ramirez',
    title: 'Virtual Reality Engineer',
    picture: 'https://example.com/alex-ramirez.jpg',
    email: 'alex.ramirez@example.com',
    phone: '987-654-3210',
    url: 'https://www.alexramirez.dev',
    summary:
      'Passionate Virtual Reality Engineer with a strong background in VR software development and immersive user experiences. Proven expertise in creating interactive and engaging VR applications for gaming, training, and simulations.',
    location: {
      city: 'Seattle',
      countryCode: 'US',
      region: 'Washington',
    },
    linkedInUrl: 'https://www.linkedin.com/in/alexramirez',
    portfolioUrl: 'https://portfolio.alexramirez.dev',
  },
  experience: [
    {
      company: 'VirtuTech Solutions',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Senior Virtual Reality Engineer',
      url: 'https://www.virtutech.com',
      startDate: '2020-08-15',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Designed and implemented a realistic physics engine for a VR game, resulting in a 30% increase in user immersion and enjoyment.',
        'Optimized VR rendering pipelines to achieve consistent 90Hz performance on leading VR headsets, ensuring a smooth and comfortable user experience.',
        'Collaborated with UX/UI designers to create intuitive and user-friendly VR interfaces, resulting in a 25% reduction in user interaction time.',
        'Mentored junior engineers, providing guidance in VR software development best practices and fostering a collaborative and productive team environment.',
      ],
      present: false,
    },
    {
      company: 'Immersive Studios',
      location: {
        city: 'Los Angeles',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Virtual Reality Developer',
      url: 'https://www.immersivestudios.com',
      startDate: '2018-04-01',
      endDate: '2020-07-31',
      summary: null,
      highlights: [
        'Developed a VR escape room game, achieving a 4.8/5 average user rating and over 100,000 downloads on VR platforms.',
        'Collaborated with 3D artists to optimize and integrate realistic virtual environments and assets, enhancing visual quality and realism.',
        'Designed and implemented a user-driven content creation tool for VR storytelling, resulting in a 50% reduction in content creation time.',
        'Participated in user testing and feedback sessions to iterate and refine VR experiences based on user preferences and behavior.',
      ],
      present: false,
    },
    {
      company: 'VR Innovators',
      location: {
        city: 'Austin',
        countryCode: 'US',
        region: 'Texas',
      },
      position: 'VR Software Engineer',
      url: 'https://www.vrinnovators.com',
      startDate: '2016-09-15',
      endDate: '2018-03-31',
      summary: null,
      highlights: [
        'Developed a real-time VR architectural visualization tool for real estate professionals, resulting in a 50% increase in property sales.',
        'Optimized VR performance on mobile devices through asset batching and dynamic level of detail (LOD) techniques, achieving smooth 60Hz performance on mid-range smartphones.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'University of Michigan',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Ann Arbor',
        countryCode: 'US',
        region: 'Michigan',
      },
      startDate: '2014-09-01',
      endDate: '2016-05-15',
      gpa: '3.85',
      courses: [
        'Computer Graphics',
        'Human-Computer Interaction',
        'Virtual Reality Fundamentals',
        'Advanced Algorithms',
      ],
      summary: null,
      present: false,
    },
    {
      institution: 'University of California, Irvine',
      degree: 'Bachelor of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Irvine',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2010-08-15',
      endDate: '2014-05-15',
      gpa: '3.90',
      courses: [
        'Data Structures and Algorithms',
        'Computer Graphics',
        'Artificial Intelligence',
        'Software Engineering',
      ],
      summary: null,
      present: false,
    },
  ],
  awards: [
    {
      title: 'Outstanding VR Development Award',
      date: '2022-12-15',
      awarder: 'Virtual Reality Society',
      summary:
        'Recognized for exceptional contributions to the field of virtual reality and innovative VR software development.',
    },
    {
      title: 'VR Innovation Challenge Winner',
      date: '2019-05-01',
      awarder: 'VR World',
      summary:
        'Won first place in the VR Innovation Challenge for developing a groundbreaking VR training application.',
    },
  ],
  skills: [
    {
      skillGroup: 'VR Development',
      skill: [
        { name: 'Unity3D' },
        { name: 'Unreal Engine' },
        { name: 'C# Programming' },
        { name: 'VR Interaction Design' },
      ],
    },
    {
      skillGroup: '3D Graphics',
      skill: [
        { name: 'OpenGL' },
        { name: 'Shader Programming' },
        { name: '3D Modeling' },
        { name: 'Texturing' },
      ],
    },
    {
      skillGroup: 'User Experience',
      skill: [
        { name: 'UI/UX Design' },
        { name: 'User-Centered Design' },
        { name: 'User Testing' },
        { name: 'Prototyping' },
      ],
    },
  ],
  projects: [
    {
      name: 'Virtual Art Gallery Experience',
      description:
        'Created an immersive VR art gallery that allows users to explore and interact with famous artworks in a virtual environment.',
      startDate: '2022-03-01',
      endDate: '2022-08-31',
      present: false,
    },
    {
      name: 'VR Flight Simulator',
      description:
        'Designed and developed a realistic VR flight simulation experience, complete with interactive cockpit controls and multiple aircraft.',
      startDate: '2019-09-15',
      endDate: '2020-02-28',
      present: false,
    },
    {
      name: 'Educational VR Physics Lab',
      description:
        'Built a VR physics laboratory application for students to conduct physics experiments in a virtual environment, enhancing learning and understanding.',
      startDate: '2017-06-01',
      endDate: '2018-01-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Languages',
      details: [
        {
          title: 'English',
          subtitle: 'Fluent',
        },
        {
          title: 'Spanish',
          subtitle: 'Intermediate',
        },
      ],
    },
  ],
}
