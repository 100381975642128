import { useFormContext } from 'react-hook-form'

const Input = ({
  name,
  className,
  label,
  type = 'text',
  placeholder = '',
  handleKeyDown = () => {},
}) => {
  const { register } = useFormContext()
  return (
    <div className={className}>
      {label && (
        <label htmlFor={name} className="block text-sm dark:text-white">
          {label}
        </label>
      )}
      <input
        {...register(name)}
        onKeyDown={handleKeyDown}
        type={type}
        id={name}
        className="py-2 px-0 block w-full border-t-0 border-r-0 border-l-0 border-b border-gray-200 placeholder-gray-300 text-gray-700 bg-white text focus:ring-0 focus:border-gray-200"
        placeholder={placeholder || label}
      />
    </div>
  )
}

export default Input
