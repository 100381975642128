import _ from 'lodash'
import { useEffect, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { flushSync } from 'react-dom'
import DropComponent from './DropComponent'

function moveItem(arr, from, to, setItems) {
  const arrClone = _.clone(arr)
  // remove `from` item and store it
  var f = arrClone.splice(from, 1)[0]
  // insert stored item into position `to`
  arrClone.splice(to, 0, f)
  flushSync(() => {
    setItems(arrClone)
  })
}

function moveItemToArray(fromArr, toArr, from, to, setFromArr, setToArr) {
  const fromArrClone = _.clone(fromArr)
  const toArrClone = _.clone(toArr)

  var f = fromArrClone.splice(from, 1)[0]

  toArrClone.splice(to, 0, f)

  flushSync(() => {
    setToArr(toArrClone)
    setFromArr(fromArrClone)
  })
}

const parseItem = (item) => {
  if (item.startsWith('custom:')) {
    return item.slice(7, -25)
  }
  return item
}

const capitalizeFirstLetter = (word) =>
  word.charAt(0).toUpperCase() + word.slice(1)

function SectionOrdering(props) {
  const order = props.resume.order
  const columnOne = order[0].map((item, idx) => {
    return {
      id: item,
      value: capitalizeFirstLetter(parseItem(item)),
    }
  })
  const columnTwo = order[1]?.map((item, idx) => {
    return {
      id: item,
      value: capitalizeFirstLetter(parseItem(item)),
    }
  })
  const [items, setItems] = useState(columnOne)
  const [items2, setItems2] = useState(columnTwo)

  useEffect(() => {
    reset()
  }, [props.resume.order])

  const tmpOrder = [items.map((item) => item.id)]
  if (items2) {
    tmpOrder.push(items2.map((item) => item.id))
  }
  const isDirty = !_.isEqual(order, tmpOrder)

  const reset = () => {
    setItems(columnOne)
    setItems2(columnTwo)
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.droppableId === result.source.droppableId) {
      if (result.destination.droppableId === 'droppable1') {
        moveItem(items, result.source.index, result.destination.index, setItems)
      } else {
        moveItem(
          items2,
          result.source.index,
          result.destination.index,
          setItems2
        )
      }
    } else {
      if (result.source.droppableId === 'droppable1') {
        moveItemToArray(
          items,
          items2,
          result.source.index,
          result.destination.index,
          setItems,
          setItems2
        )
      } else {
        moveItemToArray(
          items2,
          items,
          result.source.index,
          result.destination.index,
          setItems2,
          setItems
        )
      }
    }
  }

  return (
    <div className="col-span-2 border rounded p-4">
      <div className="flex h-8 items-center justify-center mb-8">
        <h2 className="flex-1 text-md font-semibold text-gray-700">
          Section Ordering
        </h2>
        <div className="flex gap-3">
          <button
            onClick={reset}
            disabled={!isDirty}
            className="py-1.5 px-5 text-sm bg-gray-100 disabled:opacity-60 border rounded"
          >
            Cancel
          </button>
          <button
            onClick={() => props.updateOrder(tmpOrder)}
            disabled={!isDirty}
            className="py-1.5 px-5 text-sm bg-blue-600 disabled:opacity-60 text-white border rounded"
          >
            Save
          </button>
        </div>
      </div>
      <div className="mb-4 text-center">
        Drag and drop to rearrange the sections
      </div>
      <div className="grid gap-4 bg-white border rounded shadow-lg p-6">
        <div className="border-2 h-16 border-gray-300 flex justify-center items-center text-md rounded">
          Profile
        </div>
        <div className="flex gap-4">
          <DragDropContext onDragEnd={onDragEnd}>
            <DropComponent items={items} droppableId="droppable1" />
            {items2 && (
              <DropComponent items={items2} droppableId="droppable2" />
            )}
          </DragDropContext>
        </div>
      </div>
    </div>
  )
}

export default SectionOrdering
