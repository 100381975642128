export const resume = {
  basics: {
    firstName: 'Alex',
    lastName: 'Johnson',
    title: 'Continuous Integration/Continuous Deployment Engineer',
    picture: 'https://example.com/alex-johnson.jpg',
    email: 'alex.johnson@example.com',
    phone: '555-123-4567',
    url: 'https://www.alexjohnson-ci-cd.dev',
    summary:
      'Experienced CI/CD Engineer with a track record of designing and implementing efficient and reliable continuous integration and deployment pipelines. Skilled in automation, testing, and optimizing software development workflows.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/alexjohnson',
    portfolioUrl: 'https://portfolio.alexjohnson-ci-cd.dev',
  },
  experience: [
    {
      company: 'TechNexus Innovations',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Senior CI/CD Engineer',
      url: 'https://www.technexusinnovations.com',
      startDate: '2020-06-15',
      endDate: '2023-08-31',
      summary: null,
      highlights: [
        'Designed and implemented Jenkins-based CI/CD pipelines, reducing deployment time by 40% and increasing deployment frequency.',
        'Automated build, test, and deployment processes using Docker and Kubernetes, resulting in a 30% reduction in manual intervention and deployment errors.',
        'Collaborated with cross-functional teams to define and implement CI/CD best practices, improving overall software quality and stability.',
        'Implemented infrastructure as code (IaC) using Terraform to provision and manage development and production environments.',
      ],
      present: false,
    },
    {
      company: 'InnoTech Solutions',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'CI/CD Engineer',
      url: 'https://www.innotechsolutions.com',
      startDate: '2018-09-01',
      endDate: '2020-05-31',
      summary: null,
      highlights: [
        'Built and maintained Jenkins pipelines for multiple projects, enabling automated testing and deployment.',
        'Integrated automated testing frameworks, resulting in a 50% reduction in regression bugs and improved code quality.',
        'Developed scripts for infrastructure provisioning using Ansible, reducing deployment time by 25%.',
      ],
      present: false,
    },
    {
      company: 'CodeCrafters LLC',
      location: {
        city: 'Austin',
        countryCode: 'US',
        region: 'Texas',
      },
      position: 'Software Development Intern',
      url: 'https://www.codecraftersllc.com',
      startDate: '2017-05-15',
      endDate: '2017-08-15',
      summary: null,
      highlights: [
        'Contributed to the development of a CI/CD pipeline for a web application using GitLab CI/CD.',
        'Assisted in automating unit and integration tests, resulting in improved code quality and faster development cycles.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Software Engineering',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2016-09-01',
      endDate: '2018-05-15',
      gpa: '3.9',
      courses: [
        'Software Architecture',
        'Distributed Systems',
        'Agile Development',
        'Software Testing and Quality Assurance',
      ],
      summary: null,
      present: false,
    },
    {
      institution: 'University of Washington',
      degree: 'Bachelor of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      startDate: '2012-08-01',
      endDate: '2016-05-15',
      gpa: '3.8',
      courses: [
        'Data Structures and Algorithms',
        'Object-Oriented Programming',
        'Software Engineering',
        'Operating Systems',
      ],
      summary: null,
      present: false,
    },
  ],
  awards: [
    {
      title: 'Excellence in CI/CD Award',
      date: '2022-07-10',
      awarder: 'TechNexus Innovations',
      summary:
        'Recognized for outstanding contributions to continuous integration and deployment practices.',
    },
    {
      title: 'Software Engineering Scholarship',
      date: '2016-03-20',
      awarder: 'Stanford University',
      summary:
        'Received a scholarship for excellence in software engineering studies.',
    },
  ],
  skills: [
    {
      skillGroup: 'Continuous Integration/Continuous Deployment',
      skill: [
        { name: 'Jenkins' },
        { name: 'GitLab CI/CD' },
        { name: 'Docker' },
        { name: 'Kubernetes' },
        { name: 'Terraform' },
      ],
    },
    {
      skillGroup: 'Automation',
      skill: [
        { name: 'Ansible' },
        { name: 'Bash Scripting' },
        { name: 'Python Scripting' },
      ],
    },
    {
      skillGroup: 'Version Control',
      skill: [{ name: 'Git' }, { name: 'GitHub' }],
    },
  ],
  projects: [
    {
      name: 'Microservices Deployment',
      description:
        'Implemented a CI/CD pipeline for deploying microservices-based applications using Kubernetes.',
      startDate: '2021-01-15',
      endDate: '2021-06-30',
      present: false,
    },
    {
      name: 'Infrastructure as Code',
      description:
        'Developed infrastructure as code (IaC) templates using Terraform for provisioning cloud resources.',
      startDate: '2019-09-01',
      endDate: '2020-03-15',
      present: false,
    },
    {
      name: 'Automated Testing Framework',
      description:
        'Created an automated testing framework for unit and integration testing of a web application.',
      startDate: '2018-03-01',
      endDate: '2018-06-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Languages',
      details: [
        {
          title: 'English',
          subtitle: 'Native',
        },
        {
          title: 'Spanish',
          subtitle: 'Intermediate',
        },
      ],
    },
    {
      name: 'Certifications',
      details: [
        {
          title: 'Certified Kubernetes Administrator',
          subtitle: 'Cloud Native Computing Foundation',
          location: {
            city: 'Online',
            countryCode: 'US',
          },
          startDate: '2021-05-01',
          endDate: 'No Expiry',
          summary:
            'Demonstrated expertise in Kubernetes administration and orchestration.',
        },
        {
          title: 'AWS Certified DevOps Engineer – Professional',
          subtitle: 'Amazon Web Services',
          location: {
            city: 'Online',
            countryCode: 'US',
          },
          startDate: '2020-07-15',
          endDate: 'No Expiry',
          summary:
            'Certification showcasing advanced knowledge of AWS DevOps practices.',
        },
      ],
    },
  ],
}
