import HtmlContent from '../../HtmlContent'
import SafeText from '../../SafeText'
import ValueMapper from '../ValueMapper'
import { getSubtitle } from '../utils'

class EducationValueMapper extends ValueMapper {
  constructor(education, styles) {
    super()
    this.education = education
    this.styles = styles
  }

  title() {
    return this.education.institution
  }

  subtitle() {
    return getSubtitle(
      this.education.location,
      this.education.startDate,
      this.education.endDate,
      this.education.present,
      this.education.gpa
    )
  }

  text() {
    return this.education.degree
  }

  children() {
    return (
      <SafeText val={this.education.summary} style={this.styles?.highlights}>
        <HtmlContent styles={this.styles?.htmlContent}>
          {this.education.summary}
        </HtmlContent>
      </SafeText>
    )
  }
}

export default EducationValueMapper
