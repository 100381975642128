export const resume = {
  basics: {
    firstName: 'Alex',
    lastName: 'Williams',
    title: 'Big Data Engineer',
    picture: 'https://example.com/alex-williams.jpg',
    email: 'alex.williams@example.com',
    phone: '123-456-7890',
    url: 'https://www.alexwilliams.dev',
    summary:
      'Results-driven Big Data Engineer with extensive experience in designing, building, and optimizing large-scale data processing systems. Skilled in creating data pipelines, managing distributed data platforms, and implementing real-time analytics solutions. Passionate about leveraging big data technologies to drive data-driven insights and business growth.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/alexwilliams',
    portfolioUrl: 'https://portfolio.alexwilliams.dev',
  },
  experience: [
    {
      company: 'Lyft',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Big Data Engineer',
      url: 'https://www.lyft.com',
      startDate: '2020-03-15',
      endDate: '2023-06-30',
      summary: null,
      highlights: [
        'Designed and implemented ETL processes using Apache Spark, processing over 10TB of raw data daily.',
        'Optimized data processing jobs to reduce processing time by 40%, leading to faster analytics insights.',
        'Built real-time data streaming pipelines with Apache Kafka, enabling instant analysis of streaming data.',
        'Migrated on-premises data infrastructure to cloud-based platforms, resulting in improved scalability and cost efficiency.',
      ],
      present: false,
    },
    {
      company: 'Box',
      location: {
        city: 'Redwood City',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Data Engineer',
      url: 'https://www.box.com',
      startDate: '2018-07-01',
      endDate: '2020-02-28',
      summary: null,
      highlights: [
        'Developed and maintained data integration pipelines using Apache Airflow, handling data from multiple sources.',
        'Designed and managed NoSQL databases such as Apache Cassandra, enabling efficient storage and retrieval of unstructured data.',
        'Collaborated with cross-functional teams to define data requirements and optimize data models for reporting and analytics.',
      ],
      present: false,
    },
    {
      company: 'Zillow',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Junior Data Engineer',
      url: 'https://www.zillow.com',
      startDate: '2016-04-15',
      endDate: '2018-06-30',
      summary: null,
      highlights: [
        'Assisted in the creation of data pipelines using Hadoop and Apache Pig, enabling efficient data processing and analysis.',
        'Participated in data quality improvement projects, identifying and rectifying data anomalies and inconsistencies.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'University of Texas at Austin',
      degree: 'Master of Science in Computer Science',
      major: 'Big Data Systems',
      location: {
        city: 'Austin',
        countryCode: 'US',
        region: 'Texas',
      },
      startDate: '2014-09-01',
      endDate: '2016-05-15',
      gpa: '3.85',
      courses: [
        'Distributed Computing',
        'Big Data Analytics',
        'Advanced Data Structures',
        'Machine Learning',
      ],
      summary:
        'Gained advanced knowledge in big data technologies, distributed computing, and machine learning.',
      present: false,
    },
    {
      institution: 'University of Illinois at Urbana-Champaign',
      degree: 'Bachelor of Science in Computer Engineering',
      major: 'Computer Engineering',
      location: {
        city: 'Urbana-Champaign',
        countryCode: 'US',
        region: 'Illinois',
      },
      startDate: '2010-08-01',
      endDate: '2014-05-15',
      gpa: '3.75',
      courses: [
        'Database Systems',
        'Computer Networks',
        'Software Engineering',
        'Algorithms',
      ],
      summary: null,
      present: false,
    },
  ],
  awards: [
    {
      title: 'Excellence in Big Data Engineering',
      date: '2022-02-15',
      awarder: 'Tech Innovators Association',
      summary:
        'Recognized for outstanding contributions to the field of big data engineering and analytics.',
    },
    {
      title: 'Data Engineering Achievement Award',
      date: '2019-05-01',
      awarder: 'University of Texas at Austin',
      summary:
        'Received academic recognition for exceptional performance and contributions to data engineering projects.',
    },
  ],
  skills: [
    {
      skillGroup: 'Big Data Technologies',
      skill: [
        { name: 'Apache Spark' },
        { name: 'Hadoop' },
        { name: 'Apache Kafka' },
        { name: 'Apache Cassandra' },
      ],
    },
    {
      skillGroup: 'Data Processing',
      skill: [
        { name: 'ETL Development' },
        { name: 'Data Warehousing' },
        { name: 'Data Modeling' },
      ],
    },
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'Python' }, { name: 'Java' }, { name: 'Scala' }],
    },
    {
      skillGroup: 'Database Management',
      skill: [{ name: 'SQL' }, { name: 'NoSQL' }, { name: 'Data Governance' }],
    },
    {
      skillGroup: 'Cloud Platforms',
      skill: [
        { name: 'Amazon Web Services (AWS)' },
        { name: 'Google Cloud Platform (GCP)' },
      ],
    },
  ],
  projects: [
    {
      name: 'Real-Time Clickstream Analytics',
      description:
        'Designed and implemented a real-time clickstream analytics system using Apache Kafka and Apache Spark Streaming, enabling instant analysis of user interactions on the website.',
      startDate: '2022-01-15',
      endDate: '2022-07-30',
      present: false,
    },
    {
      name: 'Customer Segmentation',
      description:
        'Developed a data pipeline to perform customer segmentation based on behavioral data, enabling personalized marketing campaigns and improving customer engagement.',
      startDate: '2021-03-01',
      endDate: '2021-08-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Certifications',
      details: [
        {
          title: 'Certified Big Data Professional',
          subtitle: 'Data Science Institute',
          location: {
            city: 'New York',
            countryCode: 'US',
            region: 'New York',
          },
          url: 'https://www.datascienceinstitute.org',
          startDate: '2019-10-01',
          endDate: '2023-10-01',
          summary:
            'Demonstrated expertise in big data technologies and data engineering through comprehensive certification.',
        },
        {
          title: 'AWS Certified Big Data - Specialty',
          subtitle: 'Amazon Web Services',
          location: {
            city: 'Seattle',
            countryCode: 'US',
            region: 'Washington',
          },
          url: 'https://aws.amazon.com/certification',
          startDate: '2018-08-01',
          endDate: '2023-08-01',
          summary:
            'Validated skills in designing and implementing big data solutions on the AWS platform.',
        },
      ],
    },
  ],
}
