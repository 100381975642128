export const resume = {
  basics: {
    firstName: 'Lila',
    lastName: 'Nguyen',
    title: 'Site Reliability Engineer',
    picture: 'https://example.com/lila-nguyen.jpg',
    email: 'lila.nguyen@example.com',
    phone: '987-654-3210',
    url: 'https://www.lilanguyen.dev',
    summary:
      'Dedicated Site Reliability Engineer with a proven track record of designing and implementing reliable, scalable, and highly available infrastructure solutions. Skilled in automating operational tasks, optimizing performance, and ensuring system resiliency. Passionate about driving efficiency and enhancing the user experience.',
    location: {
      city: 'Seattle',
      countryCode: 'US',
      region: 'Washington',
    },
    linkedInUrl: 'https://www.linkedin.com/in/lilanguyen',
    portfolioUrl: 'https://portfolio.lilanguyen.dev',
  },
  experience: [
    {
      company: 'Atlassian',
      location: {
        city: 'Mountain View',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Site Reliability Engineer',
      url: 'https://www.atlassian.com',
      startDate: '2020-05-15',
      endDate: '2023-08-31',
      summary: null,
      highlights: [
        'Automated deployment processes using Kubernetes, reducing deployment time by 30% and minimizing manual errors.',
        'Implemented advanced monitoring and alerting systems, resulting in a 20% reduction in mean-time-to-detect incidents.',
        'Optimized database performance by tuning query performance and implementing caching mechanisms, leading to a 25% reduction in database response time.',
        'Collaborated with development teams to design and implement disaster recovery strategies, achieving 99.99% uptime for critical services.',
      ],
      present: false,
    },
    {
      company: 'Twilio',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Site Reliability Engineer',
      url: 'https://www.twilio.com',
      startDate: '2018-09-01',
      endDate: '2020-04-30',
      summary: null,
      highlights: [
        'Developed and maintained infrastructure-as-code templates using Terraform, enabling consistent and repeatable deployment of services.',
        'Reduced system downtime by 15% through proactive monitoring, capacity planning, and performance optimization.',
        'Implemented CI/CD pipelines for automated testing and deployment, resulting in a 40% reduction in release cycles.',
        'Collaborated with cross-functional teams to conduct disaster recovery testing, ensuring data integrity and business continuity.',
      ],
      present: false,
    },
    {
      company: 'Dropbox',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Site Reliability Engineer (Intern)',
      url: 'https://www.dropbox.com',
      startDate: '2017-06-01',
      endDate: '2017-08-31',
      summary: null,
      highlights: [
        'Automated routine operational tasks using Ansible, reducing manual effort and improving system efficiency.',
        'Participated in capacity planning and load testing to ensure optimal performance during peak usage periods.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'University of Washington',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      startDate: '2016-09-01',
      endDate: '2018-05-31',
      gpa: '3.9',
      courses: ['Distributed Systems', 'Cloud Computing', 'Network Security'],
      summary: '',
      present: false,
    },
    {
      institution: 'University of California, Berkeley',
      degree:
        'Bachelor of Science in Electrical Engineering and Computer Sciences',
      major: 'Electrical Engineering and Computer Sciences',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2012-08-15',
      endDate: '2016-05-15',
      gpa: '3.8',
      courses: [
        'Operating Systems',
        'Computer Networks',
        'Database Management',
      ],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Google Cloud Professional Cloud Architect',
      date: '2022-03-15',
      awarder: 'Google Cloud',
      summary:
        'Earned the Professional Cloud Architect certification, showcasing expertise in designing and managing scalable and reliable solutions on Google Cloud Platform.',
    },
    {
      title: 'AWS Certified DevOps Engineer - Professional',
      date: '2021-06-01',
      awarder: 'Amazon Web Services (AWS)',
      summary:
        'Achieved the AWS Certified DevOps Engineer - Professional certification, demonstrating proficiency in designing, deploying, and managing applications on AWS.',
    },
  ],
  skills: [
    {
      skillGroup: 'Cloud Platforms',
      skill: [
        { name: 'Amazon Web Services (AWS)' },
        { name: 'Google Cloud Platform (GCP)' },
      ],
    },
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'Python' }, { name: 'Java' }],
    },
    {
      skillGroup: 'Infrastructure as Code',
      skill: [{ name: 'Terraform' }, { name: 'Ansible' }, { name: 'Puppet' }],
    },
    {
      skillGroup: 'Containerization',
      skill: [{ name: 'Docker' }, { name: 'Kubernetes' }],
    },
  ],
  projects: [
    {
      name: 'Scalable Microservices Deployment',
      description:
        'Designed and implemented a microservices architecture using Kubernetes for automatic scaling and load balancing, resulting in 30% cost savings and improved application performance.',
      startDate: '2022-01-01',
      endDate: '2022-06-30',
      present: false,
    },
    {
      name: 'Automated Incident Response',
      description:
        'Developed an incident response automation platform using Python and Ansible, reducing incident resolution time by 40% and minimizing human errors.',
      startDate: '2021-02-01',
      endDate: '2021-08-31',
      present: false,
    },
    {
      name: 'Infrastructure Monitoring and Alerting',
      description:
        'Led a project to implement a comprehensive monitoring and alerting system using Prometheus and Grafana, enabling real-time visibility into system health and performance.',
      startDate: '2020-03-01',
      endDate: '2020-09-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Certifications',
      details: [
        {
          title: 'Google Cloud Professional Cloud Architect',
          subtitle: 'Certification',
          location: {
            city: 'Online',
            countryCode: 'US',
            region: 'Virtual',
          },
          url: 'https://cloud.google.com/certification/cloud-architect',
          startDate: '2022-03-15',
          endDate: '2025-03-15',
          summary:
            'Certified Professional Cloud Architect with expertise in designing and managing scalable and highly available solutions on Google Cloud Platform.',
        },
        {
          title: 'AWS Certified DevOps Engineer - Professional',
          subtitle: 'Certification',
          location: {
            city: 'Online',
            countryCode: 'US',
            region: 'Virtual',
          },
          url: 'https://aws.amazon.com/certifications/devops-engineer-professional/',
          startDate: '2021-06-01',
          endDate: '2024-06-01',
          summary:
            'AWS Certified DevOps Engineer - Professional with advanced skills in building, deploying, and managing applications on AWS.',
        },
      ],
    },
  ],
}
