import Autosave from 'components/Forms/EditorV2/Autosave'
import Selector from 'components/Forms/EditorV2/SectionSelector'
import _ from 'lodash'
import { createContext, useEffect, useRef, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { customizeTabs, templateTabs, templates } from 'utils/defs'
import { classNames } from 'utils/helper'
import ContentView from './ContentView'
import Form from './Form'
import SectionOrdering from './customize-sections/section-ordering'
import Color from './customize-sections/color'
import {
  CheckIcon,
  ColorSwatchIcon,
  DocumentIcon,
  DocumentTextIcon,
  PencilAltIcon,
} from '@heroicons/react/outline'

export const ContentLeftPanelContext = createContext()

function ContentLeftPanel({ selectedItem, setSelectedItem }) {
  const customSections = useWatch({ name: 'customSections' })
  const [dropdownState, setDropdownState] = useState({})
  const dropdownAllOpen =
    Object.keys(dropdownState).length === 0 &&
    dropdownState.constructor === Object

  const expandAll = () => {
    setDropdownState({})
  }

  const collapseAll = () => {
    const collapsedState = {
      basics: true,
      personal: true,
      education: true,
      experience: true,
      projects: true,
      skills: true,
    }
    customSections.forEach((section) => (collapsedState[section['_id']] = true))
    setDropdownState(collapsedState)
  }
  return (
    <ContentLeftPanelContext.Provider
      value={{ dropdownState, setDropdownState, selectedItem, setSelectedItem }}
    >
      <div className="flex flex-col flex-1 overflow-y-hidden">
        <div className="px-6 py-4 flex items-center gap-1 shadow border-b">
          <div className="flex-1 font-semibold">Content</div>
          {dropdownAllOpen ? (
            <button
              onClick={collapseAll}
              className="flex items-center rounded text-blue-500 text-sm font-medium px-2 py-1"
            >
              Collapse all
            </button>
          ) : (
            <button
              onClick={expandAll}
              className="flex items-center rounded text-blue-500 text-sm font-medium px-2 py-1"
            >
              Expand all
            </button>
          )}
        </div>
        <ContentView />
      </div>
    </ContentLeftPanelContext.Provider>
  )
}

function ContentPanels(props) {
  const methods = useForm({})
  const form = methods.getValues()

  useEffect(() => {
    methods.reset(props.resume)
  }, [])

  const [selectedItem, setSelectedItem] = useState({
    section: 'basics',
  })

  if (_.isEmpty(form)) {
    return <TwoPanelLayout leftPanel={null} rightPanel={null} />
  }
  return (
    <FormProvider {...methods}>
      <TwoPanelLayout
        leftPanel={
          <ContentLeftPanel
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        }
        rightPanel={
          <form className="h-full">
            <Autosave
              initialValues={props.resume}
              onSubmit={(data) => props.updateResume(data)}
            />
            {selectedItem && <Form selectedItem={selectedItem} />}
          </form>
        }
      />
    </FormProvider>
  )
}

function TemplatePanels(props) {
  const [selectedTab, setSelectedTab] = useState(0)

  const TemplateDisplay = ({ template, idx, isSelected }) => {
    return (
      <div
        className={classNames(
          isSelected ? 'border-blue-500' : '',
          'relative border hover:border-blue-500 rounded overflow-hidden'
        )}
      >
        {isSelected && (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-8 h-8 bg-blue-500 rounded-full flex items-center justify-center">
              <CheckIcon className="w-5 h-5 text-white" />
            </div>
          </div>
        )}
        <img
          className=""
          alt="template"
          src={require(`assets/img/Template${idx}.png`)}
        />
        <div className="bg-gray-50 px-2 border-t w-full flex justify-between items-center">
          <div className="font-medium text-sm mt-2 mb-2">{template.name}</div>
          <div className="flex gap-1">
            {template.premium ? (
              <span className="bg-yellow-200 py-0.5 px-2 rounded text-xs">
                Premium
              </span>
            ) : (
              <span className="bg-blue-200 py-0.5 px-2 rounded text-xs">
                Free
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }
  return (
    <TwoPanelLayout
      leftPanel={
        null
        // <div className="flex flex-col mt-4">
        //   <div className="px-6 font-semibold text-lg mb-4">Select Template</div>
        //   {templateTabs.map((tab, idx) => (
        //     <button
        //       key={idx}
        //       onClick={() => setSelectedTab(idx)}
        //       className={classNames(
        //         selectedTab === idx ? 'bg-gray-100' : '',
        //         'px-6 py-3 font-medium hover:bg-gray-100 text-left'
        //       )}
        //     >
        //       {tab.name}
        //     </button>
        //   ))}
        // </div>
      }
      rightPanel={
        <div className="py-4 flex flex-col overflow-auto">
          <div className="col-span-4 h-8 font-semibold px-6 flex items-center mb-6">
            <h2 className="flex-1 text-lg">Resume Templates</h2>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 px-6">
            {templates.map((template, i) => {
              const isSelected = props.resume.template === i
              return (
                <div
                  key={i}
                  onClick={() => props.selectTemplate(props.resume.id, i)}
                  className={classNames(
                    'cursor-pointer w-full flex flex-col justify-center items-center'
                  )}
                >
                  <TemplateDisplay
                    isSelected={isSelected}
                    template={template}
                    idx={i}
                  />
                </div>
              )
            })}
          </div>
        </div>
      }
    />
  )
}

function CustomizePanels(props) {
  const [selectedTab, setSelectedTab] = useState(0)

  const content = () => {
    // switch (selectedTab) {
    //   case 0:
    //     return <SectionOrdering {...props} />
    //   case 1:
    //     return <Color {...props} />
    //   default:
    //     return null
    // }
    return (
      <>
        <SectionOrdering {...props} />
        <Color {...props} />
      </>
    )
  }

  return (
    <TwoPanelLayout
      leftPanel={
        null
        // <div className="flex flex-col mt-4">
        //   <div className="px-6 font-semibold text-lg mb-4">Customize</div>
        //   {customizeTabs.map((tab, idx) => (
        //     <button
        //       key={idx}
        //       onClick={() => setSelectedTab(idx)}
        //       className={classNames(
        //         selectedTab === idx ? 'bg-gray-100' : '',
        //         'px-6 py-3 font-medium hover:bg-gray-100 flex justify-between items-center'
        //       )}
        //     >
        //       {tab.name}
        //       {tab.premium && (
        //         <span className="text-xs bg-yellow-200 py-0.5 px-3 rounded-full">
        //           Premium
        //         </span>
        //       )}
        //     </button>
        //   ))}
        // </div>
      }
      rightPanel={
        <div className="pt-4 flex flex-col h-full">
          <div className="grid grid-cols-2 gap-8 px-6">{content()}</div>
        </div>
      }
    />
  )
}

function TwoPanelLayout({ leftPanel, rightPanel }) {
  const rightPanelRef = useRef()

  useEffect(() => {
    // rightPanelRef.current.scrollTop = 0
  }, [rightPanel])

  return (
    <div className="w-full flex flex-1 overflow-y-hidden">
      {leftPanel && (
        <div className="hidden flex-none w-64 h-full bg-white border-r sm:flex flex-col">
          {leftPanel}
        </div>
      )}
      <div className="flex-none w-full sm:w-[32rem] h-full border-r flex flex-col">
        <div ref={rightPanelRef} className="flex-1 overflow-y-scroll">
          {rightPanel}
        </div>
      </div>
    </div>
  )
}

export default function PanelLayout({ ...props }) {
  const [selectedTab, setSelectedTab] = useState(0)

  const layouts = [
    {
      name: 'Edit',
      Icon: PencilAltIcon,
      component: <ContentPanels {...props} />,
    },
    {
      name: 'Template',
      Icon: DocumentTextIcon,
      component: <TemplatePanels {...props} />,
    },
    {
      name: 'Customize',
      Icon: ColorSwatchIcon,
      component: <CustomizePanels {...props} />,
    },
  ]

  return (
    <div className="flex w-full sm:w-auto">
      <div className="flex-none">
        <Selector
          tabs={layouts}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      {layouts[selectedTab].component}
    </div>
  )
}
