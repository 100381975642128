import { EditorContext } from 'layouts/Dashboard/EditorLayout'
import { useContext, useState } from 'react'
import { TwitterPicker } from 'react-color'

const Color = () => {
  const { resume, updateColor } = useContext(EditorContext)
  const currColor = resume.color || '#000000'
  const [color, setColor] = useState(currColor)
  const isDirty = currColor !== color

  const handleChangeComplete = (color, event) => {
    setColor(color.hex)
  }

  const handleSave = () => {
    updateColor(resume._id, color)
  }

  const handleCancel = () => {
    setColor(currColor)
  }

  const handleReset = () => {
    setColor('#000000')
    updateColor(resume._id, '#000000')
  }

  return (
    <div className="col-span-2 border rounded p-4">
      <div className="flex h-8 items-center justify-center mb-8">
        <h2 className="flex-1 text-md font-semibold text-gray-700">Color</h2>
        <div className="flex gap-3">
          <button
            onClick={handleReset}
            disabled={color === '#000000'}
            className="py-1.5 px-2 text-sm text-blue-500 disabled:text-blue-300 rounded"
          >
            Clear
          </button>
          <button
            onClick={handleCancel}
            disabled={!isDirty}
            className="py-1.5 px-5 text-sm bg-gray-100 disabled:opacity-60 rounded border"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            // onClick={() => props.updateOrder(tmpOrder)}
            disabled={!isDirty}
            className="py-1.5 px-5 text-sm bg-blue-600 disabled:opacity-60 text-white border rounded"
          >
            Save
          </button>
        </div>
      </div>
      <div className="">
        <TwitterPicker
          width="100%"
          triangle="hide"
          color={color}
          colors={[
            '#000000',
            '#F9BC2F',
            '#FD8D30',
            '#F3613E',
            '#FF5E5E',
            '#E13F79',
            '#FFB6C1',
            '#8B1540',
            '#8B1B65',
            '#9554C8',
            '#5B7FC5',
            '#3A8DEF',
            '#87CEFA',
            '#15ABAB',
            '#6DB56D',
            '#6F5392',
            '#2C446F',
            '#20349F',
            '#359EBF',
            '#468F92',
            '#B2D1C9',
            '#005842',
          ]}
          onChangeComplete={handleChangeComplete}
        />
      </div>
    </div>
  )
}

export default Color
