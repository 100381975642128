export const resume = {
  basics: {
    firstName: 'Carlos',
    lastName: 'Garcia',
    title: 'Android Developer',
    picture: 'https://example.com/profile_picture.jpg',
    email: 'carlosgarcia@email.com',
    phone: '123-456-7890',
    url: 'https://carlosgarcia.com',
    summary:
      'Skilled Android Developer with a strong passion for creating user-friendly and high-performance mobile applications. Proficient in Java, Kotlin, and Android Studio.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'CA',
    },
    linkedInUrl: 'https://www.linkedin.com/in/carlosgarcia',
    portfolioUrl: 'https://github.com/carlosgarcia',
  },
  experience: [
    {
      company: 'Google',
      location: {
        city: 'Mountain View',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'Android Developer',
      url: 'https://www.google.com',
      startDate: '2020-01-01',
      endDate: '2023-07-31',
      summary:
        "Developed and maintained user-centric and high-quality Android applications for Google's ecosystem.",
      highlights: [
        'Designed and implemented interactive user interfaces using Java and Kotlin, ensuring a seamless user experience.',
        'Collaborated with backend teams to integrate RESTful APIs and optimize data retrieval and storage.',
        'Optimized app performance, resulting in a 25% reduction in app crashes and improved overall stability.',
      ],
      present: false,
    },
    {
      company: 'Facebook',
      location: {
        city: 'Menlo Park',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'Android Software Engineer',
      url: 'https://www.facebook.com',
      startDate: '2017-06-01',
      endDate: '2019-12-31',
      summary:
        "Contributed to the development of feature-rich and user-friendly Android applications for Facebook's social platform.",
      highlights: [
        'Implemented new features and enhancements using Kotlin and Android Jetpack components, improving user engagement.',
        'Collaborated closely with design teams to ensure consistent UI/UX and seamless integration of design assets.',
        'Participated in code reviews and provided mentoring to junior developers, improving overall code quality and knowledge sharing.',
      ],
      present: false,
    },
    {
      company: 'Amazon',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'WA',
      },
      position: 'Android Developer Intern',
      url: 'https://www.amazon.com',
      startDate: '2015-02-15',
      endDate: '2015-05-31',
      summary:
        "Contributed to the development of Amazon's Android applications, focusing on UI enhancements and performance optimization.",
      highlights: [
        'Developed and maintained Android app features using Java and Android Studio.',
        'Collaborated with product managers to implement new functionalities and improve user experience.',
        'Assisted in conducting testing and debugging, ensuring high-quality and bug-free app releases.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'CA',
      },
      startDate: '2013-09-01',
      endDate: '2015-05-15',
      gpa: '3.9',
      courses: [
        'Mobile App Development',
        'User Interface Design',
        'Software Engineering',
      ],
      summary: 'Graduated with Honors',
      present: false,
    },
  ],
  skills: [
    {
      skillGroup: 'Programming Languages',
      skill: [
        {
          name: 'Java',
        },
        {
          name: 'Kotlin',
        },
        {
          name: 'C++',
        },
      ],
    },
    {
      skillGroup: 'Android Development',
      skill: [
        {
          name: 'Android Studio',
        },
        {
          name: 'Android Jetpack',
        },
        {
          name: 'Firebase',
        },
      ],
    },
    {
      skillGroup: 'Version Control',
      skill: [
        {
          name: 'Git',
        },
        {
          name: 'SVN',
        },
      ],
    },
    {
      skillGroup: 'UI/UX Design',
      skill: [
        {
          name: 'XML Layouts',
        },
        {
          name: 'Material Design',
        },
        {
          name: 'User Testing',
        },
      ],
    },
    {
      skillGroup: 'App Architecture',
      skill: [
        {
          name: 'MVVM',
        },
        {
          name: 'MVP',
        },
        {
          name: 'Clean Architecture',
        },
      ],
    },
    {
      skillGroup: 'Testing and Debugging',
      skill: [
        {
          name: 'Android Profiler',
        },
        {
          name: 'Unit Testing',
        },
        {
          name: 'Espresso',
        },
      ],
    },
  ],
  projects: [
    {
      name: 'Travel Guide App',
      description:
        'Developed a travel guide app for Android, providing users with information on popular travel destinations and attractions.',
      startDate: '2019-02-01',
      endDate: '2020-05-31',
      present: false,
    },
    {
      name: 'Social Media Chat App',
      description:
        'Designed and implemented a real-time chat application with multimedia sharing capabilities for Android users.',
      startDate: '2017-08-15',
      endDate: '2018-12-31',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Excellence in Android Development',
      date: '2022-03-10',
      awarder: 'Google',
      summary:
        'Recognized for outstanding contributions to Android app development and innovation.',
    },
    {
      title: 'Innovation Award',
      date: '2018-10-18',
      awarder: 'Facebook',
      summary:
        'Received for innovative solutions and impactful contributions to the development of user-centric Android applications.',
    },
  ],
  customSections: [],
}
