import ValueMapper from '../ValueMapper'
import { getCustomSectionTitle, getHighlights, getSubtitle } from '../utils'

class CustomSectionValueMapper extends ValueMapper {
  constructor(customSection, styles) {
    super()
    this.customSection = customSection
    this.styles = styles
  }

  title() {
    return getCustomSectionTitle(
      this.customSection.title,
      this.customSection.subtitle,
      this.styles
    )
  }

  subtitle() {
    return getSubtitle(
      this.customSection.location,
      this.customSection.startDate,
      this.customSection.endDate,
      this.customSection.present
    )
  }

  text() {
    return this.customSection.summary
  }

  children() {
    return getHighlights(this.customSection.highlights, this.styles)
  }
}

export default CustomSectionValueMapper
