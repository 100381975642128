import ValueMapper from '../ValueMapper'
import { getExperienceTitle, getHighlights, getSubtitle } from '../utils'

class ExperienceValueMapper extends ValueMapper {
  constructor(experience, styles) {
    super()
    this.experience = experience
    this.styles = styles
  }

  title() {
    return getExperienceTitle(this.experience.position, this.experience.company)
  }

  subtitle() {
    return getSubtitle(
      this.experience.location,
      this.experience.startDate,
      this.experience.endDate,
      this.experience.present
    )
  }

  text() {
    return this.experience.summary
  }

  children() {
    return getHighlights(this.experience.highlights, this.styles)
  }
}

export default ExperienceValueMapper
