import { blogPosts } from 'content/blog/register'
import Footer from 'partials/landing/Footer'
import NewHeader from 'partials/landing/NewHeader'
import { Navigate, Route, Routes } from 'react-router'
import { Link } from 'react-router-dom'
import BlogPost from './BlogPost'

export default function Blog(props) {
  return (
    <div className="flex flex-col">
      <main className="flex-grow">
        <NewHeader />
        <Routes>
          {blogPosts.map((blogPost) => {
            return (
              <Route
                path={`/${blogPost.url}`}
                element={<BlogPost post={blogPost} />}
              ></Route>
            )
          })}
          <Route
            path=""
            element={
              <div className="max-w-7xl mx-auto pt-8 pb-16 sm:pt-12 sm:pb-24 lg:pt-24 lg:pb-48 px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    The Career Guide
                  </h2>
                  <p className="mt-2 text-lg leading-8 text-gray-600">
                    Learn how to build a successful career with our expert
                    advice.
                  </p>
                </div>
                <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                  {blogPosts.map((blogPost) => {
                    return (
                      <article
                        key={blogPost.id}
                        className="flex max-w-xl flex-col items-start justify-between"
                      >
                        <div className="flex items-center gap-x-4 text-xs">
                          <time
                            dateTime={blogPost.datetime}
                            className="text-gray-500"
                          >
                            {blogPost.date}
                          </time>
                        </div>
                        <div className="group relative">
                          <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                            <Link to={`/career-guide/${blogPost.url}`}>
                              <span className="absolute inset-0" />
                              {blogPost.title}
                            </Link>
                          </h3>
                          <p className="mt-5 text-sm leading-6 text-gray-600 line-clamp-3">
                            {blogPost.description}
                          </p>
                        </div>
                        <div className="mt-3 flex items-center gap-x-4 text-xs">
                          <a
                            href={blogPost.category.href}
                            className="relative z-10 rounded-full bg-gray-50 py-1.5 px-3 font-medium text-gray-600 hover:bg-gray-100"
                          >
                            {blogPost.category.title}
                          </a>
                        </div>
                        {/* <div className="relative mt-8 flex items-center gap-x-4">
                        <img
                          src={blogPost.author.imageUrl}
                          alt=""
                          className="h-10 w-10 rounded-full bg-gray-50"
                        />
                        <div className="text-sm leading-6">
                          <p className="font-semibold text-gray-900">
                            <a href={blogPost.author.href}>
                              <span className="absolute inset-0" />
                              {blogPost.author.name}
                            </a>
                          </p>
                          <p className="text-gray-600">
                            {blogPost.author.role}
                          </p>
                        </div>
                      </div> */}
                      </article>
                    )
                  })}
                </div>
              </div>
            }
          />
          <Route path="*" element={<Navigate to="/career-guide" />} />
        </Routes>
        <Footer />
      </main>
    </div>
  )
}
