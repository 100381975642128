import { Field, FieldArray } from 'formik'
import { useState } from 'react'

import { AccordionPage } from 'components/Forms/Editor/Accordion'
import DatePickerField from 'components/Forms/Editor/DatePickerField'
import TitleInput from 'components/Forms/Editor/TitleInput'
import DeleteResumeModal from 'components/Modals/DeleteResumeModal'

import { MinusIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid'
import QuillInput from './common/QuillInput'

const RenderHeaderInput = ({ index, deleteSection }) => {
  const [isOpen, setIsOpen] = useState(false)

  const submitModal = () => {
    deleteSection(index)
    setIsOpen(false)
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <div className="flex justify-between">
      <div>
        <DeleteResumeModal
          isOpen={isOpen}
          closeModal={closeModal}
          submitModal={submitModal}
        />
        <TitleInput
          name={`customSections.${index}.name`}
          id={`customSections.${index}.name`}
        />
      </div>
      <div>
        <div className="pb-4 flex h-full justify-end items-center">
          <TrashIcon
            className="cursor-pointer inline h-5 w-5"
            onClick={openModal}
          />
        </div>
      </div>
    </div>
  )
}

function renderHighlights(props, field) {
  const { name, children } = props
  const { values } = props.form
  return (
    <div className="col-span-6 sm:col-span-12">
      <label
        htmlFor="highlights"
        className="block text-sm font-medium text-gray-700"
      >
        Bullet Points
      </label>
      <div className="mt-1">
        {field.highlights &&
          field.highlights.map((value, index, array) => {
            const arraySize = array.length
            return (
              <div key={index} className="flex mb-2 gap-2">
                <div className="flex-1">
                  <QuillInput
                    id={`${name}.${index}`}
                    name={`${name}.${index}`}
                    placeholder="Add bullet point"
                  />
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => props.remove(index)}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <MinusIcon
                      className="-ml-2 -mr-2 h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            )
          })}
        <div className="col-span-6 flex">
          <div className="mt-1 flex flex-col">
            <button
              type="button"
              onClick={() => props.push('')}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PlusIcon
                className="-ml-2 mr-1 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
              Add Bullet Point
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function CustomSectionForm(index, field, outerIndex) {
  return (
    <div className="m-4 grid grid-cols-6 md:grid-cols-12 gap-6">
      <div className="col-span-6 sm:col-span-12">
        <label
          htmlFor={`customSections.${outerIndex}.details.${index}.title`}
          className="block text-sm font-medium text-gray-700"
        >
          Title
        </label>
        <Field
          component="input"
          type="text"
          name={`customSections.${outerIndex}.details.${index}.title`}
          id={`customSections.${outerIndex}.details.${index}.title`}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md"
          placeholder="Entry title"
        />
      </div>

      <div className="col-span-6 sm:col-span-6">
        <label
          htmlFor={`customSections.${outerIndex}.details.${index}.subtitle`}
          className="block text-sm font-medium text-gray-700"
        >
          Subtitle
        </label>
        <Field
          component="input"
          type="text"
          name={`customSections.${outerIndex}.details.${index}.subtitle`}
          id={`customSections.${outerIndex}.details.${index}.subtitle`}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md"
          placeholder="Entry subtitle"
        />
      </div>

      <div className="col-span-4 sm:col-span-4">
        <label
          htmlFor={`customSections.${outerIndex}.details.${index}.location.city`}
          className="block text-sm font-medium text-gray-700"
        >
          City
        </label>
        <Field
          component="input"
          type="text"
          name={`customSections.${outerIndex}.details.${index}.location.city`}
          id={`customSections.${outerIndex}.details.${index}.location.city`}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md"
          placeholder="Entry city"
        />
      </div>

      <div className="col-span-2 sm:col-span-2">
        <label
          htmlFor={`customSections.${outerIndex}.details.${index}.location.region`}
          className="block text-sm font-medium text-gray-700"
        >
          State
        </label>
        <Field
          component="input"
          type="text"
          name={`customSections.${outerIndex}.details.${index}.location.region`}
          id={`customSections.${outerIndex}.details.${index}.location.region`}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md"
          placeholder="Entry state"
        />
      </div>

      <div className="col-span-3 sm:col-span-3 sm:col-start-1">
        <label
          htmlFor="start_date"
          className="block text-sm font-medium text-gray-700"
        >
          Start Date
        </label>
        <DatePickerField
          name={`customSections.${outerIndex}.details.${index}.startDate`}
          id={`customSections.${outerIndex}.details.${index}.startDate`}
          className="py-2 px-3 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md"
        />
      </div>

      <div className="col-span-3 sm:col-span-3">
        <label
          htmlFor="end_date"
          className="block text-sm font-medium text-gray-700"
        >
          End Date
        </label>
        <DatePickerField
          name={`customSections.${outerIndex}.details.${index}.endDate`}
          id={`customSections.${outerIndex}.details.${index}.endDate`}
          className="py-2 px-3 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md"
        />
      </div>

      <div className="col-span-6 sm:col-span-4">
        <label className="inline-flex items-center cursor-pointer md:mt-8">
          <Field
            component="input"
            type="checkbox"
            name={`customSections.${outerIndex}.details.${index}.present`}
            id={`customSections.${outerIndex}.details.${index}.present`}
            className="form-checkbox border rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
          />
          <span className="ml-2 text-sm font-semibold text-blueGray-600">
            Present
          </span>
        </label>
      </div>

      <div className="col-span-6 sm:col-span-12">
        <label
          htmlFor={`customSections.${outerIndex}.details.${index}.summary`}
          className="block text-sm font-medium text-gray-700"
        >
          Summary
        </label>
        <div className="mt-1">
          <QuillInput
            id={`customSections.${outerIndex}.details.${index}.summary`}
            name={`customSections.${outerIndex}.details.${index}.summary`}
            placeholder="Add a brief summary"
          />
        </div>
      </div>

      <FieldArray
        name={`customSections.${outerIndex}.details.${index}.highlights`}
        render={(arrayHelpers) => renderHighlights(arrayHelpers, field)}
      />
    </div>
  )
}

function CustomSectionsForm(props) {
  const { values } = props.form
  return values['customSections'].map((field, index) => {
    return (
      <div className="mt-5 md:mt-0 md:col-span-3" key={index}>
        <div className="overflow-hidden sm:rounded-md">
          <div className="px-4 sm:px-6">
            <FieldArray
              name={`customSections.${index}.details`}
              render={(arrayHelpers) => (
                <AccordionPage
                  {...arrayHelpers}
                  render={CustomSectionForm}
                  nestedArray="highlights"
                  outerIndex={index}
                  headerInput={
                    <RenderHeaderInput
                      index={index}
                      deleteSection={props.remove}
                    />
                  }
                  titleSelector="title"
                  subtitleSelector="subtitle"
                />
              )}
            />
          </div>
        </div>
      </div>
    )
  })
}

export default function MultipleCustomSectionsForm() {
  return (
    <FieldArray
      name="customSections"
      render={(arrayHelpers) => <CustomSectionsForm {...arrayHelpers} />}
    />
  )
}
