export const resume = {
  basics: {
    firstName: 'Alex',
    lastName: 'Chen',
    title: 'Natural Language Processing Engineer',
    picture: 'https://example.com/alex-chen.jpg',
    email: 'alex.chen@example.com',
    phone: '987-654-3210',
    url: 'https://www.alexchen-nlp.dev',
    summary:
      'Experienced Natural Language Processing Engineer with a strong background in developing and deploying NLP models to extract insights and drive meaningful business outcomes. Skilled in text classification, sentiment analysis, and language generation.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/alexchen',
    portfolioUrl: 'https://portfolio.alexchen-nlp.dev',
  },
  experience: [
    {
      company: 'TextTech Solutions',
      location: {
        city: 'New York',
        countryCode: 'US',
        region: 'New York',
      },
      position: 'Senior NLP Engineer',
      url: 'https://www.texttechsolutions.com',
      startDate: '2020-07-01',
      endDate: '2023-09-30',
      summary: null,
      highlights: [
        'Designed and implemented a sentiment analysis model for social media data, resulting in a 20% increase in user engagement and brand sentiment.',
        'Developed a chatbot using transformer-based language models, reducing customer support response time by 40% and enhancing user experience.',
        'Optimized Named Entity Recognition (NER) models for financial documents, achieving an 85% accuracy rate and enabling efficient information extraction.',
        'Collaborated with data scientists to integrate NLP models into recommendation systems, increasing click-through rates by 15% and revenue by 10%.',
      ],
      present: false,
    },
    {
      company: 'LinguoAI',
      location: {
        city: 'Toronto',
        countryCode: 'CA',
        region: 'Ontario',
      },
      position: 'NLP Researcher',
      url: 'https://www.linguoai.com',
      startDate: '2018-05-15',
      endDate: '2020-06-30',
      summary: null,
      highlights: [
        'Designed and implemented a text summarization model using transformer architectures, reducing document length by 80% while preserving key information.',
        'Developed a domain-specific chatbot for medical diagnosis, achieving a diagnosis accuracy of 90% compared to expert clinicians.',
        'Contributed to the creation of a multilingual language model, improving translation accuracy for low-resource languages by 25%.',
      ],
      present: false,
    },
    {
      company: 'CogniLabs',
      location: {
        city: 'Berlin',
        countryCode: 'DE',
        region: 'DE',
      },
      position: 'NLP Developer',
      url: 'https://www.cognilabs.ai',
      startDate: '2016-08-01',
      endDate: '2018-04-30',
      summary: null,
      highlights: [
        'Built a text classification system for customer reviews, categorizing feedback and enabling targeted product improvements, leading to a 15% increase in customer satisfaction.',
        'Developed a language generation model for automated content creation, reducing content production time by 60% and expanding content offerings.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Artificial Intelligence',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2014-09-01',
      endDate: '2016-05-15',
      gpa: '3.98',
      courses: [
        'Natural Language Processing',
        'Machine Learning',
        'Deep Learning',
        'Data Mining',
      ],
      summary: null,
      present: false,
    },
    {
      institution: 'University of Cambridge',
      degree: 'Bachelor of Arts in Linguistics',
      major: 'Linguistics',
      location: {
        city: 'Cambridge',
        countryCode: 'GB',
        region: 'England',
      },
      startDate: '2010-09-01',
      endDate: '2014-06-15',
      gpa: '3.85',
      courses: [
        'Syntax and Semantics',
        'Phonetics and Phonology',
        'Language Acquisition',
        'Computational Linguistics',
      ],
      summary: null,
      present: false,
    },
  ],
  awards: [
    {
      title: 'Outstanding NLP Researcher Award',
      date: '2022-11-15',
      awarder: 'Association for Computational Linguistics',
      summary:
        'Recognized for exceptional contributions to the field of natural language processing and innovative research outcomes.',
    },
    {
      title: 'Best NLP Application',
      date: '2019-07-01',
      awarder: 'AI Innovation Summit',
      summary:
        'Received award for developing an impactful and practical NLP application that demonstrated tangible business value.',
    },
  ],
  skills: [
    {
      skillGroup: 'NLP Techniques',
      skill: [
        { name: 'Text Classification' },
        { name: 'Sentiment Analysis' },
        { name: 'Named Entity Recognition (NER)' },
        { name: 'Topic Modeling' },
      ],
    },
    {
      skillGroup: 'Machine Learning',
      skill: [
        { name: 'Supervised Learning' },
        { name: 'Unsupervised Learning' },
        { name: 'Deep Learning' },
      ],
    },
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'Python' }, { name: 'Java' }, { name: 'R' }],
    },
    {
      skillGroup: 'Tools & Frameworks',
      skill: [{ name: 'NLTK' }, { name: 'spaCy' }, { name: 'TensorFlow' }],
    },
  ],
  projects: [
    {
      name: 'Contextual Chatbot',
      description:
        'Developed a context-aware chatbot using transformer-based models that provides natural and engaging conversations.',
      startDate: '2022-03-01',
      endDate: '2022-08-31',
      present: false,
    },
    {
      name: 'Multilingual Text Summarization',
      description:
        'Built a model for summarizing news articles in multiple languages, improving information retrieval and content consumption.',
      startDate: '2021-01-15',
      endDate: '2021-06-30',
      present: false,
    },
    {
      name: 'Emotion Recognition from Text',
      description:
        'Developed an emotion recognition system that accurately detects emotions expressed in social media posts and reviews.',
      startDate: '2020-03-01',
      endDate: '2020-08-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Languages',
      details: [
        {
          title: 'English',
          subtitle: 'Native',
        },
        {
          title: 'French',
          subtitle: 'Fluent',
        },
      ],
    },
    {
      name: 'Certifications',
      details: [
        {
          title: 'Deep Learning Specialization',
          subtitle: 'Coursera',
          location: {
            city: 'Online',
            countryCode: 'US',
          },
          startDate: '2019-09-01',
          endDate: '2019-12-01',
          summary:
            'Completed a comprehensive deep learning specialization covering neural networks, CNNs, RNNs, and generative models.',
        },
        {
          title: 'Natural Language Processing Certification',
          subtitle: 'DataCamp',
          location: {
            city: 'Online',
            countryCode: 'US',
          },
          startDate: '2018-03-01',
          endDate: '2018-05-01',
          summary:
            'Acquired hands-on skills in NLP techniques, including tokenization, POS tagging, and text classification.',
        },
      ],
    },
  ],
}
