import {
  ChevronRightIcon,
  DotsVerticalIcon,
  PlusIcon,
} from '@heroicons/react/solid'
import ObjectID from 'bson-objectid'
import Autosave from 'components/Forms/EditorV2/Autosave'
import SectionHeaderEditable from 'components/Forms/EditorV2/SectionHeaderEditable'
import { memo, useCallback, useContext, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { flushSync } from 'react-dom'
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form'
import { defaultValues, formFields } from 'utils/form/helper'
import { classNames } from 'utils/helper'
import { EditorContext } from '../EditorLayout'
import ActionMenuModal from './ActionMenuModal'
import ConfirmModal from './ConfirmModal'
import EditItemModal from './EditItemModal'
import Input from './Input'
import QuillInput from 'components/Forms/EditorV2/Quill'

const CustomSections = () => {
  const { fields, append, remove } = useFieldArray({ name: 'customSections' })
  return (
    <>
      {fields.map((field, idx) => {
        return (
          <CustomSection
            key={field.id}
            field={field}
            idx={idx}
            deleteAction={() => remove(idx)}
          />
        )
      })}
      <div className="bg-white mt-6">
        <div className="grid divide-y ml-4">
          <div
            onClick={() =>
              append({
                name: `Section Name`,
                details: [],
                _id: ObjectID().toHexString(),
              })
            }
            className="pr-2 py-3 flex items-center justify-center gap-1"
          >
            <PlusIcon className="w-4 h-4 text-blue-500" />
            <div>
              <p className="text-blue-500">Add custom section</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const CustomSection = ({ idx, deleteAction }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  return (
    <div>
      <ConfirmModal
        isOpen={confirmModalOpen}
        closeModal={() => setConfirmModalOpen(false)}
        confirmAction={() => {
          deleteAction()
          setConfirmModalOpen(false)
        }}
        idx={null}
      />
      <ActionMenuModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        actions={[
          // {
          //   name: 'Edit name',
          //   color: 'text-blue-500',
          //   action: () => {
          //     setIsOpen(false)
          //   },
          // },
          {
            name: 'Delete',
            color: 'text-red-500',
            action: () => {
              setIsOpen(false)
              setConfirmModalOpen(true)
            },
          },
        ]}
      />
      <Section
        title={
          <SectionHeaderEditable fieldPath={`customSections.${idx}.name`} />
        }
        name={`customSections.${idx}.details`}
        titleSelector="title"
        subtitleSelector="subtitle"
        formFields={Object.entries(formFields.custom)}
        isCustomSection
        openActionMenu={() => setIsOpen(true)}
      />
    </div>
  )
}

const Section = ({
  title,
  name,
  titleSelector,
  subtitleSelector,
  formFields = [],
  isCustomSection = false,
  openActionMenu = null,
}) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    idx: undefined,
  })
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)
  const { control } = useFormContext()
  const { fields, append, remove, move } = useFieldArray({ control, name })
  const openModal = (idx) => {
    if (document.activeElement?.tagName === 'INPUT') {
      document.activeElement.blur()
    }
    setModalState({
      isOpen: true,
      idx,
    })
  }
  const closeModal = () => {
    setModalState({
      isOpen: false,
      idx: undefined,
    })
  }
  const addItem = () => {
    const values = isCustomSection
      ? defaultValues['custom']
      : defaultValues[name]
    const item = {
      _id: ObjectID().toHexString(),
      ...values,
    }
    append(item)
    openModal(fields.length)
  }

  const onDragEnd = useCallback((result) => {
    const { source, destination } = result
    if (!destination) {
      return
    }
    const from = source.index
    const to = destination.index
    flushSync(() => {
      move(from, to)
    })
  }, [])

  return (
    <div className="">
      <EditItemModal
        openConfirmModal={() => setConfirmIsOpen(true)}
        fields={formFields}
        title={title}
        name={name}
        idx={modalState.idx}
        isOpen={modalState.isOpen}
        closeModal={closeModal}
        isCustomSection={isCustomSection}
      />
      <ConfirmModal
        isOpen={confirmIsOpen}
        closeModal={() => {
          setConfirmIsOpen(false)
        }}
        confirmAction={(idx) => {
          closeModal()
          remove(idx)
          setConfirmIsOpen(false)
        }}
        idx={modalState.idx}
      />
      {isCustomSection && (
        <p className="text-xs font-medium uppercase px-4 mb-1">
          Custom Section
        </p>
      )}
      <h3 className="mb-3 font-semibold px-4 flex gap-1 items-center justify-between">
        {title}
        {openActionMenu && (
          <div onClick={() => openActionMenu()} className="p-1 rounded">
            <DotsVerticalIcon className="w-5 h-5" />
          </div>
        )}
      </h3>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="bg-white">
          <Droppable droppableId={name}>
            {(provided) => {
              return (
                <>
                  <div
                    ref={provided.innerRef}
                    className="flex flex-col divide-gray-300"
                    {...provided.droppableProps}
                  >
                    <LinkItems
                      // @ts-ignore
                      fields={fields}
                      name={name}
                      titleSelector={titleSelector}
                      subtitleSelector={subtitleSelector}
                      openModal={openModal}
                    />
                    {provided.placeholder}
                  </div>
                  <div
                    onClick={() => addItem()}
                    className="py-3 flex items-center justify-center gap-1"
                  >
                    <PlusIcon className="w-4 h-4 text-blue-500" />
                    <div>
                      <p className="text-blue-500">
                        Add{!isCustomSection && ` ${title.toLowerCase()}`}
                      </p>
                    </div>
                  </div>
                </>
              )
            }}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  )
}

const LinkItems = memo(function ({
  // @ts-ignore
  fields,
  // @ts-ignore
  name,
  // @ts-ignore
  titleSelector,
  // @ts-ignore
  subtitleSelector,
  // @ts-ignore
  openModal,
}) {
  const { watch } = useFormContext()
  return fields.map((field, idx) => (
    <LinkItem
      key={idx}
      idx={idx}
      itemId={field.id}
      openModal={() => openModal(idx)}
      title={titleSelector && watch(`${name}.${idx}.${titleSelector}`)}
      subtitle={subtitleSelector && watch(`${name}.${idx}.${subtitleSelector}`)}
    />
  ))
})

const LinkItem = ({ openModal, title, itemId, subtitle, idx }) => {
  return (
    <Draggable key={itemId} draggableId={itemId} index={idx}>
      {(provided, snapshot) => {
        return (
          <div
            className={classNames(
              snapshot.isDragging ? 'shadow-lg bg-gray-100' : 'bg-white',
              'pl-4 cursor-pointer'
            )}
            onClick={openModal}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div
              className={classNames(
                snapshot.isDragging ? '' : 'border-b',
                'flex justify-between select-none items-center pr-2 py-3'
              )}
            >
              <div className="text-left">
                <p className="">{title || '(Untitled)'}</p>
                {subtitle && (
                  <p className="mt-0.5 text-xs text-gray-500">{subtitle}</p>
                )}
              </div>
              <div className="flex pl-2 py-1 items-center justify-center text-gray-400">
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </div>
            </div>
          </div>
        )
      }}
    </Draggable>
  )
}

const MobileForm = () => {
  const { resume, updateResume } = useContext(EditorContext)
  const methods = useForm({
    defaultValues: resume,
  })
  const { handleSubmit } = methods

  const onSubmit = (data) => updateResume(data)

  return (
    <FormProvider {...methods}>
      <Autosave initialValues={resume} onSubmit={onSubmit} />
      <form onSubmit={handleSubmit(onSubmit)} className="grid gap-6">
        <div className="px-4 mt-4">
          <div className="bg-white rounded-lg p-3 grid grid-cols-6 gap-3">
            <Input className="col-span-6" name="name" label="Resume Name" />
          </div>
        </div>
        <div className="px-4">
          <h3 className="mb-3 font-semibold">Personal Information</h3>
          <div className="bg-white rounded-lg p-3 grid grid-cols-6 gap-3">
            <Input
              className="col-span-6"
              name="basics.title"
              label="Job Title"
            />
            <Input
              className="col-span-6"
              name="basics.firstName"
              label="First Name"
            />
            <Input
              className="col-span-6"
              name="basics.lastName"
              label="Last Name"
            />
            <Input className="col-span-6" name="basics.email" label="Email" />
            <Input className="col-span-6" name="basics.phone" label="Phone" />
            <Input
              className="col-span-6"
              name="basics.portfolioUrl"
              label="Professional URL"
            />
            <Input
              className="col-span-6"
              name="basics.linkedInUrl"
              label="LinkedIn URL"
            />
            <Input
              className="col-span-6"
              name="basics.url"
              label="Personal URL"
            />
            <div className="col-span-6">
              <label htmlFor="basics.summary" className="block text-sm">
                Summary
              </label>
              <div className="py-2">
                <QuillInput
                  name="basics.summary"
                  placeholder="Add summary or objective"
                />
              </div>
            </div>
          </div>
        </div>
        <Section
          formFields={Object.entries(formFields.experience)}
          title="Experience"
          name="experience"
          titleSelector="company"
          subtitleSelector="position"
        />
        <Section
          formFields={Object.entries(formFields.education)}
          title="Education"
          name="education"
          titleSelector="institution"
          subtitleSelector="degree"
        />
        <Section
          formFields={Object.entries(formFields.projects)}
          title="Projects"
          name="projects"
          titleSelector="name"
          subtitleSelector={null}
        />
        <Section
          formFields={Object.entries(formFields.skills)}
          title="Skills"
          name="skills"
          titleSelector="skillGroup"
          subtitleSelector={null}
        />
        <CustomSections />
      </form>
    </FormProvider>
  )
}

const EditTabContent = () => {
  return <MobileForm />
}

export default EditTabContent
