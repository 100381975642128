export const resume = {
  basics: {
    firstName: 'John',
    lastName: 'Smith',
    title: 'Systems Engineer',
    picture: 'https://example.com/john-smith.jpg',
    email: 'john.smith@example.com',
    phone: '123-456-7890',
    url: 'https://www.johnsmith.dev',
    summary:
      'Results-driven systems engineer with a strong background in designing, implementing, and maintaining complex IT systems. Skilled in optimizing performance, ensuring reliability, and enhancing security for mission-critical infrastructure. Experienced in collaborating with cross-functional teams to deliver solutions that align with business objectives.',
    location: {
      city: 'Austin',
      countryCode: 'US',
      region: 'Texas',
    },
    linkedInUrl: 'https://www.linkedin.com/in/johnsmith',
    portfolioUrl: 'https://portfolio.johnsmith.dev',
  },
  experience: [
    {
      company: 'Dell Technologies',
      location: {
        city: 'Round Rock',
        countryCode: 'US',
        region: 'Texas',
      },
      position: 'Systems Engineer',
      url: 'https://www.delltechnologies.com',
      startDate: '2020-06-15',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Led a team of 5 engineers in migrating 50+ servers to a virtualized environment, resulting in a 30% reduction in hardware costs and a 25% improvement in resource utilization.',
        'Implemented disaster recovery strategies, reducing recovery time by 40% and achieving 99.99% uptime during critical incidents.',
        'Automated system monitoring and alerting using Nagios, leading to a 20% reduction in incident response time and improved overall system stability.',
        'Collaborated with cross-functional teams to develop and execute a cybersecurity strategy, resulting in a 30% reduction in security vulnerabilities.',
        'Provided technical expertise and training to junior engineers, enhancing their skills in systems administration and troubleshooting.',
      ],
      present: false,
    },
    {
      company: 'Hewlett Packard Enterprise',
      location: {
        city: 'Houston',
        countryCode: 'US',
        region: 'Texas',
      },
      position: 'Systems Administrator',
      url: 'https://www.hpe.com',
      startDate: '2018-02-01',
      endDate: '2020-05-31',
      summary: null,
      highlights: [
        'Performed capacity planning and implemented hardware upgrades, resulting in a 20% increase in system performance and responsiveness.',
        'Collaborated with software development teams to provision and manage development and testing environments, reducing deployment time by 30%.',
        'Led the implementation of a robust backup and recovery solution, reducing data loss by 95% during system failures.',
        'Developed and documented standard operating procedures for routine maintenance and troubleshooting, improving operational efficiency.',
      ],
      present: false,
    },
    {
      company: 'IBM',
      location: {
        city: 'Dallas',
        countryCode: 'US',
        region: 'Texas',
      },
      position: 'Junior Systems Engineer',
      url: 'https://www.ibm.com',
      startDate: '2016-07-01',
      endDate: '2018-01-31',
      summary: null,
      highlights: [
        'Collaborated with senior engineers to troubleshoot and resolve complex technical issues, achieving a 95% customer satisfaction rate.',
        'Managed user accounts and access control using Active Directory, maintaining data security and compliance with company policies.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Texas A&M University',
      degree: 'Bachelor of Science in Computer Engineering',
      major: 'Computer Engineering',
      location: {
        city: 'College Station',
        countryCode: 'US',
        region: 'Texas',
      },
      startDate: '2012-08-15',
      endDate: '2016-05-31',
      gpa: '3.7',
      courses: [
        'Computer Networks',
        'Operating Systems',
        'Digital Systems Design',
      ],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Excellence in Systems Engineering Award',
      date: '2022-08-15',
      awarder: 'Texas Engineering Association',
      summary:
        'Recognized for outstanding contributions and achievements in the field of systems engineering.',
    },
  ],
  skills: [
    {
      skillGroup: 'Operating Systems',
      skill: [{ name: 'Linux' }, { name: 'Windows Server' }],
    },
    {
      skillGroup: 'Infrastructure Management',
      skill: [{ name: 'VMware' }, { name: 'Hyper-V' }, { name: 'Nagios' }],
    },
    {
      skillGroup: 'Networking',
      skill: [
        { name: 'TCP/IP' },
        { name: 'Firewalls' },
        { name: 'Routing and Switching' },
      ],
    },
    {
      skillGroup: 'Scripting and Automation',
      skill: [{ name: 'Bash' }, { name: 'PowerShell' }],
    },
    {
      skillGroup: 'Security',
      skill: [
        { name: 'Security Hardening' },
        { name: 'Vulnerability Management' },
      ],
    },
  ],
  projects: [
    {
      name: 'Infrastructure Automation',
      description:
        'Designed and implemented an automated infrastructure provisioning and configuration management solution using Ansible, reducing deployment time by 50%.',
      startDate: '2022-02-01',
      endDate: '2022-06-30',
      present: false,
    },
    {
      name: 'Network Redundancy Enhancement',
      description:
        'Led a team in optimizing network redundancy and failover mechanisms, resulting in a 40% improvement in network availability and minimal downtime.',
      startDate: '2021-05-01',
      endDate: '2021-09-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Certifications',
      details: [
        {
          title: 'Certified Information Systems Security Professional (CISSP)',
          subtitle: 'ISC2',
          location: {
            city: 'Online',
            countryCode: 'Global',
            region: 'Virtual',
          },
          url: 'https://www.isc2.org',
          startDate: '2021-03-01',
          endDate: '2024-03-01',
          summary:
            'Achieved CISSP certification, demonstrating expertise in information security and risk management.',
          highlights: [
            'Covers domains such as security and risk management, asset security, security architecture, and more.',
          ],
          present: false,
        },
      ],
    },
  ],
}
