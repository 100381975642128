export const resume = {
  basics: {
    firstName: 'Natalie',
    lastName: 'Chen',
    title: 'Database Engineer',
    picture: 'https://example.com/natalie-chen.jpg',
    email: 'natalie.chen@example.com',
    phone: '987-654-3210',
    url: 'https://www.nataliechen.dev',
    summary:
      'Results-oriented Database Engineer with a strong background in designing, implementing, and optimizing database systems. Proven expertise in SQL development, performance tuning, and data modeling. Adept at collaborating with cross-functional teams to ensure data integrity, security, and availability.',
    location: {
      city: 'Seattle',
      countryCode: 'US',
      region: 'Washington',
    },
    linkedInUrl: 'https://www.linkedin.com/in/nataliechen',
    portfolioUrl: 'https://portfolio.nataliechen.dev',
  },
  experience: [
    {
      company: 'Expedia Group',
      location: {
        city: 'Bellevue',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Database Engineer',
      url: 'https://www.expedia.com',
      startDate: '2021-04-15',
      endDate: '2023-03-31',
      summary: null,
      highlights: [
        'Developed and implemented complex SQL queries and stored procedures, improving data retrieval speed by 30%.',
        'Collaborated with application development teams to design and optimize database schemas, reducing query response times by 20%.',
        'Implemented automated backup and disaster recovery solutions, ensuring data availability and minimizing downtime.',
        'Conducted regular performance tuning and analysis, resulting in a 25% reduction in database resource consumption.',
      ],
      present: false,
    },
    {
      company: 'Tableau Software',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Database Analyst',
      url: 'https://www.tableau.com',
      startDate: '2019-08-01',
      endDate: '2021-02-28',
      summary: null,
      highlights: [
        'Collaborated with data engineering teams to identify and resolve performance bottlenecks, resulting in a 15% improvement in dashboard loading times.',
        'Designed and implemented data access controls and security measures to ensure compliance with data protection regulations.',
        'Participated in cross-functional discussions to define data requirements and recommend best practices for data modeling and storage.',
      ],
      present: false,
    },
    {
      company: 'DocuSign',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Database Developer',
      url: 'https://www.docusign.com',
      startDate: '2017-05-15',
      endDate: '2019-06-30',
      summary: null,
      highlights: [
        'Designed and optimized database schemas for document storage and retrieval, leading to a 25% reduction in document search times.',
        'Implemented data validation checks to improve data quality and prevent data integrity issues.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'University of Maryland, College Park',
      degree: 'Master of Science in Computer Science',
      major: 'Database Management',
      location: {
        city: 'College Park',
        countryCode: 'US',
        region: 'Maryland',
      },
      startDate: '2015-09-01',
      endDate: '2017-05-15',
      gpa: '3.90',
      courses: [
        'Advanced Database Systems',
        'Data Warehousing',
        'Big Data Analytics',
        'Distributed Databases',
      ],
      summary:
        'Acquired in-depth knowledge of database management concepts and techniques, specializing in database design, optimization, and administration.',
      present: false,
    },
    {
      institution: 'University of California, Irvine',
      degree: 'Bachelor of Science in Information and Computer Science',
      major: 'Database Management',
      location: {
        city: 'Irvine',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2011-08-01',
      endDate: '2015-05-15',
      gpa: '3.80',
      courses: [
        'Introduction to Database Management',
        'Data Modeling',
        'Database Administration',
        'Data Mining',
      ],
      summary: null,
      present: false,
    },
  ],
  awards: [
    {
      title: 'Excellence in Database Management',
      date: '2022-01-15',
      awarder: 'Tech Innovators Association',
      summary:
        'Recognized for outstanding contributions to the field of database management and optimization.',
    },
    {
      title: 'Database Innovation Award',
      date: '2018-04-01',
      awarder: 'University of Maryland, College Park',
      summary:
        'Received academic recognition for innovative research and projects in database management.',
    },
  ],
  skills: [
    {
      skillGroup: 'Database Technologies',
      skill: [
        { name: 'SQL Server' },
        { name: 'MySQL' },
        { name: 'Oracle Database' },
      ],
    },
    {
      skillGroup: 'Data Modeling',
      skill: [{ name: 'ER Modeling' }, { name: 'Normalization' }],
    },
    {
      skillGroup: 'Performance Tuning',
      skill: [
        { name: 'Query Optimization' },
        { name: 'Indexing' },
        { name: 'Database Partitioning' },
      ],
    },
    {
      skillGroup: 'Data Security',
      skill: [{ name: 'Data Encryption' }, { name: 'Access Control' }],
    },
  ],
  projects: [
    {
      name: 'Global Sales Reporting System',
      description:
        'Led the design and implementation of a centralized sales reporting system, enabling real-time tracking and analysis of global sales data.',
      startDate: '2022-03-01',
      endDate: '2022-09-30',
      present: false,
    },
    {
      name: 'Customer Data Integration',
      description:
        'Designed and executed a data integration solution to consolidate customer information from multiple sources, resulting in improved data accuracy and customer insights.',
      startDate: '2021-01-15',
      endDate: '2021-06-30',
      present: false,
    },
    {
      name: 'Healthcare Data Analytics',
      description:
        'Collaborated with a healthcare team to develop a data analytics platform for analyzing patient records and medical trends, contributing to data-driven decision-making in healthcare.',
      startDate: '2020-05-01',
      endDate: '2020-11-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Professional Memberships',
      details: [
        {
          title: 'Member',
          subtitle: 'Association for Computing Machinery (ACM)',
          location: {
            city: 'New York',
            countryCode: 'US',
            region: 'New York',
          },
          url: 'https://www.acm.org',
          startDate: '2017-01-01',
          endDate: '2023-03-31',
          summary:
            'Active participant in ACM events, conferences, and discussions on database technologies and advancements.',
        },
        {
          title: 'Member',
          subtitle: 'Data Management Association (DAMA)',
          location: {
            city: 'Chicago',
            countryCode: 'US',
            region: 'Illinois',
          },
          url: 'https://www.dama.org',
          startDate: '2018-04-01',
          endDate: '2023-03-31',
          summary:
            'Engaged in knowledge-sharing and networking opportunities with data management professionals.',
        },
      ],
    },
  ],
}
