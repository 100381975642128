import { StyleSheet } from '@react-pdf/renderer'
import HorizontalHeader from '../common/header/HorizontalHeader'
import TwoColumnLayout from '../common/layouts/TwoColumnLayout'
import DefaultTemplateEntry from '../common/template-entry/DefaultTemplateEntry'
import DefaultTemplateSection from '../common/template-section/DefaultTemplateSection'
import CustomSectionValueMapper from '../common/value-mappers/custom-section/CustomSectionValueMapper'
import EducationValueMapper from '../common/value-mappers/education/EducationValueMapper'
import ExperienceValueMapper from '../common/value-mappers/experience/ExperienceValueMapper'
import ProjectValueMapper from '../common/value-mappers/project/ProjectValueMapper'
import SkillValueMapper from '../common/value-mappers/skill/SkillValueMapper'

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 50,
    paddingVertical: 40,
    fontFamily: 'Open Sans',
  },
  leftColumn: { flexDirection: 'column', flex: 1, display: 'flex' },
  rightColumn: {
    flexDirection: 'column',
    flex: 2,
    display: 'flex',
    paddingLeft: 20,
  },
  sectionTitle: {
    fontFamily: 'Open Sans',
    fontSize: 9,
    marginBottom: 10,
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  entryContainer: {
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 8,
    fontFamily: 'Open Sans',
    flexDirection: 'row',
    marginBottom: 4,
  },
  text: {
    lineHeight: 1.5,
    marginBottom: 1,
  },
  title: {
    fontSize: 9.5,
    color: 'black',
    fontFamily: 'Open Sans',
    marginBottom: 1,
    fontWeight: 600,
  },
  titleSecondary: {
    fontFamily: 'Open Sans',
    marginLeft: 2,
    fontWeight: 400,
  },
  highlights: {
    lineHeight: 1.5,
  },
  htmlContent: {
    fontFamily: 'Open Sans',
    fontSize: 9,
  },
  item: {
    marginBottom: 1,
  },
  itemContent: {
    lineHeight: 1.5,
  },
})

const SharpConfigs = {
  styles: styles,
  Layout: TwoColumnLayout,
  Header: HorizontalHeader,
  entryTransformerMap: {
    education: EducationValueMapper,
    experience: ExperienceValueMapper,
    projects: ProjectValueMapper,
    skills: SkillValueMapper,
    custom: CustomSectionValueMapper,
  },
  TemplateSection: DefaultTemplateSection,
  TemplateEntry: DefaultTemplateEntry,
  settings: {},
}

export default SharpConfigs
