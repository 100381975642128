export const resume = {
  basics: {
    firstName: 'Eli',
    lastName: 'Robinson',
    title: 'Research Engineer',
    picture: 'https://example.com/profile_picture.jpg',
    email: 'elirobinson@email.com',
    phone: '123-456-7890',
    url: 'https://elirobinson.com',
    summary:
      'Dedicated and innovative Research Engineer with a strong background in scientific research, experimental design, and technology development. Proven expertise in translating theoretical concepts into practical applications.',
    location: {
      city: 'Cambridge',
      countryCode: 'US',
      region: 'MA',
    },
    linkedInUrl: 'https://www.linkedin.com/in/elirobinson',
    portfolioUrl: 'https://github.com/elirobinson',
  },
  experience: [
    {
      company: 'MIT Research Labs',
      location: {
        city: 'Cambridge',
        countryCode: 'US',
        region: 'MA',
      },
      position: 'Research Engineer',
      url: 'https://www.mit.edu',
      startDate: '2020-01-01',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Led the design and construction of a novel experimental setup for quantum computing research, achieving 15% improvement in measurement accuracy.',
        'Collaborated with multidisciplinary teams to develop innovative solutions and prototypes, resulting in patent applications and industry partnerships.',
        'Conducted data analysis and simulations using Python and MATLAB to validate theoretical models and optimize experimental parameters.',
      ],
      present: false,
    },
    {
      company: 'IBM Research',
      location: {
        city: 'Yorktown Heights',
        countryCode: 'US',
        region: 'NY',
      },
      position: 'Research Associate',
      url: 'https://www.ibm.com/research',
      startDate: '2017-06-01',
      endDate: '2019-12-31',
      summary: null,
      highlights: [
        'Designed and characterized nanoscale devices using electron microscopy and atomic force microscopy, enabling the development of next-generation materials.',
        'Developed process workflows and conducted fabrication processes in cleanroom environments, achieving high-quality and reproducible device performance.',
        'Published research findings in peer-reviewed journals and presented at conferences, contributing to the advancement of scientific knowledge.',
      ],
      present: false,
    },
    {
      company: 'InnovationTech Labs',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'Research Intern',
      url: 'https://www.innovationtechlabs.com',
      startDate: '2015-02-15',
      endDate: '2015-05-31',
      summary: null,
      highlights: [
        'Assisted senior researchers in designing and conducting experiments related to solar cell efficiency enhancement, resulting in a 10% increase in energy conversion efficiency.',
        'Conducted data collection and analysis using Python and Excel, presenting findings to project teams and contributing to decision-making processes.',
        'Collaborated with engineers to prototype and test novel device concepts, providing valuable insights for technology development.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Massachusetts Institute of Technology (MIT)',
      degree: 'Ph.D. in Electrical Engineering',
      major: 'Electrical Engineering',
      location: {
        city: 'Cambridge',
        countryCode: 'US',
        region: 'MA',
      },
      startDate: '2013-09-01',
      endDate: '2019-05-15',
      gpa: '3.8',
      courses: ['Nanotechnology', 'Advanced Materials', 'Experimental Methods'],
      summary:
        'Doctoral Dissertation: "Advanced Nanoscale Devices for Quantum Computing"',
      present: false,
    },
  ],
  skills: [
    {
      skillGroup: 'Experimental Design',
      skill: [
        {
          name: 'Device Fabrication',
        },
        {
          name: 'Cleanroom Techniques',
        },
        {
          name: 'Experimental Setup',
        },
      ],
    },
    {
      skillGroup: 'Data Analysis',
      skill: [
        {
          name: 'Python',
        },
        {
          name: 'MATLAB',
        },
        {
          name: 'Data Visualization',
        },
      ],
    },
    {
      skillGroup: 'Nanotechnology',
      skill: [
        {
          name: 'Nanofabrication',
        },
        {
          name: 'Electron Microscopy',
        },
        {
          name: 'Materials Characterization',
        },
      ],
    },
    {
      skillGroup: 'Technical Writing',
      skill: [
        {
          name: 'Research Papers',
        },
        {
          name: 'Conference Presentations',
        },
        {
          name: 'Patent Applications',
        },
      ],
    },
    {
      skillGroup: 'Collaboration',
      skill: [
        {
          name: 'Interdisciplinary Teams',
        },
        {
          name: 'Industry Partnerships',
        },
        {
          name: 'Project Management',
        },
      ],
    },
  ],
  projects: [
    {
      name: 'Quantum Computing Experiment',
      description:
        'Led the design and implementation of an experiment to demonstrate quantum entanglement and its potential applications.',
      startDate: '2019-02-01',
      endDate: '2020-05-31',
      present: false,
    },
    {
      name: 'Nanomaterials for Energy Storage',
      description:
        'Investigated the use of nanomaterials in energy storage devices, exploring novel approaches to improve efficiency and performance.',
      startDate: '2017-08-15',
      endDate: '2018-12-31',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Outstanding Research Contribution',
      date: '2022-03-10',
      awarder: 'MIT Research Labs',
      summary:
        'Recognized for exceptional research achievements and contributions to groundbreaking projects.',
    },
    {
      title: 'Innovation Award',
      date: '2018-10-18',
      awarder: 'IBM Research',
      summary:
        'Received for innovative research and technology development in the field of nanotechnology.',
    },
  ],
  customSections: [],
}
