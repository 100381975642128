class ValueMapper {
  title() {}

  subtitle() {}

  text() {}

  children() {}

  subheader() {}

  values() {
    return {
      title: this.title(),
      subtitle: this.subtitle(),
      text: this.text(),
      children: this.children(),
      subheader: this.subheader(),
    }
  }
}

export default ValueMapper
