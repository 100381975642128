import { Link } from 'react-router-dom'
import { TypeAnimation } from 'react-type-animation'

export default function Hero() {
  return (
    <div className="">
      <div className="relative isolate px-6 pt-14 lg:pt-32 lg:px-8">
        <div className="mx-auto max-w-6xl">
          <div className="mb-4 flex sm:justify-center">
            <p className="flex w-full text-xs sm:text-sm font-semibold uppercase justify-center">
              <span className="sm:flex-1 text-right">Best&nbsp;</span>
              <span className="flex-none text-gray-900">Resume Builder</span>
              <p className="flex-1 flex items-start justify-start text-gray-900">
                &nbsp;For&nbsp;
                <p className="px-1 bg-indigo-600 text-white">
                  <TypeAnimation
                    sequence={[
                      'Software Engineers',
                      1000,
                      'Sales',
                      1000,
                      'Students',
                      1000,
                      'Hospitality',
                      1000,
                      'Analysts',
                      1000,
                      'Designers',
                      1000,
                      'Consulting',
                      1000,
                      'Customer Service',
                      1000,
                      'Everyone',
                      1000,
                    ]}
                    cursor={false}
                    preRenderFirstString
                    repeat={Infinity}
                  />
                </p>
              </p>
            </p>
          </div>
          <div className="sm:text-center pb-16">
            <h1 className="text-5xl font-bold tracking-tight text-blueGray-900 sm:text-6xl mb-10">
              Elevate your resume to career highs
            </h1>
            <p className="max-w-5xl mx-auto text-xl font-medium leading-8 text-blueGray-700">
              Build your resume with confidence. Create a professional resume in
              15 minutes using proven, job-winning templates to land your next
              job faster and smoother.
            </p>
            <div className="mt-10 flex flex-col sm:flex-row sm:items-start justify-center gap-x-6 gap-y-1 text-center">
              <div className="flex flex-col gap-1">
                <Link
                  to="/auth/register"
                  className="rounded-md border-2 border-indigo-500 bg-indigo-500 px-8 py-5 text-sm font-semibold text-white shadow-sm hover:border-blueGray-900 hover:bg-indigo-600 transition-all duration-300"
                >
                  Get Started Free
                </Link>
                <span className="text-xs font-medium">
                  No credit card required.
                </span>
              </div>
              <Link
                to="/resume-examples"
                className="rounded-md sm:border-2 border-gray-800 px-8 py-5 text-sm font-semibold text-gray-800 sm:shadow-sm sm:hover:text-white sm:hover:bg-gray-800 transition-all duration-300"
              >
                See Examples
              </Link>
            </div>
          </div>
          <div>
            <div className="relative max-w-4xl mx-auto sm:px-8">
              <div className="relative">
                <div className="absolute w-full h-full top-1 left-1 border border-gray-900 bg-gray-900 rounded-xl" />
                <div className="relative border border-gray-900 rounded-xl p-1 bg-blueGray-100">
                  <img
                    className="border border-gray-200 rounded-xl"
                    src={require('assets/img/Editor.gif')}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
