import { Link, useNavigate } from 'react-router-dom'
import ContentLayout from './ContentLayout'
import Questions from 'partials/Questions'
import { useEffect, useState } from 'react'
import axios from 'axios'
import ThreeDotsLoadingScreen from 'components/LoadingScreens/ThreeDotsLoadingScreen'

const StarterCard = () => {
  return (
    <div className="bg-white border-4 border-transparent rounded-md">
      <div className="p-6 md:py-10 md:px-9">
        <div className="inline-block px-4 py-2 bg-gray-100 rounded-full">
          <h3 className="text-sm font-semibold text-gray-900">Starter</h3>
        </div>
        <p className="mt-5 text-5xl font-bold text-black">Free</p>
        <p className="mt-2 text-base text-gray-600">Forever</p>
        <ul className="flex flex-col mt-8 space-y-4">
          <li className="inline-flex items-center space-x-2">
            <svg
              className="flex-shrink-0 w-5 h-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-medium text-gray-900">
              {' '}
              Starter Templates{' '}
            </span>
            {/* <svg
                    className="w-4 h-4 ml-0.5 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg> */}
          </li>
          <li className="inline-flex items-center space-x-2">
            <svg
              className="flex-shrink-0 w-5 h-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-medium text-gray-900">
              {' '}
              Watermark-free Resumes{' '}
            </span>
          </li>
          <li className="inline-flex items-center space-x-2">
            <svg
              className="flex-shrink-0 w-5 h-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-medium text-gray-900">
              {' '}
              Unlimited PDF Downloads{' '}
            </span>
          </li>
          <li className="inline-flex items-center space-x-2">
            <svg
              className="flex-shrink-0 w-5 h-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-medium text-gray-400">
              {' '}
              Unlimited # of Resumes{' '}
            </span>
          </li>
          <li className="inline-flex items-center space-x-2">
            <svg
              className="flex-shrink-0 w-5 h-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-medium text-gray-400">
              {' '}
              Template Customizations{' '}
            </span>
          </li>
        </ul>
        <div className="inline-flex items-center justify-center w-full px-4 py-4 mt-8 font-semibold text-gray-800 transition-all duration-200 bg-gray-100 rounded-md">
          Current Plan
        </div>
      </div>
    </div>
  )
}

const PremiumCard = ({ stripeSessionId }) => {
  function redirectToStripe(stripeSessionId) {
    if (!stripeSessionId) {
      return
    }
    var stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC)
    stripe
      .redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: stripeSessionId,
      })
      .then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      })
  }
  return (
    <div className="bg-white border-4 border-indigo-600 rounded-md">
      <div className="p-6 md:py-10 md:px-9">
        <div className="inline-block px-4 py-2 bg-indigo-100 rounded-full">
          <h3 className="text-sm font-semibold text-indigo-600">Premium</h3>
        </div>
        <p className="mt-5 text-5xl font-bold text-black">$5</p>
        <p className="mt-2 text-base text-gray-600">/ month</p>
        <ul className="flex flex-col mt-8 space-y-4">
          <li className="inline-flex items-center space-x-2">
            <svg
              className="flex-shrink-0 w-5 h-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-medium text-gray-900">
              {' '}
              Starter & Premium Templates{' '}
            </span>
            {/* <svg
                    className="w-4 h-4 ml-0.5 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg> */}
          </li>
          <li className="inline-flex items-center space-x-2">
            <svg
              className="flex-shrink-0 w-5 h-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-medium text-gray-900">
              {' '}
              Watermark-free Resumes{' '}
            </span>
          </li>
          <li className="inline-flex items-center space-x-2">
            <svg
              className="flex-shrink-0 w-5 h-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-medium text-gray-900">
              {' '}
              Unlimited PDF Downloads{' '}
            </span>
          </li>
          <li className="inline-flex items-center space-x-2">
            <svg
              className="flex-shrink-0 w-5 h-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-medium text-gray-900">
              {' '}
              Unlimited # of Resumes{' '}
            </span>
          </li>
          <li className="inline-flex items-center space-x-2">
            <svg
              className="flex-shrink-0 w-5 h-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-medium text-gray-900">
              {' '}
              Template Customizations{' '}
            </span>
          </li>
        </ul>
        <button
          type="button"
          onClick={() => redirectToStripe(stripeSessionId)}
          className="inline-flex items-center justify-center w-full px-4 py-4 mt-8 font-semibold text-white transition-all duration-200 rounded-md bg-gradient-to-r from-fuchsia-600 to-blue-600 hover:opacity-80 focus:opacity-80"
        >
          Go Premium
        </button>
      </div>
    </div>
  )
}

const UpgradeContent = ({ stripeSessionId }) => {
  return (
    <div className="grid grid-cols-1 gap-6 mt-12 sm:grid-cols-2">
      <StarterCard />
      <PremiumCard stripeSessionId={stripeSessionId} />
    </div>
  )
}

const UpgradePage = () => {
  const navigate = useNavigate()
  const [isSubscribed, setIsSubscribed] = useState(undefined)
  const [stripeSessionId, setStripeSessionId] = useState(undefined)
  useEffect(() => {
    const fetchStripeSession = () => {
      axios
        .get('/payment/createSessionV2', {})
        .then((res) => {
          setIsSubscribed(res.data.isSubscribed)
          if (!res.data.isSubscribed) setStripeSessionId(res.data.session.id)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    fetchStripeSession()
  }, [])

  if (isSubscribed) {
    navigate('/dashboard/resumes')
    return null
  }
  if (isSubscribed === undefined) {
    return (
      <div className="h-full w-full">
        <ThreeDotsLoadingScreen />
      </div>
    )
  }
  return (
    <ContentLayout name="Upgrade to Premium" maxWidth="max-w-3xl">
      <p className="text-lg">
        Whether you're just getting started on your resume or looking to improve
        your job search, there's a plan for you. Upgrade to create resumes that
        will land you interview after interview!
      </p>
      <UpgradeContent stripeSessionId={stripeSessionId} />
      <Questions />
    </ContentLayout>
  )
}

export default UpgradePage
