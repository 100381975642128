import React, { useRef, useState } from 'react'
import ObjectId from 'bson-objectid'

import {
  ChevronDownIcon,
  ChevronUpIcon,
  DotsVerticalIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/solid'
import DeleteResumeModal from 'components/Modals/DeleteResumeModal'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

class DroppableComponent extends React.Component {
  render() {
    const {
      render,
      header,
      arr,
      activeItem,
      toggleActiveItem,
      openModal,
      titleSelector,
      subtitleSelector,
      outerIndex,
    } = this.props
    return (
      <Droppable droppableId="droppable">
        {(provided, snapshot) => {
          return (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {arr.map((field, index) => {
                return (
                  <Draggable
                    key={field._id}
                    draggableId={field._id}
                    index={index}
                  >
                    {(provided, snapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="pb-4"
                        >
                          <AccordionHeader
                            key={index}
                            header={header}
                            id={`item-${index}`}
                            index={index}
                            activeItem={activeItem}
                            onClick={() => {
                              toggleActiveItem(`item-${index}`)
                            }}
                            deleteEntry={openModal}
                            title={field[titleSelector]}
                            subtitle={field[subtitleSelector]}
                            provided={provided}
                          >
                            <Accordion id={`item-${index}`} isOpen={activeItem}>
                              {render(index, field, outerIndex)}
                            </Accordion>
                          </AccordionHeader>
                        </div>
                      )
                    }}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )
        }}
      </Droppable>
    )
  }
}

export const AccordionPage = (props) => {
  const { name, header, children, titleSelector, subtitleSelector } = props
  const { values } = props.form
  const [activeItem, setActiveItem] = useState('')
  let [isOpen, setIsOpen] = useState(false)
  let [args, setArgs] = useState([])
  let arr = values
  let nameArr = name.split('.')
  nameArr.map((val) => {
    arr = arr[val]
  })

  function toggleActiveItem(id) {
    setActiveItem((prevState) => (prevState !== id ? id : ''))
  }

  function addEntry() {
    if (props.nestedArray)
      props.push({ _id: ObjectId().toHexString(), [props.nestedArray]: [] })
    else props.push({ _id: ObjectId().toHexString() })
    toggleActiveItem(`item-${arr.length}`)
  }

  function deleteEntry(index) {
    props.remove(index)
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }
    if (result.source.index === result.destination.index) {
      return
    }
    if (activeItem === `item-${result.source.index}`) {
      toggleActiveItem(`item-${result.destination.index}`)
    } else if (activeItem === `item-${result.destination.index}`) {
      toggleActiveItem(`item-${result.source.index}`)
    }
    const { move } = props
    move(result.source.index, result.destination.index)
  }

  function formatAddButton() {
    if (header.endsWith('s')) {
      return header.substring(0, header.length - 1)
    }
    return header
  }

  function closeModal() {
    setIsOpen(false)
  }

  function submitModal(index) {
    deleteEntry(index)
    setIsOpen(false)
    toggleActiveItem(`item-${index}`)
  }

  function openModal(index) {
    setArgs([index])
    setIsOpen(true)
  }

  return (
    <div>
      <DeleteResumeModal
        args={args}
        isOpen={isOpen}
        closeModal={closeModal}
        submitModal={submitModal}
      />
      <div>
        {header && (
          <div className="pt-4 pb-8">
            <h2 className="text-xl font-bold leading-6 text-gray-900">
              {header}
            </h2>
          </div>
        )}
        {props.headerInput}
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <ul className="md:col-span-3">
          <DroppableComponent
            arr={arr}
            header={header}
            render={props.render}
            activeItem={activeItem}
            toggleActiveItem={toggleActiveItem}
            openModal={openModal}
            titleSelector={titleSelector}
            subtitleSelector={subtitleSelector}
            outerIndex={props.outerIndex}
          />
        </ul>
        <div className={style.placeholder} onClick={addEntry}>
          <div className="px-4 py-4 sm:px-6">
            <div className="flex justify-center">
              <div className="flex items-center text-base md:text-lg font-semibold text-indigo-500">
                <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true" />
                <span className=""> Add {header && formatAddButton()}</span>
              </div>
            </div>
          </div>
        </div>
      </DragDropContext>
    </div>
  )
}

/* Logic */
const style = {
  accordion: `overflow-x-hidden transition-height ease duration-300 text-gray-600`,
  accordionHeader: `group focus:outline-none border rounded block bg-white relative`,
  placeholder: `focus:outline-none border-2 border-dashed rounded block mb-4 cursor-pointer`,
}

const Accordion = ({ children, id, isOpen }) => {
  const ref = useRef()
  const inlineStyle = isOpen === id ? {} : { height: 0 }

  return (
    <div id={id} className={style.accordion} ref={ref} style={inlineStyle}>
      {children}
    </div>
  )
}

const AccordionHeader = ({
  activeItem,
  header,
  id,
  index,
  children,
  onClick,
  deleteEntry,
  title,
  subtitle,
  provided,
  ...rest
}) => {
  return (
    <li {...rest} className={style.accordionHeader}>
      <div className="absolute top-0 -left-6 md:-left-7">
        <div className="h-[72px] md:h-[80px] flex items-center">
          <div className="py-1 flex" {...provided.dragHandleProps}>
            <DotsVerticalIcon
              className="h-5 -mr-3 text-gray-500"
              aria-hidden="true"
            />
            <DotsVerticalIcon
              className="h-5 -ml-0.5 text-gray-500"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
      <div className="absolute top-0 -right-5 md:-right-6 pl-1">
        <div className="h-[72px] md:h-[80px] flex items-center">
          <button
            type="button"
            className={`py-1 ${activeItem === id ? 'visible' : 'invisible'}`}
            onClick={() => deleteEntry(index)}
          >
            <TrashIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div
        className="px-4 py-3 md:px-6 md:py-4 cursor-pointer min-h-[72px] md:min-h-[80px] flex items-center space-between"
        onClick={onClick}
      >
        <div className="flex flex-col flex-grow overflow-hidden select-none">
          <div className="text-sm md:text-base font-semibold text-indigo-600 truncate">
            {title || `${header} ${index + 1}`}
          </div>
          <div className="text-sm md:text-base truncate">{subtitle}</div>
        </div>
        <div className="flex-none flex lg:mt-0 lg:ml-4">
          <div className="flex items-center">
            <div>
              {activeItem === id ? (
                <ChevronUpIcon
                  className="-ml-1 -mr-1 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              ) : (
                <ChevronDownIcon
                  className="-ml-1 -mr-1 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {children}
    </li>
  )
}
