import {
  AdjustmentsIcon,
  CheckIcon,
  DocumentTextIcon,
  CheckCircleIcon as OutlineCheckCircleIcon,
  SelectorIcon,
  TemplateIcon,
} from '@heroicons/react/outline'
import { CheckCircleIcon, RefreshIcon } from '@heroicons/react/solid'
import { Formik, Form as FormikForm, useFormikContext } from 'formik'
import debounce from 'just-debounce-it'
import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Listbox, Transition } from '@headlessui/react'
import AddSection from 'components/Forms/Editor/AddSection'
import MultipleCustomSectionsForm from 'components/Forms/Editor/CustomSectionsForm'
import EducationsForm from 'components/Forms/Editor/EducationsForm'
import ExperiencesForm from 'components/Forms/Editor/ExperiencesForm'
import ProfileForm from 'components/Forms/Editor/ProfileForm'
import ProjectsForm from 'components/Forms/Editor/ProjectsForm'
import SkillsForm from 'components/Forms/Editor/SkillsForm'
import SectionOrder from './Customize/SectionOrder'
import Template from './Customize/Template'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AutoSave = ({ setOpen, debounceMs }) => {
  const formik = useFormikContext()
  const [prevState, setPrevState] = React.useState(null)
  const [initial, setInitial] = React.useState(true)
  const [updated, setUpdated] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const debouncedSubmit = React.useCallback(
    debounce(() => {
      setLoading(true)
      formik.submitForm().then(() => {
        setTimeout(() => setLoading(false), 500)
        setUpdated(true)
      })
    }, debounceMs),
    [debounceMs, formik.submitForm]
  )

  React.useEffect(() => {
    const currState = {
      name: formik.values.name,
      basics: formik.values.basics,
      customSections: _.clone(formik.values.customSections).sort((a, b) =>
        a._id > b._id ? 1 : -1
      ),
      education: formik.values.education,
      experience: formik.values.experience,
      projects: formik.values.projects,
      skills: formik.values.skills,
      color: formik.values.color,
    }
    if (!initial) {
      if (!_.isEqual(currState, prevState)) {
        debouncedSubmit()
      }
    } else {
      setInitial(false)
    }
    setPrevState(currState)
  }, [formik.values])

  return (
    <div className="h-full">
      <div className="px-4 border-l h-full flex items-center justify-center">
        {loading ? (
          <RefreshIcon
            className="animate-spin h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        ) : updated ? (
          <CheckCircleIcon
            className="h-5 w-5 text-green-500"
            aria-hidden="true"
          />
        ) : (
          <OutlineCheckCircleIcon
            className="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        )}
      </div>
    </div>
  )
}

function renderLeftColumn(tab) {
  switch (tab) {
    case 0:
    default:
      return [
        <ProfileForm />,
        <EducationsForm />,
        <ExperiencesForm />,
        <ProjectsForm />,
        <SkillsForm />,
        <MultipleCustomSectionsForm />,
        <AddSection />,
      ]
    case 1:
      return [<Template />]
    case 2:
      return [<SectionOrder />]
  }
}

const SelectOptions = [
  { id: 0, value: 'Content', icon: DocumentTextIcon },
  { id: 1, value: 'Template', icon: TemplateIcon },
  { id: 2, value: 'Customize', icon: AdjustmentsIcon },
]

function Selector(props) {
  const [selected, setSelected] = useState(SelectOptions[props.tab])

  const onChange = (value) => {
    props.setTab(value.id)
    setSelected(value)
  }

  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <div className="relative mt-1 mx-3">
          <Listbox.Button className="relative w-full cursor-pointer rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm sm:text-sm">
            <span className="flex items-center">
              <selected.icon className="w-6 h-6 flex-shrink-0" />
              <span className="ml-3 block truncate">{selected.value}</span>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <SelectorIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-20 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {SelectOptions.map((option) => (
                <Listbox.Option
                  key={option.id}
                  className={({ active }) =>
                    classNames(
                      active ? 'text-white bg-indigo-600' : 'text-gray-900',
                      'relative cursor-pointer select-none py-2 pl-3 pr-9'
                    )
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <div className="flex items-center">
                        <option.icon className="w-6 h-6 flex-shrink-0" />
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'ml-3 block truncate'
                          )}
                        >
                          {option.value}
                        </span>
                      </div>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}

function Tab(props) {
  if (props.selected) {
    return (
      <button
        type="button"
        className="h-full -bottom-px relative px-6 border-b-2 border-blue-600 text-blue-600 text-base flex items-center justify-center self-end"
        onClick={props.setTab}
      >
        {props.children}
      </button>
    )
  } else {
    return (
      <button
        type="button"
        className="h-full relative px-6 text-base flex items-center justify-center self-end"
        onClick={props.setTab}
      >
        {props.children}
      </button>
    )
  }
}

function Form(props) {
  const [tab, setTab] = useState(0)

  return (
    <Formik
      initialValues={props.initialValues}
      validateOnChange={false}
      onSubmit={(values, { setSubmitting }) => {
        return new Promise((resolve) =>
          setTimeout(() => {
            props.onSubmit(values)
            setSubmitting(false)
            resolve()
          }, 0)
        )
      }}
    >
      <FormikForm className="bg-white overflow-y-hidden h-full flex flex-col">
        <div className="z-10 flex-none sticky top-0 h-16 bg-white border-b flex items-center">
          <Link
            to="/dashboard/resumes"
            className="bg-indigo-600 h-full text-white inline-flex items-center px-4 hover:bg-indigo-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-7 w-7"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </Link>
          <div className="flex-1 flex items-center lg:items-end h-full">
            <div className="lg:hidden w-full">
              <Selector tab={tab} setTab={setTab} />
            </div>
            <div className="hidden h-full lg:flex px-2 lg:px-4">
              {SelectOptions.map((option) => (
                <Tab
                  selected={tab === option.id}
                  setTab={() => setTab(option.id)}
                >
                  {option.value}
                </Tab>
              ))}
            </div>
          </div>
          {/* <button
            type="button"
            className="px-3 py-2 flex items-center border bg-white lg:hidden rounded-md text-gray-600 mr-2 focus:outline-none focus:ring-2 focus:ring-white"
            onClick={() => props.setOpen(true)}
          >
            <span className="sr-only">Open panel</span>
            Preview
          </button> */}
          <AutoSave debounceMs={1000} />
        </div>
        <div className="overflow-y-scroll p-4 lg:p-8 pb-72 lg:pb-72">
          {renderLeftColumn(tab)}
        </div>
      </FormikForm>
    </Formik>
  )
}

export default connect(null, {})(Form)
