import ValueMapper from '../ValueMapper'

class ProjectValueMapper extends ValueMapper {
  constructor(project, styles) {
    super()
    this.project = project
    this.styles = styles
  }

  title() {
    return this.project.name
  }

  subtitle() {
    return null
  }

  text() {
    return this.project.description
  }

  children() {
    return null
  }
}

export default ProjectValueMapper
