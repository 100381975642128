export const resume = {
  basics: {
    firstName: 'Samantha',
    lastName: 'Chow',
    title: 'Network Engineer',
    picture: 'https://example.com/samantha-chow.jpg',
    email: 'samantha.chow@example.com',
    phone: '123-456-7890',
    url: 'https://www.samanthachow.network',
    summary:
      'Results-driven Network Engineer with expertise in designing, implementing, and optimizing complex network infrastructures. Proven track record of enhancing network performance, security, and reliability. Skilled in collaborating with cross-functional teams to deliver seamless communication solutions.',
    location: {
      city: 'New York',
      countryCode: 'US',
      region: 'New York',
    },
    linkedInUrl: 'https://www.linkedin.com/in/samanthachow',
    portfolioUrl: 'https://portfolio.samanthachow.network',
  },
  experience: [
    {
      company: 'Cisco Systems',
      location: {
        city: 'San Jose',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Network Engineer',
      url: 'https://www.cisco.com',
      startDate: '2020-06-15',
      endDate: '2023-09-30',
      summary: null,
      highlights: [
        'Designed and implemented a redundant MPLS network architecture, reducing network downtime by 25% and enhancing data transmission efficiency.',
        'Collaborated with security teams to deploy robust firewall policies and intrusion detection systems, mitigating potential threats and ensuring network integrity.',
        'Led a cross-functional team to upgrade core network switches, resulting in a 40% improvement in network latency and an enhanced user experience.',
        'Provided on-call support and promptly resolved critical network incidents, maintaining 99.99% network uptime.',
      ],
      present: false,
    },
    {
      company: 'Juniper Networks',
      location: {
        city: 'Sunnyvale',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Network Engineer',
      url: 'https://www.juniper.net',
      startDate: '2018-09-01',
      endDate: '2020-05-31',
      summary: null,
      highlights: [
        'Architected a virtualized network infrastructure using Juniper Contrail, reducing hardware costs by 30% and improving resource utilization.',
        'Implemented Quality of Service (QoS) policies to prioritize critical application traffic, resulting in a 20% reduction in latency for real-time applications.',
        'Collaborated with customers to assess network requirements and provided tailored solutions to meet their specific needs, resulting in a 95% customer satisfaction rate.',
      ],
      present: false,
    },
    {
      company: 'Verizon Communications',
      location: {
        city: 'New York',
        countryCode: 'US',
        region: 'New York',
      },
      position: 'Network Engineer (Intern)',
      url: 'https://www.verizon.com',
      startDate: '2017-06-01',
      endDate: '2017-08-31',
      summary: null,
      highlights: [
        'Conducted network performance analysis using packet capture tools, identifying and resolving latency issues and improving network responsiveness.',
        'Collaborated with senior engineers to configure and troubleshoot Cisco routers and switches, ensuring smooth network operations and minimizing downtime.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Carnegie Mellon University',
      degree: 'Master of Science in Information Networking',
      major: 'Information Networking',
      location: {
        city: 'Pittsburgh',
        countryCode: 'US',
        region: 'Pennsylvania',
      },
      startDate: '2016-08-15',
      endDate: '2018-05-15',
      gpa: '3.7',
      courses: [
        'Network Architecture',
        'Wireless Networking',
        'Network Security',
      ],
      summary: '',
      present: false,
    },
    {
      institution: 'University of Texas at Austin',
      degree: 'Bachelor of Science in Electrical Engineering',
      major: 'Electrical Engineering',
      location: {
        city: 'Austin',
        countryCode: 'US',
        region: 'Texas',
      },
      startDate: '2012-08-15',
      endDate: '2016-05-15',
      gpa: '3.6',
      courses: [
        'Digital Communications',
        'Network Protocols',
        'Embedded Systems',
      ],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Cisco Certified Network Professional (CCNP)',
      date: '2022-02-15',
      awarder: 'Cisco Systems',
      summary:
        'Earned the CCNP certification, showcasing proficiency in advanced networking concepts and design.',
    },
    {
      title: 'Juniper Networks Certified Professional (JNCIP-ENT)',
      date: '2021-04-01',
      awarder: 'Juniper Networks',
      summary:
        'Achieved the JNCIP-ENT certification, demonstrating expertise in Juniper enterprise routing and switching technologies.',
    },
  ],
  skills: [
    {
      skillGroup: 'Network Protocols',
      skill: [{ name: 'TCP/IP' }, { name: 'BGP' }],
    },
    {
      skillGroup: 'Network Security',
      skill: [
        { name: 'Firewalls' },
        { name: 'Intrusion Detection' },
        { name: 'VPN' },
      ],
    },
    {
      skillGroup: 'Routing and Switching',
      skill: [{ name: 'Cisco' }, { name: 'Juniper' }],
    },
    {
      skillGroup: 'Automation',
      skill: [{ name: 'Python' }, { name: 'Ansible' }],
    },
  ],
  projects: [
    {
      name: 'Network Automation Platform',
      description:
        'Developed a custom network automation platform using Python and Ansible, reducing configuration deployment time by 60% and minimizing human errors.',
      startDate: '2021-01-01',
      endDate: '2021-06-30',
      present: false,
    },
    {
      name: 'Secure Remote Access Implementation',
      description:
        'Led the implementation of a secure remote access solution using VPN technologies, enabling remote employees to securely connect to the corporate network.',
      startDate: '2020-03-01',
      endDate: '2020-09-30',
      present: false,
    },
    {
      name: 'Network Performance Optimization',
      description:
        'Optimized network performance by conducting thorough analysis of traffic patterns and implementing QoS policies, resulting in a 25% reduction in latency.',
      startDate: '2019-04-01',
      endDate: '2019-09-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Certifications',
      details: [
        {
          title: 'Cisco Certified Network Professional (CCNP)',
          subtitle: 'Certification',
          location: {
            city: 'Online',
            countryCode: 'US',
            region: 'Virtual',
          },
          url: 'https://www.cisco.com/c/en/us/training-events/training-certifications/certifications/professional/ccnp-routing-switching.html',
          startDate: '2022-02-15',
          endDate: '2025-02-15',
          summary:
            'Certified Network Professional with advanced skills in network design, implementation, and troubleshooting.',
        },
        {
          title: 'Juniper Networks Certified Professional (JNCIP-ENT)',
          subtitle: 'Certification',
          location: {
            city: 'Online',
            countryCode: 'US',
            region: 'Virtual',
          },
          url: 'https://www.juniper.net/documentation/en_US/certification/jncip-ent.html',
          startDate: '2021-04-01',
          endDate: '2024-04-01',
          summary:
            'Juniper Networks Certified Professional with expertise in enterprise routing and switching technologies.',
        },
      ],
    },
  ],
}
