import App from 'App.js'
import { createRoot } from 'react-dom/client'
import ReactGA from 'react-ga4'
import * as serviceWorker from 'serviceWorker.js'

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('G-J0V1B0VEJ1')
}
const domNode = document.getElementById('root')
const root = createRoot(domNode)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
