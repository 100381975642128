import Alert from 'components/Banners/VerifyEmailBanner'
import ThreeDotsLoadingScreen from 'components/LoadingScreens/ThreeDotsLoadingScreen'
import useUser from 'hooks/user'
import { Outlet } from 'react-router'

function Dashboard() {
  const { loading } = useUser()
  if (loading) {
    return (
      <div className="w-full h-screen">
        <ThreeDotsLoadingScreen />
      </div>
    )
  }
  return (
    <div className="max-h-full h-full flex flex-col overflow-hidden">
      <Alert />
      <Outlet />
    </div>
  )
}

export default Dashboard
