export const resume = {
  basics: {
    firstName: 'Raj',
    lastName: 'Patel',
    title: 'Backend Developer',
    picture: 'https://example.com/profile_picture.jpg',
    email: 'rajpatel@email.com',
    phone: '123-456-7890',
    url: 'https://rajpatel.com',
    summary:
      'Experienced Backend Developer specializing in designing and implementing efficient, scalable, and secure server-side applications. Proficient in multiple programming languages and frameworks.',
    location: {
      city: 'New York',
      countryCode: 'US',
      region: 'NY',
    },
    linkedInUrl: 'https://www.linkedin.com/in/rajpatel',
    portfolioUrl: 'https://github.com/rajpatel',
  },
  experience: [
    {
      company: 'Uber Technologies, Inc.',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'Backend Developer',
      url: 'https://www.uber.com',
      startDate: '2020-01-01',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Implemented RESTful APIs and microservices for real-time ride tracking and driver allocation.',
        'Optimized database queries, reducing response time by 40% and improving overall system efficiency.',
        'Collaborated with frontend teams to design and integrate APIs, enabling seamless user experiences across multiple platforms.',
      ],
      present: false,
    },
    {
      company: 'Airbnb, Inc.',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'Software Engineer',
      url: 'https://www.airbnb.com',
      startDate: '2017-06-01',
      endDate: '2019-12-31',
      summary: null,
      highlights: [
        'Designed and implemented backend components using Node.js and Express.js, supporting high traffic and data volume.',
        'Integrated third-party payment gateways, enabling seamless and secure payment processing.',
        'Collaborated with cross-functional teams to define and implement data models and APIs for new features.',
      ],
      present: false,
    },
    {
      company: 'Stripe, Inc.',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'Backend Engineer',
      url: 'https://www.stripe.com',
      startDate: '2015-02-15',
      endDate: '2017-05-31',
      summary: null,
      highlights: [
        'Designed and implemented backend services using Python and Django, handling millions of transactions daily.',
        'Implemented fraud detection mechanisms and enhanced security measures, reducing fraudulent activities by 20%.',
        'Collaborated with frontend teams to design and expose APIs for integration with external partners and customers.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'CA',
      },
      startDate: '2013-09-01',
      endDate: '2015-05-15',
      gpa: '3.9',
      courses: ['Advanced Algorithms', 'Distributed Systems', 'Web Services'],
      summary: 'Graduated with Honors',
      present: false,
    },
    {
      institution: 'University of California, Berkeley',
      degree: 'Bachelor of Science in Computer Engineering',
      major: 'Computer Engineering',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'CA',
      },
      startDate: '2009-09-01',
      endDate: '2013-05-15',
      gpa: '3.7',
      courses: ['Data Structures', 'Operating Systems', 'Database Management'],
      summary: "Dean's List",
      present: false,
    },
  ],
  skills: [
    {
      skillGroup: 'Languages',
      skill: [
        {
          name: 'Python',
        },
        {
          name: 'JavaScript',
        },
        {
          name: 'Java',
        },
      ],
    },
    {
      skillGroup: 'Frameworks',
      skill: [
        {
          name: 'Node.js',
        },
        {
          name: 'Express.js',
        },
        {
          name: 'Django',
        },
      ],
    },
    {
      skillGroup: 'Databases',
      skill: [
        {
          name: 'MongoDB',
        },
        {
          name: 'MySQL',
        },
        {
          name: 'Redis',
        },
      ],
    },
    {
      skillGroup: 'API Design',
      skill: [
        {
          name: 'RESTful APIs',
        },
        {
          name: 'GraphQL',
        },
        {
          name: 'OAuth',
        },
      ],
    },
    {
      skillGroup: 'DevOps',
      skill: [
        {
          name: 'Docker',
        },
        {
          name: 'Kubernetes',
        },
        {
          name: 'Jenkins',
        },
      ],
    },
  ],
  projects: [
    {
      name: 'E-commerce Order Processing System',
      description:
        'Designed and developed a backend system for order processing, integrating payment gateways and inventory management.',
      startDate: '2019-02-01',
      endDate: '2020-05-31',
      present: false,
    },
    {
      name: 'Real-time Data Analytics API',
      description:
        'Built a scalable and performant API for real-time data analytics, enabling clients to query and visualize data in real-time.',
      startDate: '2017-08-15',
      endDate: '2018-12-31',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Excellence in Backend Development',
      date: '2022-04-10',
      awarder: 'Uber Technologies, Inc.',
      summary:
        'Recognized for exceptional contributions to backend development and system optimization.',
    },
    {
      title: 'Innovation Award',
      date: '2018-10-18',
      awarder: 'Airbnb, Inc.',
      summary:
        'Received for innovative solutions and impactful contributions to the development of critical backend systems.',
    },
  ],
  customSections: [],
}
