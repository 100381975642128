export const resume = {
  basics: {
    firstName: 'Michael',
    lastName: 'Johnson',
    title: 'Principal Software Engineer',
    picture: 'https://example.com/michael-johnson.jpg',
    email: 'michael.johnson@example.com',
    phone: '123-456-7890',
    url: 'https://www.michaeljohnson.com',
    summary:
      'Results-driven principal software engineer with a proven track record of leading complex software projects and delivering innovative solutions. Passionate about driving technical excellence and mentoring cross-functional teams.',
    location: {
      city: 'Seattle',
      countryCode: 'US',
      region: 'Washington',
    },
    linkedInUrl: 'https://www.linkedin.com/in/michaeljohnson',
    portfolioUrl: 'https://portfolio.michaeljohnson.com',
  },
  experience: [
    {
      company: 'Google',
      location: {
        city: 'Mountain View',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Principal Software Engineer',
      url: 'https://www.google.com',
      startDate: '2020-09-01',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Championed the adoption of a microservices architecture, reducing system downtime by 20% and increasing scalability to handle 10x more traffic.',
        'Directed a team of 15 engineers in the design and implementation of a real-time data analytics platform, resulting in a 40% improvement in data processing speed.',
        'Collaborated with product managers to define technical roadmaps and delivered high-impact features, leading to a 25% increase in user engagement.',
        'Conducted regular code reviews and introduced coding standards, improving code quality and reducing defects by 30%.',
      ],
      present: false,
    },
    {
      company: 'Amazon',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Senior Software Development Engineer',
      url: 'https://www.amazon.com',
      startDate: '2017-06-15',
      endDate: '2020-08-31',
      summary: null,
      highlights: [
        'Led a cross-functional team of 10 engineers in the migration of a monolithic application to a serverless architecture, resulting in a 50% reduction in operational costs.',
        'Designed and implemented an automated testing framework, reducing test execution time by 30% and enhancing code coverage by 20%.',
        'Mentored junior engineers and conducted technical workshops to foster knowledge sharing and professional growth.',
      ],
      present: false,
    },
    {
      company: 'Microsoft',
      location: {
        city: 'Redmond',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Software Development Engineer II',
      url: 'https://www.microsoft.com',
      startDate: '2014-08-01',
      endDate: '2017-05-31',
      summary: null,
      highlights: [
        'Collaborated with a global team to deliver a feature-rich mobile application, achieving 4.5-star user ratings and 10 million downloads within the first year.',
        'Led performance optimization efforts, reducing application startup time by 20% and improving responsiveness on low-end devices.',
        "Received Microsoft's Excellence in Innovation Award for developing a patent-pending algorithm that enhanced search accuracy by 25%.",
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2012-09-01',
      endDate: '2014-06-30',
      gpa: '3.9',
      courses: [
        'Advanced Algorithms',
        'Distributed Systems',
        'Artificial Intelligence',
      ],
      summary: '',
      present: false,
    },
    {
      institution: 'Massachusetts Institute of Technology (MIT)',
      degree:
        'Bachelor of Science in Electrical Engineering and Computer Science',
      major: 'Electrical Engineering and Computer Science',
      location: {
        city: 'Cambridge',
        countryCode: 'US',
        region: 'Massachusetts',
      },
      startDate: '2008-09-01',
      endDate: '2012-06-30',
      gpa: '3.8',
      courses: ['Operating Systems', 'Computer Networks', 'Database Systems'],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Google Technical Leadership Award',
      date: '2023-02-15',
      awarder: 'Google',
      summary:
        'Recognized for exceptional technical leadership and contributions to complex software projects.',
    },
    {
      title: 'Amazon Innovation Award',
      date: '2019-07-10',
      awarder: 'Amazon',
      summary:
        "Received for driving innovative solutions and impactful improvements to the company's technical capabilities.",
    },
  ],
  skills: [
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'Java' }, { name: 'Python' }, { name: 'C++' }],
    },
    {
      skillGroup: 'Software Architecture',
      skill: [
        { name: 'Microservices' },
        { name: 'Serverless' },
        { name: 'Distributed Systems' },
      ],
    },
    {
      skillGroup: 'Leadership',
      skill: [
        { name: 'Team Management' },
        { name: 'Technical Leadership' },
        { name: 'Mentorship' },
      ],
    },
  ],
  projects: [
    {
      name: 'High-Performance Data Processing Framework',
      description:
        'Led the development of a high-performance data processing framework capable of handling massive data streams in real-time.',
      startDate: '2022-01-01',
      endDate: '2022-12-31',
      present: false,
    },
    {
      name: 'Advanced Machine Learning Platform',
      description:
        'Designed and implemented an advanced machine learning platform that enabled the rapid deployment and experimentation of complex models.',
      startDate: '2020-03-01',
      endDate: '2021-06-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Technical Conferences',
      details: [
        {
          title: 'IEEE International Conference on Software Engineering',
          subtitle: 'Speaker',
          location: {
            city: 'San Francisco',
            countryCode: 'US',
            region: 'California',
          },
          url: 'https://www.icseconf.org',
          startDate: '2019-05-01',
          endDate: '2019-05-05',
          summary:
            'Presented a research paper on optimizing microservices architectures for improved scalability.',
          highlights: [
            'Engaged in panel discussions on emerging trends in software architecture.',
            'Facilitated technical workshops on distributed systems and cloud computing.',
          ],
          present: false,
        },
      ],
    },
  ],
}
