import * as resumes from 'resources/resume-examples'

const SoftwareEngineerExamplesMap = {
  'software-engineer-resume-example': {
    name: 'Software Engineer',
    resume: resumes.softwareEngineer,
  },
  'senior-software-engineer-resume-example': {
    name: 'Senior Software Engineer',
    resume: resumes.seniorSoftwareEngineer,
  },
  'principal-software-engineer-resume-example': {
    name: 'Principal Software Engineer',
    resume: resumes.principalSoftwareEngineer,
  },
  'lead-software-engineer-resume-example': {
    name: 'Lead Software Engineer',
    resume: resumes.leadSoftwareEngineer,
  },
  'backend-software-engineer-resume-example': {
    name: 'Backend Software Engineer',
    resume: resumes.backendSoftwareEngineer,
  },
  'frontend-software-engineer-resume-example': {
    name: 'Frontend Software Engineer',
    resume: resumes.frontendSoftwareEngineer,
  },
  'full-stack-software-engineer-resume-example': {
    name: 'Full Stack Software Engineer',
    resume: resumes.fullStackSoftwareEngineer,
  },
  'mobile-app-developer-resume-example': {
    name: 'Mobile App Developer',
    resume: resumes.mobileAppDeveloper,
  },
  'web-developer-resume-example': {
    name: 'Web Developer',
    resume: resumes.webDeveloper,
  },
  'devops-engineer-resume-example': {
    name: 'DevOps Engineer',
    resume: resumes.devOpsEngineer,
  },
  'systems-engineer-resume-example': {
    name: 'Systems Engineer',
    resume: resumes.systemsEngineer,
  },
  'embedded-software-engineer-resume-example': {
    name: 'Embedded Software Engineer',
    resume: resumes.embeddedSoftwareEngineer,
  },
  'game-developer-resume-example': {
    name: 'Game Developer',
    resume: resumes.gameDeveloper,
  },
  'ui-ux-engineer-resume-example': {
    name: 'UI/UX Engineer',
    resume: resumes.uIUXEngineer,
  },
  'cloud-engineer-resume-example': {
    name: 'Cloud Engineer',
    resume: resumes.cloudEngineer,
  },
  'machine-learning-engineer-resume-example': {
    name: 'Machine Learning Engineer',
    resume: resumes.machineLearningEngineer,
  },
  'data-engineer-resume-example': {
    name: 'Data Engineer',
    resume: resumes.dataEngineer,
  },
  'test-automation-engineer-resume-example': {
    name: 'Test Automation Engineer',
    resume: resumes.testAutomationEngineer,
  },
  'qa-engineer-resume-example': {
    name: 'QA Engineer',
    resume: resumes.qAEngineer,
  },
  'security-engineer-resume-example': {
    name: 'Security Engineer',
    resume: resumes.securityEngineer,
  },
  'site-reliability-engineer-resume-example': {
    name: 'Site Reliability Engineer',
    resume: resumes.siteReliabilityEngineer,
  },
  'network-engineer-resume-example': {
    name: 'Network Engineer',
    resume: resumes.networkEngineer,
  },
  'firmware-engineer-resume-example': {
    name: 'Firmware Engineer',
    resume: resumes.firmwareEngineer,
  },
  'application-developer-resume-example': {
    name: 'Application Developer',
    resume: resumes.applicationDeveloper,
  },
  'database-engineer-resume-example': {
    name: 'Database Engineer',
    resume: resumes.databaseEngineer,
  },
  'big-data-engineer-resume-example': {
    name: 'Big Data Engineer',
    resume: resumes.bigDataEngineer,
  },
  'robotics-software-engineer-resume-example': {
    name: 'Robotics Software Engineer',
    resume: resumes.roboticsSoftwareEngineer,
  },
  'augmented-reality-engineer-resume-example': {
    name: 'Augmented Reality Engineer',
    resume: resumes.augmentedRealityEngineer,
  },
  'virtual-reality-engineer-resume-example': {
    name: 'Virtual Reality Engineer',
    resume: resumes.virtualRealityEngineer,
  },
  'blockchain-developer-resume-example': {
    name: 'Blockchain Developer',
    resume: resumes.blockchainDeveloper,
  },
  'natural-language-processing-engineer-resume-example': {
    name: 'Natural Language Processing Engineer',
    resume: resumes.naturalLanguageProcessingEngineer,
  },
  'ai-engineer-resume-example': {
    name: 'AI Engineer',
    resume: resumes.aIEngineer,
  },
  'automation-engineer-resume-example': {
    name: 'Automation Engineer',
    resume: resumes.automationEngineer,
  },
  'integration-engineer-resume-example': {
    name: 'Integration Engineer',
    resume: resumes.integrationEngineer,
  },
  'software-development-engineer-in-test-resume-example': {
    name: 'Software Development Engineer in Test',
    resume: resumes.softwareDevelopmentEngineerinTest,
  },
  'infrastructure-engineer-resume-example': {
    name: 'Infrastructure Engineer',
    resume: resumes.infrastructureEngineer,
  },
  'continuous-integration-continuous-deployment-engineer-resume-example': {
    name: 'Continuous Integration/Continuous Deployment Engineer',
    resume: resumes.continuousIntegrationContinuousDeploymentEngineer,
  },
  'performance-engineer-resume-example': {
    name: 'Performance Engineer',
    resume: resumes.performanceEngineer,
  },
  'algorithm-engineer-resume-example': {
    name: 'Algorithm Engineer',
    resume: resumes.algorithmEngineer,
  },
  'computer-vision-engineer-resume-example': {
    name: 'Computer Vision Engineer',
    resume: resumes.computerVisionEngineer,
  },
  'software-architect-resume-example': {
    name: 'Software Architect',
    resume: resumes.softwareArchitect,
  },
  'technical-lead-resume-example': {
    name: 'Technical Lead',
    resume: resumes.technicalLead,
  },
  'backend-developer-resume-example': {
    name: 'Backend Developer',
    resume: resumes.backendDeveloper,
  },
  'frontend-developer-resume-example': {
    name: 'Frontend Developer',
    resume: resumes.frontendDeveloper,
  },
  'ios-developer-resume-example': {
    name: 'iOS Developer',
    resume: resumes.iOSDeveloper,
  },
  'android-developer-resume-example': {
    name: 'Android Developer',
    resume: resumes.androidDeveloper,
  },
  'game-programmer-resume-example': {
    name: 'Game Programmer',
    resume: resumes.gameProgrammer,
  },
  'ui-ux-designer-and-developer-resume-example': {
    name: 'UI/UX Designer and Developer',
    resume: resumes.uIUXDesignerandDeveloper,
  },
  'data-scientist-resume-example': {
    name: 'Data Scientist',
    resume: resumes.dataScientist,
  },
  'research-engineer-resume-example': {
    name: 'Research Engineer',
    resume: resumes.researchEngineer,
  },
}

const ResumeExamplesMap = {
  'software-engineer': {
    name: 'Software Engineer',
    items: SoftwareEngineerExamplesMap,
  },
}

export const ExamplesMapToArray = (category) =>
  Object.entries(ResumeExamplesMap[category].items).map(([key, value]) => {
    return {
      id: key,
      name: value.name,
      to: `/resume-examples/${category}/${key}`,
      resume: {
        ...value.resume,
        name: key,
        template: 0,
        order: [
          ['projects', 'skills'],
          ['experience', 'education'],
        ],
        color: '#000000',
      },
    }
  })

export const ResumeExamplesArrayNoItems = Object.entries(ResumeExamplesMap).map(
  ([key1, value1]) => {
    return {
      id: key1,
      to: `/resume-examples/${key1}`,
      name: value1.name,
      size: Object.keys(value1.items).length,
    }
  }
)

export const ResumeExamplesArray = () =>
  Object.entries(ResumeExamplesMap).map(([key1, value1]) => {
    return {
      id: key1,
      to: `/resume-examples/${key1}`,
      name: value1.name,
      items: Object.entries(value1.items).map(([key2, value2]) => {
        return {
          id: key2,
          name: value2.name,
          to: `/resume-examples/${key1}/${key2}`,
          file: value2.file,
          resume: {
            ...value2.resume,
            template: 0,
            order: [
              ['projects', 'skills'],
              ['experience', 'education'],
            ],
            color: '#000000',
          },
        }
      }),
    }
  })

export default ResumeExamplesMap
