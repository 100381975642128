import { Page, View } from '@react-pdf/renderer'
import { mapIdToSection } from '../Utils/helper'
import Column from './Column'

const OneColumnLayout = (props) => {
  const { configs = { styles: {} }, resume } = props
  const { styles, entryTransformerMap, Header } = configs
  const { basics, color, customSections, order } = resume
  const customSectionEntriesMap = mapIdToSection(customSections)
  return (
    <Page style={styles.page} size="LETTER">
      {basics && <Header basics={basics} color={color} />}
      <View style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}>
        <View style={{ flexDirection: 'column', flex: 1, display: 'flex' }}>
          {order && order.length > 0 && (
            <Column
              settings={configs.settings}
              styles={configs.styles}
              TemplateEntry={configs.TemplateEntry}
              TemplateSection={configs.TemplateSection}
              sections={order[0]}
              entryTransformer={entryTransformerMap}
              resume={resume}
              customSectionEntriesMap={customSectionEntriesMap}
            />
          )}
        </View>
      </View>
    </Page>
  )
}

export default OneColumnLayout
