import ActionTypes from 'actions/types'

const initialState = {
  bannerDetails: undefined,
  referralCode: undefined,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_BANNER_MESSAGE:
      return {
        ...state,
        bannerDetails: action.payload,
      }
    case ActionTypes.SET_REFERRAL_CODE:
      return {
        ...state,
        referralCode: action.payload,
      }
    default:
      return state
  }
}
