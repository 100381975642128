import { Link, StyleSheet, View } from '@react-pdf/renderer'
import HtmlContent from '../HtmlContent'
import SafeText from '../SafeText'
import { getContactInfoArray } from '../Utils/linkHelper'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  detailColumn: {
    flexDirection: 'column',
    marginBottom: 50,
  },
  linkColumn: {
    flexDirection: 'column',
    flexGrow: 2,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  name: {
    fontSize: 20,
    fontFamily: 'Open Sans',
    marginBottom: 3,
    fontWeight: 700,
  },
  subtitle: {
    fontSize: 10,
    justifySelf: 'flex-end',
    fontFamily: 'Open Sans',
    fontWeight: 700,
    marginBottom: 6,
  },
  summary: {
    marginTop: 8,
  },
  linkGroup: {
    fontSize: 9,
    fontFamily: 'Open Sans',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 600,
  },
  link: {
    color: 'black',
    textDecoration: 'none',
    marginBottom: 3,
  },
  subtitleSeparator: {
    marginRight: 2,
    marginLeft: 2,
  },
  box: {
    height: 50,
    width: 50,
    marginBottom: 8,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000000',
  },
  boxText: {
    fontSize: 32,
    fontFamily: 'Open Sans',
    color: '#faf8f9',
    fontWeight: 700,
  },
  htmlContent: {
    fontFamily: 'Open Sans',
    fontSize: 9,
  },
})

function getName(basics) {
  if (basics.firstName && basics.lastName) {
    return `${basics.firstName} ${basics.lastName}`
  } else if (basics.firstName) {
    return basics.firstName
  } else if (basics.lastName) {
    return basics.lastName
  }
  return undefined
}

function getContactInfo(basics) {
  const contact = getContactInfoArray(basics, styles)
  return <View style={styles.linkGroup}>{contact}</View>
}

export default function TwoColumnHeader({ basics, color }) {
  var name = getName(basics)
  var title = basics.title
  var summary = basics.summary
  return (
    <View style={styles.container}>
      <View style={{ ...styles.box, backgroundColor: color || '#000' }}>
        <SafeText val={true} style={styles.boxText}>
          {(name && name[0]) || 'R'}
        </SafeText>
      </View>
      <View style={styles.detailColumn}>
        <SafeText val={name} style={{ ...styles.name, color: color }}>
          {name}
        </SafeText>
        <SafeText val={title} style={{ ...styles.subtitle, color: color }}>
          {title}
        </SafeText>
        {getContactInfo(basics)}
        <SafeText val={summary} style={styles.summary}>
          <HtmlContent styles={styles.htmlContent}>{summary}</HtmlContent>
        </SafeText>
      </View>
    </View>
  )
}
