import ResumeExamplesMap, {
  ExamplesMapToArray,
} from 'marketing/resources/ResumeExamplesMap'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'

export const examples = {
  'account-executive-example-resume': {
    name: 'Account Executive',
  },
  'software-engineer-example-resume': {
    name: 'Software Engineer',
  },
}

const ExampleCard = ({ name, resume, to }) => {
  return (
    <div className="px-4 sm:px-0">
      <Link to={to}>
        <div className="border hover:border-gray-900 rounded-lg bg-gray-50 px-6 py-4 mb-6">
          <img
            className="rounded border-2 border-gray-200 shadow-xl"
            src={require(`assets/img/resume-examples/${resume.name}.png`)}
          />
        </div>
      </Link>
      <div>
        <Link to={to} className="text-lg font-semibold hover:underline">
          {name}
        </Link>
      </div>
    </div>
  )
}

const ResumeExampleCategory = () => {
  const params = useParams()
  const category = params.category
  if (!category) {
    return null
  }
  const categoryMap = ResumeExamplesMap[category]
  const categoryArray = ExamplesMapToArray(category)
  return (
    <>
      <Helmet>
        <title>
          {`${categoryArray.length}+ ${categoryMap.name} Resume Examples - Resumary`}
        </title>
        <meta
          name="description"
          content={`Explore ${categoryArray.length}+ ${categoryMap.name} resume examples to give your resume content a boost. Create a job-winning resume today with our powerful and easy-to-use resume builder.`}
        />
      </Helmet>
      <div className="relative bg-blue-100">
        <div className="max-w-6xl mx-auto py-24 px-4">
          <div className="flex flex-col sm:items-center sm:text-center">
            <h1 className="mb-8 text-5xl font-bold text-gray-900">
              {categoryArray.length}+ {categoryMap.name} Resume Examples
            </h1>
            <div className="max-w-2xl mx-auto mb-6">
              <p className="text-xl text-gray-900">
                Impress potential employers and showcase your skills with our
                collection of {categoryMap.name} resume examples.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="relative bg-white">
        <div className="max-w-6xl mx-auto px-4 py-32">
          <div className="grid grid-cols-1 sm:grid-cols-4 items-start gap-8">
            <div className="hidden sm:flex flex-col gap-3">
              {categoryArray.map((example) => (
                <Link
                  to={example.to}
                  className="text-lg text-blue-600 hover:underline"
                >
                  {example.name}
                </Link>
              ))}
            </div>
            <div className="col-span-1 sm:col-span-3 grid grid-cols-1 sm:grid-cols-3 gap-8">
              {categoryArray.map((example, idx) => (
                <ExampleCard
                  to={example.to}
                  name={example.name}
                  resume={example.resume}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResumeExampleCategory
