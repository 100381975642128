import { setCurrentUser } from 'actions/authActions'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

export default function useUser(disable = false) {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchUser = () => {
      setLoading(true)
      axios
        .get('/user')
        .then((res) => {
          dispatch(setCurrentUser(res.data))
          setLoading(false)
        })
        .catch((err) => {
          console.log('error', err)
        })
    }
    if (!disable) {
      fetchUser()
    }
  }, [])

  return { loading }
}
