import { getDate, getGpa, joinFields } from '../../Utils/helper'
import EducationValueMapper from './EducationValueMapper'

class DateEducationValueMapper extends EducationValueMapper {
  subtitle() {
    const { location, startDate, endDate, present, gpa } = this.education
    const locationText =
      location && joinFields(', ', location.city, location.region)
    const dateText = getDate(startDate, endDate, present)
    const gpaText = getGpa(gpa)
    return joinFields(' • ', locationText, dateText, gpaText)
  }
}

export default DateEducationValueMapper
