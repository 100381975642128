import {
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { classNames } from 'utils/helper'

const TemplatesDisplay = () => {
  const [selected, setSelected] = useState(0)
  const templates = ['Sharp', 'Modern', 'Clean', 'Fresh']
  return (
    <div className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 py-16">
        <div className="max-w-5xl mx-auto sm:text-center">
          <p className="mt-3 mb-3 text-sm font-semibold uppercase text-indigo-600">
            Professional Templates
          </p>
          <h2 className="text-4xl sm:text-6xl text-gray-900 font-bold mb-10">
            Explore all of our resume templates
          </h2>
        </div>
        <div className="flex flex-wrap sm:justify-center gap-2 mb-6">
          {templates.map((template, idx) => (
            <button
              onClick={() => setSelected(idx)}
              className={classNames(
                idx === selected
                  ? 'border-gray-900 text-gray-900'
                  : 'border-transparent text-gray-600',
                'border rounded-full p-2 text-sm sm:py-4 sm:px-4 sm:text-md font-bold transition-all duration-150'
              )}
            >
              {template} Template
            </button>
          ))}
          <Link
            to="/resume-templates"
            className="border-2 border-transparent rounded-full p-2 sm:py-4 sm:px-4 text-sm sm:text-md font-bold text-gray-600 hover:text-gray-900 flex gap-1.5 items-center"
          >
            View More
            <ArrowRightIcon className="w-4 h-4" />
          </Link>
        </div>
        <div className="flex justify-center gap-2 sm:gap-8">
          <div className="sm:flex items-center hidden">
            <button
              onClick={() => setSelected((selected + 4 - 1) % 4)}
              className="ring-1 ring-transparent hover:ring-gray-900 p-1 rounded-full"
            >
              <ChevronLeftIcon className="w-6 h-6 sm:w-8 sm:h-8 text-gray-900" />
            </button>
          </div>
          <div className="relative max-w-3xl mx-auto">
            <div className="absolute w-full h-full top-1 left-1 border border-gray-900 bg-gray-900 rounded-xl" />
            <div className="relative bg-blueGray-100 px-6 py-6 sm:px-0 sm:py-8 rounded-xl border border-gray-900">
              <div className="relative mx-auto w-full sm:w-1/2 rounded-lg shadow-xl bg-blueGray-100 transition-all duration-150">
                <img
                  className="rounded-lg"
                  src={require(`assets/img/Template${selected}.png`)}
                />
              </div>
            </div>
          </div>
          <div className="sm:flex items-center hidden">
            <button
              onClick={() => setSelected((selected + 1) % 4)}
              className="ring-1 ring-transparent hover:ring-gray-900 p-1 rounded-full"
            >
              <ChevronRightIcon className="w-6 h-6 sm:w-8 sm:h-8 text-gray-900" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplatesDisplay
