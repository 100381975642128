import { Link, StyleSheet, View } from '@react-pdf/renderer'
import HtmlContent from '../../common/HtmlContent'
import SafeText from '../../common/SafeText'
import { getContactInfoArray } from '../../common/Utils/linkHelper'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    // alignItems: 'stretch',
    paddingBottom: '15',
  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  leftColumn: {
    flexDirection: 'column',
    width: '96',
  },
  rightColumn: {
    flexDirection: 'column',
    flex: 1,
  },
  // linkColumn: {
  //   flexDirection: 'row',
  //   // flexGrow: 2,
  //   alignSelf: 'flex-end',
  //   // justifySelf: 'flex-end',
  //   // textAlign: 'left',
  // },
  name: {
    fontSize: 16,
    // lineHeight: 1.2,
    textTransform: 'uppercase',
    fontFamily: 'Open Sans',
    fontWeight: '700',
    // marginBottom: 0,
  },
  subtitle: {
    fontSize: 8,
    // justifySelf: 'flex-end',
    textTransform: 'uppercase',
    fontFamily: 'Open Sans',
    fontWeight: '700',
    // marginBottom: 5,
  },
  summary: {
    justifySelf: 'flex-end',
    marginTop: 10,
  },
  link: {
    marginTop: 2,
    fontSize: 8,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Open Sans',
  },
  linkGroup: {
    marginTop: 2,
    fontSize: 8,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Open Sans',
    display: 'flex',
    flexDirection: 'row',
  },
  linkColumn: {
    marginRight: 8,
  },
  htmlContent: {
    fontSize: 8,
  },
})

function chunkArray(myArray, chunk_size) {
  var results = []

  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size))
  }

  return results
}

function getName(basics) {
  if (basics.firstName && basics.lastName) {
    return `${basics.firstName} ${basics.lastName}`
  } else if (basics.firstName) {
    return basics.firstName
  } else if (basics.lastName) {
    return basics.lastName
  }
  return undefined
}

function getContactInfo(basics) {
  let subtitles = getContactInfoArray(basics, styles)
  var rightColumn = []
  var leftColumm = []
  subtitles.forEach((subtitle, index) => {
    if (index % 2 == 0) {
      leftColumm.push(subtitles[index])
    }
    if (index % 2 == 1) {
      rightColumn.push(subtitles[index])
    }
  })
  return (
    <View style={styles.linkGroup}>
      <View style={styles.linkColumn}>{leftColumm}</View>
      <View style={styles.linkColumn}>{rightColumn}</View>
    </View>
  )
}

export default function Header({ basics, color }) {
  var name = getName(basics)
  var title = basics.title
  var summary = basics.summary
  return (
    <View style={styles.container}>
      <View style={styles.leftColumn}></View>
      <View style={styles.rightColumn}>
        <SafeText val={name} style={styles.name}>
          {name}
        </SafeText>
        <SafeText val={title} style={{ ...styles.subtitle, color: color }}>
          {title}
        </SafeText>
        {getContactInfo(basics)}
        <SafeText val={summary} style={styles.summary}>
          <HtmlContent styles={styles.htmlContent}>{summary}</HtmlContent>
        </SafeText>
      </View>
    </View>
  )
}
