import { ChevronRightIcon } from '@heroicons/react/outline'
import {
  ExamplesMapToArray,
  ResumeExamplesArrayNoItems,
} from 'marketing/resources/ResumeExamplesMap'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export const examples = {
  'account-executive-example-resume': {
    name: 'Account Executive',
  },
  'software-engineer-example-resume': {
    name: 'Software Engineer',
  },
}

const ExampleCard = ({ name, resume, to }) => {
  return (
    <div className="px-4 sm:px-0">
      <Link to={to}>
        <div className="border hover:border-gray-900 rounded-lg bg-gray-50 px-6 py-4 mb-6">
          <img
            className="rounded border-2 border-gray-200 shadow-xl"
            src={require(`assets/img/resume-examples/${resume.name}.png`)}
          />
          {/* <DemoViewer resumeObj={resume} /> */}
        </div>
      </Link>
      <div>
        <Link to={to} className="text-base font-semibold hover:underline">
          {name}
        </Link>
      </div>
    </div>
  )
}

const ResumeExampleRow = ({ example }) => {
  const [items, setItems] = useState(ExamplesMapToArray(example.id))
  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold mb-6">{example.name}</h2>
        <Link to={example.to}>
          <div className="text-blue-500 rounded-lg px-6 py-4 mb-6 flex items-center gap-1">
            Access {items.length}+ {example.name} examples
            <ChevronRightIcon className="w-4 h-4" />
          </div>
        </Link>
      </div>
      <div className="grid grid-cols-4 gap-8">
        {items.slice(0, 4).map((item, idx) => {
          return (
            <ExampleCard to={item.to} name={item.name} resume={item.resume} />
          )
        })}
      </div>
    </div>
  )
}

const ResumeExamples = () => {
  return (
    <>
      <Helmet>
        <title>50+ Professional Resume Examples in 2023 - Resumary</title>
        <meta
          name="description"
          content="Impress potential employers and showcase your skills with our collection of real-world, professional examples. Create a job-winning resume today with our powerful and easy-to-use resume builder."
        />
      </Helmet>
      <div className="relative bg-blue-100">
        <div className="max-w-6xl mx-auto py-24 px-4">
          <div className="flex flex-col sm:items-center sm:text-center">
            <h1 className="mb-8 text-5xl font-bold text-gray-900">
              50+ Professional Resume Examples
            </h1>
            <div className="max-w-2xl mx-auto mb-6">
              <p className="text-xl text-gray-900">
                Impress potential employers and showcase your skills with our
                collection of real-world, professional examples.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="relative bg-white">
        <div className="max-w-6xl mx-auto px-4 py-32">
          <div className="flex flex-col gap-8">
            {ResumeExamplesArrayNoItems.map((example) => (
              <ResumeExampleRow example={example} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default ResumeExamples
