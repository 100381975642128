import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { classNames } from 'utils/helper'
import { navigation, subNavigation } from './common'

export default function Navbar() {
  const location = useLocation()
  const hiddenOnMobile = ['/dashboard/jobs'].includes(location.pathname)
  return (
    <Disclosure
      as="nav"
      className={classNames(
        hiddenOnMobile ? 'hidden sm:block' : '',
        'bg-white relative'
      )}
    >
      {({ open }) => (
        <>
          <div className="md:hidden px-4 sm:px-6 lg:px-8 border-b">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  {/* <div className="h-8 w-8 bg-indigo-600 rounded" /> */}
                  <Link to="/dashboard/resumes">
                    <img
                      src={require('assets/img/R_logo_round.png')}
                      alt="logo"
                      className="w-10 h-10"
                    />
                  </Link>
                </div>
              </div>
              <div className="-mr-2 flex">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-700 hover:bg-gray-200">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="absolute w-full z-10 bg-white border-b md:hidden">
            {({ close }) => (
              <>
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.map((items) =>
                    items.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.to}
                        onClick={(e) => {
                          if (item.isComingSoon) {
                            e.preventDefault()
                            return
                          }
                          close()
                        }}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? 'bg-blue-600 text-white'
                              : 'text-gray-700 hover:bg-gray-300',
                            'rounded-md px-3 py-2 text-base font-medium flex items-center gap-2'
                          )
                        }
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                        {item.isComingSoon && (
                          <span className="text-xs rounded-full py-0.5 px-2 bg-blue-200 font-medium">
                            Coming soon
                          </span>
                        )}
                      </NavLink>
                    ))
                  )}
                  <NavLink
                    onClick={() => close()}
                    to="/dashboard/upgrade"
                    className={({ isActive }) =>
                      classNames(
                        isActive
                          ? 'bg-blue-600 text-white'
                          : 'text-gray-700 hover:bg-gray-300',
                        'rounded-md px-3 py-2 text-base font-medium flex items-center gap-2'
                      )
                    }
                  >
                    Upgrade
                  </NavLink>
                </div>
                <div className="border-t border-gray-700 pb-3 pt-4">
                  <div className="space-y-1 px-2">
                    {subNavigation.map((items) =>
                      items.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.to}
                          onClick={() => close()}
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? 'bg-blue-600 text-white'
                                : 'text-gray-700 hover:bg-gray-700 hover:text-white',
                              'block rounded-md px-3 py-2 text-base font-medium'
                            )
                          }
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </NavLink>
                      ))
                    )}
                  </div>
                </div>
              </>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
