import { StyleSheet } from '@react-pdf/renderer'
import OneColumnLayout from '../common/layouts/OneColumnLayout'
import DefaultTemplateEntry from '../common/template-entry/DefaultTemplateEntry'
import DefaultTemplateSection from '../common/template-section/DefaultTemplateSection'
import HyphenatedCustomSectionValueMapper from '../common/value-mappers/custom-section/HyphenatedCustomSectionValueMapper'
import DateEducationValueMapper from '../common/value-mappers/education/DateEducationValueMapper'
import HyphenatedExperienceValueMapper from '../common/value-mappers/experience/HypenatedExperienceValueMapper'
import ProjectValueMapper from '../common/value-mappers/project/ProjectValueMapper'
import StackedSkillValueMapper from '../common/value-mappers/skill/StackedSkillValueMapper'
import Header from '../pure/sections/Header'
import DotExperienceValueMapper from '../common/value-mappers/experience/DotExperienceValueMapper'
import DotCustomSectionValueMapper from '../common/value-mappers/custom-section/DotCustomSectionValueMapper'
import SplitTemplateSection from '../common/template-section/SplitTemplateSection'

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 50,
    paddingVertical: 40,
    fontFamily: 'Open Sans',
  },
  sectionTitle: {
    fontFamily: 'Open Sans',
    fontSize: 9,
    textAlign: 'right',
    paddingRight: 18,
    fontWeight: 600,
  },
  container: {
    flexDirection: 'row',
    marginBottom: 9,
  },
  entryContainer: {
    marginBottom: 6,
  },
  subtitle: {
    fontSize: 8,
    fontFamily: 'Open Sans',
  },
  detailContainer: {
    flexDirection: 'row',
  },
  detailLeftColumn: {
    flexDirection: 'column',
    marginLeft: 10,
    marginRight: 10,
  },
  detailRightColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  bulletPoint: {
    fontSize: 8,
  },
  text: {
    marginTop: 2,
  },
  innerLeftColumn: {
    flexDirection: 'column',
    width: '72',
  },
  innerRightColumn: {
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    fontSize: 8,
    fontFamily: 'Open Sans',
    fontWeight: 600,
  },
  inlineSubtitle: {
    fontSize: 8,
    fontFamily: 'Open Sans',
  },
  highlights: {
    lineHeight: 1.5,
  },
  htmlContent: {
    fontFamily: 'Open Sans',
    fontSize: 8,
  },
  item: {
    marginTop: 2,
  },
})

const PureConfigs = {
  styles: styles,
  Layout: OneColumnLayout,
  Header: Header,
  entryTransformerMap: {
    education: DateEducationValueMapper,
    experience: DotExperienceValueMapper,
    projects: ProjectValueMapper,
    skills: StackedSkillValueMapper,
    custom: DotCustomSectionValueMapper,
  },
  TemplateSection: SplitTemplateSection,
  TemplateEntry: DefaultTemplateEntry,
  settings: {
    capitalizeSection: true,
  },
}

export default PureConfigs
