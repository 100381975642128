export const resume = {
  basics: {
    firstName: 'Jordan',
    lastName: 'Bennett',
    title: 'Game Programmer',
    picture: 'https://example.com/profile_picture.jpg',
    email: 'jordanbennett@email.com',
    phone: '123-456-7890',
    url: 'https://jordanbennett.com',
    summary:
      'Experienced Game Programmer with a passion for creating immersive and interactive gameplay experiences. Proficient in game engines, scripting, and optimizing game performance.',
    location: {
      city: 'Los Angeles',
      countryCode: 'US',
      region: 'CA',
    },
    linkedInUrl: 'https://www.linkedin.com/in/jordanbennett',
    portfolioUrl: 'https://github.com/jordanbennett',
  },
  experience: [
    {
      company: 'Electronic Arts (EA)',
      location: {
        city: 'Redwood City',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'Game Programmer',
      url: 'https://www.ea.com',
      startDate: '2020-01-01',
      endDate: '2023-07-31',
      summary:
        'Developed and optimized gameplay mechanics and systems for AAA titles, ensuring engaging and seamless player experiences.',
      highlights: [
        'Implemented gameplay features using C++ and Unreal Engine, enhancing player interaction and immersion.',
        'Collaborated with designers and artists to implement and fine-tune game mechanics, achieving high-quality visuals and gameplay.',
        'Optimized game performance through profiling and code refactoring, resulting in a 30% improvement in frame rates and reduced loading times.',
      ],
      present: false,
    },
    {
      company: 'Ubisoft',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'Gameplay Engineer',
      url: 'https://www.ubisoft.com',
      startDate: '2017-06-01',
      endDate: '2019-12-31',
      summary:
        'Contributed to the development of gameplay systems and mechanics for open-world and action-adventure games.',
      highlights: [
        'Designed and implemented character movement and animation systems using C# and Unity, ensuring smooth and realistic player control.',
        'Collaborated with level designers to create interactive and dynamic environments, enhancing player exploration and engagement.',
        'Participated in playtesting and iterative design processes to gather feedback and iterate on gameplay features.',
      ],
      present: false,
    },
    {
      company: 'Blizzard Entertainment',
      location: {
        city: 'Irvine',
        countryCode: 'US',
        region: 'CA',
      },
      position: 'Game Programmer Intern',
      url: 'https://www.blizzard.com',
      startDate: '2015-02-15',
      endDate: '2015-05-31',
      summary:
        'Contributed to the development of gameplay mechanics and systems for a popular multiplayer online game.',
      highlights: [
        'Implemented and debugged gameplay scripts using Lua and the proprietary game engine, enhancing player interactions and balancing.',
        "Collaborated with designers and artists to prototype and iterate on new gameplay features, contributing to the game's ongoing development.",
        'Assisted in code reviews and documentation, ensuring consistent and high-quality codebase.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'University of California, Los Angeles (UCLA)',
      degree: 'Bachelor of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Los Angeles',
        countryCode: 'US',
        region: 'CA',
      },
      startDate: '2013-09-01',
      endDate: '2017-05-15',
      gpa: '3.8',
      courses: ['Game Development', 'Computer Graphics', 'Algorithms'],
      summary: "Dean's List",
      present: false,
    },
  ],
  skills: [
    {
      skillGroup: 'Programming Languages',
      skill: [
        {
          name: 'C++',
        },
        {
          name: 'C#',
        },
        {
          name: 'Lua',
        },
      ],
    },
    {
      skillGroup: 'Game Engines',
      skill: [
        {
          name: 'Unreal Engine',
        },
        {
          name: 'Unity',
        },
        {
          name: 'Proprietary Engines',
        },
      ],
    },
    {
      skillGroup: 'Version Control',
      skill: [
        {
          name: 'Git',
        },
        {
          name: 'Perforce',
        },
      ],
    },
    {
      skillGroup: 'Scripting',
      skill: [
        {
          name: 'Blueprints',
        },
        {
          name: 'UnityScript',
        },
        {
          name: 'Python',
        },
      ],
    },
    {
      skillGroup: 'Optimization',
      skill: [
        {
          name: 'Profiling',
        },
        {
          name: 'Code Refactoring',
        },
        {
          name: 'Memory Management',
        },
      ],
    },
    {
      skillGroup: 'Game Design',
      skill: [
        {
          name: 'Player Mechanics',
        },
        {
          name: 'Level Design',
        },
        {
          name: 'Balancing',
        },
      ],
    },
  ],
  projects: [
    {
      name: 'Action RPG Game',
      description:
        'Developed a 3D action RPG game with dynamic combat mechanics and an engaging storyline.',
      startDate: '2019-02-01',
      endDate: '2020-05-31',
      present: false,
    },
    {
      name: 'Open-World Adventure Game',
      description:
        'Contributed to the development of an open-world adventure game, focusing on creating immersive environments and challenging gameplay.',
      startDate: '2017-08-15',
      endDate: '2018-12-31',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Excellence in Game Programming',
      date: '2022-03-10',
      awarder: 'Electronic Arts (EA)',
      summary:
        'Recognized for exceptional contributions to game programming and innovative gameplay mechanics.',
    },
    {
      title: 'Innovation Award',
      date: '2018-10-18',
      awarder: 'Ubisoft',
      summary:
        'Received for innovative solutions and impactful contributions to the development of immersive game experiences.',
    },
  ],
  customSections: [],
}
