import { Field, FieldArray } from 'formik'

import { AccordionPage } from 'components/Forms/Editor/Accordion'

import { MinusIcon, PlusIcon } from '@heroicons/react/solid'
import DatePickerField from 'components/Forms/Editor/DatePickerField'
import QuillInput from './common/QuillInput'

function renderHighlights(props, field, outerIndex) {
  const { name } = props
  return (
    <div className="col-span-6 sm:col-span-12">
      <label
        htmlFor={`experience-highlight-${outerIndex}`}
        className="block text-sm font-medium text-gray-700"
      >
        Bullet Points
      </label>
      <div className="mt-1">
        {field.highlights &&
          field.highlights.map((value, index, array) => {
            const arraySize = array.length
            return (
              <div key={index} className="flex mb-2 gap-2">
                <div className="flex-1">
                  <QuillInput
                    id={`${name}.${index}`}
                    name={`${name}.${index}`}
                    placeholder="Add bullet point to showcase your impact"
                  />
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => props.remove(index)}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <MinusIcon
                      className="-ml-2 -mr-2 h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            )
          })}
        <div className="col-span-6 flex">
          <div className="mt-1 flex flex-col">
            <button
              type="button"
              onClick={() => props.push('')}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PlusIcon
                className="-ml-2 mr-1 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
              Add Bullet Point
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function ExperienceForm(index, field) {
  return (
    <div className="m-4 grid grid-cols-6 md:grid-cols-12 gap-6">
      <div className="col-span-6 sm:col-span-12">
        <label
          htmlFor={`experience-position-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          Position
        </label>
        <Field
          component="input"
          type="text"
          name={`experience.${index}.position`}
          id={`experience-position-${index}`}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
          placeholder="Position Name"
        />
      </div>

      <div className="col-span-6 sm:col-span-6">
        <label
          htmlFor={`experience-company-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          Company
        </label>
        <Field
          component="input"
          type="text"
          name={`experience.${index}.company`}
          id={`experience-company-${index}`}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
          placeholder="Company Name"
        />
      </div>

      <div className="col-span-4 sm:col-span-4">
        <label
          htmlFor={`experience-city-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          City
        </label>
        <Field
          component="input"
          type="text"
          name={`experience.${index}.location.city`}
          id={`experience-city-${index}`}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
          placeholder="City"
        />
      </div>

      <div className="col-span-2 sm:col-span-2">
        <label
          htmlFor={`experience-region-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          State
        </label>
        <Field
          component="input"
          type="text"
          name={`experience.${index}.location.region`}
          id={`experience-region-${index}`}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
          placeholder="State"
        />
      </div>

      <div className="col-span-3 sm:col-span-3 sm:col-start-1">
        <label
          htmlFor={`experience-start_date-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          Start Date
        </label>
        <DatePickerField
          name={`experience.${index}.startDate`}
          id={`experience-start_date-${index}`}
          className="py-2 px-3 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md"
        />
      </div>

      <div className="col-span-3 sm:col-span-3">
        <label
          htmlFor={`experience-end_date-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          End Date
        </label>
        <DatePickerField
          name={`experience.${index}.endDate`}
          id={`experience-end_date-${index}`}
          className="py-2 px-3 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md"
        />
      </div>

      <div className="col-span-6 sm:col-span-4">
        <label className="inline-flex items-center cursor-pointer md:mt-8">
          <Field
            component="input"
            type="checkbox"
            name={`experience.${index}.present`}
            id="present"
            className="form-checkbox border rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
          />
          <span className="ml-2 text-sm font-semibold text-blueGray-600">
            Present
          </span>
        </label>
      </div>

      <div className="col-span-6 sm:col-span-12">
        <label
          htmlFor={`experience-summary-${index}`}
          className="block text-sm font-medium text-gray-700"
        >
          Summary
        </label>
        <div className="mt-1">
          <QuillInput
            id={`experience-summary-${index}`}
            name={`experience.${index}.summary`}
            placeholder="Include a short summary of your responsibilities, projects, and/or accomplishments"
          />
        </div>
      </div>

      <FieldArray
        name={`experience.${index}.highlights`}
        render={(arrayHelpers) => renderHighlights(arrayHelpers, field, index)}
      />
    </div>
  )
}

export default function ExperiencesForm(props) {
  return (
    <>
      <div className="mt-5 md:mt-0 md:col-span-3">
        <div className="overflow-hidden sm:rounded-md">
          <div className="px-4 sm:px-6">
            <FieldArray
              name="experience"
              render={(arrayHelpers) => (
                <AccordionPage
                  {...arrayHelpers}
                  render={ExperienceForm}
                  nestedArray="highlights"
                  header="Experience"
                  titleSelector="company"
                  subtitleSelector="position"
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  )
}
