import { Field } from 'formik'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const QuillInput = (props) => {
  const modules = {
    toolbar: [['bold', 'italic', 'underline']],
  }
  const formats = ['bold', 'italic', 'underline']
  return (
    <Field id={props.id} name={props.name}>
      {({ field }) => {
        return (
          <ReactQuill
            value={field.value}
            onChange={(value) => {
              if (value === '<p><br></p>') {
                field.onChange(field.name)('')
              } else field.onChange(field.name)(value)
            }}
            modules={modules}
            formats={formats}
            placeholder={props.placeholder}
          />
        )
      }}
    </Field>
  )
}

export default QuillInput
