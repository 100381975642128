export const resume = {
  basics: {
    firstName: 'Jessica',
    lastName: 'Miller',
    title: 'Lead Software Engineer',
    picture: 'https://example.com/jessica-miller.jpg',
    email: 'jessica.miller@example.com',
    phone: '123-456-7890',
    url: 'https://www.jessicamiller.com',
    summary:
      'Results-oriented lead software engineer with a track record of driving technical excellence and leading high-performing engineering teams. Passionate about solving complex problems, optimizing software systems, and delivering innovative solutions.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/jessicamiller',
    portfolioUrl: 'https://portfolio.jessicamiller.com',
  },
  experience: [
    {
      company: 'Apple Inc.',
      location: {
        city: 'Cupertino',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Lead Software Engineer',
      url: 'https://www.apple.com',
      startDate: '2018-10-01',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Led a cross-functional team of 20 engineers in the development of a high-performance cloud-based application, resulting in a 40% reduction in latency and a 30% increase in user satisfaction.',
        'Architected a microservices-based system, improving scalability by enabling seamless integration of new features and services.',
        'Implemented continuous integration and deployment processes, reducing software release cycles from weeks to days.',
        'Mentored junior engineers, fostering skill development and promoting a culture of technical excellence.',
      ],
      present: false,
    },
    {
      company: 'Microsoft Corporation',
      location: {
        city: 'Redmond',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Senior Software Development Engineer',
      url: 'https://www.microsoft.com',
      startDate: '2015-06-15',
      endDate: '2018-09-30',
      summary: null,
      highlights: [
        'Led the migration of a monolithic legacy application to a cloud-native architecture, resulting in a 50% reduction in operational costs and improved scalability.',
        'Developed performance monitoring tools, enhancing the identification and resolution of performance bottlenecks and reducing system downtime by 25%.',
        "Received the Microsoft Achievement Award for outstanding contributions to the company's technical capabilities.",
      ],
      present: false,
    },
    {
      company: 'Intel Corporation',
      location: {
        city: 'Santa Clara',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Software Engineer',
      url: 'https://www.intel.com',
      startDate: '2012-09-01',
      endDate: '2015-05-31',
      summary: null,
      highlights: [
        'Designed and implemented optimized algorithms for real-time data processing, resulting in a 30% improvement in processing speed.',
        'Collaborated with hardware engineers to develop device drivers and system software, ensuring seamless integration of new hardware features.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2010-09-01',
      endDate: '2012-06-30',
      gpa: '3.9',
      courses: [
        'Advanced Algorithms',
        'Distributed Systems',
        'Artificial Intelligence',
      ],
      summary: '',
      present: false,
    },
    {
      institution: 'University of California, Berkeley',
      degree:
        'Bachelor of Science in Electrical Engineering and Computer Science',
      major: 'Electrical Engineering and Computer Science',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2006-09-01',
      endDate: '2010-05-31',
      gpa: '3.7',
      courses: ['Operating Systems', 'Computer Networks', 'Database Systems'],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Apple Technical Excellence Award',
      date: '2023-03-15',
      awarder: 'Apple Inc.',
      summary:
        'Recognized for outstanding technical leadership and contributions to complex software projects.',
    },
    {
      title: 'Microsoft Achievement Award',
      date: '2018-09-10',
      awarder: 'Microsoft Corporation',
      summary:
        "Received for driving innovation and delivering impactful improvements to the company's technical capabilities.",
    },
  ],
  skills: [
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'Java' }, { name: 'C++' }, { name: 'Python' }],
    },
    {
      skillGroup: 'Software Architecture',
      skill: [
        { name: 'Microservices' },
        { name: 'Cloud-Native' },
        { name: 'Distributed Systems' },
      ],
    },
    {
      skillGroup: 'Leadership',
      skill: [
        { name: 'Team Management' },
        { name: 'Technical Leadership' },
        { name: 'Mentorship' },
      ],
    },
  ],
  projects: [
    {
      name: 'High-Performance Data Processing Framework',
      description:
        'Led the design and development of a high-performance data processing framework capable of handling massive data streams in real-time.',
      startDate: '2021-01-01',
      endDate: '2022-12-31',
      present: false,
    },
    {
      name: 'Cloud-Native Application Migration',
      description:
        'Led the migration of a legacy monolithic application to a cloud-native architecture, optimizing scalability and reducing operational costs.',
      startDate: '2019-03-01',
      endDate: '2020-06-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Technical Conferences',
      details: [
        {
          title: 'IEEE International Conference on Software Engineering',
          subtitle: 'Speaker',
          location: {
            city: 'San Francisco',
            countryCode: 'US',
            region: 'California',
          },
          url: 'https://www.icseconf.org',
          startDate: '2022-05-01',
          endDate: '2022-05-05',
          summary:
            'Presented on the challenges and best practices of modernizing legacy software systems.',
          highlights: [
            'Engaged in panel discussions on software architecture trends and future directions.',
            'Provided technical insights on designing scalable and maintainable software systems.',
          ],
          present: false,
        },
      ],
    },
  ],
}
