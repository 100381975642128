import ActionTypes from '../actions/types'

const initialState = {
  premiumDownloadModal: {
    isOpen: false,
    props: {
      resume: {},
    },
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_MODAL:
      return {
        ...state,
        [action.modalType]: {
          isOpen: true,
          props: action.props || {},
        },
      }
    case ActionTypes.HIDE_MODAL:
      return {
        ...state,
        [action.modalType]: {
          isOpen: false,
          props: {},
        },
      }
    default:
      return state
  }
}
