import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'

export default function Questions() {
  return (
    <div className="w-full my-16">
      <div className="w-full max-w-3xl p-2 mx-auto">
        <div className="text-center mt-4 lg:mt-8">
          <h2 className="text-3xl md:text-4xl font-extrabold mb-8">
            Frequently Asked Questions
          </h2>
          {/* <h3 className="text-lg md:text-xl text-gray-700 mb-10">
            Find answers to the questions we get asked by job seekers like you.
          </h3> */}
        </div>
        <Disclosure as="div" className="bg-white">
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`${
                  open ? 'border-b-0 rounded-t-lg' : 'rounded-lg'
                } flex justify-between w-full p-6 font-medium text-left border focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}
              >
                <span>Can I use Resumary for free?</span>
                <ChevronUpIcon
                  className={`${open ? 'transform rotate-180' : ''} w-5 h-5`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="p-6 pt-0 text-sm text-gray-500 border border-t-0 rounded-b-lg">
                Yes, the Starter plan is free-to-use and doesn't require
                purchasing a subscription. It's a great plan to try out the
                different features and to start building your resume.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2 bg-white">
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`${
                  open ? 'border-b-0 rounded-t-lg' : 'rounded-lg'
                } flex justify-between w-full p-6 font-medium text-left border focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}
              >
                <span>Do you have a Student Discount?</span>
                <ChevronUpIcon
                  className={`${open ? 'transform rotate-180' : ''} w-5 h-5`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="p-6 pt-0 text-sm text-gray-500 border border-t-0 rounded-b-lg">
                Yes, we do offer a discount for students! Students get their
                first month of Premium for free to get a head start on the job
                search. Make sure to sign up using your .edu email to activate
                the discount during checkout.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2 bg-white">
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`${
                  open ? 'border-b-0 rounded-t-lg' : 'rounded-lg'
                } flex justify-between w-full p-6 font-medium text-left border focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}
              >
                <span>Still have more questions?</span>
                <ChevronUpIcon
                  className={`${open ? 'transform rotate-180' : ''} w-5 h-5`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="p-6 pt-0 text-sm text-gray-500 border border-t-0 rounded-b-lg">
                If you have still have more questions about Resumary, feel free
                to reach out to us at{' '}
                <a className="text-blue-400" href="mailto:support@resumary.com">
                  support@resumary.com
                </a>
                .
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  )
}
