import { useEffect, useState } from 'react'

const DateInput = ({
  initialValue,
  setValue,
  className,
  name,
  label,
  present,
}) => {
  const [text, setText] = useState(initialValue)

  const handleChange = (e) => {
    const date = e.target.value
    if (!date) {
      setValue('')
      return setText('')
    }

    const dValue = date.split('/')
    if (dValue.length === 1) {
      const month = dValue[0]
      if (/^\d{1}$/.test(month) && month >= 0 && month < 2) {
        return setText(date)
      }
      if (/^\d{1}$/.test(month) && month >= 2 && month < 10) {
        return setText('0' + date)
      }
      if (/^\d{2}$/.test(month) && month > 0 && month <= 12) {
        return setText(date)
      }
      if (/^\d{3}$/.test(month)) {
        const year = month.charAt(2)
        if (year < 1 || year > 2) return
        return setText(
          month.charAt(0) + month.charAt(1) + '/' + month.charAt(2)
        )
      }
    } else if (dValue.length === 2) {
      const year = dValue[1]
      if (dValue[1] === '') {
        if (text.length > date.length) {
          const month = dValue[0]
          return setText(month.charAt(0) + month.charAt(1))
        } else {
          const month = dValue[0]
          return setText(month.charAt(0) + month.charAt(1) + '/')
        }
      }
      if (/^\d{1}$/.test(year) && year >= 1 && year <= 2) {
        return setText(date)
      }
      if (/^\d{2}$/.test(year) && year >= 19 && year <= 20) {
        return setText(date)
      }
      if (/^\d{3}$/.test(year) && year >= 190 && year <= 205) {
        return setText(date)
      }
      if (/^\d{4}$/.test(year) && year >= 1900 && year <= 2059) {
        setValue(date)
        return setText(date)
      }
    }
  }

  useEffect(() => {
    if (present) {
      setText('')
      setValue('')
    }
  }, [present])

  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={name}
          className="block text-xs font-normal pb-2 dark:text-white"
        >
          {label}
        </label>
      )}
      <input
        // type="number"
        type="tel"
        // pattern="[0-9]*"
        disabled={present}
        id={name}
        value={text}
        onChange={handleChange}
        className="disabled:opacity-60 py-2 px-3 block w-full border-gray-200 bg-blueGray-100 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
        placeholder="MM/YYYY"
      />
    </div>
  )
}

export default DateInput
