import { StyleSheet } from '@react-pdf/renderer'
import OneColumnLayout from '../common/layouts/OneColumnLayout'
import SplitTemplateEntry from '../common/template-entry/SplitTemplateEntry'
import DefaultTemplateSection from '../common/template-section/DefaultTemplateSection'
import HyphenatedCustomSectionValueMapper from '../common/value-mappers/custom-section/HyphenatedCustomSectionValueMapper'
import EducationValueMapper from '../common/value-mappers/education/EducationValueMapper'
import HyphenatedExperienceValueMapper from '../common/value-mappers/experience/HypenatedExperienceValueMapper'
import ProjectValueMapper from '../common/value-mappers/project/ProjectValueMapper'
import SkillValueMapper from '../common/value-mappers/skill/SkillValueMapper'
import Header from '../fresh/sections/Header'
import DateEducationValueMapper from '../common/value-mappers/education/DateEducationValueMapper'
import CondensedSkillValueMapper from '../common/value-mappers/skill/CondensedSkillValueMapper'
import SplitTemplateSection from '../common/template-section/SplitTemplateSection'

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 50,
    paddingVertical: 40,
    fontFamily: 'Open Sans',
  },
  container: {
    flexDirection: 'row',
    marginBottom: 9,
  },
  sectionTitle: {
    fontFamily: 'Open Sans',
    fontSize: 7.5,
    paddingTop: 8,
    textTransform: 'uppercase',
    borderTop: '1 solid black',
    textAlign: 'right',
    fontWeight: 600,
  },
  entryContainer: {
    marginBottom: 6,
  },
  subtitle: {
    fontSize: 8,
    fontFamily: 'Open Sans',
    color: '#71717A',
  },
  details: {
    marginTop: 2,
  },
  innerLeftColumn: {
    flexDirection: 'column',
    width: '96',
    paddingRight: 18,
  },
  innerRightColumn: {
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    fontSize: 8,
    fontFamily: 'Open Sans',
    fontWeight: 600,
  },
  skillSubtitle: {
    fontSize: 8,
    fontFamily: 'Open Sans',
  },
  item: {
    marginTop: 2,
  },
  htmlContent: {
    fontSize: 8,
  },
})

const FreshConfigs = {
  styles: styles,
  Layout: OneColumnLayout,
  Header: Header,
  entryTransformerMap: {
    education: DateEducationValueMapper,
    experience: HyphenatedExperienceValueMapper,
    projects: ProjectValueMapper,
    skills: CondensedSkillValueMapper,
    custom: HyphenatedCustomSectionValueMapper,
  },
  TemplateSection: SplitTemplateSection,
  TemplateEntry: SplitTemplateEntry,
  settings: {},
}

export default FreshConfigs
