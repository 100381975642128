import { ArrowNarrowRightIcon } from '@heroicons/react/outline'
import Breadcrumbs from 'components/Core/Breadcrumbs'
import HeadingRenderer from 'content/blog/HeadingRenderer'
import { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import { classNames } from 'utils/helper'

const ResumaryPromo = () => {
  const [scrollPast, setScrollPast] = useState(false)

  useEffect(() => {
    const onScroll = (e) => {
      setScrollPast(e.target.documentElement.scrollTop > 200)
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  return (
    <div className="rounded w-full p-6 lg:p-8 text-white bg-indigo-600 flex flex-col items-center justify-between gap-3">
      <div className="flex justify-center">
        <div className="relative w-1/2 sm:w-2/3 mb-3">
          <img
            src={require('assets/img/Template1.png')}
            className={classNames(
              scrollPast ? '-translate-x-8 -rotate-12' : '',
              'rounded border absolute top-0 left-0 transition-all duration-300'
            )}
          />
          <img
            src={require('assets/img/Template5.png')}
            className="rounded relative border w-full"
          />
          <img
            src={require('assets/img/Template6.png')}
            className={classNames(
              scrollPast ? 'translate-x-8 rotate-12' : '',
              'rounded border absolute top-0 right-0 transition-all duration-300'
            )}
          />
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-xl font-semibold text-center">
          Create a professional resume in 15 minutes
        </div>
        <Link
          to="/auth/register"
          className="text-center py-2 px-6 w-full rounded font-medium bg-white text-indigo-600 flex items-center justify-center gap-2 hover:bg-gray-100"
        >
          <span className="flex-none">Get started</span>
          <ArrowNarrowRightIcon className="w-5 h-5" />
        </Link>
      </div>
    </div>
  )
}

export default function BlogPost(props) {
  const [markdown, setMarkdown] = useState(null)
  const [toc, setToc] = useState(null)

  useEffect(() => {
    const fetchMarkdown = async () => {
      var path = await import(`assets/${props.post.filepath}`)
      fetch(path.default)
        .then((response) => response.text())
        .then((text) => {
          var arr = text.split(':::Resumary:::')
          setToc(arr[0])
          setMarkdown(arr[1])
        })
    }
    fetchMarkdown()
  }, [])

  return (
    <div>
      <Helmet>
        <title>{`${props.post.title} | Resumary`}</title>
      </Helmet>
      <div className="max-w-7xl mx-auto py-8 sm:py-12 lg:py-24 grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-12 px-4 sm:px-6 lg:px-8">
        <div className="mt-3 hidden lg:block sticky top-8 self-start pl-6 rounded w-full text-gray-900">
          <div className="prose prose-sm">
            <ReactMarkdown children={toc} />
          </div>
        </div>
        <div className="col-span-1 sm:col-span-2 lg:col-span-2">
          <div className="flex mb-3">
            <Breadcrumbs
              links={[
                { to: '/career-guide', text: 'Career Guide' },
                { to: '', text: props.post.title },
              ]}
            />
          </div>
          <div className="prose sm:prose-lg">
            <ReactMarkdown
              children={markdown}
              components={{
                h2: HeadingRenderer,
                h6: () => (
                  <div className="sm:hidden mb-3">
                    <ResumaryPromo />
                  </div>
                ),
              }}
            />
          </div>
        </div>
        <div className="hidden sm:block mt-3 sticky top-8 self-start">
          <ResumaryPromo />
        </div>
      </div>
    </div>
  )
}
