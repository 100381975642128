import { showModal } from 'actions/actions'
import { ResumeContext } from 'layouts/Resume'
import { useContext, useState } from 'react'
import { TwitterPicker } from 'react-color'
import { useDispatch } from 'react-redux'

export default function ColorSelector(props) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const dispatch = useDispatch()
  const { resume } = useContext(ResumeContext)

  const handleClick = () => {
    if (!resume.premium) {
      dispatch(
        showModal('premiumDownloadModal', {
          resume: resume,
          width: 'max-w-4xl',
          type: 'download',
        })
      )
      return
    }
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const handleChangeComplete = (color) => {
    props.form.setFieldValue(props.field.name, color.hex)
  }

  const popover = {
    position: 'absolute',
    zIndex: '2',
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  const picker = {
    position: 'absolute',
    top: '15px',
    // right: -myRef.current?.offsetWidth,
  }

  const button = {
    backgroundColor: props.field.value || '#000000',
  }

  return (
    <div>
      <button
        type="button"
        onClick={handleClick}
        className="mt-1 px-3 py-3 bg-white focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border border-gray-300 rounded-md"
      >
        <div className="h-4 rounded" style={button}></div>
      </button>
      {displayColorPicker ? (
        <div style={popover}>
          <div style={cover} onClick={handleClose} />
          <div style={picker}>
            <TwitterPicker
              colors={[
                '#000000',
                '#FF6900',
                '#FCB900',
                '#7BDCB5',
                '#00D084',
                '#8ED1FC',
                '#0693E3',
                '#EB144C',
                '#F78DA7',
                '#9900EF',
              ]}
              color={props.field.value || '#000000'}
              onChangeComplete={handleChangeComplete}
              triangle="top-left"
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}
