import ActionTypes from '../actions/types'

const initialState = {
  stripeSessionId: undefined,
  isSubscribed: undefined,
}

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_STRIPE_SESSION:
      return {
        ...state,
        stripeSessionId: action.payload,
      }
    case ActionTypes.SET_IS_SUBSCRIBED:
      return {
        ...state,
        isSubscribed: action.payload,
      }
    case ActionTypes.CLEAR_STRIPE:
      return initialState
    default:
      return state
  }
}
