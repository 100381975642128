import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

const ReviewPage = () => {
  const [crunchbase, setCrunchbase] = useState(null)
  const params = useParams()
  const navigate = useNavigate()

  const name = co[params.idx]
  const careerPage = career[params.idx]

  const image_id = crunchbase?.properties?.identifier?.image_id
  const website_url = crunchbase?.properties?.website_url

  useEffect(() => {
    const searchCompany = (company) => {
      axios
        .get(`/companies/${company}`)
        .then((res) => {
          setCrunchbase(res.data)
        })
        .catch((err) => {
          console.log('error', err)
        })
    }

    if (name) searchCompany(name)
  }, [name])

  return (
    <div className="px-6 w-full flex flex-col gap-3 justify-start items-start">
      <div className="flex w-full justify-between gap-6">
        <button
          className="py-2 px-6 border rounded-lg"
          onClick={() =>
            navigate(`/dashboard/review/${parseInt(params.idx) - 1}`)
          }
        >
          Back
        </button>
        <button
          className="py-2 px-6 border rounded-lg"
          onClick={() =>
            navigate(`/dashboard/review/${parseInt(params.idx) + 1}`)
          }
        >
          Next
        </button>
      </div>
      <div className="py-1 flex items-center space-x-6">
        {image_id ? (
          <img
            className="w-20 flex-none bg-white"
            alt="logo"
            src={`https://res.cloudinary.com/crunchbase-production/image/upload/${image_id}`}
          ></img>
        ) : (
          <div className="w-20 h-20 bg-gray-100 animate-pulse"></div>
        )}
        <span>{crunchbase?.properties?.identifier?.value}</span>
        <span>crunchbase: {name}</span>
        <a href={website_url} target="_blank">
          {website_url}
        </a>
        <a href={website_url + '/careers'} target="_blank">
          {website_url + '/careers'}
        </a>
        <a href={careerPage} target="_blank">
          {careerPage}
        </a>
      </div>
      <div className="py-3 w-full grid grid-cols-2 gap-6">
        <iframe className="w-full h-[600px]" src={website_url} />
        {careerPage && <iframe className="w-full h-[600px]" src={careerPage} />}
        <iframe className="w-full h-[600px]" src={website_url + '/careers'} />
      </div>
    </div>
  )
}

export default ReviewPage

const co = [
  '100ms',
  '1047-games',
  '10x-genomics',
  '1910-genetics',
  '1password',
  '1uphealth',
  '23andme',
  '2k',
  '6sense',
  'a-thinking-ape',
  'ethlend',
  'abelsontaylor',
  'abelsontaylor',
  'able-2',
  'able-2',
  'able-2',
  'ableto',
  'abnormal-security',
  'abridge-d1a4',
  'access-softek',
  'accesso',
  'acelerate',
  'AchieveFinancial',
  'aclima',
  'acorns-grow',
  'actioniq',
  'actioniq',
  'adaptive-08c7',
  'addepar',
  'addepar',
  'addi',
  'addi',
  'addi',
  'adyen',
  'fusionops',
  'fusionops',
  'aetion-2',
  'aetion-2',
  'aeva',
  'affinitiv',
  'affinity-inc',
  'affinity-inc',
  'affirm',
  'afresh-technologies',
  'afresh-technologies',
  'agentsync',
  'agero',
  'agtonomy',
  'the-ai-fleet-inc',
  'aigen',
  'airbnb',
  'airbyte-b3bb',
  'aircall',
  'aircall',
  'airkit',
  'airkit',
  'airplane',
  'airtable',
  'airtable',
  'alpha-health',
  'alpha-health',
  'akuna-capital',
  'alation',
  'alchemy-insights',
  'algolia',
  'algolia',
  'alkymi',
  'alkymi',
  'alloy-2',
  'alloy-2',
  'alloy-2',
  'alluxio',
  'aloft-appraisal',
  'alpacadb',
  'alpacadb',
  'altana-trade',
  'altana-trade',
  'amass-botanics',
  'amino',
  'amp-robotics',
  'amp-robotics',
  'amperity',
  'ampla',
  'amplitude',
  'anaplan',
  'anchorage',
  'andela',
  'andela',
  'anduril-industries',
  'angi-homeservices',
  'ansira',
  'anyscale',
  'aon3d',
  'apeel-sciences',
  'apixio',
  'appian',
  'applied-intuition',
  'applyboard',
  'aptos-96b4',
  'aquatic-group',
  'arcadia-power-2',
  'armorblox',
  'arrive-logistics',
  'arsenalbio',
  'arta-finance',
  'arta-finance',
  'asana',
  'asana',
  'asapp',
  'ascend-io',
  'assembled',
  'assembled',
  'assemblyai',
  'assured-insurance-technologies',
  'astra-b6dc',
  'astra-b6dc',
  'astranis',
  'astranis',
  'atlan-239d',
  'atlassian',
  'atob',
  'atomic-2',
  'atomic-2',
  'atomwise',
  'attentive',
  'attest',
  'audius',
  'augmedix',
  'aurora-6292',
  'aurora-solar',
  'aurora-solar',
  'auterion',
  'autograph-862f',
  'autograph-862f',
  'ava-labs',
  'avantstay',
  'avenue-2468',
  'aviatrix',
  'away-2',
  'away-2',
  'away-2',
  'axiom-zen',
  'axoni',
  'b12io',
  'babylon-health',
  'bandwidth-com',
  'bark-co',
  'baseten',
  'beacon',
  'beacon',
  'beam-d6ad',
  'beam-d6ad',
  'beam-d6ad',
  'beamery',
  'beamery',
  'belvedere-trading-llc',
  'benchling',
  'benchling',
  'benchsci',
  'benefitfocus',
  'benepass',
  'berlinrosen',
  'bestow-inc',
  'bettercloud',
  'betterup',
  'betterup',
  'betterworks-2',
  'beyond-identity',
  'beyond-identity',
  'big-health',
  'big-time-studios',
  'billiontoone',
  'bilt-technologies',
  'bilt-technologies',
  'binance',
  'binance',
  'binti',
  'binti',
  'bitcoin-depot',
  'bitfury',
  'bitfury',
  'bitgo',
  'bitgo',
  'bitwarden',
  'bixal',
  'blameless',
  'blend-labs',
  'blend-labs',
  'blinkux',
  'blockfi-inc',
  'blockstream',
  'blockstream',
  'blue-bottle-coffee',
  'bolt-5',
  'bond-vet',
  'boom-technology',
  'boost-insurance',
  'boulevard-3',
  'boulevard-3',
  'boulevard-3',
  'bounteous',
  'bracebridge-capital',
  'brave-software',
  'braze',
  'breeze-airways',
  'brex',
  'brightedge',
  'brightflow-ai',
  'emilio-health',
  'brightwheel',
  'brightwheel',
  'brigit',
  'broadway-technology',
  'brooklinen',
  'brooks-running',
  'bubble',
  'bugcrowd',
  'bugcrowd',
  'built-in',
  'built-robotics',
  'business-wire',
  'businessolver',
  'butterfly-network',
  'buzzfeed',
  'caffeine',
  'caffeine',
  'calendly',
  'calm-com',
  'cambly',
  'cambly',
  'cameo-3',
  'canary-technologies',
  'candid-health',
  'candy-digital-d2d1',
  'canva',
  'capsule-3',
  'bay-labs-inc',
  'carbon3d',
  'carbon3d',
  'cardiologs',
  'cardlytics',
  'careem',
  'carrot-fertility',
  'eshares',
  'carta-healthcare',
  'castiron',
  'cb-insights',
  'centivo',
  'cents-ba2f',
  'cerebral',
  'certik',
  'chainalysis',
  'chainalysis',
  'chainlink',
  'chapter-4a29',
  'character-ai',
  'character-ai',
  'chargepoint',
  'charthop',
  'check-2a75',
  'checkr',
  'cherre',
  'chime-2',
  'chime-2',
  'chownow',
  'chronosphere',
  'chronosphere',
  'circle-2',
  'circle-ci',
  'cirrus-logic',
  'citizen-d7ce',
  'citizen-d7ce',
  'cityblock-health',
  'clabs',
  'clari',
  'clari',
  'classdojo',
  'classpass',
  'clearbit',
  'clever',
  'clever',
  'clickup',
  'clockwise',
  'cloudflare',
  'cloudtrucks',
  'clubhouse-voice',
  'clubhouse-voice',
  'clubhouse-voice',
  'clutter',
  'coalition-535d',
  'coast-967f',
  'coast-967f',
  'coda-add7',
  'coda-add7',
  'code-42-software',
  'coder',
  'cognite',
  'cognite',
  'coherus-biosciences',
  'cohesity',
  'coin-metrics-6fa3',
  'coindesk',
  'coindesk',
  'coinlist',
  'collectivehealth',
  'collectivehealth',
  'color-genomics',
  'color-genomics',
  'column-3577',
  'commercetools',
  'ovid-research',
  'confluent',
  'confluent',
  'content-square',
  'convoy-3',
  'convoy-3',
  'core-digital-media',
  'corelight',
  'cortex-98f5',
  'cortex-98f5',
  'corvus-insurance',
  'couchbase',
  'coursehero',
  'coursera',
  'covariant',
  'cover-genius',
  'cover-genius',
  'cowbell-cyber-inc',
  'cprime',
  'craft',
  'craft',
  'picasso-labs',
  'cred-6ab0',
  'credible',
  'credible',
  'cribl',
  'cribl',
  'critical-mass',
  'crossbeam',
  'crosswire',
  'crosswire',
  'cruise',
  'crunchbase',
  'crunchbase',
  'crusoe-energy-systems',
  'crux-informatics',
  'cubesoftware',
  'culdesac-f070',
  'curaleaf',
  'curated-6efa',
  'curated-6efa',
  'curative',
  'curology',
  'curve-health',
  'avanti-financial-group',
  'D2iQ',
  'dagger-d500',
  'daily-harvest',
  'daily-harvest',
  'dailypay-inc',
  'databook',
  'databricks',
  'data-camp',
  'data-camp',
  'datagrail',
  'dataiku',
  'dataminr',
  'datavant',
  'datavant',
  'dave-com',
  'dave-com',
  'dazn',
  'dealpath',
  'dealpath',
  'lockerdome',
  'deepcell',
  'deepgram',
  'deepgram',
  'deepmind',
  'degreed',
  'deliveroo',
  'deliveroo',
  'demiurge-studios',
  'descript',
  'descript',
  'deserve',
  'dexterity-93d4',
  'digit-4',
  'digital-trends',
  'digitalocean',
  'diligent-robotics',
  'cs-disco',
  'discord',
  'divvy-homes',
  'docugami',
  'states-title',
  'domeyard-llc',
  'domino-data-lab',
  'domino-data-lab',
  'domo',
  'domo',
  'doordash',
  'dopple-2',
  'doppler',
  'doximity',
  'doximity',
  'doxo',
  'dragos',
  'drata',
  'drivewealth',
  'drizly',
  'dronedeploy',
  'dropbox',
  'ducera-partners',
  'duolingo',
  'duolingo',
  'duolingo',
  'dwolla',
  'dwolla',
  'dydx',
  'dydx',
  'activehours',
  'earnup',
  'eco-66cd',
  'eco-66cd',
  'ecobee',
  'ecocart',
  'eden-health',
  'ekimetrics',
  'eko-health',
  'eko-health',
  'electric-hydrogen-co',
  'element-bioscience',
  'elementary-robotics',
  'elwood-asset-management',
  'embrace-io',
  'emerald-therapeutics',
  'emerald-therapeutics',
  'enable-f952',
  'enable-f952',
  'energy-recovery',
  'enova',
  'enthought',
  'enveda-therapeutics',
  'envoy',
  'epic-games-2',
  'epirus',
  'epsilon3',
  'equip-health',
  'ergeon',
  'ethena',
  'eventbrite',
  'respond-1781',
  'respond-1781',
  'everlaw',
  'evidation-health',
  'evocalize',
  'exabeam',
  'exabeam',
  'execonline',
  'exiger',
  'exoduspoint',
  'explo',
  'extreme-networks',
  'extreme-networks',
  'omicia',
  'facet-7c96',
  'indigo-fair',
  'indigo-fair',
  'fairmarkit',
  'falconx',
  'fampay',
  'fanatics',
  'fanatics',
  'fanduel',
  'farmwise',
  'byepass',
  'byepass',
  'fast-radius-inc',
  'fauna',
  'fauna',
  'fernish',
  'fictiv',
  'figma',
  'figure-66e9',
  'figure-66e9',
  'filecoin-foundation',
  'filevine',
  'finix-payments',
  'fireblocks',
  'firstbasehq',
  'fiscalnote',
  'fiscalnote',
  'five9',
  'flatfile',
  'flatfile',
  'flatfile',
  'flatiron-health',
  'flexe',
  'flexport',
  'flock-freight',
  'flock-safety',
  'floqast',
  'flow-traders',
  'flowhub',
  'flowspace',
  'flywheel-software-ab7d',
  'fnatic',
  'fnatic',
  'zipongo',
  'insidesherpa',
  'formic',
  'formlabs',
  'forte-e22c',
  'forte-e22c',
  'goforward',
  'bankonfound',
  'bankonfound',
  'found-energy',
  'foundry-digital-070f',
  'fountain',
  'fountain',
  'fourkites',
  'freedom-financial-network',
  'freenome',
  'front-app',
  'front-app',
  'ftx-exchange',
  'fueled',
  'full-stack-finance--llc',
  'fullstory',
  'fullstory',
  'fyllo',
  'gametime',
  'garner-health',
  'gather-ai',
  'gather-ai',
  'gauss-labs',
  'gauss-labs',
  'gecko-robotics',
  'gecko-robotics',
  'gecko-robotics',
  'gemini-99b2',
  'gemini-99b2',
  'general-assembly',
  'generally-intelligent',
  'genesis-therapeutics',
  'geotab',
  'gettyimages',
  'gettyimages',
  'ginkgo-bioworks',
  'ginkgo-bioworks',
  'github',
  'github',
  'gitlab-com',
  'gitlab-com',
  'givecampus',
  'glance-6e2a',
  'glassdoor',
  'scio-technologies',
  'goatapp',
  'godaddy',
  'goguardian',
  'goguardian',
  'golden-hippo-media',
  'goodrx',
  'gopuff',
  'gordian-software',
  'gorgias',
  'gorgias',
  'raintank',
  'grailed',
  'grammarly',
  'grammarly',
  'grand-rounds',
  'graphite-020e',
  'greenlight-4',
  'greenlight-4',
  'gretel-ai',
  'guideline-technologies-inc',
  'guidepoint-global',
  'guidewire-software',
  'gusto',
  'hackerrank',
  'hackerrank',
  'hadrian-caa2',
  'hadrian-caa2',
  'handshake-2',
  'agora-9',
  'hap-capital',
  'happyfresh',
  'harmony-9000',
  'harness-512d',
  'hashicorp',
  'trw-consulting-services-inc',
  'tourlandish',
  'headway-6412',
  'headway-6412',
  'heal',
  'healthverity',
  'heap',
  'heartbeat-4',
  'helion-energy',
  'hellofresh',
  'hermeus',
  'hex-technologies-inc',
  'heycar',
  'highnote-platform',
  'hightouch-0e87',
  'hightouch-0e87',
  'hinge-health',
  'hipcamp',
  'hiro-systems',
  'hive-dc0c',
  'hive-dc0c',
  'homebase',
  'homebase',
  'homelight',
  'hometap',
  'homexco',
  'hopin-8ff6',
  'hq-digital',
  'hqo',
  'hubspot',
  'hudl',
  'humaninterest',
  'huntress',
  'hyperscience',
  'hyprcorp',
  'ibotta',
  'icertis',
  'illumio',
  'immuta',
  'imperfect-produce',
  'impossible-foods',
  'imprint-cd0f',
  'inari-8d6b',
  'incredible-health',
  'indigov',
  'insitro',
  'inspire-energy',
  'inspire11',
  'instabase',
  'instacart',
  'integralads',
  'interactive-brokers-group',
  'intercom',
  'interfolio',
  'internet-brands',
  'intersection-2',
  'intro-537e',
  'invisionapp',
  'invitae-corporation',
  'invoca',
  'ionq-inc',
  'ionq-inc',
  'iron-fish',
  'ironclad',
  'ironclad',
  'isometric-technologies',
  'iterative-scopes',
  'ixl-learning',
  'jackpocket',
  'jam-city',
  'jamf-software',
  'january-technologies',
  'january-technologies',
  'jasper-da51',
  'jerry-3',
  'jobget',
  'joytheapp',
  'jump-trading-llc',
  'just-appraised',
  'justworks',
  'juul',
  'kabam',
  'kalepa',
  'kalshi',
  'kandji',
  'kandji',
  'kapwing',
  'karat',
  'karat',
  'kasisto-inc',
  'kayak',
  'kensho',
  'kensho',
  'cloudhelix-inc',
  'kevel',
  'khan-academy',
  'kin-insurance',
  'kinside',
  'kitcheck',
  'kitware',
  'kkr',
  'klaviyo',
  'klue',
  'knoetic',
  'knowbe4-llc',
  'koddi',
  'koddi',
  'komodo-health',
  'konghq',
  'konrad-group',
  'kraken',
  'kraken',
  'kraken',
  'kunai',
  'labelbox',
  'lacework',
  'laika-e1e8',
  'landis',
  'landis',
  'lark',
  'latergramme',
  'launchdarkly',
  'launchdarkly',
  'leaflink',
  'league',
  'leandata',
  'leantaas',
  'leantaas',
  'leapyear-technologies',
  'ledger-investing',
  'ledger-investing',
  'lendbuzz',
  'lendingtree',
  'lendingtree',
  'lesson-ly',
  'lev',
  'lever',
  'lever',
  'levro',
  'lilt',
  'lilt',
  'lilt',
  'limebike',
  'limebike',
  'linear-app',
  'linear-app',
  'linus-health',
  'little-otter',
  'lob',
  'lob',
  'logikcull',
  'logikcull',
  'logitech',
  'logrocket',
  'loom',
  'atieva',
  'digital-brain',
  'luminary-cloud',
  'lumos-ab09',
  'luxury-presence',
  'lyft',
  'lyra-health',
  'lyra-health',
  'mabl',
  'machinify',
  'madison-logic',
  'madison-logic',
  'madkudu',
  'magical-52fc',
  'nanocore',
  'mark43',
  'marqeta',
  'marqeta',
  'mashgin',
  'mason',
  'match-group',
  'matician',
  'matician',
  'matterport',
  'may-mobility',
  'massachusetts-bay-transportation-authority',
  'medely',
  'medium',
  'medium',
  'meero',
  'meero',
  'memora-health',
  'menlo-security',
  'menlo-security',
  'mento-7306',
  'mercury-technologies',
  'mercury-technologies',
  'merge-69aa',
  'merge-69aa',
  'merit',
  'merit',
  'messagebird',
  'messagebird',
  'messagebird',
  'metadata',
  'meter-5c65',
  'meter-5c65',
  'metropolis-db86',
  'logdna',
  'logdna',
  'middesk',
  'zwieco',
  'mindsdb',
  'minted',
  'realtimeboard',
  'stratalux',
  'mission-lane',
  'mixpanel',
  'modern-animal',
  'modern-treasury',
  'modernloop',
  'monad-3a10',
  'monad-3a10',
  'mongodb-inc',
  'monte-carlo-3232',
  'monte-carlo-3232',
  'monzo',
  'moonpay',
  'morning-brew',
  'morning-brew',
  'mothership-7ba8',
  'hyundai-aptiv-autonomous-driving-joint-venture',
  'Motive',
  'Motive',
  'Motive',
  'moveworks',
  'moxe-health',
  'moxion-power-co',
  'mozilla',
  'mparticle',
  'mparticle',
  'mudflap-9393',
  'multiply-labs',
  'mural-ly',
  'mural-ly',
  'mutinyhq',
  'myfitnesspal',
  'mysten-labs',
  'isocline',
  'narmi',
  'nash-3281',
  'nash-3281',
  'natera',
  'national-research-group',
  'nationbuilder',
  'creditera',
  'nava-2',
  'nava-2',
  'neeva',
  'neo-cybernetica',
  'neondatabase',
  'nerdwallet',
  'ness-a336',
  'ness-a336',
  'netflix',
  'netlify',
  'netlify',
  'neuralink',
  'abe-4',
  'newsela',
  'nexhealth',
  'adroll-semantic-sugar-inc',
  'adroll-semantic-sugar-inc',
  'nianticlabs',
  'nianticlabs',
  'nimble-pharmacy',
  'ninja-van-2',
  'nextev',
  'nobl9',
  'nomi-health',
  'normalyze',
  'northspyre',
  'northwestern-mutual-life',
  'notable',
  'notarize',
  'notarize',
  'notion-so',
  'notion-so',
  'novetta-solutions',
  'nowsta',
  'nuna-health',
  'nutanix',
  'nylas',
  'options-clearing-corporation',
  'ockam',
  'odeko',
  'okcoin',
  'okcoin',
  'omada-health',
  'omaze',
  'one-medical-group',
  'gata-labs',
  'gata-labs',
  'oneschema',
  'one-signal',
  'onna-com',
  'opaque',
  'openai',
  'openai',
  'opencare',
  'opencare',
  'opendoor-2',
  'openinvest',
  'openphone',
  'opensea',
  'opensea',
  'openstore',
  'openzeppelin',
  'optimism',
  'optiver',
  'optoro',
  'orchardhomes',
  'orum',
  'orum',
  'osaro',
  'osaro',
  'osmo',
  'outliant',
  'outreach',
  'outreach',
  'outschool',
  'outschool',
  'outset-medical',
  'owl-labs',
  'ownbackup',
  'oxio',
  'oxio',
  'oyster-3150',
  'pachyderm',
  'paddle',
  'paddle',
  'pagerduty',
  'palantir-technologies',
  'palantir-technologies',
  'panther-labs',
  'papa-technologies-llc',
  'parafin-1b48',
  'parallel-wireless',
  'parasol-d2ae',
  'parkade',
  'patch-34bf',
  'patch-34bf',
  'pathai',
  'pathai',
  'pathrise',
  'patreon',
  'pave',
  'pave',
  'pave',
  'pax8',
  'pax8',
  'payfit',
  'payit',
  'payscale',
  'pdt-partners',
  'pdt-partners',
  'peloton-interactive',
  'peloton-interactive',
  'peptilogics',
  'peptilogics',
  'perchwell',
  'perpay-inc',
  'persona-188f',
  'persona-188f',
  'petuum',
  'phonepe',
  'physna',
  'pic-collage',
  'pickle-robots',
  'picogrid',
  'pie-insurance',
  'pilot-a94a',
  'pilot-a94a',
  'pilot-a94a',
  'pinterest',
  'pitch-5b94',
  'plaid',
  'plaid',
  'platform-science',
  'plotly',
  'plotly',
  'plume-design-inc',
  'plume-design-inc',
  'pocket-gems',
  'podium-2',
  'point-4',
  'point-b-inc',
  'point72',
  'pointclickcare',
  'policy-genius',
  'policy-genius',
  'pollyex',
  'pollyex',
  'poshmark',
  'postera-e932',
  'postman',
  'blue-marble',
  'power-finance-e858',
  'original-tech',
  'premise',
  'premise',
  'preset',
  'prime-medicine',
  'primer-7cbe',
  'prisma-io',
  'prisma-io',
  'privitar',
  'privitar',
  'projectronin',
  'projectronin',
  'projectronin',
  'projekt202',
  'projekt202',
  'propel-4',
  'propel-4',
  'protocol-labs',
  'proveidentity',
  'psyonix',
  'publicdotcom',
  'pulsedata',
  'pulumi-corporation',
  'pure-storage',
  'pure-storage',
  'pushpay',
  'pyka',
  'braid-75b6',
  'pythian',
  'pythian',
  'qualtrics',
  'qualtrics',
  'quantcast',
  'quantum-metric',
  'quartzy',
  'quest-analytics',
  'quidel',
  'qumulo',
  'qumulo',
  'quora',
  'quora',
  'analyticsmd',
  'analyticsmd',
  'ramp-financial',
  'ramp-financial',
  'rapp',
  'rarible',
  'ready-e28c',
  'reality-defender',
  'recess',
  'recorded-future',
  'red-ventures',
  'reddit',
  'reddit',
  'redesign-health',
  'redwood-materials-3',
  'reforge',
  'reforge',
  'regal-voice',
  'reify-health',
  'kcura',
  'kcura',
  'relay-b375',
  'relay-b375',
  'relay-therapeutics',
  'reliable-robotics',
  'remitly',
  'render',
  'rent-the-runway',
  'replit',
  'retool',
  'retool',
  'revenuecat',
  'revolut',
  'rhombus-power',
  'ribbonhealth',
  'ridecell',
  'ridgeline-ef0d',
  'rigetti-computing',
  'riot-games',
  'ripcord-2',
  'ripcord-2',
  'ripple-labs',
  'ripple-labs',
  'rippling',
  'riverside-research',
  'rivian-automotive',
  'roman-2',
  'robinhood',
  'roblox',
  'roboflow',
  'rockset',
  'roku',
  'roofr',
  'routable',
  'real-time-innovations',
  'rundoo',
  'rutter-1560',
  'rvo-health',
  'sambanova-systems',
  'samsara-2',
  'samsungs-research-and-development-center',
  'sana-benefits',
  'sanctuary-ai',
  'sardine',
  'scale-2',
  'scale-2',
  'scality',
  'scality',
  'schoolhouse',
  'sciencelogic',
  'scratch-fi',
  'scribd',
  'scribd',
  'searchlight-057a',
  'searchlight-057a',
  'seatgeek',
  'securitize-inc',
  'security-scorecard',
  'security-scorecard',
  'seekout',
  'seesaw-2',
  'seesaw-2',
  'self-lender',
  'sentry',
  'serve-robotics',
  'seven-eight-capital',
  'sezzle-inc',
  'sharethrough',
  'sharkninja-2',
  'shef',
  'shield-ai',
  'shield-ai',
  'shiftcars',
  'shiftcars',
  'shopmonkey-io',
  'short-story',
  'short-story',
  'shutterfly',
  'sigma-computing',
  'signal-messenger',
  'signal-messenger',
  'sigopt',
  'simplisafe',
  'simpplr-inc',
  'simpplr-inc',
  'memsql',
  'skilljar',
  'skilljar',
  'skupos-inc',
  'skydio',
  'skydio',
  'skyryse',
  'skyscanner',
  'sleuth-c94e',
  'slickdeals',
  'smarking',
  'smart-adserver',
  'smartasset',
  'smartcar',
  'smartertravel',
  'smartrent',
  'smartsheet-3',
  'snackpass',
  'snackpass',
  'snorkel-ai',
  'social-finance',
  'softrams',
  'solana-io',
  'solana-foundation',
  'sona-labs',
  'sonatype',
  'sondermind',
  'sourcegraph',
  'space-exploration-technologies',
  'space-exploration-technologies',
  'speak-3',
  'speakeasy-1055',
  'spendesk',
  'spendesk',
  'getsphere',
  'splice',
  'splice',
  'splunk',
  'spotify',
  'spotnana-technology',
  'spreetail',
  'spreetail',
  'spring-care',
  'spring-care',
  'sliderule',
  'squarespace',
  'stackline',
  'stackline',
  'stackline',
  'stairwell',
  'standard-ai',
  'starlight-f603',
  'startree',
  'startree',
  'status-im',
  'stellar-health',
  'stockx',
  'storable',
  'strava',
  'streamsets',
  'streamsets',
  'stripe',
  'strongdm',
  'strongdm',
  'stytch-auth',
  'submishmash',
  'subspace-labs',
  'subspace-labs',
  'sumo-logic',
  'sunshine-2',
  'supabase',
  'superdotcom',
  'superblocks-de08',
  'sure-inc',
  'swoop-search',
  'sword-health',
  'symplicity-corporation',
  'synack',
  'synapsefi',
  'syndicate-a949',
  'lthy',
  'lthy',
  'openmail-llc',
  'taboola',
  'tackle-io',
  'tailscale',
  'tailscale',
  'tailwind',
  'talend',
  'tally-technologies',
  'talos-trading',
  'tamr',
  'tanium',
  'tapcart',
  'taplytics',
  'tatari',
  'fedora',
  'fedora',
  'teachfx',
  'tecovas-2',
  'tecton',
  'tecton',
  'tekion',
  'gravitational',
  'teletracking',
  'teller-inc',
  'tencent',
  'tenstorrent',
  'tessian',
  'textio',
  'the-hub-project',
  'newyorktimes',
  'thoughtspot',
  'thoughtspot',
  'thrasio',
  'threekit',
  'threekit',
  'thunder-2adf',
  'the-tifin-group',
  'titan',
  'titan',
  'toast',
  'tomorrow-health',
  'tonal',
  'tonic-ai',
  'tonic-ai',
  'torc',
  'tower-research-capital-llc',
  'tradeblock',
  'transcarent',
  'transcend',
  'transfix',
  'tremendous',
  'trialspark',
  'triple-lift',
  'trucksmarter',
  'truepill',
  'truveta',
  'truveta',
  'turnstile-dba4',
  'tusimple-llc',
  'twilio',
  'twitch',
  'udacity',
  'udemy',
  'udemy',
  'udisc',
  'uncountable',
  'uniphore',
  'unison-home-ownership-investors',
  'unite-us',
  'unqork',
  'upbound',
  'upcodes',
  'upgrade',
  'upgrade',
  'upguard',
  'upguard',
  'uplift-inc',
  'upside-2',
  'upstox',
  'utopia-labs',
  'validere-technologies',
  'valkyrie-trading',
  'valon',
  'toca-4c9d',
  'vanta',
  'vanta',
  'varda-ba64',
  'vectra-networks',
  'veev-6ddd',
  'veho-technologies',
  'vendr',
  'verdant-robotics',
  'verge-genomics',
  'vergesense',
  'veritone',
  'verkada',
  'verkada',
  'verkada',
  'vestmark',
  'vetted-ai',
  'vetted-ai',
  'vevo',
  'vevo',
  'via-transportation',
  'via-transportation',
  'videahealth',
  'vidyard',
  'view-inc',
  'vimeo',
  'vimeo',
  'vitally',
  'volta-industries',
  'volta-industries',
  'voltron-data',
  'voltus-inc',
  'vori',
  'vouch-insurance-21c4',
  'vowel',
  'walkme',
  'walnut-a9f9',
  'waltz-health',
  'warby-parker',
  'warp-664e',
  'watershed-07e2',
  'wayfair',
  'wealthfront',
  'webflow',
  'webflow',
  'webfx',
  'well-dot',
  'wellframe',
  'wepay',
  'werideai',
  'wework',
  'whatnot',
  'whatnot',
  'wholesail',
  'whoop-inc',
  'wild-type',
  'willowtree-apps',
  'wisk',
  'wisk',
  'wistia',
  'wiz-inc',
  'wiz-inc',
  'wizards-of-the-coast',
  'woflow',
  'wonderschool',
  'wonolo',
  'wonolo',
  'workera',
  'crowdcomputing-systems',
  'workramp',
  'rigup',
  'workstream-is',
  'worldcoin',
  'xealth',
  'xometry',
  'xometry',
  'xylem-inc',
  'yext',
  'yodlee',
  'youpi',
  'yugabyte',
  'yugabyte',
  'zanbato',
  'zapier',
  'zapier',
  'zenefits',
  'zerion',
  'zeta',
  'zignal-labs',
  'zipmoney-payments',
  'zip-f492',
  'zipmoney-payments',
  'zip-f492',
  'zipmoney-payments',
  'zip-f492',
  'zippin',
  'ziprecruiter',
  'zoox',
  'zora-742a',
  'zotec-partners',
  'zscaler',
]

const career = [
  'https://jobs.lever.co/100ms',
  'https://boards.greenhouse.io/1047games',
  'https://boards.greenhouse.io/10xgenomics',
  'https://jobs.lever.co/1910genetics',
  'https://jobs.lever.co/1password',
  'https://boards.greenhouse.io/1uphealth',
  'https://boards.greenhouse.io/23andme',
  'https://boards.greenhouse.io/2k',
  'https://boards.greenhouse.io/6sense',
  'https://boards.greenhouse.io/athinkingape',
  'https://jobs.eu.lever.co/aave',
  'https://boards.greenhouse.io/abelsontaylor',
  'https://jobs.lever.co/abelsontaylor',
  'https://jobs.ashbyhq.com/able',
  'https://able.bamboohr.com/jobs',
  'https://boards.greenhouse.io/able',
  'https://boards.greenhouse.io/ableto',
  'https://boards.greenhouse.io/abnormalsecurity',
  'https://jobs.lever.co/abridge',
  'https://jobs.lever.co/accesssoftek',
  'https://jobs.lever.co/accesso',
  'https://boards.greenhouse.io/acelerate',
  'https://jobs.jobvite.com/achieve',
  'https://jobs.lever.co/aclima',
  'https://boards.greenhouse.io/acorns',
  'https://actioniq.bamboohr.com/jobs',
  'https://boards.greenhouse.io/actioniq',
  'https://jobs.ashbyhq.com/adaptive',
  'https://addepar.bamboohr.com/jobs',
  'https://boards.greenhouse.io/addepar1',
  'https://jobs.ashbyhq.com/addi',
  'https://addi.bamboohr.com/jobs',
  'https://boards.greenhouse.io/addi',
  'https://boards.greenhouse.io/adyen',
  'https://aeratechnology.bamboohr.com/jobs',
  'https://jobs.lever.co/aeratechnology',
  'https://jobs.ashbyhq.com/aetion',
  'https://boards.greenhouse.io/aetion',
  'https://jobs.lever.co/aeva',
  'https://boards.greenhouse.io/affinitiv',
  'https://affinity.bamboohr.com/jobs',
  'https://boards.greenhouse.io/affinity',
  'https://boards.greenhouse.io/affirm',
  'https://jobs.ashbyhq.com/afresh',
  'https://boards.greenhouse.io/afresh',
  'https://boards.greenhouse.io/agentsync',
  'https://boards.greenhouse.io/agero',
  'https://jobs.lever.co/agtonomy',
  'https://boards.greenhouse.io/aifleet',
  'https://jobs.lever.co/aigen',
  'https://boards.greenhouse.io/airbnb',
  'https://boards.greenhouse.io/airbyte',
  'https://aircall.bamboohr.com/jobs',
  'https://jobs.lever.co/aircall',
  'https://jobs.ashbyhq.com/airkit',
  'https://boards.greenhouse.io/airkit',
  'https://jobs.ashbyhq.com/airplane',
  'https://jobs.ashbyhq.com/airtable',
  'https://boards.greenhouse.io/airtable',
  'https://akasa.bamboohr.com/jobs',
  'https://jobs.lever.co/akasa.com',
  'https://boards.greenhouse.io/akunacapital',
  'https://jobs.lever.co/alation',
  'https://boards.greenhouse.io/alchemy',
  'https://algolia.bamboohr.com/jobs',
  'https://boards.greenhouse.io/algolia',
  'https://alkymi.bamboohr.com/jobs',
  'https://jobs.lever.co/alkymi',
  'https://jobs.ashbyhq.com/alloy',
  'https://boards.greenhouse.io/alloy',
  'https://jobs.lever.co/alloy',
  'https://jobs.lever.co/alluxio',
  'https://boards.greenhouse.io/aloft',
  'https://alpaca.bamboohr.com/jobs',
  'https://boards.greenhouse.io/alpaca',
  'https://altanaai.bamboohr.com/jobs',
  'https://boards.greenhouse.io/altanaai',
  'https://jobs.lever.co/amass',
  'https://boards.greenhouse.io/aminohealth',
  'https://jobs.ashbyhq.com/amprobotics',
  'https://boards.greenhouse.io/amprobotics',
  'https://boards.greenhouse.io/amperity',
  'https://boards.greenhouse.io/ampla',
  'https://boards.greenhouse.io/amplitude',
  'https://boards.greenhouse.io/anaplan',
  'https://jobs.lever.co/anchorage',
  'https://andela.bamboohr.com/jobs',
  'https://boards.greenhouse.io/andela',
  'https://jobs.lever.co/anduril',
  'https://boards.greenhouse.io/angi',
  'https://jobs.lever.co/ansira',
  'https://jobs.lever.co/anyscale',
  'https://jobs.lever.co/aon3d',
  'https://boards.greenhouse.io/apeelsciences',
  'https://boards.greenhouse.io/apixio',
  'https://boards.greenhouse.io/appian',
  'https://boards.greenhouse.io/appliedintuition',
  'https://applyboard.bamboohr.com/jobs',
  'https://boards.greenhouse.io/aptoslabs',
  'https://boards.greenhouse.io/aquaticcapitalmanagement',
  'https://jobs.lever.co/arcadia',
  'https://jobs.lever.co/armorblox',
  'https://jobs.lever.co/arrivelogistics',
  'https://jobs.lever.co/arsenalbio',
  'https://jobs.ashbyhq.com/artafinance',
  'https://artafinance.bamboohr.com/jobs',
  'https://asana.bamboohr.com/jobs',
  'https://boards.greenhouse.io/asana',
  'https://asapp.bamboohr.com/jobs',
  'https://jobs.lever.co/ascend.io',
  'https://jobs.ashbyhq.com/assembled',
  'https://boards.greenhouse.io/assembled',
  'https://boards.greenhouse.io/assemblyai',
  'https://jobs.ashbyhq.com/assured',
  'https://jobs.ashbyhq.com/astra',
  'https://jobs.lever.co/astra',
  'https://boards.greenhouse.io/astranis',
  'https://jobs.lever.co/astranis',
  'https://jobs.lever.co/atlan',
  'https://jobs.lever.co/atlassian',
  'https://jobs.lever.co/atob',
  'https://atomic.bamboohr.com/jobs',
  'https://jobs.lever.co/atomic',
  'https://boards.greenhouse.io/atomwise',
  'https://jobs.lever.co/attentive',
  'https://boards.greenhouse.io/attest',
  'https://jobs.lever.co/audius',
  'https://jobs.lever.co/augmedix',
  'https://boards.greenhouse.io/aurorainnovation',
  'https://jobs.ashbyhq.com/aurorasolar',
  'https://jobs.lever.co/aurorasolar',
  'https://boards.greenhouse.io/auterion',
  'https://autograph.bamboohr.com/jobs',
  'https://boards.greenhouse.io/autograph',
  'https://boards.greenhouse.io/avalabs',
  'https://boards.greenhouse.io/avantstay',
  'https://jobs.ashbyhq.com/avenue',
  'https://boards.greenhouse.io/aviatrix',
  'https://jobs.ashbyhq.com/away',
  'https://away.bamboohr.com/jobs',
  'https://boards.greenhouse.io/away',
  'https://jobs.lever.co/axiomzen',
  'https://jobs.lever.co/axoni',
  'https://boards.greenhouse.io/b12',
  'https://babylonhealth.bamboohr.com/jobs',
  'https://boards.greenhouse.io/bandwidth',
  'https://boards.greenhouse.io/bark',
  'https://jobs.ashbyhq.com/baseten',
  'https://beaconplatform.bamboohr.com/jobs',
  'https://boards.greenhouse.io/beaconplatform',
  'https://beam.bamboohr.com/jobs',
  'https://boards.greenhouse.io/beam',
  'https://jobs.lever.co/beam',
  'https://jobs.ashbyhq.com/beamery',
  'https://beamery.bamboohr.com/jobs',
  'https://jobs.lever.co/belvederetrading',
  'https://benchling.bamboohr.com/jobs',
  'https://boards.greenhouse.io/benchling',
  'https://jobs.lever.co/benchsci',
  'https://jobs.jobvite.com/benefitfocus',
  'https://boards.greenhouse.io/benepass',
  'https://boards.greenhouse.io/berlinrosen',
  'https://bestow.bamboohr.com/jobs',
  'https://boards.greenhouse.io/bettercloud',
  'https://jobs.ashbyhq.com/betterup',
  'https://boards.greenhouse.io/betterup',
  'https://boards.greenhouse.io/betterworks',
  'https://beyondidentity.bamboohr.com/jobs',
  'https://boards.greenhouse.io/beyondidentity',
  'https://jobs.lever.co/bighealth',
  'https://bigtimestudios.bamboohr.com/jobs',
  'https://boards.greenhouse.io/billiontoone',
  'https://biltrewards.bamboohr.com/jobs',
  'https://boards.greenhouse.io/biltrewards',
  'https://jobs.ashbyhq.com/binance',
  'https://jobs.lever.co/binance',
  'https://jobs.ashbyhq.com/binti',
  'https://boards.greenhouse.io/binti',
  'https://boards.greenhouse.io/bitcoindepot',
  'https://bitfury.bamboohr.com/jobs',
  'https://boards.greenhouse.io/bitfury',
  'https://bitgo.bamboohr.com/jobs',
  'https://boards.greenhouse.io/bitgo',
  'https://boards.greenhouse.io/bitwarden',
  'https://jobs.lever.co/bixal',
  'https://jobs.lever.co/blameless',
  'https://jobs.ashbyhq.com/blend',
  'https://boards.greenhouse.io/blend',
  'https://jobs.lever.co/blinkux',
  'https://blockfi.bamboohr.com/jobs',
  'https://blockstream.bamboohr.com/jobs',
  'https://boards.greenhouse.io/blockstream',
  'https://jobs.lever.co/bluebottlecoffee',
  'https://jobs.ashbyhq.com/bolt',
  'https://boards.greenhouse.io/bondvet',
  'https://boards.greenhouse.io/boomsupersonic',
  'https://boards.greenhouse.io/boostinsurance',
  'https://jobs.ashbyhq.com/boulevard',
  'https://boulevard.bamboohr.com/jobs',
  'https://boards.greenhouse.io/boulevard',
  'https://jobs.lever.co/bounteous',
  'https://boards.greenhouse.io/bracebridgecapital',
  'https://boards.greenhouse.io/brave',
  'https://boards.greenhouse.io/braze',
  'https://boards.greenhouse.io/breezeairways',
  'https://boards.greenhouse.io/brex',
  'https://jobs.lever.co/brightedge',
  'https://boards.greenhouse.io/brightflowai',
  'https://brightline.bamboohr.com/jobs',
  'https://jobs.ashbyhq.com/brightwheel',
  'https://jobs.lever.co/brightwheel',
  'https://jobs.ashbyhq.com/brigit',
  'https://jobs.lever.co/broadwaytechnology',
  'https://boards.greenhouse.io/brooklinen',
  'https://jobs.lever.co/brooksrunning',
  'https://boards.greenhouse.io/bubble',
  'https://bugcrowd.bamboohr.com/jobs',
  'https://boards.greenhouse.io/bugcrowd',
  'https://boards.greenhouse.io/builtin',
  'https://jobs.lever.co/builtrobotics',
  'https://jobs.lever.co/businesswire',
  'https://boards.greenhouse.io/businessolver',
  'https://boards.greenhouse.io/butterflynetwork',
  'https://boards.greenhouse.io/buzzfeed',
  'https://boards.greenhouse.io/caffeine',
  'https://jobs.lever.co/caffeine',
  'https://boards.greenhouse.io/calendly',
  'https://boards.greenhouse.io/calm',
  'https://jobs.ashbyhq.com/cambly',
  'https://jobs.lever.co/cambly',
  'https://boards.greenhouse.io/cameo',
  'https://jobs.lever.co/canarytechnologies',
  'https://jobs.ashbyhq.com/candidhealth',
  'https://boards.greenhouse.io/candy',
  'https://jobs.lever.co/canva',
  'https://jobs.lever.co/capsule',
  'https://jobs.lever.co/captionhealth',
  'https://carbon.bamboohr.com/jobs',
  'https://jobs.lever.co/carbon',
  'https://jobs.lever.co/cardiologs',
  'https://boards.greenhouse.io/cardlytics',
  'https://boards.greenhouse.io/careem',
  'https://boards.greenhouse.io/carrotfertility',
  'https://boards.greenhouse.io/carta',
  'https://boards.greenhouse.io/cartahealthcare',
  'https://boards.greenhouse.io/castiron',
  'https://boards.greenhouse.io/cbinsights',
  'https://boards.greenhouse.io/centivo',
  'https://jobs.lever.co/cents',
  'https://boards.greenhouse.io/cerebral',
  'https://jobs.lever.co/certik',
  'https://jobs.ashbyhq.com/chainalysis',
  'https://boards.greenhouse.io/chainalysis',
  'https://jobs.lever.co/chainlink',
  'https://boards.greenhouse.io/chapter',
  'https://jobs.ashbyhq.com/character',
  'https://jobs.lever.co/character',
  'https://boards.greenhouse.io/chargepoint',
  'https://boards.greenhouse.io/charthop',
  'https://boards.greenhouse.io/check',
  'https://boards.greenhouse.io/checkr',
  'https://jobs.lever.co/cherre',
  'https://chime.bamboohr.com/jobs',
  'https://boards.greenhouse.io/chime',
  'https://jobs.lever.co/chownow',
  'https://jobs.ashbyhq.com/chronosphere',
  'https://boards.greenhouse.io/chronosphere',
  'https://boards.greenhouse.io/circle',
  'https://boards.greenhouse.io/circleci',
  'https://jobs.jobvite.com/cirruslogic',
  'https://citizen.bamboohr.com/jobs',
  'https://boards.greenhouse.io/citizen',
  'https://boards.greenhouse.io/cityblockhealth',
  'https://jobs.lever.co/clabs',
  'https://jobs.ashbyhq.com/clari',
  'https://jobs.lever.co/clari',
  'https://boards.greenhouse.io/classdojo',
  'https://boards.greenhouse.io/classpass',
  'https://jobs.lever.co/clearbit',
  'https://jobs.ashbyhq.com/clever',
  'https://boards.greenhouse.io/clever',
  'https://boards.greenhouse.io/clickup',
  'https://boards.greenhouse.io/clockwise',
  'https://boards.greenhouse.io/cloudflare',
  'https://jobs.ashbyhq.com/cloudtrucks',
  'https://jobs.ashbyhq.com/clubhouse',
  'https://clubhouse.bamboohr.com/jobs',
  'https://jobs.lever.co/clubhouse',
  'https://boards.greenhouse.io/clutter',
  'https://boards.greenhouse.io/coalition',
  'https://coast.bamboohr.com/jobs',
  'https://boards.greenhouse.io/coast',
  'https://coda.bamboohr.com/jobs',
  'https://boards.greenhouse.io/coda',
  'https://boards.greenhouse.io/code42',
  'https://jobs.ashbyhq.com/coder',
  'https://cognite.bamboohr.com/jobs',
  'https://jobs.lever.co/cognite',
  'https://boards.greenhouse.io/coherusbiosciences',
  'https://boards.greenhouse.io/cohesity',
  'https://boards.greenhouse.io/coinmetrics',
  'https://coindesk.bamboohr.com/jobs',
  'https://boards.greenhouse.io/coindesk',
  'https://boards.greenhouse.io/coinlist',
  'https://collectivehealth.bamboohr.com/jobs',
  'https://boards.greenhouse.io/collectivehealth',
  'https://color.bamboohr.com/jobs',
  'https://jobs.lever.co/color',
  'https://jobs.ashbyhq.com/column',
  'https://boards.greenhouse.io/commercetools',
  'https://jobs.ashbyhq.com/conception',
  'https://jobs.lever.co/confluent',
  'https://jobs.jobvite.com/confluent',
  'https://jobs.lever.co/contentsquare',
  'https://boards.greenhouse.io/convoy',
  'https://jobs.jobvite.com/convoy',
  'https://jobs.lever.co/coredigitalmedia',
  'https://boards.greenhouse.io/corelight',
  'https://jobs.ashbyhq.com/cortex',
  'https://jobs.lever.co/cortex',
  'https://boards.greenhouse.io/corvusinsurance',
  'https://jobs.lever.co/couchbase',
  'https://boards.greenhouse.io/coursehero',
  'https://boards.greenhouse.io/coursera',
  'https://jobs.lever.co/covariant',
  'https://covergenius.bamboohr.com/jobs',
  'https://jobs.lever.co/covergenius',
  'https://boards.greenhouse.io/cowbellcyber',
  'https://jobs.lever.co/cprime',
  'https://jobs.lever.co/craft',
  'https://jobs.jobvite.com/craft',
  'https://boards.greenhouse.io/creativex',
  'https://jobs.lever.co/cred',
  'https://boards.greenhouse.io/credible',
  'https://jobs.jobvite.com/credible',
  'https://cribl.bamboohr.com/jobs',
  'https://boards.greenhouse.io/cribl',
  'https://boards.greenhouse.io/criticalmass',
  'https://boards.greenhouse.io/crossbeam',
  'https://jobs.ashbyhq.com/crosswire',
  'https://crosswire.bamboohr.com/jobs',
  'https://boards.greenhouse.io/cruise',
  'https://jobs.ashbyhq.com/crunchbase',
  'https://boards.greenhouse.io/crunchbase',
  'https://jobs.ashbyhq.com/crusoe',
  'https://jobs.lever.co/cruxinformatics',
  'https://cube.bamboohr.com/jobs',
  'https://jobs.lever.co/culdesac',
  'https://boards.greenhouse.io/curaleaf',
  'https://jobs.ashbyhq.com/curated',
  'https://boards.greenhouse.io/curated',
  'https://boards.greenhouse.io/curative',
  'https://jobs.lever.co/curology',
  'https://boards.greenhouse.io/curvehealth',
  'https://jobs.lever.co/custodiabank',
  'https://jobs.ashbyhq.com/d2iq',
  'https://boards.greenhouse.io/dagger',
  'https://dailyharvest.bamboohr.com/jobs',
  'https://boards.greenhouse.io/dailyharvest',
  'https://boards.greenhouse.io/dailypay',
  'https://boards.greenhouse.io/databook',
  'https://boards.greenhouse.io/databricks',
  'https://datacamp.bamboohr.com/jobs',
  'https://boards.greenhouse.io/datacamp',
  'https://boards.greenhouse.io/datagrail',
  'https://boards.greenhouse.io/dataiku',
  'https://boards.greenhouse.io/dataminr',
  'https://datavant.bamboohr.com/jobs',
  'https://boards.greenhouse.io/datavant',
  'https://jobs.ashbyhq.com/dave',
  'https://jobs.lever.co/dave',
  'https://jobs.lever.co/dazn',
  'https://dealpath.bamboohr.com/jobs',
  'https://boards.greenhouse.io/dealpath',
  'https://jobs.lever.co/decide',
  'https://jobs.lever.co/deepcell',
  'https://deepgram.bamboohr.com/jobs',
  'https://jobs.lever.co/deepgram',
  'https://boards.greenhouse.io/deepmind',
  'https://boards.greenhouse.io/degreed',
  'https://jobs.ashbyhq.com/deliveroo',
  'https://boards.greenhouse.io/deliveroo',
  'https://jobs.lever.co/demiurgestudios',
  'https://descript.bamboohr.com/jobs',
  'https://boards.greenhouse.io/descript',
  'https://jobs.lever.co/deserve',
  'https://jobs.lever.co/dexterity',
  'https://boards.greenhouse.io/digit',
  'https://boards.greenhouse.io/digitaltrends',
  'https://digitalocean.bamboohr.com/jobs',
  'https://boards.greenhouse.io/diligentrobotics',
  'https://boards.greenhouse.io/disco',
  'https://boards.greenhouse.io/discord',
  'https://boards.greenhouse.io/divvyhomes',
  'https://boards.greenhouse.io/docugami',
  'https://boards.greenhouse.io/doma',
  'https://boards.greenhouse.io/domeyard',
  'https://dominodatalab.bamboohr.com/jobs',
  'https://boards.greenhouse.io/dominodatalab',
  'https://domo.bamboohr.com/jobs',
  'https://boards.greenhouse.io/domo',
  'https://boards.greenhouse.io/doordash',
  'https://jobs.lever.co/dopple',
  'https://jobs.lever.co/doppler',
  'https://doximity.bamboohr.com/jobs',
  'https://boards.greenhouse.io/doximity',
  'https://boards.greenhouse.io/doxo',
  'https://jobs.lever.co/dragos',
  'https://boards.greenhouse.io/drata',
  'https://boards.greenhouse.io/drivewealth',
  'https://jobs.lever.co/drizly',
  'https://jobs.lever.co/dronedeploy',
  'https://boards.greenhouse.io/dropbox',
  'https://boards.greenhouse.io/ducerapartners',
  'https://jobs.ashbyhq.com/duolingo',
  'https://duolingo.bamboohr.com/jobs',
  'https://boards.greenhouse.io/duolingo',
  'https://dwolla.bamboohr.com/jobs',
  'https://jobs.lever.co/dwolla',
  'https://dydx.bamboohr.com/jobs',
  'https://boards.greenhouse.io/dydx',
  'https://boards.greenhouse.io/earnin',
  'https://boards.greenhouse.io/earnup',
  'https://eco.bamboohr.com/jobs',
  'https://boards.greenhouse.io/eco',
  'https://boards.greenhouse.io/ecobee',
  'https://jobs.lever.co/ecocart',
  'https://jobs.lever.co/edenhealth',
  'https://jobs.lever.co/ekimetrics',
  'https://eko.bamboohr.com/jobs',
  'https://boards.greenhouse.io/eko',
  'https://boards.greenhouse.io/electrichydrogen',
  'https://boards.greenhouse.io/elementbiosciences',
  'https://elementary.bamboohr.com/jobs',
  'https://boards.greenhouse.io/elwoodtechnologies',
  'https://boards.greenhouse.io/embrace',
  'https://emeraldcloudlab.bamboohr.com/jobs',
  'https://jobs.lever.co/emeraldcloudlab',
  'https://enable.bamboohr.com/jobs',
  'https://jobs.lever.co/enable',
  'https://jobs.lever.co/energyrecovery',
  'https://boards.greenhouse.io/enova',
  'https://jobs.lever.co/enthought',
  'https://boards.greenhouse.io/envedabiosciences',
  'https://boards.greenhouse.io/envoy',
  'https://boards.greenhouse.io/epicgames',
  'https://boards.greenhouse.io/epirus',
  'https://jobs.lever.co/epsilon3',
  'https://jobs.lever.co/equiphealth',
  'https://boards.greenhouse.io/ergeon',
  'https://jobs.lever.co/ethena',
  'https://jobs.lever.co/eventbrite',
  'https://jobs.ashbyhq.com/everbridge',
  'https://jobs.lever.co/everbridge',
  'https://boards.greenhouse.io/everlaw',
  'https://boards.greenhouse.io/evidationhealth',
  'https://jobs.lever.co/evocalize',
  'https://boards.greenhouse.io/exabeam',
  'https://jobs.lever.co/exabeam',
  'https://boards.greenhouse.io/execonline',
  'https://boards.greenhouse.io/exiger',
  'https://boards.greenhouse.io/exoduspoint',
  'https://boards.greenhouse.io/explo',
  'https://jobs.lever.co/extremenetworks',
  'https://jobs.jobvite.com/extremenetworks',
  'https://jobs.lever.co/fabricgenomics',
  'https://boards.greenhouse.io/facet',
  'https://jobs.ashbyhq.com/faire',
  'https://boards.greenhouse.io/faire',
  'https://boards.greenhouse.io/fairmarkit',
  'https://boards.greenhouse.io/falconx',
  'https://jobs.lever.co/fampay',
  'https://jobs.ashbyhq.com/fanatics',
  'https://jobs.lever.co/fanatics',
  'https://boards.greenhouse.io/fanduel',
  'https://jobs.lever.co/farmwise',
  'https://jobs.ashbyhq.com/fast',
  'https://fast.bamboohr.com/jobs',
  'https://jobs.lever.co/fastradius',
  'https://fauna.bamboohr.com/jobs',
  'https://boards.greenhouse.io/fauna',
  'https://boards.greenhouse.io/fernish',
  'https://boards.greenhouse.io/fictiv',
  'https://boards.greenhouse.io/figma',
  'https://jobs.ashbyhq.com/figure',
  'https://boards.greenhouse.io/figure',
  'https://boards.greenhouse.io/filecoinfoundation',
  'https://jobs.lever.co/filevine',
  'https://jobs.lever.co/finix',
  'https://boards.greenhouse.io/fireblocks',
  'https://jobs.ashbyhq.com/firstbase',
  'https://fiscalnote.bamboohr.com/jobs',
  'https://jobs.lever.co/fiscalnote',
  'https://boards.greenhouse.io/five9',
  'https://jobs.ashbyhq.com/flatfile',
  'https://flatfile.bamboohr.com/jobs',
  'https://jobs.lever.co/flatfile',
  'https://boards.greenhouse.io/flatironhealth',
  'https://boards.greenhouse.io/flexe',
  'https://boards.greenhouse.io/flexport',
  'https://boards.greenhouse.io/flockfreight',
  'https://boards.greenhouse.io/flocksafety',
  'https://jobs.lever.co/floqast',
  'https://boards.greenhouse.io/flowtraders',
  'https://jobs.lever.co/flowhub',
  'https://jobs.lever.co/flowspace',
  'https://boards.greenhouse.io/flywheelsoftware',
  'https://fnatic.bamboohr.com/jobs',
  'https://jobs.lever.co/fnatic',
  'https://jobs.lever.co/foodsmart',
  'https://boards.greenhouse.io/forage',
  'https://boards.greenhouse.io/formic',
  'https://boards.greenhouse.io/formlabs',
  'https://forte.bamboohr.com/jobs',
  'https://boards.greenhouse.io/forte',
  'https://jobs.ashbyhq.com/forward',
  'https://jobs.ashbyhq.com/found',
  'https://boards.greenhouse.io/found',
  'https://boards.greenhouse.io/foundenergy',
  'https://boards.greenhouse.io/foundrydigital',
  'https://boards.greenhouse.io/fountain',
  'https://jobs.lever.co/fountain',
  'https://boards.greenhouse.io/fourkites',
  'https://jobs.jobvite.com/freedomfinancialnetwork',
  'https://boards.greenhouse.io/freenome',
  'https://jobs.ashbyhq.com/front',
  'https://front.bamboohr.com/jobs',
  'https://jobs.lever.co/ftx',
  'https://boards.greenhouse.io/fueled',
  'https://jobs.lever.co/fullstackfinance',
  'https://jobs.ashbyhq.com/fullstory',
  'https://boards.greenhouse.io/fullstory',
  'https://boards.greenhouse.io/fyllo',
  'https://jobs.lever.co/gametime',
  'https://boards.greenhouse.io/garnerhealth',
  'https://boards.greenhouse.io/gatherai',
  'https://jobs.lever.co/gatherai',
  'https://gausslabs.bamboohr.com/jobs',
  'https://jobs.lever.co/gausslabs',
  'https://geckorobotics.bamboohr.com/jobs',
  'https://boards.greenhouse.io/geckorobotics',
  'https://jobs.lever.co/geckorobotics',
  'https://gemini.bamboohr.com/jobs',
  'https://boards.greenhouse.io/gemini',
  'https://boards.greenhouse.io/generalassembly',
  'https://jobs.lever.co/generallyintelligent',
  'https://jobs.lever.co/genesistherapeutics',
  'https://jobs.lever.co/geotab',
  'https://jobs.lever.co/gettyimages',
  'https://jobs.jobvite.com/gettyimages',
  'https://boards.greenhouse.io/ginkgobioworks',
  'https://jobs.lever.co/ginkgobioworks',
  'https://github.bamboohr.com/jobs',
  'https://boards.greenhouse.io/github',
  'https://gitlab.bamboohr.com/jobs',
  'https://boards.greenhouse.io/gitlab',
  'https://jobs.lever.co/givecampus',
  'https://boards.greenhouse.io/glance',
  'https://boards.greenhouse.io/glassdoor',
  'https://boards.greenhouse.io/glean',
  'https://boards.greenhouse.io/goatgroup',
  'https://boards.greenhouse.io/godaddy',
  'https://boards.greenhouse.io/goguardian',
  'https://jobs.lever.co/goguardian',
  'https://boards.greenhouse.io/goldenhippo',
  'https://jobs.lever.co/goodrx',
  'https://jobs.lever.co/gopuff',
  'https://jobs.ashbyhq.com/gordiansoftware',
  'https://jobs.ashbyhq.com/gorgias',
  'https://jobs.lever.co/gorgias',
  'https://boards.greenhouse.io/grafanalabs',
  'https://boards.greenhouse.io/grailed',
  'https://grammarly.bamboohr.com/jobs',
  'https://boards.greenhouse.io/grammarly',
  'https://jobs.ashbyhq.com/grandrounds',
  'https://jobs.ashbyhq.com/graphite',
  'https://jobs.ashbyhq.com/greenlight',
  'https://jobs.lever.co/greenlight',
  'https://jobs.ashbyhq.com/gretel',
  'https://boards.greenhouse.io/guideline',
  'https://boards.greenhouse.io/guidepoint',
  'https://jobs.lever.co/guidewire',
  'https://boards.greenhouse.io/gusto',
  'https://hackerrank.bamboohr.com/jobs',
  'https://boards.greenhouse.io/hackerrank',
  'https://hadrian.bamboohr.com/jobs',
  'https://jobs.lever.co/hadrian',
  'https://boards.greenhouse.io/handshake',
  'https://jobs.ashbyhq.com/hang',
  'https://boards.greenhouse.io/hapcapital',
  'https://jobs.lever.co/happyfresh',
  'https://jobs.lever.co/harmony',
  'https://jobs.lever.co/harness',
  'https://boards.greenhouse.io/hashicorp',
  'https://jobs.lever.co/hatchit',
  'https://jobs.lever.co/headout',
  'https://headway.bamboohr.com/jobs',
  'https://boards.greenhouse.io/headway',
  'https://heal.bamboohr.com/jobs',
  'https://jobs.lever.co/healthverity',
  'https://boards.greenhouse.io/heap',
  'https://jobs.lever.co/heartbeathealth',
  'https://boards.greenhouse.io/helionenergy',
  'https://boards.greenhouse.io/hellofresh',
  'https://jobs.lever.co/hermeus',
  'https://boards.greenhouse.io/hextechnologies',
  'https://boards.greenhouse.io/heycar',
  'https://boards.greenhouse.io/highnote',
  'https://jobs.ashbyhq.com/hightouch',
  'https://boards.greenhouse.io/hightouch',
  'https://jobs.lever.co/hingehealth',
  'https://jobs.lever.co/hipcamp',
  'https://jobs.lever.co/hiro',
  'https://boards.greenhouse.io/hive',
  'https://jobs.lever.co/hive',
  'https://jobs.ashbyhq.com/homebase',
  'https://boards.greenhouse.io/homebase',
  'https://boards.greenhouse.io/homelight',
  'https://jobs.lever.co/hometap',
  'https://jobs.lever.co/homex',
  'https://boards.greenhouse.io/hopin',
  'https://boards.greenhouse.io/hqdigital',
  'https://jobs.ashbyhq.com/hqo',
  'https://boards.greenhouse.io/hubspot',
  'https://boards.greenhouse.io/hudl',
  'https://boards.greenhouse.io/humaninterest',
  'https://boards.greenhouse.io/huntress',
  'https://jobs.lever.co/hyperscience',
  'https://boards.greenhouse.io/hypr',
  'https://boards.greenhouse.io/ibotta',
  'https://jobs.lever.co/icertis',
  'https://boards.greenhouse.io/illumio',
  'https://jobs.lever.co/immuta',
  'https://jobs.lever.co/imperfectfoods',
  'https://jobs.lever.co/impossiblefoods',
  'https://boards.greenhouse.io/imprint',
  'https://boards.greenhouse.io/inariagriculture',
  'https://boards.greenhouse.io/incrediblehealth',
  'https://jobs.lever.co/indigov',
  'https://boards.greenhouse.io/insitro',
  'https://boards.greenhouse.io/inspire',
  'https://boards.greenhouse.io/inspire11',
  'https://boards.greenhouse.io/instabase',
  'https://boards.greenhouse.io/instacart',
  'https://boards.greenhouse.io/integraladscience',
  'https://boards.greenhouse.io/interactivebrokers',
  'https://boards.greenhouse.io/intercom',
  'https://boards.greenhouse.io/interfolio',
  'https://jobs.jobvite.com/internetbrands',
  'https://boards.greenhouse.io/intersection',
  'https://jobs.lever.co/intro',
  'https://boards.greenhouse.io/invision',
  'https://boards.greenhouse.io/invitae',
  'https://boards.greenhouse.io/invoca',
  'https://ionq.bamboohr.com/jobs',
  'https://boards.greenhouse.io/ionq',
  'https://jobs.lever.co/ironfish',
  'https://jobs.ashbyhq.com/ironclad',
  'https://ironclad.bamboohr.com/jobs',
  'https://jobs.lever.co/isometrictechnologies',
  'https://boards.greenhouse.io/iterativehealth',
  'https://boards.greenhouse.io/ixllearning',
  'https://boards.greenhouse.io/jackpocket',
  'https://jobs.lever.co/jamcity',
  'https://boards.greenhouse.io/jamf',
  'https://january.bamboohr.com/jobs',
  'https://boards.greenhouse.io/january',
  'https://boards.greenhouse.io/jasper',
  'https://jobs.ashbyhq.com/jerry',
  'https://boards.greenhouse.io/jobget',
  'https://boards.greenhouse.io/joy',
  'https://boards.greenhouse.io/jumptrading',
  'https://boards.greenhouse.io/justappraised',
  'https://boards.greenhouse.io/justworks',
  'https://boards.greenhouse.io/juullabs',
  'https://jobs.lever.co/kabam',
  'https://boards.greenhouse.io/kalepa',
  'https://boards.greenhouse.io/kalshi',
  'https://jobs.ashbyhq.com/kandji',
  'https://jobs.lever.co/kandji',
  'https://jobs.lever.co/kapwing',
  'https://karat.bamboohr.com/jobs',
  'https://boards.greenhouse.io/karat',
  'https://boards.greenhouse.io/kasisto',
  'https://boards.greenhouse.io/kayak',
  'https://kensho.bamboohr.com/jobs',
  'https://jobs.lever.co/kensho',
  'https://boards.greenhouse.io/kentik',
  'https://boards.greenhouse.io/kevel',
  'https://boards.greenhouse.io/khanacademy',
  'https://boards.greenhouse.io/kininsurance',
  'https://jobs.lever.co/kinside',
  'https://jobs.lever.co/kitcheck',
  'https://jobs.lever.co/kitware',
  'https://boards.greenhouse.io/kkr',
  'https://boards.greenhouse.io/klaviyo',
  'https://jobs.lever.co/klue',
  'https://boards.greenhouse.io/knoetic',
  'https://boards.greenhouse.io/knowbe4',
  'https://koddi.bamboohr.com/jobs',
  'https://jobs.lever.co/koddi',
  'https://boards.greenhouse.io/komodohealth',
  'https://jobs.lever.co/kong',
  'https://boards.greenhouse.io/konradgroup',
  'https://jobs.ashbyhq.com/kraken',
  'https://kraken.bamboohr.com/jobs',
  'https://jobs.lever.co/kraken',
  'https://jobs.lever.co/kunai',
  'https://jobs.lever.co/labelbox',
  'https://boards.greenhouse.io/lacework',
  'https://boards.greenhouse.io/laika',
  'https://jobs.ashbyhq.com/landis',
  'https://boards.greenhouse.io/landis',
  'https://boards.greenhouse.io/larkhealth',
  'https://later.bamboohr.com/jobs',
  'https://jobs.ashbyhq.com/launchdarkly',
  'https://boards.greenhouse.io/launchdarkly',
  'https://boards.greenhouse.io/leaflink',
  'https://league.bamboohr.com/jobs',
  'https://leandata.bamboohr.com/jobs',
  'https://leantaas.bamboohr.com/jobs',
  'https://jobs.lever.co/leantaas',
  'https://jobs.lever.co/leapyear',
  'https://ledgerinvesting.bamboohr.com/jobs',
  'https://boards.greenhouse.io/ledgerinvesting',
  'https://jobs.lever.co/lendbuzz',
  'https://boards.greenhouse.io/lendingtree',
  'https://jobs.jobvite.com/lendingtree',
  'https://boards.greenhouse.io/lessonly',
  'https://boards.greenhouse.io/lev',
  'https://jobs.lever.co/lever',
  'https://jobs.eu.lever.co/lever',
  'https://jobs.ashbyhq.com/levro',
  'https://jobs.ashbyhq.com/lilt',
  'https://lilt.bamboohr.com/jobs',
  'https://jobs.lever.co/lilt',
  'https://lime.bamboohr.com/jobs',
  'https://jobs.lever.co/lime',
  'https://jobs.ashbyhq.com/linear',
  'https://linear.bamboohr.com/jobs',
  'https://boards.greenhouse.io/linushealth',
  'https://jobs.lever.co/littleotter',
  'https://jobs.ashbyhq.com/lob',
  'https://boards.greenhouse.io/lob',
  'https://boards.greenhouse.io/logikcull',
  'https://jobs.lever.co/logikcull',
  'https://jobs.jobvite.com/logitech',
  'https://jobs.lever.co/logrocket',
  'https://jobs.ashbyhq.com/loom',
  'https://jobs.lever.co/lucidmotors',
  'https://jobs.lever.co/luminai',
  'https://jobs.lever.co/luminarycloud',
  'https://boards.greenhouse.io/lumos',
  'https://jobs.lever.co/luxurypresence',
  'https://boards.greenhouse.io/lyft',
  'https://jobs.ashbyhq.com/lyrahealth',
  'https://jobs.lever.co/lyrahealth',
  'https://jobs.lever.co/mabl',
  'https://jobs.ashbyhq.com/machinify',
  'https://madisonlogic.bamboohr.com/jobs',
  'https://jobs.lever.co/madisonlogic',
  'https://jobs.lever.co/madkudu',
  'https://jobs.ashbyhq.com/magical',
  'https://boards.greenhouse.io/mantle',
  'https://boards.greenhouse.io/mark43',
  'https://jobs.ashbyhq.com/marqeta',
  'https://boards.greenhouse.io/marqeta',
  'https://jobs.lever.co/mashgin',
  'https://boards.greenhouse.io/mason',
  'https://jobs.lever.co/matchgroup',
  'https://jobs.ashbyhq.com/matician',
  'https://jobs.lever.co/matician',
  'https://jobs.lever.co/matterport',
  'https://boards.greenhouse.io/maymobility',
  'https://jobs.lever.co/mbta',
  'https://jobs.ashbyhq.com/medely',
  'https://medium.bamboohr.com/jobs',
  'https://jobs.lever.co/medium',
  'https://meero.bamboohr.com/jobs',
  'https://jobs.lever.co/meero',
  'https://boards.greenhouse.io/memorahealth',
  'https://menlosecurity.bamboohr.com/jobs',
  'https://boards.greenhouse.io/menlosecurity',
  'https://boards.greenhouse.io/mento',
  'https://jobs.ashbyhq.com/mercury',
  'https://boards.greenhouse.io/mercury',
  'https://merge.bamboohr.com/jobs',
  'https://boards.greenhouse.io/merge',
  'https://boards.greenhouse.io/merit',
  'https://jobs.lever.co/merit',
  'https://jobs.ashbyhq.com/messagebird',
  'https://messagebird.bamboohr.com/jobs',
  'https://boards.greenhouse.io/messagebird',
  'https://boards.greenhouse.io/metadata',
  'https://jobs.ashbyhq.com/meter',
  'https://boards.greenhouse.io/meter',
  'https://boards.greenhouse.io/metropolis',
  'https://mezmo.bamboohr.com/jobs',
  'https://boards.greenhouse.io/mezmo',
  'https://jobs.ashbyhq.com/middesk',
  'https://jobs.lever.co/midpointlabs',
  'https://jobs.lever.co/mindsdb',
  'https://jobs.lever.co/minted',
  'https://miro.bamboohr.com/jobs',
  'https://jobs.lever.co/missioncloud',
  'https://boards.greenhouse.io/missionlane',
  'https://boards.greenhouse.io/mixpanel',
  'https://boards.greenhouse.io/modernanimal',
  'https://jobs.ashbyhq.com/moderntreasury',
  'https://boards.greenhouse.io/modernloop',
  'https://boards.greenhouse.io/monad',
  'https://jobs.lever.co/monad',
  'https://boards.greenhouse.io/mongodb',
  'https://jobs.ashbyhq.com/montecarlodata',
  'https://jobs.lever.co/montecarlodata',
  'https://boards.greenhouse.io/monzo',
  'https://boards.greenhouse.io/moonpay',
  'https://morningbrew.bamboohr.com/jobs',
  'https://jobs.lever.co/morningbrew',
  'https://jobs.lever.co/mothership',
  'https://boards.greenhouse.io/motional',
  'https://motive.bamboohr.com/jobs',
  'https://boards.greenhouse.io/motive',
  'https://jobs.lever.co/motive',
  'https://boards.greenhouse.io/moveworks',
  'https://boards.greenhouse.io/moxehealth',
  'https://jobs.lever.co/moxionpower',
  'https://boards.greenhouse.io/mozilla',
  'https://mparticle.bamboohr.com/jobs',
  'https://boards.greenhouse.io/mparticle',
  'https://boards.greenhouse.io/mudflap',
  'https://boards.greenhouse.io/multiplylabs',
  'https://jobs.ashbyhq.com/mural',
  'https://boards.greenhouse.io/mural',
  'https://boards.greenhouse.io/mutiny',
  'https://boards.greenhouse.io/myfitnesspal',
  'https://jobs.ashbyhq.com/mystenlabs',
  'https://boards.greenhouse.io/mythic',
  'https://jobs.lever.co/narmi',
  'https://jobs.ashbyhq.com/nash',
  'https://boards.greenhouse.io/nash',
  'https://boards.greenhouse.io/natera',
  'https://boards.greenhouse.io/nationalresearchgroup',
  'https://boards.greenhouse.io/nationbuilder',
  'https://boards.greenhouse.io/navtechnologies',
  'https://boards.greenhouse.io/nava',
  'https://jobs.lever.co/nava',
  'https://jobs.lever.co/neeva',
  'https://boards.greenhouse.io/neocybernetica',
  'https://jobs.lever.co/neon',
  'https://boards.greenhouse.io/nerdwallet',
  'https://jobs.ashbyhq.com/ness',
  'https://jobs.jobvite.com/ness',
  'https://jobs.lever.co/netflix',
  'https://netlify.bamboohr.com/jobs',
  'https://boards.greenhouse.io/netlify',
  'https://boards.greenhouse.io/neuralink',
  'https://jobs.lever.co/newfrontinsurance',
  'https://boards.greenhouse.io/newsela',
  'https://boards.greenhouse.io/nexhealth',
  'https://jobs.ashbyhq.com/nextroll',
  'https://boards.greenhouse.io/nextroll',
  'https://jobs.ashbyhq.com/niantic',
  'https://boards.greenhouse.io/niantic',
  'https://jobs.lever.co/nimblerx',
  'https://jobs.lever.co/ninjavan',
  'https://jobs.lever.co/nio',
  'https://boards.greenhouse.io/nobl9',
  'https://jobs.lever.co/nomihealth',
  'https://jobs.lever.co/normalyze',
  'https://boards.greenhouse.io/northspyre',
  'https://northwesternmutual.bamboohr.com/jobs',
  'https://jobs.ashbyhq.com/notable',
  'https://notarize.bamboohr.com/jobs',
  'https://jobs.lever.co/notarize',
  'https://jobs.ashbyhq.com/notion',
  'https://boards.greenhouse.io/notion',
  'https://boards.greenhouse.io/novetta',
  'https://boards.greenhouse.io/nowsta',
  'https://boards.greenhouse.io/nuna',
  'https://jobs.jobvite.com/nutanix',
  'https://boards.greenhouse.io/nylas',
  'https://occ.bamboohr.com/jobs',
  'https://jobs.lever.co/ockam',
  'https://boards.greenhouse.io/odeko',
  'https://okcoin.bamboohr.com/jobs',
  'https://boards.greenhouse.io/okcoin',
  'https://boards.greenhouse.io/omadahealth',
  'https://boards.greenhouse.io/omaze',
  'https://boards.greenhouse.io/onemedical',
  'https://onelocal.bamboohr.com/jobs',
  'https://boards.greenhouse.io/onelocal',
  'https://jobs.ashbyhq.com/oneschema',
  'https://boards.greenhouse.io/onesignal',
  'https://boards.greenhouse.io/onna',
  'https://boards.greenhouse.io/opaquesystems',
  'https://openai.bamboohr.com/jobs',
  'https://boards.greenhouse.io/openai',
  'https://opencare.bamboohr.com/jobs',
  'https://jobs.lever.co/opencare',
  'https://boards.greenhouse.io/opendoor',
  'https://jobs.lever.co/openinvest',
  'https://boards.greenhouse.io/openphone',
  'https://jobs.ashbyhq.com/opensea',
  'https://opensea.bamboohr.com/jobs',
  'https://boards.greenhouse.io/openstore',
  'https://boards.greenhouse.io/openzeppelin',
  'https://boards.greenhouse.io/optimism',
  'https://boards.greenhouse.io/optiverus',
  'https://boards.greenhouse.io/optoro',
  'https://boards.greenhouse.io/orchard',
  'https://boards.greenhouse.io/orum',
  'https://jobs.lever.co/orum',
  'https://osaro.bamboohr.com/jobs',
  'https://jobs.lever.co/osaro',
  'https://boards.greenhouse.io/osmo',
  'https://jobs.ashbyhq.com/outliant',
  'https://boards.greenhouse.io/outreach',
  'https://jobs.lever.co/outreach',
  'https://outschool.bamboohr.com/jobs',
  'https://boards.greenhouse.io/outschool',
  'https://boards.greenhouse.io/outsetmedical',
  'https://boards.greenhouse.io/owllabs',
  'https://boards.greenhouse.io/ownbackup',
  'https://oxio.bamboohr.com/jobs',
  'https://jobs.lever.co/oxio',
  'https://boards.greenhouse.io/oyster',
  'https://boards.greenhouse.io/pachyderm',
  'https://jobs.ashbyhq.com/paddle',
  'https://boards.greenhouse.io/paddle',
  'https://boards.greenhouse.io/pagerduty',
  'https://palantir.bamboohr.com/jobs',
  'https://jobs.lever.co/palantir',
  'https://boards.greenhouse.io/pantherlabs',
  'https://boards.greenhouse.io/papa',
  'https://jobs.ashbyhq.com/parafin',
  'https://jobs.lever.co/parallelwireless',
  'https://jobs.ashbyhq.com/parasol',
  'https://jobs.ashbyhq.com/parkade',
  'https://jobs.ashbyhq.com/patch',
  'https://jobs.lever.co/patch',
  'https://jobs.ashbyhq.com/pathai',
  'https://boards.greenhouse.io/pathai',
  'https://jobs.lever.co/pathrise',
  'https://boards.greenhouse.io/patreon',
  'https://jobs.ashbyhq.com/pave',
  'https://pave.bamboohr.com/jobs',
  'https://boards.greenhouse.io/pave',
  'https://pax8.bamboohr.com/jobs',
  'https://boards.greenhouse.io/pax8',
  'https://jobs.lever.co/payfit',
  'https://boards.greenhouse.io/payit',
  'https://jobs.jobvite.com/payscale',
  'https://pdtpartners.bamboohr.com/jobs',
  'https://boards.greenhouse.io/pdtpartners',
  'https://boards.greenhouse.io/peloton',
  'https://jobs.jobvite.com/peloton',
  'https://peptilogics.bamboohr.com/jobs',
  'https://boards.greenhouse.io/peptilogics',
  'https://jobs.ashbyhq.com/perchwell',
  'https://jobs.lever.co/perpay',
  'https://jobs.ashbyhq.com/persona',
  'https://jobs.lever.co/persona',
  'https://boards.greenhouse.io/petuum',
  'https://boards.greenhouse.io/phonepe',
  'https://boards.greenhouse.io/physna',
  'https://jobs.lever.co/piccollage',
  'https://jobs.lever.co/picklerobot',
  'https://jobs.ashbyhq.com/picogrid',
  'https://boards.greenhouse.io/pieinsurance',
  'https://pilot.bamboohr.com/jobs',
  'https://boards.greenhouse.io/pilot',
  'https://jobs.lever.co/pilot',
  'https://boards.greenhouse.io/pinterest',
  'https://boards.greenhouse.io/pitch',
  'https://jobs.ashbyhq.com/plaid',
  'https://jobs.lever.co/plaid',
  'https://boards.greenhouse.io/platformscience',
  'https://plotly.bamboohr.com/jobs',
  'https://boards.greenhouse.io/plotly',
  'https://boards.greenhouse.io/plume',
  'https://jobs.lever.co/plume',
  'https://boards.greenhouse.io/pocketgems',
  'https://jobs.ashbyhq.com/podium',
  'https://jobs.ashbyhq.com/point',
  'https://jobs.lever.co/pointb',
  'https://boards.greenhouse.io/point72',
  'https://jobs.lever.co/pointclickcare',
  'https://jobs.ashbyhq.com/policygenius',
  'https://boards.greenhouse.io/policygenius',
  'https://polly.bamboohr.com/jobs',
  'https://boards.greenhouse.io/polly',
  'https://boards.greenhouse.io/poshmark',
  'https://jobs.lever.co/postera',
  'https://boards.greenhouse.io/postman',
  'https://jobs.ashbyhq.com/potion',
  'https://boards.greenhouse.io/powerfinance',
  'https://jobs.lever.co/prelim',
  'https://premise.bamboohr.com/jobs',
  'https://boards.greenhouse.io/premise',
  'https://jobs.lever.co/preset',
  'https://boards.greenhouse.io/primemedicine',
  'https://boards.greenhouse.io/primerai',
  'https://prisma.bamboohr.com/jobs',
  'https://boards.greenhouse.io/prisma',
  'https://privitar.bamboohr.com/jobs',
  'https://jobs.lever.co/privitar',
  'https://projectronin.bamboohr.com/jobs',
  'https://boards.greenhouse.io/projectronin',
  'https://jobs.lever.co/projectronin',
  'https://projekt202.bamboohr.com/jobs',
  'https://boards.greenhouse.io/projekt202',
  'https://propel.bamboohr.com/jobs',
  'https://boards.greenhouse.io/propel',
  'https://boards.greenhouse.io/protocollabs',
  'https://boards.greenhouse.io/prove',
  'https://boards.greenhouse.io/psyonix',
  'https://boards.greenhouse.io/public',
  'https://jobs.lever.co/pulsedata',
  'https://pulumi.bamboohr.com/jobs',
  'https://jobs.ashbyhq.com/purestorage',
  'https://boards.greenhouse.io/purestorage',
  'https://boards.greenhouse.io/pushpay',
  'https://boards.greenhouse.io/pyka',
  'https://jobs.ashbyhq.com/pylon',
  'https://pythian.bamboohr.com/jobs',
  'https://jobs.lever.co/pythian',
  'https://jobs.ashbyhq.com/qualtrics',
  'https://boards.greenhouse.io/qualtrics',
  'https://jobs.lever.co/quantcast',
  'https://jobs.lever.co/quantummetric',
  'https://jobs.lever.co/quartzy',
  'https://jobs.lever.co/questanalytics',
  'https://jobs.lever.co/quidel',
  'https://qumulo.bamboohr.com/jobs',
  'https://boards.greenhouse.io/qumulo',
  'https://jobs.ashbyhq.com/quora',
  'https://quora.bamboohr.com/jobs',
  'https://jobs.ashbyhq.com/qventus',
  'https://jobs.lever.co/qventus',
  'https://jobs.ashbyhq.com/ramp',
  'https://boards.greenhouse.io/ramp',
  'https://boards.greenhouse.io/rapp',
  'https://rarible.bamboohr.com/jobs',
  'https://jobs.ashbyhq.com/ready',
  'https://jobs.lever.co/realitydefender',
  'https://jobs.lever.co/recess',
  'https://boards.greenhouse.io/recordedfuture',
  'https://boards.greenhouse.io/redventures',
  'https://jobs.ashbyhq.com/reddit',
  'https://boards.greenhouse.io/reddit',
  'https://boards.greenhouse.io/redesignhealth',
  'https://boards.greenhouse.io/redwoodmaterials',
  'https://reforge.bamboohr.com/jobs',
  'https://boards.greenhouse.io/reforge',
  'https://regal.bamboohr.com/jobs',
  'https://boards.greenhouse.io/reifyhealth',
  'https://boards.greenhouse.io/relativity',
  'https://jobs.lever.co/relativity',
  'https://boards.greenhouse.io/relay',
  'https://jobs.lever.co/relay',
  'https://boards.greenhouse.io/relaytherapeutics',
  'https://reliablerobotics.bamboohr.com/jobs',
  'https://boards.greenhouse.io/remitly',
  'https://boards.greenhouse.io/render',
  'https://boards.greenhouse.io/renttherunway',
  'https://jobs.ashbyhq.com/replit',
  'https://jobs.ashbyhq.com/retool',
  'https://boards.greenhouse.io/retool',
  'https://boards.greenhouse.io/revenuecat',
  'https://jobs.lever.co/revolut',
  'https://boards.greenhouse.io/rhombuspower',
  'https://boards.greenhouse.io/ribbonhealth',
  'https://boards.greenhouse.io/ridecell',
  'https://boards.greenhouse.io/ridgeline',
  'https://jobs.lever.co/rigetti',
  'https://boards.greenhouse.io/riotgames',
  'https://ripcord.bamboohr.com/jobs',
  'https://boards.greenhouse.io/ripcord',
  'https://ripple.bamboohr.com/jobs',
  'https://boards.greenhouse.io/ripple',
  'https://boards.greenhouse.io/rippling',
  'https://boards.greenhouse.io/riversideresearch',
  'https://boards.greenhouse.io/rivian',
  'https://jobs.lever.co/ro',
  'https://boards.greenhouse.io/robinhood',
  'https://boards.greenhouse.io/roblox',
  'https://jobs.lever.co/roboflow',
  'https://jobs.lever.co/rockset',
  'https://boards.greenhouse.io/roku',
  'https://boards.greenhouse.io/roofr',
  'https://boards.greenhouse.io/routable',
  'https://boards.greenhouse.io/rti',
  'https://jobs.ashbyhq.com/rundoo',
  'https://jobs.ashbyhq.com/rutter',
  'https://boards.greenhouse.io/rvohealth',
  'https://boards.greenhouse.io/sambanovasystems',
  'https://boards.greenhouse.io/samsara',
  'https://boards.greenhouse.io/samsungresearchamerica',
  'https://sana.bamboohr.com/jobs',
  'https://jobs.lever.co/sanctuary',
  'https://jobs.ashbyhq.com/sardine',
  'https://scaleai.bamboohr.com/jobs',
  'https://boards.greenhouse.io/scaleai',
  'https://scality.bamboohr.com/jobs',
  'https://jobs.lever.co/scality',
  'https://jobs.lever.co/schoolhouse',
  'https://boards.greenhouse.io/sciencelogic',
  'https://jobs.lever.co/scratchfinancial',
  'https://scribd.bamboohr.com/jobs',
  'https://jobs.lever.co/scribd',
  'https://jobs.ashbyhq.com/searchlight',
  'https://searchlight.bamboohr.com/jobs',
  'https://boards.greenhouse.io/seatgeek',
  'https://securitize.bamboohr.com/jobs',
  'https://securityscorecard.bamboohr.com/jobs',
  'https://boards.greenhouse.io/securityscorecard',
  'https://boards.greenhouse.io/seekout',
  'https://boards.greenhouse.io/seesaw',
  'https://jobs.lever.co/seesaw',
  'https://boards.greenhouse.io/selffinancial',
  'https://boards.greenhouse.io/sentry',
  'https://jobs.lever.co/serverobotics',
  'https://boards.greenhouse.io/seveneightcapital',
  'https://boards.greenhouse.io/sezzle',
  'https://jobs.lever.co/sharethrough',
  'https://jobs.lever.co/sharkninja',
  'https://jobs.lever.co/shef',
  'https://shieldai.bamboohr.com/jobs',
  'https://jobs.lever.co/shieldai',
  'https://shift.bamboohr.com/jobs',
  'https://boards.greenhouse.io/shift',
  'https://boards.greenhouse.io/shopmonkey',
  'https://jobs.ashbyhq.com/shortstory',
  'https://boards.greenhouse.io/shortstory',
  'https://jobs.jobvite.com/shutterfly',
  'https://boards.greenhouse.io/sigmacomputing',
  'https://signal.bamboohr.com/jobs',
  'https://jobs.lever.co/signal',
  'https://jobs.lever.co/sigopt',
  'https://boards.greenhouse.io/simplisafe',
  'https://simpplr.bamboohr.com/jobs',
  'https://boards.greenhouse.io/simpplr',
  'https://boards.greenhouse.io/singlestore',
  'https://jobs.ashbyhq.com/skilljar',
  'https://boards.greenhouse.io/skilljar',
  'https://boards.greenhouse.io/skupos',
  'https://jobs.ashbyhq.com/skydio',
  'https://boards.greenhouse.io/skydio',
  'https://boards.greenhouse.io/skyryse',
  'https://boards.greenhouse.io/skyscanner',
  'https://jobs.lever.co/sleuth',
  'https://boards.greenhouse.io/slickdeals',
  'https://jobs.lever.co/smarking',
  'https://jobs.lever.co/smartadserver',
  'https://boards.greenhouse.io/smartasset',
  'https://jobs.lever.co/smartcar',
  'https://boards.greenhouse.io/smartertravel',
  'https://boards.greenhouse.io/smartrent',
  'https://boards.greenhouse.io/smartsheet',
  'https://jobs.ashbyhq.com/snackpass',
  'https://boards.greenhouse.io/snackpass',
  'https://boards.greenhouse.io/snorkelai',
  'https://boards.greenhouse.io/sofi',
  'https://jobs.lever.co/softrams',
  'https://boards.greenhouse.io/solana',
  'https://boards.greenhouse.io/solanafoundation',
  'https://jobs.ashbyhq.com/sonalabs',
  'https://jobs.lever.co/sonatype',
  'https://boards.greenhouse.io/sondermind',
  'https://sourcegraph.bamboohr.com/jobs',
  'https://boards.greenhouse.io/spacex',
  'https://jobs.jobvite.com/spacex',
  'https://jobs.ashbyhq.com/speak',
  'https://jobs.ashbyhq.com/speakeasy',
  'https://jobs.ashbyhq.com/spendesk',
  'https://jobs.lever.co/spendesk',
  'https://jobs.lever.co/sphere',
  'https://splice.bamboohr.com/jobs',
  'https://boards.greenhouse.io/splice',
  'https://jobs.jobvite.com/splunk',
  'https://jobs.lever.co/spotify',
  'https://spotnana.bamboohr.com/jobs',
  'https://jobs.ashbyhq.com/spreetail',
  'https://jobs.lever.co/spreetail',
  'https://springhealth.bamboohr.com/jobs',
  'https://jobs.lever.co/springhealth',
  'https://jobs.lever.co/springboard',
  'https://boards.greenhouse.io/squarespace',
  'https://stackline.bamboohr.com/jobs',
  'https://boards.greenhouse.io/stackline',
  'https://jobs.lever.co/stackline',
  'https://boards.greenhouse.io/stairwell',
  'https://boards.greenhouse.io/standardai',
  'https://jobs.ashbyhq.com/starlight',
  'https://startree.bamboohr.com/jobs',
  'https://boards.greenhouse.io/startree',
  'https://boards.greenhouse.io/status',
  'https://boards.greenhouse.io/stellarhealth',
  'https://boards.greenhouse.io/stockx',
  'https://boards.greenhouse.io/storable',
  'https://boards.greenhouse.io/strava',
  'https://streamsets.bamboohr.com/jobs',
  'https://jobs.lever.co/streamsets',
  'https://boards.greenhouse.io/stripe',
  'https://strongdm.bamboohr.com/jobs',
  'https://jobs.lever.co/strongdm',
  'https://jobs.ashbyhq.com/stytch',
  'https://boards.greenhouse.io/submittable',
  'https://subspacelabs.bamboohr.com/jobs',
  'https://jobs.lever.co/subspacelabs',
  'https://boards.greenhouse.io/sumologic',
  'https://boards.greenhouse.io/sunshine',
  'https://boards.greenhouse.io/supabase',
  'https://jobs.lever.co/super',
  'https://boards.greenhouse.io/superblocks',
  'https://jobs.lever.co/sure',
  'https://jobs.ashbyhq.com/swoop',
  'https://jobs.lever.co/swordhealth',
  'https://jobs.lever.co/symplicity',
  'https://boards.greenhouse.io/synack',
  'https://boards.greenhouse.io/synapse',
  'https://jobs.ashbyhq.com/syndicate',
  'https://synthego.bamboohr.com/jobs',
  'https://jobs.lever.co/synthego',
  'https://jobs.lever.co/system1',
  'https://boards.greenhouse.io/taboola',
  'https://jobs.lever.co/tackle',
  'https://tailscale.bamboohr.com/jobs',
  'https://boards.greenhouse.io/tailscale',
  'https://boards.greenhouse.io/tailwind',
  'https://jobs.lever.co/talend',
  'https://boards.greenhouse.io/tally',
  'https://boards.greenhouse.io/talos',
  'https://jobs.lever.co/tamr',
  'https://boards.greenhouse.io/tanium',
  'https://jobs.lever.co/tapcart',
  'https://jobs.lever.co/taplytics',
  'https://boards.greenhouse.io/tatari',
  'https://teachable.bamboohr.com/jobs',
  'https://jobs.lever.co/teachable',
  'https://jobs.ashbyhq.com/teachfx',
  'https://boards.greenhouse.io/tecovas',
  'https://tecton.bamboohr.com/jobs',
  'https://jobs.lever.co/tecton',
  'https://boards.greenhouse.io/tekion',
  'https://jobs.lever.co/teleport',
  'https://jobs.jobvite.com/teletracking',
  'https://jobs.lever.co/teller',
  'https://jobs.jobvite.com/tencent',
  'https://jobs.lever.co/tenstorrent',
  'https://jobs.lever.co/tessian',
  'https://boards.greenhouse.io/textio',
  'https://jobs.lever.co/thehubproject',
  'https://boards.greenhouse.io/thenewyorktimes',
  'https://thoughtspot.bamboohr.com/jobs',
  'https://boards.greenhouse.io/thoughtspot',
  'https://jobs.lever.co/thrasio',
  'https://threekit.bamboohr.com/jobs',
  'https://jobs.lever.co/threekit',
  'https://boards.greenhouse.io/thunder',
  'https://boards.greenhouse.io/tifin',
  'https://titan.bamboohr.com/jobs',
  'https://boards.greenhouse.io/titan',
  'https://boards.greenhouse.io/toast',
  'https://boards.greenhouse.io/tomorrowhealth',
  'https://jobs.lever.co/tonal',
  'https://boards.greenhouse.io/tonic',
  'https://jobs.lever.co/tonic',
  'https://boards.greenhouse.io/torcrobotics',
  'https://boards.greenhouse.io/towerresearchcapital',
  'https://boards.greenhouse.io/tradeblock',
  'https://boards.greenhouse.io/transcarent',
  'https://jobs.lever.co/transcend',
  'https://boards.greenhouse.io/transfix',
  'https://jobs.ashbyhq.com/tremendous',
  'https://boards.greenhouse.io/trialspark',
  'https://boards.greenhouse.io/triplelift',
  'https://jobs.ashbyhq.com/trucksmarter',
  'https://jobs.lever.co/truepill',
  'https://truveta.bamboohr.com/jobs',
  'https://boards.greenhouse.io/truveta',
  'https://jobs.ashbyhq.com/turnstile',
  'https://boards.greenhouse.io/tusimple',
  'https://boards.greenhouse.io/twilio',
  'https://boards.greenhouse.io/twitch',
  'https://boards.greenhouse.io/udacity',
  'https://boards.greenhouse.io/udemy',
  'https://jobs.lever.co/udemy',
  'https://jobs.ashbyhq.com/udisc',
  'https://jobs.lever.co/uncountable',
  'https://jobs.lever.co/uniphore',
  'https://jobs.ashbyhq.com/unison',
  'https://boards.greenhouse.io/uniteus',
  'https://boards.greenhouse.io/unqork',
  'https://boards.greenhouse.io/upbound',
  'https://jobs.ashbyhq.com/upcodes',
  'https://upgrade.bamboohr.com/jobs',
  'https://boards.greenhouse.io/upgrade',
  'https://upguard.bamboohr.com/jobs',
  'https://jobs.lever.co/upguard',
  'https://jobs.lever.co/uplift',
  'https://upside.bamboohr.com/jobs',
  'https://jobs.lever.co/upstox',
  'https://boards.greenhouse.io/utopialabs',
  'https://jobs.lever.co/validere',
  'https://jobs.lever.co/valkyrietrading',
  'https://boards.greenhouse.io/valon',
  'https://jobs.ashbyhq.com/vana',
  'https://jobs.ashbyhq.com/vanta',
  'https://boards.greenhouse.io/vanta',
  'https://boards.greenhouse.io/vardaspace',
  'https://vectra.bamboohr.com/jobs',
  'https://jobs.ashbyhq.com/veev',
  'https://jobs.lever.co/veho',
  'https://boards.greenhouse.io/vendr',
  'https://jobs.lever.co/verdantrobotics',
  'https://jobs.lever.co/vergegenomics',
  'https://jobs.lever.co/vergesense',
  'https://boards.greenhouse.io/veritone',
  'https://jobs.ashbyhq.com/verkada',
  'https://verkada.bamboohr.com/jobs',
  'https://jobs.lever.co/verkada',
  'https://boards.greenhouse.io/vestmark',
  'https://vetted.bamboohr.com/jobs',
  'https://jobs.lever.co/vetted',
  'https://vevo.bamboohr.com/jobs',
  'https://jobs.lever.co/vevo',
  'https://boards.greenhouse.io/via',
  'https://jobs.lever.co/via',
  'https://jobs.lever.co/videahealth',
  'https://boards.greenhouse.io/vidyard',
  'https://jobs.jobvite.com/view',
  'https://vimeo.bamboohr.com/jobs',
  'https://boards.greenhouse.io/vimeo',
  'https://jobs.ashbyhq.com/vitally',
  'https://voltacharging.bamboohr.com/jobs',
  'https://boards.greenhouse.io/voltacharging',
  'https://boards.greenhouse.io/voltrondata',
  'https://jobs.lever.co/voltus',
  'https://boards.greenhouse.io/vori',
  'https://boards.greenhouse.io/vouchinsurance',
  'https://jobs.lever.co/vowel',
  'https://jobs.lever.co/walkme',
  'https://boards.greenhouse.io/walnut',
  'https://boards.greenhouse.io/waltzhealth',
  'https://boards.greenhouse.io/warbyparker',
  'https://boards.greenhouse.io/warp',
  'https://boards.greenhouse.io/watershed',
  'https://boards.greenhouse.io/wayfair',
  'https://jobs.lever.co/wealthfront',
  'https://jobs.ashbyhq.com/webflow',
  'https://boards.greenhouse.io/webflow',
  'https://jobs.lever.co/webfx',
  'https://boards.greenhouse.io/well',
  'https://jobs.lever.co/wellframe',
  'https://jobs.lever.co/wepay',
  'https://jobs.lever.co/weride',
  'https://boards.greenhouse.io/wework',
  'https://jobs.ashbyhq.com/whatnot',
  'https://boards.greenhouse.io/whatnot',
  'https://boards.greenhouse.io/wholesail',
  'https://jobs.lever.co/whoop',
  'https://boards.greenhouse.io/wildtype',
  'https://boards.greenhouse.io/willowtree',
  'https://jobs.ashbyhq.com/wisk',
  'https://jobs.lever.co/wisk',
  'https://boards.greenhouse.io/wistia',
  'https://jobs.ashbyhq.com/wiz',
  'https://jobs.lever.co/wiz',
  'https://boards.greenhouse.io/wizardsofthecoast',
  'https://jobs.ashbyhq.com/woflow',
  'https://boards.greenhouse.io/wonderschool',
  'https://wonolo.bamboohr.com/jobs',
  'https://jobs.lever.co/wonolo',
  'https://boards.greenhouse.io/workera',
  'https://workfusion.bamboohr.com/jobs',
  'https://jobs.lever.co/workramp',
  'https://boards.greenhouse.io/workrise',
  'https://boards.greenhouse.io/workstream',
  'https://jobs.ashbyhq.com/worldcoin',
  'https://boards.greenhouse.io/xealth',
  'https://boards.greenhouse.io/xometry',
  'https://jobs.lever.co/xometry',
  'https://jobs.jobvite.com/xylem',
  'https://boards.greenhouse.io/yext',
  'https://jobs.jobvite.com/yodlee',
  'https://jobs.ashbyhq.com/youpi',
  'https://boards.greenhouse.io/yugabyte',
  'https://jobs.lever.co/yugabyte',
  'https://boards.greenhouse.io/zanbato',
  'https://zapier.bamboohr.com/jobs',
  'https://boards.greenhouse.io/zapier',
  'https://boards.greenhouse.io/zenefits',
  'https://jobs.lever.co/zerion',
  'https://jobs.lever.co/zeta',
  'https://jobs.lever.co/zignallabs',
  'https://jobs.ashbyhq.com/zip',
  'https://jobs.ashbyhq.com/zip',
  'https://boards.greenhouse.io/zip',
  'https://boards.greenhouse.io/zip',
  'https://jobs.lever.co/zip',
  'https://jobs.lever.co/zip',
  'https://jobs.ashbyhq.com/zippin',
  'https://boards.greenhouse.io/ziprecruiter',
  'https://jobs.lever.co/zoox',
  'https://boards.greenhouse.io/zora',
  'https://jobs.lever.co/zotecpartners',
  'https://boards.greenhouse.io/zscaler',
]
