import SafeText from '../../SafeText'
import SkillValueMapper from './SkillValueMapper'

class InlineSkillValueMapper extends SkillValueMapper {
  title() {
    return null
  }

  children() {
    const { skillGroup, skill } = this.skillEntry
    return (
      <SafeText val={true} style>
        <SafeText val={skillGroup} style={this.styles.title}>
          {skillGroup}{' '}
        </SafeText>
        <SafeText
          val={skill && skill.length > 0}
          style={this.styles.inlineSubtitle}
        >
          {skill && skill.map((s) => s.name).join(', ')}
        </SafeText>
      </SafeText>
    )
  }
}

export default InlineSkillValueMapper
