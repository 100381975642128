import { Menu, Transition } from '@headlessui/react'
import { TrashIcon } from '@heroicons/react/outline'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DotsVerticalIcon,
  HashtagIcon,
  PlusIcon,
} from '@heroicons/react/solid'
import ObjectID from 'bson-objectid'
import DeleteResumeModal from 'components/Modals/DeleteResumeModal'
import usePrevious from 'hooks/usePrevious'
import { Fragment, useContext, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { flushSync } from 'react-dom'
import { useFormContext } from 'react-hook-form'
import { defaultValues } from 'utils/form/helper'
import { classNames } from 'utils/helper'
import { ContentLeftPanelContext } from 'views/editor/PanelLayoutSelector'

function DropdownMenu({ deleteSection }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <>
      <SectionDeleteModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        submitAction={deleteSection}
      />
      <Menu as="span" className="relative">
        {({ open }) => (
          <>
            <Menu.Button
              onClick={(e) => e.stopPropagation()}
              className="inline-flex items-center p-1 rounded text-sm font-medium text-gray-700 hover:bg-gray-200"
            >
              <DotsVerticalIcon
                className="h-4 w-4 text-gray-700"
                aria-hidden="true"
              />
            </Menu.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="z-10 divide-y absolute bottom-full right-0 mb-2 -mr-1 w-28 rounded-md border overflow-hidden shadow-lg bg-white text-left"
              >
                <div>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={(e) => {
                          e.stopPropagation()
                          setIsModalOpen(true)
                        }}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block cursor-pointer px-4 py-2 text-sm text-red-600'
                        )}
                      >
                        Delete
                      </div>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  )
}

function DropdownContent({
  setIsOpen,
  isOpen,
  title,
  showActionMenu = false,
  deleteSection = () => {},
  children,
}) {
  return (
    <div className="py-2">
      {showActionMenu && (
        <div className="mt-1 flex justify-between items-center">
          <p className="text-xs uppercase tracking-wide font-medium">
            Custom Section
          </p>
          <span className="text-xs bg-yellow-200 rounded-full py-0.5 px-2">
            Premium
          </span>
        </div>
      )}
      <div className="flex gap-1 items-center h-12 focus:outline-none">
        <div className="flex min-w-0 items-center flex-1 leading-none font-medium text-base">
          {title}
        </div>
        <div className="flex-none">
          {showActionMenu && <DropdownMenu deleteSection={deleteSection} />}
        </div>
        <button
          onClick={setIsOpen}
          className={classNames('hover:bg-gray-200 flex-none rounded')}
        >
          {isOpen ? (
            <ChevronUpIcon className="w-6 h-6" />
          ) : (
            <ChevronDownIcon className="w-6 h-6" />
          )}
        </button>
      </div>
      {isOpen && children}
    </div>
  )
}

export function BasicsDropdown({ Icon = HashtagIcon }) {
  const { dropdownState, setDropdownState, selectedItem, setSelectedItem } =
    useContext(ContentLeftPanelContext)
  const selected = selectedItem?.section === 'basics'
  const isOpen = !dropdownState['basics']
  const setIsOpen = () =>
    setDropdownState((prev) => {
      return {
        ...prev,
        basics: isOpen,
      }
    })

  const selectItem = () => {
    setSelectedItem({
      section: 'basics',
    })
  }

  const content = (
    <div className="mb-4 flex flex-col gap-px">
      <div
        className={classNames(
          selected ? 'bg-indigo-600 text-white' : 'hover:bg-gray-200',
          'flex items-center h-8 rounded text-sm px-3'
        )}
      >
        <button
          onClick={() => selectItem(0)}
          className="flex-1 flex items-center h-full"
        >
          <Icon className="w-4 h-4" />
          <span className="flex-1 px-2 text-left leading-none font-bold">
            Basics
          </span>
        </button>
      </div>
    </div>
  )

  return (
    <DropdownContent
      title="Personal Information"
      setIsOpen={setIsOpen}
      isOpen={isOpen}
    >
      {content}
    </DropdownContent>
  )
}

const SectionDeleteModal = ({ isOpen, setIsOpen, submitAction }) => {
  const onSubmit = () => {
    setIsOpen(false)
    submitAction()
  }
  return (
    <DeleteResumeModal
      isOpen={isOpen}
      closeModal={() => {
        setIsOpen(false)
      }}
      submitModal={onSubmit}
      args={[]}
    />
  )
}

const SectionDropdownEntry = ({
  item,
  selectItem,
  title,
  Icon,
  enableEdit,
  deleteEntry,
  isSelected,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const setModalOpen = () => setIsModalOpen(true)
  return (
    <div
      key={item.id}
      className={classNames(
        isSelected ? 'bg-indigo-600 text-white' : 'hover:bg-gray-200',
        'flex items-center h-8 rounded text-sm px-3 group'
      )}
    >
      <SectionDeleteModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        submitAction={deleteEntry}
      />
      <button
        onClick={() => selectItem(item)}
        className="flex-1 flex items-center h-full overflow-hidden"
      >
        <Icon className="w-4 h-4 flex-none" />
        <span className="flex-1 pl-2 text-left leading-none font-bold truncate">
          {title}
        </span>
      </button>
      {!enableEdit && (
        <div className="m-1 w-4 h-4 items-center flex sm:hidden group-hover:flex">
          <button
            onClick={setModalOpen}
            className={classNames(
              isSelected
                ? 'bg-gray-100 hover:bg-gray-200'
                : 'hover:bg-gray-100',
              'text-red-500 p-1 rounded'
            )}
          >
            <TrashIcon className="w-4 h-4" />
          </button>
        </div>
      )}
    </div>
  )
}

export function FieldArraySectionDropdown({
  fieldArray,
  section,
  dropdownState,
  setDropdownState,
  selectedItem,
  setSelectedItem,
  title,
  overrideName = null,
  titleSelector = null,
  Icon = HashtagIcon,
  showActionMenu = false,
  enableEdit = false,
  deleteSection = () => {},
}) {
  const [initial, setInitial] = useState(true)
  const { fields, append, remove, move } = fieldArray
  const prevCount = usePrevious(fields.length)
  const { watch } = useFormContext()

  const isCustomSection = section.startsWith('customSections')

  const isOpen = !dropdownState[overrideName || section]
  const setIsOpen = () =>
    setDropdownState((prev) => {
      return {
        ...prev,
        [overrideName || section]: isOpen,
      }
    })

  const selectItem = (item) => {
    setSelectedItem({
      section: section,
      itemId: item._id,
    })
  }

  const addItem = () => {
    const values = isCustomSection
      ? defaultValues['custom']
      : defaultValues[section]
    const item = {
      _id: ObjectID().toHexString(),
      ...values,
    }
    append(item)
  }

  const onDragEnd = (result) => {
    const { source, destination } = result
    if (!destination) {
      return
    }
    const from = source.index
    const to = destination.index
    flushSync(() => {
      move(from, to)
    })
  }

  useEffect(() => {
    if (initial) {
      setInitial(false)
    } else if (fields.length > 0) {
      if (fields.length > prevCount) {
        selectItem(fields[fields.length - 1])
      }
    }
  }, [fields.length])

  const content = (
    <div className="mb-4">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={section}>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {fields.map((item, i) => {
                const isSelected = selectedItem.itemId === item._id
                return (
                  <Draggable key={item.id} draggableId={item.id} index={i}>
                    {(provided, snapshot) => (
                      <div
                        className={classNames(
                          snapshot.isDragging ? 'shadow-lg border' : '',
                          'relative group mb-px bg-white rounded'
                        )}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div
                          {...provided.dragHandleProps}
                          className={classNames(
                            // snapshot.isDragging
                            //   ? 'flex'
                            //   : 'hidden group-hover:flex',
                            'absolute justify-center items-center flex inset-y-0 -left-5'
                          )}
                        >
                          <div
                            className={classNames(
                              'relative items-center justify-center w-5 h-4'
                            )}
                          >
                            <DotsVerticalIcon className="h-4 w-4 mr-1.5" />
                            <DotsVerticalIcon className="h-4 w-4 absolute top-0 left-1.5" />
                          </div>
                        </div>
                        <SectionDropdownEntry
                          isSelected={isSelected}
                          item={item}
                          selectedItem={selectedItem}
                          selectItem={selectItem}
                          Icon={Icon}
                          enableEdit={enableEdit}
                          title={
                            watch(`${section}.${i}.${titleSelector}`) ||
                            '(Untitled)'
                          }
                          deleteEntry={() => remove(i)}
                        />
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!enableEdit && (
        <button
          onClick={addItem}
          className="cursor-pointer w-full flex items-center h-8 rounded hover:bg-gray-200 text-sm px-3"
        >
          <PlusIcon className="w-4 h-4" />
          <span className="ml-2 leading-none font-bold">Add</span>
        </button>
      )}
    </div>
  )
  return (
    <DropdownContent
      showActionMenu={showActionMenu}
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      title={title}
      deleteSection={deleteSection}
    >
      {content}
    </DropdownContent>
  )
}
