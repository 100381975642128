import { getDate, getGpa, joinFields } from '../../Utils/helper'
import EducationValueMapper from './EducationValueMapper'

class SplitEducationValueMapper extends EducationValueMapper {
  subtitle() {
    const { location, startDate, endDate, present } = this.education
    const locationText =
      location && joinFields(', ', location.city, location.region)
    const dateText = getDate(startDate, endDate, present)
    return joinFields(' • ', locationText, dateText)
  }

  text() {
    const { gpa, degree } = this.education
    const gpaText = getGpa(gpa)
    return joinFields(' • ', degree, gpaText)
  }
}

export default SplitEducationValueMapper
