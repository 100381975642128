export function allowedToDownload(resume) {
  if (!resume) {
    return true
  }
  if (resume.premium) {
    return true
  }
  if (resume.customSections && resume.customSections.length > 0) {
    return false
  }
  if (resume.color && resume.color !== '#000000') {
    return false
  }
  if (resume.template > 1) {
    return false
  }
  return true
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
