export const resume = {
  basics: {
    firstName: 'Sophia',
    lastName: 'Gonzalez',
    title: 'Blockchain Developer',
    picture: 'https://example.com/sophia-gonzalez.jpg',
    email: 'sophia.gonzalez@example.com',
    phone: '123-456-7890',
    url: 'https://www.sophiagonzalez.dev',
    summary:
      'Experienced Blockchain Developer with a proven track record of designing and implementing secure and efficient blockchain solutions. Skilled in smart contract development, decentralized applications, and cryptographic protocols.',
    location: {
      city: 'New York',
      countryCode: 'US',
      region: 'New York',
    },
    linkedInUrl: 'https://www.linkedin.com/in/sophiagonzalez',
    portfolioUrl: 'https://portfolio.sophiagonzalez.dev',
  },
  experience: [
    {
      company: 'BlockTech Innovations',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Senior Blockchain Developer',
      url: 'https://www.blocktechinnovations.com',
      startDate: '2020-06-15',
      endDate: '2023-08-31',
      summary: null,
      highlights: [
        'Designed and deployed a decentralized supply chain management system using blockchain, reducing data tampering by 90% and improving transparency.',
        'Developed and audited smart contracts for an NFT marketplace, resulting in a 60% decrease in contract vulnerabilities and enhanced user trust.',
        'Optimized blockchain consensus mechanisms, achieving a 2x increase in transaction throughput and reducing latency by 40%.',
        'Collaborated with cross-functional teams to integrate blockchain technology into existing enterprise systems, enhancing data security and immutability.',
        'Mentored junior developers, fostering a culture of continuous learning and skill development in blockchain technologies.',
      ],
      present: false,
    },
    {
      company: 'CipherTech Labs',
      location: {
        city: 'Austin',
        countryCode: 'US',
        region: 'Texas',
      },
      position: 'Blockchain Developer',
      url: 'https://www.ciphertechlabs.com',
      startDate: '2018-04-01',
      endDate: '2020-05-31',
      summary: null,
      highlights: [
        'Designed and implemented a privacy-focused decentralized identity management system, ensuring secure and verifiable user authentication.',
        'Developed custom consensus algorithms for a permissioned blockchain network, increasing network resilience and achieving 99.9% uptime.',
        'Collaborated with cryptography experts to integrate zero-knowledge proofs into smart contracts, enhancing transaction privacy and confidentiality.',
      ],
      present: false,
    },
    {
      company: 'CryptoStart',
      location: {
        city: 'Berlin',
        region: 'DE',
      },
      position: 'Blockchain Engineer',
      url: 'https://www.cryptostart.io',
      startDate: '2016-09-15',
      endDate: '2018-02-28',
      summary: null,
      highlights: [
        'Designed and developed a decentralized crowdfunding platform using Ethereum smart contracts, raising over $1 million in funding for startups.',
        'Contributed to the design and implementation of a multi-signature wallet application, enhancing user control and security for cryptocurrency storage.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2014-09-01',
      endDate: '2016-05-15',
      gpa: '3.95',
      courses: [
        'Blockchain and Cryptocurrencies',
        'Distributed Systems',
        'Smart Contract Development',
        'Cryptography',
      ],
      summary: null,
      present: false,
    },
    {
      institution: 'University of Toronto',
      degree: 'Bachelor of Applied Science in Computer Engineering',
      major: 'Computer Engineering',
      location: {
        city: 'Toronto',
        countryCode: 'CA',
        region: 'Ontario',
      },
      startDate: '2010-09-01',
      endDate: '2014-05-15',
      gpa: '3.90',
      courses: [
        'Digital Systems Design',
        'Embedded Systems',
        'Data Structures and Algorithms',
        'Computer Networks',
      ],
      summary: null,
      present: false,
    },
  ],
  awards: [
    {
      title: 'Blockchain Innovation Award',
      date: '2022-12-15',
      awarder: 'Blockchain Technology Institute',
      summary:
        'Recognized for outstanding contributions to blockchain technology and the development of innovative blockchain solutions.',
    },
    {
      title: 'Best Smart Contract Development Project',
      date: '2020-06-01',
      awarder: 'Decentralized Applications Forum',
      summary:
        'Received the award for creating a secure and efficient smart contract solution that addressed complex business logic.',
    },
  ],
  skills: [
    {
      skillGroup: 'Blockchain Development',
      skill: [
        { name: 'Solidity' },
        { name: 'Web3.js' },
        { name: 'Truffle' },
        { name: 'Hyperledger Fabric' },
      ],
    },
    {
      skillGroup: 'Smart Contracts',
      skill: [
        { name: 'Smart Contract Design' },
        { name: 'Security Audits' },
        { name: 'Token Standards (ERC-20, ERC-721)' },
        { name: 'Oracles Integration' },
      ],
    },
    {
      skillGroup: 'Cryptography',
      skill: [
        { name: 'Elliptic Curve Cryptography' },
        { name: 'Zero-Knowledge Proofs' },
        { name: 'Multi-Signature Wallets' },
        { name: 'Hash Functions' },
      ],
    },
  ],
  projects: [
    {
      name: 'Decentralized Voting Platform',
      description:
        'Developed a secure and tamper-proof voting platform using blockchain technology, enabling transparent and verifiable elections.',
      startDate: '2022-03-01',
      endDate: '2022-08-31',
      present: false,
    },
    {
      name: 'NFT Marketplace',
      description:
        'Led the development of an NFT marketplace that supports various token standards and integrates with multiple blockchain networks.',
      startDate: '2020-09-15',
      endDate: '2021-03-31',
      present: false,
    },
    {
      name: 'Cross-Chain Asset Transfer',
      description:
        'Designed and implemented a protocol for transferring assets between different blockchain networks, improving interoperability.',
      startDate: '2019-06-01',
      endDate: '2019-12-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Languages',
      details: [
        {
          title: 'English',
          subtitle: 'Fluent',
        },
        {
          title: 'Spanish',
          subtitle: 'Intermediate',
        },
      ],
    },
  ],
}
