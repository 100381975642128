import { MinusIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import DateInput from 'components/Forms/Editor/common/DateInput'
import QuillInput from 'components/Forms/EditorV2/Quill'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useController, useFieldArray, useFormContext } from 'react-hook-form'
import ReactQuill from 'react-quill'
import { formFields } from 'utils/form/helper'

const Input = ({
  register,
  name,
  className,
  label,
  type = 'text',
  placeholder = '',
  handleKeyDown = () => {},
}) => (
  <div className={className}>
    {label && (
      <label
        htmlFor={name}
        className="block text-xs font-normal pb-2 dark:text-white"
      >
        {label}
      </label>
    )}
    <input
      {...register(name)}
      onKeyDown={handleKeyDown}
      type={type}
      id={name}
      className="py-2 px-3 block w-full border-gray-200 bg-blueGray-50 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
      placeholder={placeholder || label}
    />
  </div>
)

export const Checkbox = ({ register, name, className, label }) => (
  <div className="flex items-center col-span-12">
    <input
      {...register(name)}
      id={name}
      type="checkbox"
      value=""
      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
    />
    <label
      htmlFor={name}
      className="ml-2 text-xs font-normal text-gray-900 dark:text-gray-300"
    >
      {label}
    </label>
  </div>
)

const Layout = ({ text, children, isCustomSection = false }) => {
  const [showExample, setShowExample] = useState(false)
  return showExample ? (
    <div className="py-4 px-6 relative">
      <button
        type="button"
        onClick={() => setShowExample(false)}
        className="flex items-center justify-center absolute top-2 right-2 p-2"
      >
        <XIcon className="w-6 h-6" />
      </button>
      <div className="h-8 mb-2 flex flex-col">
        <h2 className="flex-1 font-semibold flex justify-between items-center">
          Custion Section How-to Guide
        </h2>
      </div>
      <p className="mb-6">
        A "custom section" enables you to create open-ended sections you can
        customize to fit your professional experience.
      </p>
      <div className="mb-6">
        <h3 className="font-medium mb-3">Custom section entry framework</h3>
        <p className="mb-3">
          A custom section entry uses the same layout as an "Experience" entry.
          However, instead of named primary fields like company and position, we
          use title and subtitle to make it generic for you to customize. The
          fields are optional so you can fill out whichever inputs you need
          depending on how you would like to structure the section.
        </p>
        <p className="mb-4 font-medium">
          Check out how the fields will be displayed on your resume:
        </p>
        <div className="flex flex-col border border-gray-700 rounded p-4">
          <div className="flex gap-1 mb-0.5">
            <span className="font-semibold">Title</span>
            <span>Subtitle</span>
          </div>
          <div className="flex gap-1">
            <span>[Start date] - [end date]</span>
            <span>•</span>
            <span>City, State</span>
          </div>
          <div className="mt-2 mb-0.5">
            <p>Description</p>
          </div>
          <ul className="list-none">
            <li>• A bullet point</li>
            <li>• Another bullet point</li>
          </ul>
        </div>
      </div>
      <h3 className="font-medium mb-3">Examples</h3>
      <p className="mb-6">
        Here are some real-world examples of how to use custom sections to build
        out a well-rounded resume.
      </p>
      <div className="mb-6">
        <h3 className="font-medium mb-3">Volunteer Section</h3>
        <p className="mb-3 text-sm italic">
          Note the "Description" field is not used here
        </p>
        <div className="flex flex-col border border-gray-700 rounded p-4">
          <div className="mb-0.5">
            <span className="font-semibold">Central Texas Food Bank</span>
            <span className="ml-1">Volunteer</span>
          </div>
          <div className="flex gap-1">
            <span>Jan 2020 - Present</span>
            <span>•</span>
            <span>Austin, TX</span>
          </div>
          <ul className="mt-2 list-none">
            <li>
              • Assisted in organizing and distributing food supplies to
              individuals and families in need, ensuring prompt and efficient
              delivery of essentials.
            </li>
            <li>
              • Earned recognition for dedication and outstanding volunteer
              service, receiving the Central Texas Food Bank Volunteer of the
              Year award in 2020.
            </li>
          </ul>
        </div>
      </div>
      <div className="mb-6">
        <h3 className="font-medium mb-3">Awards Section</h3>
        <p className="mb-3 text-sm italic">
          Note the date, city, state, and bullet point fields are not used here
        </p>
        <div className="flex flex-col border border-gray-700 rounded p-4">
          <div className="mb-0.5">
            <span className="font-semibold">
              Rookie Sales Person of the Year,
            </span>
            <span className="ml-1">Salesforce, 2023</span>
          </div>
          <div className="mt-2 mb-0.5">
            <p>
              Recognized for exemplary sales performance and outstanding
              contributions to the team during the first year of employment.
            </p>
          </div>
        </div>
      </div>
      <div className="mb-6">
        <h3 className="font-medium mb-3">Publications Section</h3>
        <p className="mb-3 text-sm italic">
          Note the date, city, state, and bullet point fields are not used here
        </p>
        <div className="flex flex-col border border-gray-700 rounded p-4">
          <div className="mb-0.5">
            <span className="font-semibold">
              "Investigation of the Antibacterial Activity of Natural Plant
              Extracts"
            </span>
            <span className="ml-1">
              Journal of Applied Microbiology, November 2020
            </span>
          </div>
          <div className="mt-2 mb-0.5">
            <p>Co-authored with Dr. Rachel Thompson and Dr. Emily Rodriguez</p>
          </div>
        </div>
      </div>
      <p className="mb-6">
        These are just a few examples of what you can do with custom sections.
        Good luck customizing!
      </p>
    </div>
  ) : (
    <div className="pt-4 h-full">
      <div className="h-8 font-semibold px-6 flex items-center">
        <h2 className="flex-1 flex justify-between items-center">
          {text}
          {isCustomSection && (
            <button
              onClick={() => setShowExample(true)}
              type="button"
              className="font-normal text-sm text-blue-500"
            >
              How do I use this?
            </button>
          )}
        </h2>
      </div>
      <div className="py-6 px-6 grid grid-cols-12 gap-4">{children}</div>
    </div>
  )
}

const NestedQuillInput = ({ value, setValue, placeholder }) => {
  const modules = {
    toolbar: [['bold', 'italic', 'underline']],
  }
  const formats = ['bold', 'italic', 'underline']
  const onEditorStateChange = (editorState) => {
    if (editorState === '<p><br></p>') {
      setValue('')
    } else setValue(editorState)
  }
  return (
    <ReactQuill
      className="bg-blueGray-100"
      value={value}
      onChange={onEditorStateChange}
      modules={modules}
      formats={formats}
      placeholder={placeholder}
    />
  )
}

const NestedFieldArray = ({ section, idx, name, label, valueKey }) => {
  const { register } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: `${section}.${idx}.${name}`,
  })
  return (
    <>
      {fields.length === 0 && label && (
        <label
          htmlFor={name}
          className="block text-xs font-normal dark:text-white"
        >
          {label}
        </label>
      )}
      {fields.map((item, itemIdx) => {
        return (
          <div key={item.id} className="col-span-12">
            {itemIdx === 0 && label && (
              <label
                htmlFor={name}
                className="block text-xs font-normal pb-2 dark:text-white"
              >
                {label}
              </label>
            )}
            <div key={item.id} className="flex w-2/3 gap-2 items-center">
              <div className="flex-1">
                <Input
                  handleKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      append({ name: '', keywords: [] })
                    }
                  }}
                  key={item.id}
                  name={`${section}.${idx}.${name}.${itemIdx}.${valueKey}`}
                  className=""
                  label=""
                  register={register}
                />
              </div>
              <button
                className="p-1 border rounded"
                type="button"
                onClick={() => remove(itemIdx)}
              >
                <MinusIcon className="w-4 h-4" />
              </button>
            </div>
          </div>
        )
      })}
      <div className="col-span-12">
        <button
          className="text-sm font-semibold py-1.5 px-3 border rounded hover:bg-gray-100"
          type="button"
          onClick={() => append({ name: '', keywords: [] })}
        >
          + Add Skill
        </button>
      </div>
    </>
  )
}

const NestedArray = ({ section, idx, name, label, usage }) => {
  const { field } = useController({ name: `${section}.${idx}.${name}` })
  const onChange = (value, idx) => {
    const newValue = field.value
    newValue[idx] = value
    field.onChange(newValue)
  }
  const addValue = () => {
    const newValue = field.value
    newValue.push('')
    field.onChange(newValue)
  }
  const removeValue = (idx) => {
    const newValue = field.value
    newValue.splice(idx, 1)
    field.onChange(newValue)
  }
  return (
    <div className="col-span-12">
      {field.value.length === 0 && label && (
        <label
          htmlFor={name}
          className="block text-xs font-normal pb-2 dark:text-white col-span-12"
        >
          {label}
          {usage && <p className="mt-2 font-normal text-sm">{usage}</p>}
        </label>
      )}
      {field.value.map((item, idx) => {
        return (
          <div key={idx} className="col-span-12 mb-3">
            {idx === 0 && label && (
              <label
                htmlFor={name}
                className="block text-xs font-normal pb-2 dark:text-white"
              >
                {label}
                {usage && <p className="mt-2 font-normal text-xs">{usage}</p>}
              </label>
            )}
            <div className="flex gap-2 items-center">
              <div className="flex-1 min-w-0">
                <NestedQuillInput
                  value={item}
                  setValue={(value) => onChange(value, idx)}
                  placeholder={'Add a bullet point to your experience'}
                />
              </div>
              <button
                className="p-1 border rounded"
                type="button"
                onClick={() => removeValue(idx)}
              >
                <MinusIcon className="w-4 h-4" />
              </button>
            </div>
          </div>
        )
      })}
      <div className="col-span-12">
        <button
          className="text-xs font-medium py-1.5 px-3 border rounded hover:bg-gray-100"
          type="button"
          onClick={addValue}
        >
          + Add bullet point
        </button>
      </div>
    </div>
  )
}

const ArraySectionForm = ({
  section,
  selectedId,
  fieldArray,
  isCustomSection = false,
}) => {
  const { register, setFocus, watch, setValue } = useFormContext()
  const { fields } = fieldArray
  const formFieldItems = Object.entries(
    formFields[isCustomSection ? 'custom' : section]
  )
  const itemIdx = fields.findIndex((e) => e._id === selectedId)
  const title = section.startsWith('customSections')
    ? 'Entry'
    : section.charAt(0).toUpperCase() + section.slice(1)

  useEffect(() => {
    setFocus(`${section}.${itemIdx}.${formFieldItems[0][0]}`)
  }, [selectedId])

  return fields.map((item, idx) => {
    if (idx !== itemIdx) return null
    return (
      <Layout
        isCustomSection={isCustomSection}
        key={item._id}
        text={`Edit ${title}`}
      >
        {formFieldItems.map(([key, value]) => {
          if (value.isArray) {
            return (
              <NestedArray
                label={value.label}
                key={`${section}.${idx}.${key}`}
                section={section}
                name={key}
                idx={idx}
                usage={value.usage}
              />
            )
          }
          if (value.isFieldArray) {
            return (
              <NestedFieldArray
                label={value.label}
                valueKey={value.valueKey}
                key={`${section}.${idx}.${key}`}
                section={section}
                name={key}
                idx={idx}
              />
            )
          }
          if (value.isQuill) {
            const fieldValue = watch(`${section}.${idx}.${key}`)
            if (fieldValue === null) {
              return (
                <div key={`${section}.${idx}.${key}`} className="col-span-12">
                  <label className="mb-2 block text-xs font-normal">
                    {value.label}
                    {value.usage && (
                      <p className="mt-2 font-normal text-xs">{value.usage}</p>
                    )}
                  </label>
                  <div className="">
                    <button
                      className="text-xs font-medium py-1.5 px-3 border rounded hover:bg-gray-100"
                      type="button"
                      onClick={() => setValue(`${section}.${idx}.${key}`, '')}
                      // onClick={addValue}
                    >
                      + Add {value.label}
                    </button>
                  </div>
                </div>
              )
            }
            return (
              <div key={`${section}.${idx}.${key}`} className="col-span-12">
                <label
                  htmlFor={`${section}.${idx}.${key}`}
                  className="block text-xs font-normal mb-2"
                >
                  {value.label}
                  {value.usage && (
                    <p className="mt-2 font-normal text-xs">{value.usage}</p>
                  )}
                </label>
                <QuillInput
                  name={`${section}.${idx}.${key}`}
                  placeholder={value.placeholder}
                />
              </div>
            )
          }
          if (value.isCheckbox) {
            return (
              <Checkbox
                key={`${section}.${idx}.${key}`}
                name={`${section}.${idx}.${key}`}
                className={value.className}
                label={value.label}
                register={register}
              />
            )
          }
          if (value.isDate) {
            const dateValue = watch(`${section}.${idx}.${key}`)
            const present = value.isEndDate
              ? watch(`${section}.${idx}.present`)
              : null
            const formattedDate = dateValue
              ? moment.utc(dateValue).format('MM/YYYY')
              : ''
            return (
              <DateInput
                key={`${section}.${idx}.${key}`}
                name={`${section}.${idx}.${key}`}
                initialValue={formattedDate}
                setValue={(v) => {
                  console.log(formattedDate, v)
                  setValue(
                    `${section}.${idx}.${key}`,
                    moment.utc(v, 'MM/YYYY').toISOString()
                  )
                }}
                className={value.className}
                label={value.label}
                present={present}
              />
            )
          }
          return (
            <Input
              key={`${section}.${idx}.${key}`}
              name={`${section}.${idx}.${key}`}
              className={value.className}
              label={value.label}
              register={register}
              placeholder={value.placeholder}
            />
          )
        })}
      </Layout>
    )
  })
}

const BasicsForm = () => {
  const { register, setFocus } = useFormContext()

  useEffect(() => {
    setFocus(`basics.title`)
  }, [setFocus])

  return (
    <Layout text="Edit Personal Information">
      {Object.entries(formFields['basics']).map(([key, value], idx) => {
        return (
          <Input
            key={`basics.${key}`}
            name={`basics.${key}`}
            className={value.className}
            label={value.label}
            register={register}
            placeholder={value.placeholder}
          />
        )
      })}
      <div className="col-span-12">
        <label
          htmlFor="basics.summary"
          className="block text-xs font-normal mb-2 dark:text-white"
        >
          Summary
        </label>
        <QuillInput
          key="basics.summary"
          name="basics.summary"
          placeholder="Add your summary or objective here"
        />
      </div>
    </Layout>
  )
}

const FormContent = ({ section, selectedId, isCustomSection = false }) => {
  const methods = useFormContext()
  const values = methods.watch(section)
  if (!values) return null
  const fieldArray = { fields: values }
  return (
    <ArraySectionForm
      fieldArray={fieldArray}
      section={section}
      selectedId={selectedId}
      isCustomSection={isCustomSection}
    />
  )
}

export default function Form({ selectedItem }) {
  const section = selectedItem.section
  if (section === 'basics') {
    return <BasicsForm />
  }

  if (section.startsWith('customSections')) {
    return (
      <FormContent
        key={section}
        section={section}
        selectedId={selectedItem.itemId}
        isCustomSection
      />
    )
  }

  return (
    <FormContent
      key={section}
      section={section}
      selectedId={selectedItem.itemId}
    />
  )
}
