import React from 'react'

import { Field, useFormikContext } from 'formik'

import ColorSelector from 'components/Forms/Editor/ColorSelector'
import QuillInput from './common/QuillInput'
import { StarIcon } from '@heroicons/react/solid'

export default function ProfileForm(props) {
  return (
    <>
      <div className="px-4 py-5 sm:p-6">
        <div className="flex mb-5 gap-6">
          <div className="flex-grow">
            <label
              htmlFor="resume_name"
              className="block flex-none text-sm font-medium text-gray-700"
            >
              Resume Name
            </label>
            <Field
              component="input"
              type="text"
              name="name"
              id="resume_name"
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
              placeholder="Resume name"
            />
          </div>
          <div className="">
            <label
              htmlFor="resume_name"
              className="flex text-sm font-medium text-gray-700"
            >
              Color
              <span className="text-xs text-yellow-400 bg-yellow-200 ml-1 flex items-center justify-center px-1">
                <StarIcon className="h-4 w-4" />
              </span>
            </label>
            <Field component={ColorSelector} name="color" id="color" />
          </div>
        </div>
        <div className="pt-2 pb-8">
          <h2 className="text-xl font-bold leading-6 text-gray-900">
            Your Details
          </h2>
        </div>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              Job Title
            </label>
            <Field
              component="input"
              type="text"
              name="basics.title"
              id="title"
              autoComplete="title"
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
              placeholder="Your current title"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="first_name"
              className="block text-sm font-medium text-gray-700"
            >
              First name
            </label>
            <Field
              component="input"
              type="text"
              name="basics.firstName"
              id="first_name"
              autoComplete="given-name"
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
              placeholder="Your first name"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Last name
            </label>
            <Field
              component="input"
              type="text"
              name="basics.lastName"
              id="last_name"
              autoComplete="family-name"
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
              placeholder="Your last name"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="email_address"
              className="block text-sm font-medium text-gray-700"
            >
              Email address
            </label>
            <Field
              component="input"
              type="text"
              name="basics.email"
              id="email_address"
              autoComplete="email"
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
              placeholder="Your email address"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700"
            >
              Telephone
            </label>
            <Field
              component="input"
              type="text"
              name="basics.phone"
              id="phone"
              autoComplete="phone"
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md placeholder-gray-400"
              placeholder="Your phone number"
            />
          </div>

          {/*<div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Country / Region
            </label>
            <select
              id="country"
              name="basics.country"
              autoComplete="country"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option>United States</option>
              <option>Canada</option>
              <option>Mexico</option>
            </select>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="street_address" className="block text-sm font-medium text-gray-700">
              Street address
            </label>
            <Field
              component="input"
              type="text"
              name="basics.street_address"
              id="street_address"
              autoComplete="street-address"
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
              City
            </label>
            <Field
              component="input"
              type="text"
              name="basics.city"
              id="city"
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="state" className="block text-sm font-medium text-gray-700">
              State / Province
            </label>
            <Field
              component="input"
              type="text"
              name="basics.state"
              id="state"
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="postal_code" className="block text-sm font-medium text-gray-700">
              ZIP / Postal
            </label>
            <Field
              component="input"
              type="text"
              name="basics.postal_code"
              id="postal_code"
              autoComplete="postal-code"
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-md border-gray-300 rounded-md"
            />
          </div>*/}
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="personal_website"
              className="block text-sm font-medium text-gray-700"
            >
              Personal Website
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                https://
              </span>
              <Field
                component="input"
                type="text"
                name="basics.url"
                id="personal_website"
                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-md border-gray-300 placeholder-gray-400"
                placeholder="www.resumary.com"
              />
            </div>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="professional_website"
              className="block text-sm font-medium text-gray-700"
            >
              Professional Website
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                https://
              </span>
              <Field
                component="input"
                type="text"
                name="basics.portfolioUrl"
                id="professional_website"
                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-md border-gray-300 placeholder-gray-400"
                placeholder="www.github.com"
              />
            </div>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="linkedin"
              className="block text-sm font-medium text-gray-700"
            >
              LinkedIn
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                https://
              </span>
              <Field
                component="input"
                type="text"
                name="basics.linkedInUrl"
                id="linkedin"
                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-md border-gray-300 placeholder-gray-400"
                placeholder="www.linkedin.com/in/user"
              />
            </div>
          </div>
          <div className="col-span-6">
            <label
              htmlFor="summary"
              className="block text-sm font-medium text-gray-700"
            >
              Summary
            </label>
            <div className="mt-1 bg-white rounded-md shadow-sm">
              <QuillInput
                name="basics.summary"
                id="summary"
                placeholder="Add your resume summary here"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
