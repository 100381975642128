import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { Menu, Transition } from '@headlessui/react'

import CopyResumeModal from 'components/Modals/CopyResumeModal'
import DeleteResumeModal from 'components/Modals/DeleteResumeModal'
import Viewer from 'components/Viewer/Viewer.js'

import {
  DotsVerticalIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline'
import moment from 'moment'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CardResume(props) {
  let [modalState, setModalState] = useState({
    deleteResumeModal: false,
    duplicateResumeModal: false,
  })

  const premiumResume = !props.resume.premium && props.resume.premiumResume

  const updateModalState = (modalType) => (nextModalState) => {
    setModalState({ ...modalState, [modalType]: nextModalState })
  }

  function submitDeleteResumeModal() {
    props.deleteResume({ resumeId: props.resume.id })
    updateModalState('deleteResumeModal')(false)
  }

  function submitDuplicateResumeModal() {
    props.duplicateResume({ id: props.resume.id })
    updateModalState('duplicateResumeModal')(false)
  }

  return (
    <div className="bg-white border rounded-lg">
      <ReactTooltip effect="solid" />
      <DeleteResumeModal
        isOpen={modalState.deleteResumeModal}
        closeModal={() => updateModalState('deleteResumeModal')(false)}
        submitModal={submitDeleteResumeModal}
      />
      <CopyResumeModal
        isOpen={modalState.duplicateResumeModal}
        closeModal={() => updateModalState('duplicateResumeModal')(false)}
        submitModal={submitDuplicateResumeModal}
      />
      <div className="relative">
        <Link to={props.editUrl || `/resumes/${props.id}/edit`}>
          <div className="group relative">
            <div className="filter bg-[#f4f6f6] rounded-t-lg px-8 pt-4 pb-0 h-48 overflow-hidden group-hover:blur-sm">
              <div className="rounded-t-lg border-t border-r border-l overflow-hidden">
                <Viewer singlePage resume={props.resume} />
              </div>
            </div>
            <div className="invisible group-hover:visible flex items-center justify-center top-0 left-0 absolute h-full w-full">
              <div className="bg-gray-100 text-gray-700 font-medium text-xs px-4 py-2 rounded-full shadow-md">
                Edit resume
              </div>
            </div>
          </div>
          {premiumResume && (
            <button
              onClick={(e) => {
                e.preventDefault()
                props.isAllowed('download', 'max-w-4xl')
              }}
              className="absolute top-2 right-2 bg-yellow-200 p-1.5 rounded-full flex items-center group"
            >
              <InformationCircleIcon className="w-4 h-4 text-gray-500" />
              <div className="max-w-0 overflow-hidden transition-all duration-1000 group-hover:max-w-[100px]">
                <p className="font-medium text-gray-700 text-xs pl-1">
                  Premium
                </p>
              </div>
            </button>
          )}
        </Link>

        <div className="border-t">
          {/* {!allowedToDownload(props.resume) && (
            <div
              onClick={openPremiumDownloadModal}
              className="text-center cursor-pointer font-normal normal-case text-xs text-yellow-600 bg-yellow-200 rounded-b py-0.5"
            >
              This resume has premium features
            </div>
          )} */}
          {/* <div className="px-4 pt-4 text-xs uppercase font-bold text-gray-600 tracking-wide">
            Resume
          </div> */}
          <div className="flex flex-nowrap justify-between items-center px-4 py-3 w-full">
            <div className="flex flex-col items-start flex-auto overflow-hidden">
              <Link
                className="flex-auto max-w-full"
                to={props.editUrl || `/resumes/${props.id}/edit`}
              >
                <p
                  title={props.name}
                  className="font-semibold text-gray-900 hover:underline truncate"
                >
                  {props.name}
                </p>
              </Link>
              <div
                title={moment(props.updatedAt).format('lll')}
                className="text-sm text-gray-500"
              >
                <span>Edited {moment(props.updatedAt).fromNow()}</span>
              </div>
            </div>
            <div className="relative flex-none">
              <Menu as="span" className="">
                {({ open }) => (
                  <>
                    <Menu.Button className="inline-flex items-center p-1 rounded text-sm font-medium text-gray-700 hover:bg-[#f4f6f6]">
                      <DotsVerticalIcon
                        className="h-5 w-5 text-gray-700"
                        aria-hidden="true"
                      />
                    </Menu.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="z-10 divide-y absolute bottom-full right-0 mb-2 -mr-1 w-36 rounded-md border overflow-hidden shadow-lg bg-white"
                      >
                        <div>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to={
                                  props.editUrl || `/resumes/${props.id}/edit`
                                }
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block cursor-pointer px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                Edit
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                onClick={() =>
                                  props.downloadResume(props.resume)
                                }
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block cursor-pointer px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                Download
                              </div>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href={`/resumes/${props.id}/view`}
                                target="_blank"
                                rel="noreferrer"
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block cursor-pointer px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                View
                              </a>
                            )}
                          </Menu.Item>
                          {/* <Menu.Item>
                            {({ active }) => (
                              <Link
                                to={`/resumes/${props.id}/view`}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block cursor-pointer px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                Share
                              </Link>
                            )}
                          </Menu.Item> */}
                          {/* <Menu.Item>
                            {({ active }) => (
                              <Link
                                to={`/resumes/${props.id}/view`}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block cursor-pointer px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                {' '}
                                Export to Word
                              </Link>
                            )}
                          </Menu.Item> */}
                        </div>
                        <div>
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                onClick={() => {
                                  if (!props.isAllowed('duplicate')) return
                                  updateModalState('duplicateResumeModal')(true)
                                }}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block cursor-pointer px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                Duplicate
                              </div>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                onClick={() =>
                                  updateModalState('deleteResumeModal')(true)
                                }
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block cursor-pointer px-4 py-2 text-sm text-red-600'
                                )}
                              >
                                Delete
                              </div>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
