import { View } from '@react-pdf/renderer'
import SafeText from '../SafeText'
import HtmlContent from '../HtmlContent'

export default function SplitTemplateEntry({
  styles,
  title,
  subtitle,
  text,
  children,
  subheader = null,
}) {
  return (
    <View style={styles.entryContainer}>
      {(title || subtitle) && (
        <View style={styles.headerContainer}>
          <View style={styles.leftColumn}>
            <SafeText val={title} style={styles.title}>
              {title}
            </SafeText>
          </View>
          <View style={styles.rightColumn}>
            <SafeText val={subtitle} style={styles.subtitle}>
              {subtitle}
            </SafeText>
          </View>
        </View>
      )}
      {subheader && (
        <View style={styles.headerContainer}>
          <View style={styles.leftColumn}>
            <SafeText val={subheader} style>
              <HtmlContent styles={styles.htmlContent}>{subheader}</HtmlContent>
            </SafeText>
          </View>
        </View>
      )}
      <SafeText val={text} style={styles.details}>
        <HtmlContent styles={styles.htmlContent}>{text}</HtmlContent>
      </SafeText>
      {children}
    </View>
  )
}
