export const resume = {
  basics: {
    firstName: 'Taylor',
    lastName: 'Straus',
    title: 'Mobile App Developer',
    picture: 'https://example.com/taylor-straus.jpg',
    email: 'taylor.straus@example.com',
    phone: '987-654-3210',
    url: 'https://www.taylorstraus.dev',
    summary:
      'Passionate mobile app developer with a proven track record of creating user-centered and feature-rich applications. Experienced in iOS and Android development, with a strong focus on delivering high-quality, visually appealing, and responsive mobile experiences.',
    location: {
      city: 'San Francisco',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/taylorstraus',
    portfolioUrl: 'https://portfolio.taylorstraus.dev',
  },
  experience: [
    {
      company: 'Snap Inc.',
      location: {
        city: 'Los Angeles',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Mobile App Developer',
      url: 'https://www.snap.com',
      startDate: '2020-09-01',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Collaborated with cross-functional teams to implement new features and enhancements, resulting in a 20% increase in user engagement.',
        'Optimized app performance by implementing efficient caching strategies, reducing loading times by 30% and enhancing user experience.',
        'Led the migration of the app to a new version of a mobile framework, resulting in improved maintainability and code quality.',
        'Integrated third-party APIs for multimedia content sharing, expanding app functionality and user interaction.',
      ],
      present: false,
    },
    {
      company: 'Airbnb',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Mobile App Engineer',
      url: 'https://www.airbnb.com',
      startDate: '2018-03-15',
      endDate: '2020-08-31',
      summary: null,
      highlights: [
        'Designed and implemented a user-friendly booking flow, resulting in a 25% increase in successful booking completions.',
        'Developed interactive map features using location-based services, improving user navigation and enhancing overall usability.',
        'Collaborated with UX designers to create visually appealing and responsive UI components, enhancing user satisfaction and engagement.',
      ],
      present: false,
    },
    {
      company: 'Twitter',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Junior Mobile App Developer',
      url: 'https://www.twitter.com',
      startDate: '2016-07-01',
      endDate: '2018-02-28',
      summary: null,
      highlights: [
        'Implemented real-time notification features using WebSocket technology, resulting in a 40% increase in user engagement with notifications.',
        'Optimized app startup time by refactoring and optimizing code, reducing loading times by 20% and improving user experience.',
        'Participated in hackathons to develop and prototype new app features, fostering innovation and creativity within the development team.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Stanford University',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Stanford',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2014-09-01',
      endDate: '2016-05-31',
      gpa: '3.8',
      courses: [
        'Mobile Application Development',
        'User Interface Design',
        'Mobile Usability Testing',
      ],
      summary: '',
      present: false,
    },
    {
      institution: 'University of California, Berkeley',
      degree: 'Bachelor of Arts in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2010-08-01',
      endDate: '2014-05-15',
      gpa: '3.6',
      courses: [
        'Data Structures and Algorithms',
        'Mobile App Development',
        'Human-Computer Interaction',
      ],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Snap Excellence Award',
      date: '2023-05-10',
      awarder: 'Snap Inc.',
      summary:
        'Recognized for outstanding contributions to the development of a key mobile app feature.',
    },
    {
      title: 'Airbnb Innovation Award',
      date: '2020-07-20',
      awarder: 'Airbnb',
      summary:
        'Received for designing and implementing a successful and user-friendly booking flow.',
    },
  ],
  skills: [
    {
      skillGroup: 'Mobile Development',
      skill: [
        { name: 'Swift' },
        { name: 'Kotlin' },
        { name: 'React Native' },
        { name: 'iOS Development' },
        { name: 'Android Development' },
      ],
    },
    {
      skillGroup: 'UI/UX Design',
      skill: [
        { name: 'Sketch' },
        { name: 'Adobe XD' },
        { name: 'Prototyping' },
        { name: 'Wireframing' },
      ],
    },
    {
      skillGroup: 'Backend Development',
      skill: [
        { name: 'Node.js' },
        { name: 'RESTful APIs' },
        { name: 'Firebase' },
        { name: 'Serverless Architecture' },
      ],
    },
  ],
  projects: [
    {
      name: 'Travel Buddy App',
      description:
        'Developed a travel companion app that provides real-time travel recommendations, weather forecasts, and user reviews.',
      startDate: '2022-01-01',
      endDate: '2022-06-30',
      present: false,
    },
    {
      name: 'Fitness Tracker App',
      description:
        "Created a fitness tracking app that monitors users' exercise routines, tracks progress, and provides workout recommendations.",
      startDate: '2019-06-01',
      endDate: '2019-12-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Open Source Contributions',
      details: [
        {
          title: 'GitHub Repository: React Native UI Library',
          subtitle: 'Contributor',
          location: {
            city: 'Online',
            countryCode: 'US',
          },
          url: 'https://github.com/username/react-native-ui',
          startDate: '2021-03-01',
          endDate: '2022-08-31',
          summary:
            'Contributed reusable UI components and improvements to a popular open-source React Native UI library.',
          highlights: [
            'Designed and implemented a custom navigation bar component with dynamic theming support.',
            'Collaborated with a global community of developers to review and merge pull requests, ensuring code quality and compatibility.',
          ],
          present: false,
        },
      ],
    },
  ],
}
