const ContentLayout = ({ children, name, maxWidth = 'max-w-6xl' }) => {
  return (
    <div className="flex flex-col flex-1 bg-warmGray-50 overflow-y-scroll h-full px-4">
      <div
        className={`w-full flex-1 flex flex-col ${maxWidth} mx-auto py-4 sm:py-12`}
      >
        <div className="flex-none flex h-12 sm:h-16 gap-6 w-full items-center bg-warmGray-50 sm:mb-4">
          <div className="text-2xl sm:text-3xl font-semibold">{name}</div>
        </div>
        {children}
      </div>
    </div>
  )
}

export default ContentLayout
