import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { ArrowSmRightIcon, ColorSwatchIcon } from '@heroicons/react/outline'
import {
  AdjustmentsIcon,
  DocumentDuplicateIcon,
  SparklesIcon,
} from '@heroicons/react/solid'
import { hideModal } from 'actions/actions'
import axios from 'axios'
import Viewer from 'components/Viewer/Viewer'
import { classNames } from 'utils/helper'

function SelectPremiumTemplate({ loading, template, goToPremium }) {
  return (
    <div className="">
      <div className="">
        <div className="relative w-full justify-center items-center px-8 py-6">
          <div className="aspect-w-17 aspect-h-22">
            {template && (
              <img
                className="shadow-lg"
                alt="template"
                src={require(`assets/img/Template${template.idx}.png`)}
              />
            )}
          </div>
        </div>
        <div className="w-full px-8 pb-4">
          <div className="flex justify-between gap-4 items-center">
            <div className="flex-none">
              {template && (
                <h3 className="text-lg font-medium mb-1 flex flex-col sm:flex-row items-start sm:items-center gap-1 sm:gap-2">
                  {template?.name} Template
                  <span className="py-1 px-3 bg-yellow-200 rounded font-normal text-xs sm:text-sm">
                    Premium
                  </span>
                </h3>
              )}
            </div>
            <button
              onClick={goToPremium}
              disabled={loading}
              className="flex-none inline-flex items-center gap-1 cursor-pointer justify-center py-2 px-4 sm:py-3 sm:px-6 border border-transparent text-sm sm:text-base font-medium rounded-md shadow-lg text-white bg-indigo-600 hover:bg-indigo-700 group"
            >
              {loading ? (
                <div aria-label="Loading..." role="status">
                  <svg className="h-6 w-6 p-1 animate-spin" viewBox="3 3 18 18">
                    <path
                      className="fill-current text-white"
                      d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                    ></path>
                    <path
                      className="fill-current text-gray-400"
                      d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                    ></path>
                  </svg>
                </div>
              ) : (
                <>
                  Upgrade now
                  <ArrowSmRightIcon className="hidden sm:block transition-all duration-300 w-6 h-6 group-hover:translate-x-1" />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function DownloadPremiumResume({ loading, goToPremium, resume }) {
  const customSections = resume.customSections.length > 0
  const color = !!resume.color && resume.color !== '#000000'
  const template = resume.template > 1
  return (
    <div className="flex p-8 sm:p-12">
      <div className="w-1/2 hidden sm:block">
        <div className="aspect-w-17 aspect-h-22 shadow-lg">
          <Viewer resume={resume} />
        </div>
      </div>
      <div className="w-full sm:w-1/2 sm:pl-12 sm:pb-4 flex flex-col">
        <p className="text-lg md:text-2xl font-bold sm:mt-6 text-gray-800 text-left dark:text-gray-100">
          Upgrade your resume
        </p>
        <p className="mt-2 sm:mt-4 text-left text-gray-700 font-medium mb-6">
          Your resume is using premium features:
        </p>
        <div className="flex-1 flex flex-col gap-2 text-left">
          <div
            className={classNames(
              template ? 'border-blue-600 bg-blue-100' : 'border-transparent',
              'flex items-center rounded p-2 border'
            )}
          >
            <div className="w-12 h-12 bg-green-500 flex items-center justify-center rounded">
              <SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <div className="ml-4">
              <h2 className="text-sm font-bold">Premium Templates</h2>
              <p className="text-sm">Optimized for ATS</p>
            </div>
          </div>
          <div
            className={classNames(
              customSections
                ? 'border-blue-600 bg-blue-100'
                : 'border-transparent',
              'flex items-center rounded p-2 border'
            )}
          >
            <div className="w-12 h-12 bg-orange-500 flex items-center justify-center rounded">
              <AdjustmentsIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </div>
            <div className="ml-4">
              <h2 className="text-sm font-bold">Custom Sections</h2>
              <p className="text-sm">Easily tailor your resume</p>
            </div>
          </div>
          <div
            className={classNames(
              color ? 'border-blue-600 bg-blue-100' : 'border-transparent',
              'flex items-center rounded p-2 border'
            )}
          >
            <div className="w-12 h-12 bg-yellow-400 flex items-center justify-center rounded">
              <ColorSwatchIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </div>
            <div className="ml-4">
              <h2 className="text-sm font-bold">Resume Colors</h2>
              <p className="text-sm">Make your resume standout</p>
            </div>
          </div>
          <div className={classNames('flex items-center rounded p-2')}>
            <div className="w-12 h-12 bg-pink-400 flex items-center justify-center rounded">
              <DocumentDuplicateIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </div>
            <div className="ml-4">
              <h2 className="text-sm font-bold">Unlimited # of Resumes</h2>
              <p className="text-sm">Multiple resumes for different roles</p>
            </div>
          </div>
        </div>
        <div className="flex mt-6 justify-end gap-4 items-center">
          <button
            onClick={goToPremium}
            disabled={loading}
            className="flex-none inline-flex items-center gap-1 cursor-pointer justify-center py-2 px-4 sm:py-3 sm:px-6 border border-transparent text-base font-medium rounded-md shadow-lg text-white bg-indigo-600 hover:bg-indigo-700 group"
          >
            {loading ? (
              <div aria-label="Loading..." role="status">
                <svg className="h-6 w-6 p-1 animate-spin" viewBox="3 3 18 18">
                  <path
                    className="fill-current text-white"
                    d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                  ></path>
                  <path
                    className="fill-current text-gray-400"
                    d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                  ></path>
                </svg>
              </div>
            ) : (
              <>
                Download now
                <ArrowSmRightIcon className="transition-all duration-300 w-6 h-6 group-hover:translate-x-1" />
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

function DuplicateResume({ loading, goToPremium }) {
  return (
    <div className="flex">
      <div className="w-full flex flex-col">
        <div className="flex-1 grid grid-cols-2 sm:grid-cols-3 gap-4 px-8 py-8 bg-blue-500">
          <div className="aspect-w-17 aspect-h-22 shadow-xl">
            <img
              className=""
              alt="template"
              src={require(`assets/img/Template0.png`)}
            />
          </div>
          <div className="aspect-w-17 aspect-h-22 shadow-xl">
            <img
              className=""
              alt="template"
              src={require(`assets/img/Template1.png`)}
            />
          </div>
          <div className="hidden sm:block aspect-w-17 aspect-h-22 shadow-xl">
            <img
              className=""
              alt="template"
              src={require(`assets/img/Template2.png`)}
            />
          </div>
        </div>
        <div className="px-8 py-8 text-left">
          <p className="text-lg md:text-2xl font-bold text-gray-800">
            Need more resume slots? Upgrade to Premium
          </p>
          <p className="mt-2 sm:mt-4 text-gray-700 mb-2">
            <strong>Pro tip:</strong> Increase your interview chances by
            tailoring your resume to the role.
          </p>
          <p className="mb-6">
            Easily create multiple resumes for different roles or companies with{' '}
            <strong>Resumary Premium</strong>.
          </p>
          <div className="flex justify-end gap-4 items-center">
            <button
              onClick={goToPremium}
              disabled={loading}
              className="flex-none inline-flex items-center gap-1 cursor-pointer justify-center py-3 px-6 border border-transparent text-base font-medium rounded-md shadow-lg text-white bg-indigo-600 hover:bg-indigo-700 group"
            >
              {loading ? (
                <div aria-label="Loading..." role="status">
                  <svg className="h-6 w-6 p-1 animate-spin" viewBox="3 3 18 18">
                    <path
                      className="fill-current text-white"
                      d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                    ></path>
                    <path
                      className="fill-current text-gray-400"
                      d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                    ></path>
                  </svg>
                </div>
              ) : (
                <>
                  Upgrade now
                  <ArrowSmRightIcon className="transition-all duration-300 w-6 h-6 group-hover:translate-x-1" />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function PremiumContent({ type, ...props }) {
  const [stripeSessionId, setStripeSessionId] = useState(undefined)
  function goToPremium() {
    if (!stripeSessionId) {
      return
    }
    var stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC)
    stripe
      .redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: stripeSessionId,
      })
      .then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      })
  }
  useEffect(() => {
    const fetchStripeSession = () => {
      let cancel_path, success_path
      switch (type) {
        case 'template':
          cancel_path = '/dashboard/resumes/create'
          success_path = `/dashboard/resumes/create?template=${props.template.idx}`
          break
        case 'download':
          cancel_path = '/dashboard/resumes'
          success_path = '/dashboard/resumes?download=true'
          break
        case 'download_editor':
          cancel_path = `/dashboard/resumes/${props.resume.id}/edit`
          success_path = `/dashboard/resumes/${props.resume.id}/edit?download=true`
          break
        case 'duplicate':
          cancel_path = '/dashboard/resumes'
          success_path = '/dashboard/resumes?duplicate=true'
          break
        case 'create_new':
          cancel_path = '/dashboard/resumes'
          success_path = '/dashboard/resumes/create'
          break
        default:
          break
      }
      axios
        .get(
          `/payment/createSessionV2?success_path=${success_path}&cancel_path=${cancel_path}`,
          {}
        )
        .then((res) => {
          if (!res.data.isSubscribed) setStripeSessionId(res.data.session.id)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    fetchStripeSession()
  }, [])

  switch (type) {
    case 'template':
      return (
        <SelectPremiumTemplate
          loading={!stripeSessionId}
          template={props.template}
          goToPremium={goToPremium}
        />
      )
    case 'download':
    case 'download_editor':
      return (
        <DownloadPremiumResume
          loading={!stripeSessionId}
          resume={props.resume}
          goToPremium={goToPremium}
        />
      )
    case 'duplicate':
    case 'create_new':
      return (
        <DuplicateResume loading={!stripeSessionId} goToPremium={goToPremium} />
      )
    default:
      return null
  }
}

export default function PremiumDownloadModal() {
  const premiumDownloadModal = useSelector(
    (state) => state.modal.premiumDownloadModal
  )
  const isOpen = premiumDownloadModal.isOpen
  const dispatch = useDispatch()
  const width = premiumDownloadModal.props.width

  function closeModal() {
    dispatch(hideModal('premiumDownloadModal'))
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="px-4 py-4 sm:py-0 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden md:inline-block md:align-middle md:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={classNames(
                width ? width : 'max-w-xl',
                'inline-block overflow-hidden align-middle transition-all transform w-full bg-warmGray-50 rounded'
              )}
            >
              {/* {premiumDownloadModal.props.type} */}
              <PremiumContent
                type={premiumDownloadModal.props.type}
                {...premiumDownloadModal.props}
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
