export const resume = {
  basics: {
    firstName: 'Alex',
    lastName: 'Martinez',
    title: 'Full Stack Software Engineer',
    picture: 'https://example.com/alex-martinez.jpg',
    email: 'alex.martinez@example.com',
    phone: '123-456-7890',
    url: 'https://www.alexmartinez.com',
    summary:
      'Results-driven full stack software engineer with a passion for creating robust and user-friendly applications. Experienced in both frontend and backend development, with a focus on delivering high-quality, scalable, and performant solutions.',
    location: {
      city: 'Austin',
      countryCode: 'US',
      region: 'Texas',
    },
    linkedInUrl: 'https://www.linkedin.com/in/alexmartinez',
    portfolioUrl: 'https://portfolio.alexmartinez.com',
  },
  experience: [
    {
      company: 'Google',
      location: {
        city: 'Mountain View',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Full Stack Software Engineer',
      url: 'https://www.google.com',
      startDate: '2020-04-01',
      endDate: '2023-07-31',
      summary: null,
      highlights: [
        'Led the implementation of a real-time collaboration feature using WebSockets, resulting in a 40% increase in user engagement and productivity.',
        'Optimized backend APIs to reduce response times by 50%, enhancing overall application responsiveness and user experience.',
        'Collaborated with frontend teams to design and implement responsive and intuitive user interfaces, improving user satisfaction and reducing bounce rates.',
        'Introduced automated testing processes that increased code coverage to 90%, resulting in a 30% reduction in bug reports and improved software quality.',
      ],
      present: false,
    },
    {
      company: 'Uber Technologies',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Software Engineer',
      url: 'https://www.uber.com',
      startDate: '2018-06-15',
      endDate: '2020-03-31',
      summary: null,
      highlights: [
        'Designed and implemented a dynamic pricing algorithm that increased driver earnings by 15% during peak hours and improved passenger availability.',
        'Collaborated with backend teams to optimize database queries, reducing API response times by 40% and enhancing overall application performance.',
        'Received the Uber Innovator Award for developing a driver onboarding process that reduced onboarding time by 20%.',
      ],
      present: false,
    },
    {
      company: 'Amazon Web Services',
      location: {
        city: 'Seattle',
        countryCode: 'US',
        region: 'Washington',
      },
      position: 'Associate Software Engineer',
      url: 'https://aws.amazon.com',
      startDate: '2016-09-01',
      endDate: '2018-05-31',
      summary: null,
      highlights: [
        'Implemented a highly scalable and fault-tolerant user authentication service, supporting millions of requests daily with minimal downtime.',
        'Collaborated with frontend teams to integrate new features and enhancements, ensuring seamless user experiences across different services.',
        'Participated in regular code reviews, identifying and resolving performance bottlenecks and ensuring code quality and adherence to coding standards.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'University of Texas at Austin',
      degree: 'Master of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Austin',
        countryCode: 'US',
        region: 'Texas',
      },
      startDate: '2014-09-01',
      endDate: '2016-05-31',
      gpa: '3.7',
      courses: [
        'Web Application Development',
        'Database Systems',
        'Software Engineering',
      ],
      summary: '',
      present: false,
    },
    {
      institution: 'Texas A&M University',
      degree: 'Bachelor of Science in Computer Engineering',
      major: 'Computer Engineering',
      location: {
        city: 'College Station',
        countryCode: 'US',
        region: 'Texas',
      },
      startDate: '2010-08-01',
      endDate: '2014-05-15',
      gpa: '3.5',
      courses: [
        'Data Structures and Algorithms',
        'Digital Systems Design',
        'Operating Systems',
      ],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Google Impact Award',
      date: '2022-12-10',
      awarder: 'Google',
      summary:
        'Recognized for making a significant positive impact on a critical project through innovative software engineering solutions.',
    },
    {
      title: 'Uber Innovator Award',
      date: '2019-05-20',
      awarder: 'Uber Technologies',
      summary:
        'Received for developing a driver onboarding process that streamlined operations and improved driver engagement.',
    },
  ],
  skills: [
    {
      skillGroup: 'Frontend Technologies',
      skill: [
        { name: 'HTML5' },
        { name: 'CSS3' },
        { name: 'JavaScript' },
        { name: 'React' },
        { name: 'Redux' },
        { name: 'Webpack' },
      ],
    },
    {
      skillGroup: 'Backend Technologies',
      skill: [
        { name: 'Node.js' },
        { name: 'Express.js' },
        { name: 'Python' },
        { name: 'Java' },
        { name: 'RESTful APIs' },
        { name: 'Databases (SQL, NoSQL)' },
      ],
    },
    {
      skillGroup: 'DevOps',
      skill: [
        { name: 'Docker' },
        { name: 'AWS' },
        { name: 'CI/CD' },
        { name: 'Serverless Architecture' },
        { name: 'Kubernetes' },
      ],
    },
  ],
  projects: [
    {
      name: 'E-Commerce Platform',
      description:
        'Developed a full-stack e-commerce platform from scratch, including user authentication, product catalog, shopping cart, and order processing.',
      startDate: '2021-01-01',
      endDate: '2021-06-30',
      present: false,
    },
    {
      name: 'Real-Time Chat Application',
      description:
        'Built a real-time chat application using WebSockets and React, allowing users to communicate instantly with each other.',
      startDate: '2019-03-01',
      endDate: '2019-07-31',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Open Source Contributions',
      details: [
        {
          title: 'GitHub Repository: React UI Components Library',
          subtitle: 'Contributor',
          location: {
            city: 'Online',
            countryCode: 'US',
          },
          url: 'https://github.com/username/react-components',
          startDate: '2020-08-01',
          endDate: '2021-12-31',
          summary:
            'Contributed reusable UI components and improvements to a popular open-source React components library.',
          highlights: [
            'Implemented a responsive carousel component with touch support, enhancing user engagement and interaction.',
            'Collaborated with maintainers and developers to review and merge pull requests, ensuring code quality and adherence to guidelines.',
          ],
          present: false,
        },
      ],
    },
  ],
}
