import { ChevronRightIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

export default function Breadcrumbs(props) {
  const { links } = props
  return (
    <p className="text-gray-400 tracking-wide flex gap-1 text-xs font-semibold items-center justify-center">
      {links.map((link, index) => {
        if (index === links.length - 1) {
          return link.text
        }
        return (
          <>
            <Link to={link.to} className="hover:text-gray-600">
              {link.text}
            </Link>
            <ChevronRightIcon className="w-3 h-3 inline" />
          </>
        )
      })}
    </p>
  )
}
