import SafeText from '../../SafeText'
import {
  getDate,
  getGpa,
  joinFields,
  joinFieldsToArray,
} from '../../Utils/helper'
import EducationValueMapper from './EducationValueMapper'

class CityEducationValueMapper extends EducationValueMapper {
  title() {
    const { location } = this.education
    const locationText =
      location && joinFields(', ', location.city, location.region)
    return joinFieldsToArray(
      <SafeText val={true} style={this.styles.titleSecondary}>
        ,{' '}
      </SafeText>,
      this.education.institution,
      locationText && (
        <SafeText val={locationText} style={this.styles.titleSecondary}>
          {locationText}
        </SafeText>
      )
    )
  }

  subtitle() {
    const { startDate, endDate, present } = this.education
    const dateText = getDate(startDate, endDate, present)
    return dateText
  }

  text() {
    const { gpa, degree } = this.education
    const gpaText = getGpa(gpa)
    return joinFields(', ', degree, gpaText)
  }
}

export default CityEducationValueMapper
