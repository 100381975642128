export const resume = {
  basics: {
    firstName: 'Alex',
    lastName: 'Walker',
    title: 'Game Developer',
    picture: 'https://example.com/alex-walker.jpg',
    email: 'alex.walker@example.com',
    phone: '123-456-7890',
    url: 'https://www.alexwalker.games',
    summary:
      'Passionate game developer with a strong background in creating immersive and engaging gaming experiences. Experienced in all stages of game development, from concept and design to coding and testing. Committed to delivering high-quality games that captivate and entertain players.',
    location: {
      city: 'Los Angeles',
      countryCode: 'US',
      region: 'California',
    },
    linkedInUrl: 'https://www.linkedin.com/in/alexwalker',
    portfolioUrl: 'https://portfolio.alexwalker.games',
  },
  experience: [
    {
      company: 'Electronic Arts (EA)',
      location: {
        city: 'Los Angeles',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Senior Game Developer',
      url: 'https://www.ea.com',
      startDate: '2018-04-15',
      endDate: '2023-08-31',
      summary: null,
      highlights: [
        "Designed and implemented core gameplay mechanics for the hit game 'Galactic Odyssey,' resulting in a 20% increase in player engagement and positive user reviews.",
        'Led a cross-functional team of designers and artists in the creation of a new game level, achieving a seamless and visually stunning environment that contributed to a 15% increase in level completion rates.',
        'Optimized game performance by implementing efficient rendering techniques and reducing frame rate drops, resulting in a 30% improvement in overall game smoothness.',
      ],
      present: false,
    },
    {
      company: 'Ubisoft',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Gameplay Programmer',
      url: 'https://www.ubisoft.com',
      startDate: '2015-07-01',
      endDate: '2018-03-31',
      summary: null,
      highlights: [
        'Developed and maintained AI systems for enemy behavior and pathfinding, resulting in more dynamic and challenging combat scenarios.',
        'Implemented procedural content generation algorithms for in-game environments, contributing to a 40% reduction in development time for creating new game levels.',
        'Optimized memory usage and performance of game systems, resulting in a 25% reduction in loading times and improved overall game responsiveness.',
      ],
      present: false,
    },
    {
      company: 'Blizzard Entertainment',
      location: {
        city: 'Irvine',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Game Programmer',
      url: 'https://www.blizzard.com',
      startDate: '2013-01-15',
      endDate: '2015-06-30',
      summary: null,
      highlights: [
        'Collaborated with the design team to balance game economy and progression systems, resulting in a 15% increase in player retention and monetization.',
        'Designed and programmed interactive user interfaces that enhanced player accessibility and ease of use, leading to a 20% reduction in player frustration and drop-off rates.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'University of Southern California',
      degree: 'Master of Fine Arts in Interactive Media and Game Design',
      major: 'Game Design',
      location: {
        city: 'Los Angeles',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2011-08-15',
      endDate: '2013-05-31',
      gpa: '3.8',
      courses: [
        'Game Mechanics and Systems',
        'Level Design',
        '3D Modeling and Animation',
      ],
      summary: '',
      present: false,
    },
    {
      institution: 'University of California, Santa Cruz',
      degree: 'Bachelor of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Santa Cruz',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2007-09-01',
      endDate: '2011-06-30',
      gpa: '3.6',
      courses: [
        'Game Development',
        'Algorithms and Data Structures',
        'Graphics and Visualization',
      ],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Outstanding Achievement in Game Design',
      date: '2013-05-31',
      awarder: 'University of Southern California',
      summary:
        'Recognized for exceptional creativity and innovation in game design projects.',
    },
  ],
  skills: [
    {
      skillGroup: 'Programming Languages',
      skill: [{ name: 'C++' }, { name: 'C#' }, { name: 'Python' }],
    },
    {
      skillGroup: 'Game Engines',
      skill: [{ name: 'Unity' }, { name: 'Unreal Engine' }],
    },
    {
      skillGroup: 'Game Development',
      skill: [
        { name: 'Gameplay Programming' },
        { name: 'AI Programming' },
        { name: 'Multiplayer Networking' },
      ],
    },
  ],
  projects: [
    {
      name: 'Epic Quest: The Realm of Legends',
      description:
        'Designed and developed a fantasy RPG with intricate quests, challenging combat, and a captivating storyline.',
      startDate: '2021-01-01',
      endDate: '2023-08-31',
      present: false,
    },
    {
      name: 'Galactic Invasion',
      description:
        'Led a small team in creating a space-themed shooter game featuring fast-paced action and innovative power-ups.',
      startDate: '2017-03-01',
      endDate: '2017-10-31',
      present: false,
    },
    {
      name: 'Mystic Adventure',
      description:
        'Developed a mobile puzzle game that challenges players to solve intricate puzzles in a visually stunning magical world.',
      startDate: '2014-08-01',
      endDate: '2015-04-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Game Jams',
      details: [
        {
          title: 'Global Game Jam 2022',
          subtitle: 'Team Lead',
          location: {
            city: 'Los Angeles',
            countryCode: 'US',
            region: 'California',
          },
          url: 'https://globalgamejam.org/2022/games/...',
          startDate: '2022-01-28',
          endDate: '2022-01-30',
          summary:
            'Led a team of five developers to create a unique game prototype within 48 hours, receiving positive feedback from players and judges.',
          highlights: [
            'Designed and implemented core gameplay mechanics that centered around a novel time-manipulation concept.',
            'Collaborated with artists and sound designers to create immersive visuals and audio effects that enhanced player experience.',
          ],
          present: false,
        },
      ],
    },
  ],
}
