import DemoViewer from 'components/Viewer/DemoViewer'
import CTASection from 'marketing/components/CTASection'
import CompaniesSection from 'marketing/components/CompaniesSection'
import ExamplesList from 'marketing/components/sections/examples/ExamplesList'
import ResumeExamplesMap from 'marketing/resources/ResumeExamplesMap'
import { Navigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'

const ResumeExample = () => {
  const params = useParams()
  const category = params.category
  const slug = params.slug
  const example = ResumeExamplesMap[category].items[slug]
  console.log(example)
  if (!example) {
    return <Navigate to="/resume-examples" />
  }
  return (
    <div>
      <div className="relative">
        <div className="max-w-3xl mx-auto px-4">
          <div className="pt-20">
            <h1 className="text-5xl font-bold text-gray-900 leading-tight mb-4">
              {example.name} Resume Example
            </h1>
            <p className="text-lg font-medium text-gray-900 mb-6">
              Create a polished {example.name} resume in minutes using
              successful examples from industry professionals. Try it now!
            </p>
            <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-1">
              <span className="text-sm text-gray-700">
                Written by <span className="font-bold">Andrew Feng</span>
              </span>
              <span className="hidden sm:inline-block">•</span>
              <span className="text-sm text-gray-700">
                Last updated on August 2, 2023
              </span>
            </div>
          </div>
        </div>
        <div className="px-4 max-w-5xl mx-auto py-16">
          <div className="relative">
            <div className="absolute w-full h-full top-1 left-1 border border-gray-900 bg-gray-900 rounded-xl" />
            <div className="relative bg-blueGray-100 py-8 px-6 sm:px-0 sm:py-16 rounded-xl border border-gray-900">
              <div className="relative mx-auto sm:w-2/3 rounded-lg shadow-xl bg-blueGray-100">
                <DemoViewer resumeObj={example.resume} />
              </div>
            </div>
          </div>

          <div className="flex justify-center mt-8">
            <Link to="/auth/register">
              <div className="w-full py-3 px-8 text-lg bg-blue-500 rounded text-white">
                Create your resume
              </div>
            </Link>
          </div>
        </div>
        <CompaniesSection />
        <CTASection />
        <ExamplesList />
      </div>
    </div>
  )
}

export default ResumeExample
