export const resume = {
  basics: {
    firstName: 'Alex',
    lastName: 'Johnson',
    title: 'Machine Learning Engineer',
    picture: 'https://example.com/alex-johnson.jpg',
    email: 'alex.johnson@example.com',
    phone: '123-456-7890',
    url: 'https://www.alexjohnson.ml',
    summary:
      'Passionate Machine Learning Engineer with a solid background in developing and deploying machine learning models to solve complex problems. Experienced in working with large datasets and implementing cutting-edge algorithms. Adept at translating business requirements into technical solutions and driving projects from inception to deployment.',
    location: {
      city: 'New York',
      countryCode: 'US',
      region: 'New York',
    },
    linkedInUrl: 'https://www.linkedin.com/in/alexjohnson',
    portfolioUrl: 'https://portfolio.alexjohnson.ml',
  },
  experience: [
    {
      company: 'Amazon Web Services',
      location: {
        city: 'New York',
        countryCode: 'US',
        region: 'New York',
      },
      position: 'Machine Learning Engineer',
      url: 'https://aws.amazon.com',
      startDate: '2019-06-01',
      endDate: '2023-08-31',
      summary: null,
      highlights: [
        'Led a team of engineers in designing and implementing a recommendation engine for personalized content delivery, resulting in a 25% increase in user engagement.',
        'Developed a real-time anomaly detection system for monitoring AWS infrastructure, reducing downtime by 20% and saving $1M annually in operational costs.',
        'Collaborated with cross-functional teams to integrate machine learning capabilities into Amazon S3, resulting in a 30% improvement in data access speeds for users.',
        'Optimized machine learning pipelines for AWS SageMaker, achieving a 40% reduction in training time and enabling faster model iteration.',
      ],
      present: false,
    },
    {
      company: 'IBM Watson',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
        region: 'California',
      },
      position: 'Data Scientist',
      url: 'https://www.ibm.com/watson',
      startDate: '2017-02-15',
      endDate: '2019-05-31',
      summary: null,
      highlights: [
        'Developed a natural language processing model for sentiment analysis, achieving 85% accuracy and improving the accuracy of customer feedback analysis.',
        'Collaborated with domain experts to design and implement machine learning solutions for healthcare applications, resulting in improved disease prediction accuracy by 20%.',
      ],
      present: false,
    },
    {
      company: 'Accenture',
      location: {
        city: 'Chicago',
        countryCode: 'US',
        region: 'Illinois',
      },
      position: 'Data Analyst',
      url: 'https://www.accenture.com',
      startDate: '2015-07-01',
      endDate: '2017-01-31',
      summary: null,
      highlights: [
        'Developed data pipelines and ETL processes to clean, transform, and integrate diverse datasets, reducing data preparation time by 40%.',
        'Designed interactive dashboards using Tableau to visualize key performance metrics, leading to more informed business strategies for clients.',
      ],
      present: false,
    },
  ],
  education: [
    {
      institution: 'Carnegie Mellon University',
      degree: 'Master of Science in Machine Learning',
      major: 'Machine Learning',
      location: {
        city: 'Pittsburgh',
        countryCode: 'US',
        region: 'Pennsylvania',
      },
      startDate: '2013-08-15',
      endDate: '2015-05-31',
      gpa: '3.8',
      courses: [
        'Deep Learning',
        'Reinforcement Learning',
        'Statistical Learning',
      ],
      summary: '',
      present: false,
    },
    {
      institution: 'University of California, Berkeley',
      degree: 'Bachelor of Science in Computer Science',
      major: 'Computer Science',
      location: {
        city: 'Berkeley',
        countryCode: 'US',
        region: 'California',
      },
      startDate: '2009-08-15',
      endDate: '2013-05-31',
      gpa: '3.7',
      courses: [
        'Machine Learning',
        'Artificial Intelligence',
        'Data Structures',
      ],
      summary: '',
      present: false,
    },
  ],
  awards: [
    {
      title: 'Outstanding Machine Learning Project',
      date: '2022-02-15',
      awarder: 'AI World Conference',
      summary:
        'Recognized for the development of an innovative machine learning project that demonstrated significant impact and technical excellence.',
    },
  ],
  skills: [
    {
      skillGroup: 'Machine Learning',
      skill: [
        { name: 'Deep Learning' },
        { name: 'Natural Language Processing' },
        { name: 'Reinforcement Learning' },
        { name: 'Computer Vision' },
      ],
    },
    {
      skillGroup: 'Programming Languages',
      skill: [
        { name: 'Python' },
        { name: 'Java' },
        { name: 'C++' },
        { name: 'R' },
      ],
    },
    {
      skillGroup: 'Tools and Libraries',
      skill: [
        { name: 'TensorFlow' },
        { name: 'PyTorch' },
        { name: 'Scikit-learn' },
        { name: 'Pandas' },
        { name: 'NumPy' },
      ],
    },
  ],
  projects: [
    {
      name: 'HealthAI',
      description:
        'Developed a machine learning model to predict disease progression based on patient health records, assisting doctors in early intervention and treatment planning.',
      startDate: '2022-03-01',
      endDate: '2022-07-31',
      present: false,
    },
    {
      name: 'Autonomous Drone Navigation',
      description:
        'Designed and implemented a reinforcement learning algorithm for autonomous drone navigation, enabling drones to navigate complex environments.',
      startDate: '2021-01-15',
      endDate: '2021-06-30',
      present: false,
    },
    {
      name: 'Stock Price Prediction',
      description:
        'Built a time-series forecasting model using deep learning techniques to predict stock prices, achieving a prediction accuracy of 85%.',
      startDate: '2020-06-01',
      endDate: '2020-09-30',
      present: false,
    },
  ],
  customSections: [
    {
      name: 'Technical Publications',
      details: [
        {
          title: 'Advancements in Deep Reinforcement Learning',
          subtitle: 'Machine Learning Journal',
          location: {
            city: 'San Francisco',
            countryCode: 'US',
            region: 'California',
          },
          url: 'https://www.mljournal.com/issues/...',
          startDate: '2022-05-15',
          endDate: '2022-05-31',
          summary:
            'Authored a research paper on recent advancements in deep reinforcement learning algorithms and their applications.',
          highlights: [
            'Reviewed state-of-the-art deep reinforcement learning techniques and their impact on various industries.',
            'Discussed challenges and potential directions for future research in the field of reinforcement learning.',
            'Presented case studies showcasing the successful implementation of deep reinforcement learning in real-world scenarios.',
          ],
          present: false,
        },
      ],
    },
  ],
}
